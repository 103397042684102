import { MixpanelEvents } from '@/shared/thirdParty/mixpanel';
import GlossaryPlaceholder from '@/shared/assets/images/glossary.png';
import { LimitedFeatureContent, ModalContent } from '../types';
import { BUTTON_TEXTS, BULLETS_HEADER_TEXT } from './texts';
import { getBullets } from './bullets';

const BASE_CONTENT: ModalContent = {
  title: 'Unlock Translation dictionary',
  subtitle: 'Upgrade to Business to create Translation Dictionary',
  bulletsHeader: BULLETS_HEADER_TEXT.withBusiness,
  features: getBullets([
    'translate500',
    'lipSync',
    'voiceCloneSettings',
    'voicePresets',
    'glossary',
    'srtUploadDownload',
    'subtitles',
    'aiRewrite',
    'simultaneousMultiLanguageTranslation',
    'api',
    'noWatermark',
  ]),
  media: {
    type: 'image',
    src: GlossaryPlaceholder,
  },
  //TODO: Add mixpanel event
  mixpanelOpeningEvent: MixpanelEvents.upgradeDictionaryModalShown,
  mixpanelButtonClickEvent: MixpanelEvents.UpgradeDictionaryChoosePlanModalClick,
  buttonText: BUTTON_TEXTS.choosePlan,
};

const TRIAL_PLAN_CONTENT = {
  ...BASE_CONTENT,
};

const STARTER_PLAN_CONTENT = {
  ...BASE_CONTENT,
  bulletsHeader: BULLETS_HEADER_TEXT.inStarter,
};

const CREATOR_PLAN_CONTENT = {
  ...BASE_CONTENT,
  bulletsHeader: BULLETS_HEADER_TEXT.inCreator,
  features: getBullets([
    'translate500',
    'lipSync',
    'voiceCloneSettings',
    'voicePresets',
    'glossary',
    'srtUploadDownload',
    'subtitles',
    'aiRewrite',
    'simultaneousMultiLanguageTranslation',
    'api',
  ]),
};

const CREATOR_PRO_PLAN_CONTENT = {
  ...CREATOR_PLAN_CONTENT,
  bulletsHeader: BULLETS_HEADER_TEXT.inCreatorPro,
  features: getBullets(['translate500', 'glossary', 'voicePresets10', 'simultaneousMultiLanguageTranslation', 'api']),
};

export const GLOSSARY_CONTENT: LimitedFeatureContent = {
  trial: TRIAL_PLAN_CONTENT,
  starter: STARTER_PLAN_CONTENT,
  creator: CREATOR_PLAN_CONTENT,
  creatorPro: CREATOR_PRO_PLAN_CONTENT,
};
