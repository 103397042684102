export const removePortFromUrl = (rawUrl?: string) => {
  if (!rawUrl) {
    return '';
  }

  const url = new URL(rawUrl);

  url.port = '';

  return url.origin;
};
