import { BASE_URL } from '../constants';
import { post } from '../../lib/api';
import { PostSurveyRequestData } from './types';

const URL = `${BASE_URL}/api/survey`;
export const SURVEY_API = {
  postSurvey: <T>(data: PostSurveyRequestData<T>) =>
    post<{
      ok: boolean;
    }>(`${URL}/add`, { data }),
  rejectSurvey: (data: { id: string; name: string }) =>
    post<{
      ok: boolean;
    }>(`${URL}/reject`, { data }),
};
