import { Autocomplete as MuiAutocomplete, Paper as MuiPaper, styled, TextField } from '@mui/material';
import { ChevronUpIcon } from '@braskai/icons';

type TChevronIconProps = {
  disabled: boolean | undefined;
};

export const ChevronIcon = styled(ChevronUpIcon)<TChevronIconProps>(({ disabled, theme }) => ({
  width: 16,
  height: 16,
  transform: 'rotate(180deg)',
  opacity: disabled ? 0.5 : 1,
  transition: 'opacity 100ms linear',
  color: theme.palette.grey[500],
}));

export const AutocompleteTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    height: 'auto',

    '&:hover:not(.Mui-disabled, .Mui-error) fieldset': {
      border: `1px solid ${theme.palette.grey[300]}`,
    },

    '&.Mui-disabled fieldset': {
      border: `1px solid ${theme.palette.grey[300]}`,
    },
  },
}));

export const Autocomplete = styled(MuiAutocomplete)(({ theme }) => ({
  '& .MuiFilledInput-root': {
    background: 'transparent',
    padding: 0,
    alignItems: 'baseline',

    '& .MuiFilledInput-input': {
      padding: '8px 14px 7px',
      color: theme.palette.grey[900],
      '&::placeholder': { color: theme.palette.grey[500], ...theme.typography.textMdRegular },
    },

    '&:hover': {
      background: 'transparent',
    },

    '&.Mui-focused': {
      background: 'transparent',
    },

    '& .MuiInputAdornment-root ': {
      paddingLeft: 14,
    },
    '& .MuiInputBase-inputAdornedStart': {
      paddingLeft: 0,
    },

    '& .MuiIconButton-root': {
      background: 'transparent',
      padding: 0,
    },

    '&.MuiInputBase-adornedEnd': {
      padding: '0 16px 0 0',
    },
  },

  '& .MuiOutlinedInput-root': {
    padding: 0,
    background: theme.palette.common.white,
    boxShadow: `0px 0px 0px 4px transparent`,
    '& fieldset': {
      borderRadius: 8,

      border: `1px solid ${theme.palette.grey[300]}`,
    },

    '&.Mui-focused fieldset': {
      border: `1px solid ${theme.palette.primary[300]}`,
      boxShadow: `0px 0px 0px 4px ${theme.palette.primary[100]}`,
    },

    '& .MuiOutlinedInput-input': {
      padding: '10px 14px',
      color: theme.palette.grey[900],
      '&::placeholder': { color: theme.palette.grey[500], ...theme.typography.textMdRegular },
    },

    '& .MuiInputAdornment-root ': {
      paddingLeft: 14,
    },

    '& .MuiInputBase-inputAdornedStart': {
      paddingLeft: 0,
    },
  },

  '& .MuiAutocomplete-endAdornment ': {
    '& .MuiAutocomplete-clearIndicator .MuiSvgIcon-root': {
      fontSize: 16,
    },
  },

  '& .Mui-error .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${theme.palette.error[300]}`,
  },

  '.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${theme.palette.error[300]}`,
    boxShadow: `0px 0px 0px 4px ${theme.palette.error[100]}`,
  },

  '&:hover .Mui-error .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${theme.palette.error[300]}`,
  },

  '& .MuiFormHelperText-root': {
    ...theme.typography.textSmRegular,
    margin: '6px 0 0',
    color: theme.palette.grey[500],
    '&.Mui-error': {
      color: theme.palette.error[500],
    },
  },
})) as typeof MuiAutocomplete;

export const Paper = styled(MuiPaper)(({ theme }) => ({
  background: theme.palette.common.white,
  boxShadow: theme.customShadows.lg,
  border: `1px solid ${theme.palette.grey[100]}`,
  borderRadius: 8,
  marginTop: 4,
  ...theme.typography.textMdRegular,
  color: theme.palette.grey[900],

  '& .MuiAutocomplete-listbox': {
    maxHeight: 300,

    '& .MuiAutocomplete-option.Mui-focused': {
      backgroundColor: theme.palette.grey[50],
    },

    '& .MuiAutocomplete-option[aria-selected="true"]': {
      backgroundColor: theme.palette.primary[50],
      justifyContent: 'space-between',
    },
  },

  '& .MuiAutocomplete-option': {
    padding: '10px 14px',

    '&:hover': {
      backgroundColor: theme.palette.grey[50],
    },
  },
}));
