import { TServerSegment } from '@/shared/types/Editor';
import { ProjectVideoFeedback } from '../../types/VideoFeedback';
import { Voice } from '../../types/Voice';
import { MediaType, Project } from '../../types/Project';
import { del, get, patch, post } from '../../lib/api';
import { BASE_URL } from '../constants';
import {
  GetLipSyncInfoResponse,
  CopyProjectRequestData,
  RunCheckFaceTaskResponse,
  RunLipSyncTaskResponse,
  AddSegmentRequestData,
  AddSegmentsResponse,
  BurningSubtitles,
  CreateTranscriptRequestData,
  DeleteSegmentResponse,
  MergeSegmentsRequestData,
  UpdateSegmentsRequestData,
  UpdateSegmentsResponse,
  UpdateProjectRequestData,
  RunLipSyncTask,
  ApplyGlossaryToProjectRequestData,
} from './types';
import { adaptProject } from './adapters/adaptProject';
import { adaptLipSyncInfo } from './adapters/adaptLipSyncInfo';

const URL = `${BASE_URL}/api/project`;
export const PROJECT_API = {
  // Get a project by id
  getProjectById: (id: Project['id']) => get<Project>(`${URL}/${id}`, { adapter: adaptProject }),

  // Create transcript by uploading SRT
  createTranscript: (data?: CreateTranscriptRequestData) =>
    post<{
      transcript_id: Project['transcript_id'];
    }>(`${URL}/create_transcript`, { data }),

  // Update or delete project
  updateProject: ({ id, ...rest }: UpdateProjectRequestData) =>
    patch<Project>(`${URL}/${id}`, { data: rest, adapter: adaptProject }),

  deleteProject: (id: Project['id']) => del(`${URL}/${id}`),

  // Dub video
  generateVideo: (id: Project['id']) => post<Project>(`${URL}/${id}/generate`, { adapter: adaptProject }),

  // Leave feedback for dubbed video
  postFeedback: ({ id, ...rest }: ProjectVideoFeedback) =>
    post<{
      ok: boolean;
    }>(`${URL}/${id}/feedback`, { data: rest }),

  // Send video download stats
  sendMediaDownloadStats: (id: Project['id'], mediaType: MediaType) =>
    post(`${URL}/${id}/media_download`, { data: { media_type: mediaType } }),

  // Get available voices
  getVoicePool: (id: Project['id']) => get<Voice[]>(`${URL}/${id}/voice_pool`),

  // Editor requests
  addSegments: (projectId: Project['id'], payload: AddSegmentRequestData[]) =>
    post<AddSegmentsResponse>(`${URL}/${projectId}/transcript`, { data: { segments: payload } }),

  getSegments: (id: Project['id'], segmentsIds?: TServerSegment['id'][]) =>
    get<{
      segments: TServerSegment[];
    }>(`${URL}/${id}/transcript${segmentsIds ? `?segments=${segmentsIds.join(',')}` : ''}`),

  updateSegments: (projectId: Project['id'], data: UpdateSegmentsRequestData[]) =>
    patch<UpdateSegmentsResponse>(`${URL}/${projectId}/transcript`, { data: { segments: data } }),

  mergeSegments: (projectId: Project['id'], data: MergeSegmentsRequestData) =>
    post<UpdateSegmentsResponse>(`${URL}/${projectId}/transcript/merge`, { data }),

  deleteSegment: (projectId: Project['id'], segmentId: TServerSegment['id']) =>
    del<DeleteSegmentResponse>(`${URL}/${projectId}/transcript/${segmentId}`),

  // Copy project (used for translating project to another language)
  copyProject: ({ id, dst_lang, free_dubbing }: CopyProjectRequestData) =>
    post<Project>(`${URL}/${id}/copy`, { data: { dst_lang, free_dubbing }, adapter: adaptProject }),

  // Lip-sync requests
  runLipSyncTask: ({ projectId, isMultiSpeakers, isFreeLipSync }: RunLipSyncTask) =>
    post<RunLipSyncTaskResponse>(`${URL}/${projectId}/run_lipsync_task`, {
      data: { is_multiple_speakers: isMultiSpeakers, is_free_lipsync: isFreeLipSync },
    }),

  runCheckFaceTask: (projectId: Project['id']) =>
    post<RunCheckFaceTaskResponse>(`${URL}/${projectId}/run_check_face_task`),

  getLipSyncInfo: (projectId: Project['id']) =>
    get<GetLipSyncInfoResponse>(`${URL}/${projectId}/get_lipsync_info`, { adapter: adaptLipSyncInfo }),

  // Burn subtitles
  burnSubtitles: ({ id, videoType = 'translated' }: BurningSubtitles) =>
    post<{ ok: boolean }>(`${URL}/${id}/burn_subtitles?video_type=${videoType}`),

  // Hide subtitles tooltip
  hideSubtitlesTooltip: ({ id, videoType = 'translated' }: BurningSubtitles) =>
    post<Project>(`${URL}/${id}/set_subtitles_banner_displayed?video_type=${videoType}`, {
      adapter: adaptProject,
    }),

  // apply glossary to project
  applyGlossaryToProject: ({ projectId, glossaryId }: ApplyGlossaryToProjectRequestData) =>
    patch(`${URL}/${projectId}/glossary${glossaryId ? `?glossary_id=${glossaryId}` : ''}`),
};
