import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Voice } from '@/shared/types/Voice';

const initialState: Voice[] = [];
const voicesSlice = createSlice({
  name: 'voices',
  initialState,
  reducers: {
    setVoices(state, { payload }: PayloadAction<Voice[]>) {
      return payload;
    },
  },
});
export const voicesReducer = voicesSlice.reducer;
export const { setVoices } = voicesSlice.actions;
