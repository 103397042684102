import { useEffect, useRef } from 'react';

export const useExecuteOneTime = (callback: () => void | Promise<void>) => {
  const ignore = useRef(false);

  useEffect(() => {
    if (!ignore.current) {
      void callback();
    }

    return () => {
      ignore.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
