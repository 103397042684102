import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSubscription } from '@/shared/hooks-tmp/useSubscription';
import { useAppDispatch } from '@/shared/hooks-tmp/reduxToolkitHooks';
import { Customer } from '@/shared/types/Admin';
import { updateCustomer } from '@/shared/store-tmp/admin/thunks/customers';
import { showErrorAlert } from '@/shared/utils-tmp/alert/showErrorAlert';
import { Dialog, DialogTitle } from '../../../ui-tmp';
import { CancelButton, Content, UpdateButton, Subtitle, Title, Actions } from './styled';
import { FormValues } from './types';
import { VideoCredits } from './components/VideoCredits';
import { MinutesCredits } from './components/MinutesCredits/MinutesCredits';

type SetupUserModalProps = {
  onClose: () => void;
  customer?: Customer;
};

export const SetupCustomerCredits = ({ onClose, customer = {} as Customer }: SetupUserModalProps) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const {
    credits: { minutes, video },
  } = customer;

  const { hasTrialPlan } = useSubscription(customer);

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      credits: {
        minutes: {
          total: minutes.total,
          used: minutes.used,
        },
        video: {
          total: video.total,
          used: video.used,
        },
      },
    },
  });

  const onSubmit = async (input: FormValues) => {
    try {
      setIsLoading(true);

      const data = {
        customerId: customer.id,
        ...input,
      };

      await dispatch(updateCustomer(data));

      onClose();
    } catch (e) {
      showErrorAlert(e);
    }
    setIsLoading(false);
  };

  return (
    <Dialog open onClose={isLoading ? undefined : onClose} size="sm">
      <DialogTitle
        onClose={isLoading ? undefined : onClose}
        renderTitle={() => <Title>Set up credits</Title>}
        renderSubtitle={() => <Subtitle>{customer.email}</Subtitle>}
      />

      <Content>
        {hasTrialPlan ? <VideoCredits control={control} disabled={isLoading} /> : null}
        <MinutesCredits control={control} disabled={isLoading} />
      </Content>

      <Actions>
        <CancelButton onClick={onClose} disabled={isLoading}>
          Cancel
        </CancelButton>

        <UpdateButton onClick={handleSubmit(onSubmit)} isLoading={isLoading} disabled={isLoading || !isDirty}>
          Update credits
        </UpdateButton>
      </Actions>
    </Dialog>
  );
};
