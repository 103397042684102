import { Stack } from '@mui/material';
import { useLocation, Link } from 'react-router-dom';
import { Divider } from '@braskai/ui-kit';
import { IS_WHITE_LABEL } from '@/shared/lib/environments';
import { useFeatureFlags } from '@/shared/hooks-tmp/useFeatureFlags';
import { PATHS } from '@/shared/routes/paths';
import { inIframe } from '@/shared/lib/utils';
import RaskLogo from '@/shared/assets/images/rask.svg';
import { getNavOptions, getHelpNavOptions } from '../../lib/getNavOptions';
import { ProfileItem } from '../ProfileItem';
import { NavItem } from '../NavItem';
import { DATA_TEST_IDS } from '../../lib/constants';
import { SLinkContainer, SList, SWhiteLabelLogo } from './styled';

export const DesktopNav = () => {
  const { pathname } = useLocation();

  const { featureVisible: isVoicePresetsFeatureVisible } = useFeatureFlags('voice_presets');
  const { featureVisible: isGlossaryFeatureVisible } = useFeatureFlags('glossary');

  const navOptions = getNavOptions({
    isVoicePresetsFeatureVisible,
    isGlossaryFeatureVisible,
  });
  const helpNavOptions = getHelpNavOptions({ isWhiteLabel: IS_WHITE_LABEL });

  return (
    <Stack flexGrow={1} component="nav" aria-label="navigation sidebar">
      <SLinkContainer>
        {IS_WHITE_LABEL ? (
          <Link to="/">
            <SWhiteLabelLogo variant="caption-1-md">Video Translation Tool</SWhiteLabelLogo>
          </Link>
        ) : (
          <Link to={inIframe() ? '/' : 'https://www.rask.ai/'} target={inIframe() ? undefined : '_blank'}>
            <img data-test-id={DATA_TEST_IDS.logoRask} src={RaskLogo} alt="Rask" width="140" height="24" />
          </Link>
        )}
      </SLinkContainer>

      <SList $flexGrow={1}>
        {navOptions.map(({ title, path, icon, action }) => (
          <NavItem key={title} title={title} path={path} icon={icon} selected={pathname === path} onClick={action} />
        ))}
      </SList>

      <SList>
        {helpNavOptions.map(({ title, icon, action, dataTestId }) => (
          <NavItem data-test-id={dataTestId} key={title} title={title} icon={icon} onClick={action} />
        ))}
      </SList>

      <Divider />

      <ProfileItem active={pathname === PATHS.account} />
    </Stack>
  );
};
