const DEV = {
  baseUrl: 'https://d5hs.rask.ai',
  apiUrl: '',
  greenApiUrl: '',
  auth: {
    region: 'us-east-2',
    userPoolId: 'us-east-2_BQw8CV2BX',
    userPoolWebClientId: '5g1ahb3ml1v0v7ra041a24ol5f',
  },
  oauth: {
    domain: 'dev-rask.auth.us-east-2.amazoncognito.com',
    clientId: '5g1ahb3ml1v0v7ra041a24ol5f',
  },
};

const STAGE = {
  baseUrl: 'https://stage.rask.ai',
  apiUrl: 'us-west-2_cTUc2QCj0',
  greenApiUrl: '7q7rlsnqoq410jmq76vr46j5j0',
  auth: {
    region: 'us-east-2',
    userPoolId: 'us-east-2_4jfx5hSM0',
    userPoolWebClientId: '5a0q6q06nm77ckcvhi3tbrqcdj',
  },
  oauth: {
    domain: 'rask-dev.auth.us-east-2.amazoncognito.com',
    clientId: '5a0q6q06nm77ckcvhi3tbrqcdj',
  },
};

const STG = {
  baseUrl: 'https://app.stg.rask.ai',
  apiUrl: 'https://rst.stg.rask.ai',
  greenApiUrl: 'https://green-rst.stg.rask.ai',
  auth: {
    region: 'us-west-2',
    userPoolId: 'us-west-2_cTUc2QCj0',
    userPoolWebClientId: '7q7rlsnqoq410jmq76vr46j5j0',
  },
  oauth: {
    domain: 'rask-stg.auth.us-west-2.amazoncognito.com',
    clientId: '7q7rlsnqoq410jmq76vr46j5j0',
  },
};

const PROD = {
  baseUrl: 'https://app.rask.ai',
  apiUrl: 'https://rst.rask.ai',
  greenApiUrl: 'https://green-rst.rask.ai',
  auth: {
    region: 'us-east-2',
    userPoolId: 'us-east-2_39TxAbWNH',
    userPoolWebClientId: '3l205dqshftlhc51jbgppfe68n',
  },
  oauth: {
    domain: 'rask-prod.auth.us-east-2.amazoncognito.com',
    clientId: '3l205dqshftlhc51jbgppfe68n',
  },
};

const PROD_WHITE = {
  baseUrl: 'https://app.videotranslationtool.com',
  apiUrl: 'https://rst.videotranslationtool.com',
  greenApiUrl: 'https://green-rst.videotranslationtool.com',
  auth: {
    region: 'us-east-2',
    userPoolId: 'us-east-2_39TxAbWNH',
    userPoolWebClientId: '3l205dqshftlhc51jbgppfe68n',
  },
  oauth: {
    domain: 'rask-prod.auth.us-east-2.amazoncognito.com',
    clientId: '3l205dqshftlhc51jbgppfe68n',
  },
};

const QA = {
  baseUrl: 'https://app.qa.rask.ai',
  apiUrl: 'https://rst.qa.rask.ai',
  greenApiUrl: 'https://green-rst.qa.rask.ai',
  auth: {
    region: 'us-east-2',
    userPoolId: 'us-east-2_eQDWuM1cu',
    userPoolWebClientId: '356jh72oltgshmuu7m2rkgqa9v',
  },
  oauth: {
    domain: 'rask-qa.auth.us-east-2.amazoncognito.com',
    clientId: '356jh72oltgshmuu7m2rkgqa9v',
  },
};

const TST = {
  baseUrl: 'https://app.tst.rask.ai',
  apiUrl: 'https://rst.tst.rask.ai',
  greenApiUrl: 'https://green-rst.tst.rask.ai',
  auth: {
    region: 'us-east-2',
    userPoolId: 'us-east-2_mmFYFgkAs',
    userPoolWebClientId: 'o4quqk39pikfju8o44g50nutb',
  },
  oauth: {
    domain: 'rask-tst.auth.us-east-2.amazoncognito.com',
    clientId: 'o4quqk39pikfju8o44g50nutb',
  },
};

const TST_WHITE = {
  baseUrl: 'https://app.tst.videotranslationtool.com',
  apiUrl: 'https://rst.tst.videotranslationtool.com',
  greenApiUrl: 'https://green-rst.videotranslationtool.com',
  auth: {
    region: 'us-east-2',
    userPoolId: 'us-east-2_mmFYFgkAs',
    userPoolWebClientId: 'o4quqk39pikfju8o44g50nutb',
  },
  oauth: {
    domain: 'rask-tst.auth.us-east-2.amazoncognito.com',
    clientId: 'o4quqk39pikfju8o44g50nutb',
  },
};

const TST_1 = {
  baseUrl: 'https://app.tst1.rask.ai',
  apiUrl: 'https://rst.tst1.rask.ai',
  greenApiUrl: 'https://green-rst.tst1.rask.ai',
  auth: {
    region: 'us-west-2',
    userPoolId: 'us-west-2_KK2hjMCjo',
    userPoolWebClientId: '7meq276cnb7adg5j29d8170oog',
  },
  oauth: {
    domain: 'rask-tst1.auth.us-west-2.amazoncognito.com',
    clientId: '7meq276cnb7adg5j29d8170oog',
  },
};

const TST_2 = {
  baseUrl: 'https://app.tst2.rask.ai',
  apiUrl: 'https://rst.tst2.rask.ai',
  greenApiUrl: 'https://green-rst.tst2.rask.ai',
  auth: {
    region: 'us-west-2',
    userPoolId: 'us-west-2_rzdjDWBku',
    userPoolWebClientId: '4go2ltttf9abv60hd220mrv0sr',
  },
  oauth: {
    domain: 'rask-tst2.auth.us-west-2.amazoncognito.com',
    clientId: '4go2ltttf9abv60hd220mrv0sr',
  },
};

export const ENV_CONFIG = {
  DEV,
  STG,
  STAGE,
  TST,
  TST_WHITE,
  TST_1,
  TST_2,
  QA,
  PROD,
  PROD_WHITE,
};
