import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Modal, ModalContent, ModalActions, Link, Icon, ModalWrapper } from '@braskai/ui-kit';
import { Stack, Typography } from '@mui/material';
import { IS_WHITE_LABEL } from '@/shared/lib/environments';
import { createTextParser } from '@/shared/utils-tmp/createTextParser';
import { useExecuteOneTime } from '@/shared/hooks-tmp/useExecuteOneTime';
import { PATHS } from '@/shared/routes/paths';
import { RASK_CHAT_LINK } from '@/shared/lib/constants/docLinks';
import { ModalHeaderSecondary } from '@/shared/ui/modal';
import { LimitedFeature } from '../../lib/types';
import { useUpgradePlanModalStore } from '../../model/useUpgradePlanModalStore';
import { useUpgradePlanModalContent } from '../../model/useUpgradePlanModalContent';
import { DATA_TEST_IDS } from '../../lib/constants';
import { SDescriptionText, SImage, SNativeVideoPlayer } from './styled';

const UpgradePlanModalComponent = () => {
  const limitedFeature = useUpgradePlanModalStore((state) => state.limitedFeature);
  const close = useUpgradePlanModalStore((state) => state.close);

  const navigate = useNavigate();
  const location = useLocation();

  const { planCategory, content } = useUpgradePlanModalContent(limitedFeature as LimitedFeature);

  useExecuteOneTime(() => {
    content?.mixpanelOpeningEvent();
  });

  if (!content) {
    console.error('Should show upgrade modal but no content for this plan category was provided');

    return null;
  }

  const {
    title,
    subtitle,
    caption,
    bulletsHeader,
    features,
    buttonText,
    media: { type: mediaType, src, poster, videoOptions },
    mixpanelButtonClickEvent,
  } = content;

  const handleButtonClick = () => {
    mixpanelButtonClickEvent();

    if (planCategory === 'business') {
      window.open(RASK_CHAT_LINK, '_blank');
      return;
    }

    navigate(`/${PATHS.pricing}`, { state: { path: location.pathname } });
    close();
  };

  const parseLinksAndBoldAndGrayText = createTextParser(Link, SDescriptionText);

  return (
    <Modal open onClose={close} size="xs">
      <ModalHeaderSecondary onClose={close} title={title} subtitle={subtitle} />

      <ModalContent noPadding>
        {mediaType === 'video' ? (
          <SNativeVideoPlayer src={src} poster={poster} {...videoOptions} />
        ) : (
          <SImage $isGif={mediaType === 'gif'} src={src} />
        )}

        <Typography variant="caption-1-md" color="grey.700" marginTop={8}>
          {parseLinksAndBoldAndGrayText(caption)}
        </Typography>

        <Typography variant="body-1-md" color="grey.700" component="h3" marginTop={16}>
          {bulletsHeader}
        </Typography>

        <Stack gap={12} marginTop={12} marginBottom={4}>
          {features.map(({ icon, description }) => (
            <Stack key={description} direction="row" gap={8}>
              <Icon component={icon} color="primary.600" size={20} />
              <Typography variant="body-2-md" color="grey.700">
                {parseLinksAndBoldAndGrayText(description)}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </ModalContent>

      <ModalActions>
        {!IS_WHITE_LABEL && (
          <Button
            data-test-id={DATA_TEST_IDS.upgradeButton}
            variant="primary"
            fullWidth
            size="lg"
            onClick={handleButtonClick}
          >
            {buttonText}
          </Button>
        )}
      </ModalActions>
    </Modal>
  );
};

export const UpgradePlanModal = () => (
  <ModalWrapper hook={useUpgradePlanModalStore}>
    <UpgradePlanModalComponent />
  </ModalWrapper>
);
