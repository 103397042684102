import { styled, Stack, Typography } from '@mui/material';

export const Container = styled(Stack)({
  gap: 20,
});

export const TitleContent = styled(Typography)(({ theme }) => ({
  ...theme.typography.textLgMedium,
  textAlign: 'left',
}));
