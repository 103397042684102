import { lazy } from 'react';
import { createBrowserRouter, Navigate, RouteObject } from 'react-router-dom';
import { IS_WHITE_LABEL } from '@/shared/lib/environments';
import { PATHS } from '@/shared/routes/paths';
import { MainLayout } from '@/widgets/layouts/MainLayout';

import { BaseLayout, InitAppLayout } from '../layouts';
import { ProtectedRoute } from './ProtectedRoute';

const ErrorPage = lazy(() => import('@/pages/ErrorPage'));
const HomePage = lazy(() => import('@/pages/HomePage'));
const ProjectPage = lazy(() => import('@/pages/ProjectPage'));
const AuthPage = lazy(() => import('@/pages/AuthPage'));
const NotFoundPage = lazy(() => import('@/pages/NotFoundPage'));
const AdminPage = lazy(() => import('@/pages/AdminPage'));
const Customers = lazy(() => import('@/pages/AdminPage/components/Content/components/Customers'));
const CustomersFeedback = lazy(() => import('@/pages/AdminPage/components/Content/components/CustomersFeedback'));
const Survey = lazy(() => import('@/pages/AdminPage/components/Content/components/Survey'));
const PricingPage = lazy(() => import('@/pages/PricingPage'));
const AccountPage = lazy(() => import('@/pages/Account'));
const CheckoutPage = lazy(() => import('@/pages/CheckoutPage'));
const VoicePresetsPage = lazy(() => import('@/pages/VoicePresetsPage'));
const GlossariesPage = lazy(() => import('@/pages/GlossariesPage'));

const mainRoutes: RouteObject[] = [
  {
    path: PATHS.homepage,
    element: <HomePage />,
  },
  {
    path: PATHS.voices,
    element: <VoicePresetsPage />,
  },
  {
    path: PATHS.glossaries,
    element: <GlossariesPage />,
  },
];

const adminRoutes: RouteObject[] = [
  {
    index: true,
    element: <Navigate to={PATHS.admin.customers} replace />,
  },
  {
    path: PATHS.admin.customers,
    element: <Customers />,
  },
  {
    path: PATHS.admin.feedback,
    element: <CustomersFeedback />,
  },
  {
    path: PATHS.admin.survey,
    element: <Survey />,
  },
];

const routes: RouteObject[] = [
  {
    element: <MainLayout />,
    children: mainRoutes,
  },
  {
    path: `${PATHS.project}/:projectId`,
    element: <ProjectPage />,
  },
  {
    path: PATHS.admin.homepage,
    element: <AdminPage />,
    children: adminRoutes,
  },
];

if (!IS_WHITE_LABEL) {
  routes.push(
    {
      path: PATHS.pricing,
      element: <PricingPage />,
    },
    {
      path: PATHS.account,
      element: <AccountPage />,
    },
    {
      path: PATHS.checkout,
      element: <CheckoutPage />,
    },
  );
}

export const router = createBrowserRouter([
  {
    element: <ProtectedRoute />,
    errorElement: <ErrorPage />,
    children: [
      {
        element: <InitAppLayout />,
        children: [
          {
            element: <BaseLayout />,
            children: routes,
          },
        ],
      },
    ],
  },
  {
    path: PATHS.auth,
    element: <AuthPage />,
  },
  {
    path: PATHS.any,
    element: <NotFoundPage />,
  },
]);
