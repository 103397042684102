import { styled, Stack, Typography } from '@mui/material';
import { Button, ButtonProps } from '@braskai/ui-kit';

export const Content = styled(Stack)({
  gap: 8,
});

export const Subtitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.textSmRegular,
  color: theme.palette.grey[500],
}));

export const Actions = styled(Stack)({
  flexDirection: 'row',
  gap: 12,
});

export const DeclineButton = styled((props: ButtonProps) => (
  <Button size="xl" variant="outlined" fullWidth {...props} />
))(({ theme }) => ({
  '& .MuiCircularProgress-root': {
    color: theme.palette.grey[400],
  },
}));

export const ConsentButton = styled((props: ButtonProps) => <Button size="xl" fullWidth {...props} />)('');
