import { Stack } from '@mui/material';
import { Button } from '@braskai/ui-kit';
import { MixpanelEvents } from '@/shared/thirdParty/mixpanel';
import { useExecuteOneTime } from '@/shared/hooks-tmp/useExecuteOneTime';
import { GTMEvents } from '@/shared/thirdParty/gtm';
import { Dialog, DialogActions, DialogTitle } from '../../../ui-tmp';
import { ContentContainer, Label, Price, PriceCalculation, PriceContainer } from './styled';
import { CreditSlider } from './components/CreditSlider';
import { useMinutesBuying } from './hooks/useMinutesBuying';
import { usePrice } from './hooks/usePrice';
import { UpgradeCard } from './components/UpgradeCard';
import { getBuyMinutesViewItemListEventData } from './utils/getBuyMinutesViewItemListEventData';
import { DATA_TEST_IDS } from './lib/constants';

type TBuyMinutesModalProps = {
  onClose: () => void;
};

export const BuyMinutesModal = ({ onClose }: TBuyMinutesModalProps) => {
  const { totalMinutes, handleChangeMinutes, price, pricePerMin, product } = usePrice();
  const { isStripeCheckoutLoading, checkout } = useMinutesBuying(totalMinutes, price);

  const handleClose = () => {
    if (!isStripeCheckoutLoading) {
      onClose();
    }
  };

  useExecuteOneTime(() => {
    if (!product) {
      return;
    }

    MixpanelEvents.buyMinutesModalShown();
    const buyMinutesGtmData = getBuyMinutesViewItemListEventData({ price: pricePerMin, productId: product.id });
    GTMEvents.viewItemList(buyMinutesGtmData);
  });

  return (
    <Dialog open onClose={handleClose} size="sm">
      <DialogTitle onClose={handleClose} renderTitle={() => 'Buy additional minutes'} />

      <ContentContainer>
        <CreditSlider onChange={handleChangeMinutes} pricePerMin={pricePerMin} />

        {pricePerMin > 1 ? <UpgradeCard onClose={onClose} /> : null}

        <PriceContainer>
          <Stack>
            <Label>Price</Label>
            <PriceCalculation>
              ${pricePerMin}&times;{totalMinutes}
            </PriceCalculation>
          </Stack>
          <Price>${price}</Price>
        </PriceContainer>
      </ContentContainer>

      <DialogActions paddingTop={12}>
        <Button
          fullWidth
          size="lg"
          onClick={checkout}
          disabled={!price || isStripeCheckoutLoading}
          isLoading={isStripeCheckoutLoading}
          data-test-id={DATA_TEST_IDS.checkoutButton}
        >
          Checkout
        </Button>
      </DialogActions>
    </Dialog>
  );
};
