const YOUTUBE_LINK_REGEXP = /(?:https?:\/\/)?(?:www\.)?youtu(?:\.be\/|be.com\/\S*(?:watch|embed|shorts))/;
const GDRIVE_LINK_REGEXP = /https:\/\/drive\.google\.com\/file\/d\/(.*?)\/.*?/;
const EMAIL_REGEXP = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const PASSWORD_REGEXP = /^[^а-яёА-ЯЁ\s]*$/;
const SRT_TIME_REGEXP = /^\d{2}:[0-5][0-9]:[0-5][0-9],\d{3}$/;
const isRegExpValid = (regexp: RegExp, value: string) => {
  return regexp.test(value);
};
export const validateLinks = (value: string) => {
  if (isRegExpValid(YOUTUBE_LINK_REGEXP, value) || isRegExpValid(GDRIVE_LINK_REGEXP, value)) {
    return;
  }
  return 'Please provide correct link';
};
export const isLinkValid = (value: string) => {
  return isRegExpValid(YOUTUBE_LINK_REGEXP, value) || isRegExpValid(GDRIVE_LINK_REGEXP, value);
};
export const validateEmail = (value: string) => {
  if (isRegExpValid(EMAIL_REGEXP, value)) {
    return;
  }
  return 'Incorrect email';
};
export const validatePassword = (value: string) => {
  if (value.length < 6) {
    return 'At least 6 characters';
  }
  if (isRegExpValid(PASSWORD_REGEXP, value)) {
    return;
  }
  return 'Incorrect password';
};
export const isSrtTimeValid = (value: string) => {
  return isRegExpValid(SRT_TIME_REGEXP, value);
};
