import { useMemo } from 'react';
import { Box } from '@mui/material';

import { APP_ENV, CURRENT_ENV, IS_LOCAL_BUILD, setAppEnv, AppEnv } from '@/shared/lib/environments';
import { Select, Option } from '@/shared/ui-tmp';
import { IS_ENV_SELECTOR } from '@/app/config';

const LABEL_MAP: { [k in string]: string } = {
  [APP_ENV.development]: '⚪ Dev',
  [APP_ENV.qa]: '⚪ QA',
  [APP_ENV.tst]: '⚪ Tst',
  [APP_ENV.tst1]: '⚪ Tst1',
  [APP_ENV.tst2]: '⚪ Tst2',
  [APP_ENV.staging]: '🟡 Stage',
  [APP_ENV.stg]: '🟡 Stg',
  [APP_ENV.production]: '🟢 Prod',
};

const LOCAL_ENV = '⚙️ Local';

// visible only in development MODE
export const ServerEnvSelector = () => {
  const onSelectEnv = (env: AppEnv) => {
    setAppEnv(env);
    window.location.reload();
  };

  const options = useMemo(() => {
    const tmp = Object.keys(APP_ENV).map((item) => (
      <Option value={item} key={item}>
        {LABEL_MAP[item]}
      </Option>
    ));

    if (IS_LOCAL_BUILD) {
      tmp.push(
        <Option value="" key="local">
          {LOCAL_ENV}
        </Option>,
      );
    }

    return tmp;
  }, []);

  // TODO change on IS_PRODUCTION_BUILD, local build for first iteration
  if (!IS_LOCAL_BUILD || !IS_ENV_SELECTOR) {
    return null;
  }

  return (
    <Box sx={{ position: 'fixed', bottom: 14, right: 76, opacity: 0.5, zIndex: 2 }}>
      <Select
        variant="outlined"
        value={CURRENT_ENV}
        renderValue={() => LABEL_MAP[CURRENT_ENV] || LOCAL_ENV}
        style={{ position: 'relative' }}
        size="small"
        onChange={(e) => onSelectEnv(e.target.value as AppEnv)}
      >
        {options}
      </Select>
    </Box>
  );
};
