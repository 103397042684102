import { Stack, StackProps, styled } from '@mui/material';

export type DialogActionsProps = {
  paddingTop?: number;
} & StackProps;

/** @deprecated I recommend using the `import { ModalActions } from '@braskai/ui-kit'` component */
export const DialogActions = styled(Stack, { shouldForwardProp: (prop) => prop !== 'paddingTop' })<DialogActionsProps>(
  ({ paddingTop, theme }) => ({
    padding: `${paddingTop ?? 15}px 16px 24px`,
    flexDirection: 'row',
    gap: 12,

    [theme.breakpoints.up('mobileLarge')]: {
      paddingRight: 24,
      paddingLeft: 24,
    },
  }),
);
