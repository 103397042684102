import { useEffect, useRef } from 'react';
import { GTMEvents } from '@/shared/thirdParty/gtm';
import { User } from '@/shared/types/User';
import { API } from '@/shared/api';
import { LS_IS_JUST_SIGNED_IN_NAME, LS_IS_JUST_SIGNED_IN_VALUE } from '@/entities/user';

export const useGTMAuthorization = (user: User | null) => {
  const isUserIdSetRef = useRef(false);

  useEffect(() => {
    if (user && !isUserIdSetRef.current) {
      GTMEvents.setUserId(user.id);
      isUserIdSetRef.current = true;

      if (localStorage.getItem(LS_IS_JUST_SIGNED_IN_NAME) === LS_IS_JUST_SIGNED_IN_VALUE) {
        localStorage.removeItem(LS_IS_JUST_SIGNED_IN_NAME);
        GTMEvents.login();

        try {
          void API.sendUserLoginEvent();
          // eslint-disable-next-line no-empty
        } catch {}
      }
    }
  }, [user]);
};
