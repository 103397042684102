import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PROJECT_INSTANT_VOICE_PRESETS_RELEASE_DATE } from '../../lib/constants/voicePreset';
import { getUser } from '../user/thunks/getUser';
import { OnboardingStepKeys } from '../../types/Onboarding';

type OnboardingState = {
  currentStep: OnboardingStepKeys | null;
  stepStatuses: Record<OnboardingStepKeys, boolean>;
  nextSteps: OnboardingStepKeys[];
  prevSteps: OnboardingStepKeys[];
};

const initialState: OnboardingState = {
  currentStep: null,
  stepStatuses: {
    clickToPlay_v1: true,
    changeTextSection_v1: true,
    audioTrackEditor_v1: true,
    customizeVoiceClone_v1: true,
    speakerVoicesButton_v1: true,
    downloadVideo_v1: false,
    humanReview_v1: true,
  },
  nextSteps: [
    'clickToPlay_v1',
    'changeTextSection_v1',
    'audioTrackEditor_v1',
    'customizeVoiceClone_v1',
    'speakerVoicesButton_v1',
    'downloadVideo_v1',
    'humanReview_v1',
  ],
  prevSteps: [],
};

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setNextStep: (state, { payload }: PayloadAction<OnboardingStepKeys | undefined>) => {
      if (payload) {
        const stepIndex = state.nextSteps.findIndex((nextStep) => nextStep === payload);

        if (stepIndex !== -1) {
          state.currentStep = payload;
          state.nextSteps.splice(stepIndex, 1);
        }
      }

      if (!payload) {
        const nextStep = state.nextSteps[0];

        if (nextStep && state.stepStatuses[nextStep]) {
          state.currentStep = nextStep;
          state.nextSteps.shift();
        }
      }
    },

    closeCurrentStep: (state) => {
      if (state.currentStep) {
        state.prevSteps.push(state.currentStep);
        state.currentStep = null;
      }
    },

    changeStepStatus: (state, { payload }: PayloadAction<{ step: OnboardingStepKeys; status: boolean }>) => {
      state.stepStatuses[payload.step] = payload.status;
    },
  },

  extraReducers(builder) {
    builder.addCase(getUser.fulfilled, (state, { payload }) => {
      const completedSteps = payload?.onboarding || [];

      const isOldUser = payload?.created_date
        ? new Date(payload.created_date) < new Date(PROJECT_INSTANT_VOICE_PRESETS_RELEASE_DATE)
        : false;

      completedSteps.forEach((completedStep) => {
        const index = state.nextSteps.findIndex((step) => step === completedStep);
        if (index !== -1) {
          state.nextSteps.splice(index, 1);
        }
      });
      state.prevSteps = completedSteps;
      state.stepStatuses = {
        ...state.stepStatuses,
        customizeVoiceClone_v1: !isOldUser,
      };
    });
  },
});

export const onboardingReducer = onboardingSlice.reducer;
export const { closeCurrentStep, changeStepStatus, setNextStep } = onboardingSlice.actions;
