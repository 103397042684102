import { useSettingsStore } from '@/entities/user/settings';
import { SettingsFeatureWarning } from '@/shared/types/Settings';
import { AlertIcon, Banner } from './styled';

const getMessage = (time: SettingsFeatureWarning['maintenance_time'], message: SettingsFeatureWarning['message']) => {
  if (!time) {
    return message;
  }

  const date = new Date(time);
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const timeFormat = hours >= 12 ? 'pm' : 'am';
  const hoursStr = hours % 12 || 12; // the hour '0' should be '12'

  let minutesStr = minutes < 10 ? `0${minutes}` : `${minutes}`;
  minutesStr = minutes ? minutesStr : '';

  const timeStr = hoursStr + (minutesStr && `:${minutesStr}`) + timeFormat;

  return `Our service will be temporarily unavailable from ${timeStr} for 30 minutes for an exciting update delivering new features. We apologize for any inconvenience caused and appreciate your patience. We'll be back up and running shortly!`;
};

export const ServerNotificationBanner = () => {
  const warning = useSettingsStore((state) => state.features.warning);

  const severity = warning?.severity;
  const maintenance_time = warning?.maintenance_time;
  const message = warning?.message;

  if (!maintenance_time && !message) {
    return null;
  }

  return (
    <Banner color={severity || 'primary'}>
      <AlertIcon />
      {getMessage(maintenance_time as string, message as string)}
    </Banner>
  );
};
