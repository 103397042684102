import { Box, css, Stack, styled, Typography } from '@mui/material';

export const SContainer = styled(Stack)(
  () => css`
    gap: 6px;
  `,
);

export const SCard = styled(Box)(
  ({ theme }) => css`
    padding: 10px 14px;
    background-color: ${theme.palette.grey[50]};
    border: 1px solid ${theme.palette.grey[300]};
    box-shadow: ${theme.customShadows.xs};
    border-radius: 9px;
    width: 100%;
    text-overflow: clip;
  `,
);

export const SText = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.grey[500]};
    white-space: nowrap;
  `,
);

export const SLabel = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.grey[700]};
  `,
);
