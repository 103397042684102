import { store } from '../../../store-tmp';

export const getMixpanelProjectData = () => {
  const {
    project: { project },
  } = store.getState();

  if (!project) {
    return {};
  }

  return {
    raskTTS: project.used_rask_tts || project.free_dubbing,
    projectId: project.id,
    dictionary: project?.glossary_id ? 'on' : 'off',
  };
};
