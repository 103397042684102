import { useQuery } from '@tanstack/react-query';
import { AUTH_API } from '@/shared/api/amplify';
import { inIframe } from '@/shared/lib/utils';

type UseCognitoCheckUserProps = {
  enabled?: boolean;
  onSuccess?: () => void;
};

type UseCognitoCheckUser = {
  isAuthorized: boolean;
  picture?: string;
  name?: string;
  emailVerified?: string;
  isUsedSocialProviders?: boolean;
  cognitoId?: string;
};

export const COGNITO_USER_KEYS = ['cognitoUser'];

export const useCognitoCheckUser = (props?: UseCognitoCheckUserProps) => {
  const { enabled, onSuccess } = props || {};

  return useQuery<UseCognitoCheckUser>({
    enabled,
    queryKey: COGNITO_USER_KEYS,
    queryFn: async () => {
      if (inIframe()) {
        return {
          isAuthorized: true,
        };
      } else {
        try {
          const response = await AUTH_API.checkUser();
          const idToken = response?.getSignInUserSession()?.getIdToken();
          const token = idToken?.getJwtToken();

          if (token) {
            onSuccess?.();

            return {
              isAuthorized: true,
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              picture: idToken?.payload.picture,
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              name: idToken?.payload.name,
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              emailVerified: idToken?.payload.email_verified,
              isUsedSocialProviders: Boolean(idToken?.payload.identities?.length),
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              cognitoId: idToken?.payload.sub,
            };
          }
          return {
            isAuthorized: false,
          };

          // we need a catch block, because if a user is not authorized, there will be an error here
          // eslint-disable-next-line no-empty
        } catch {
          return {
            isAuthorized: false,
          };
        }
      }
    },
  });
};
