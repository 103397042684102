import { TExtendedSegment, TServerSegment } from '@/shared/types/Editor';
import { getTimeFromTimestampStr } from '@/shared/utils-tmp/dateTime/getTimeFromTimestampStr';

type TGetIntersectingTimestamps = {
  segmentsMap: { [id: TExtendedSegment['id']]: TExtendedSegment };
  segmentsIdsToCheck: TExtendedSegment['id'][];
  segment: TServerSegment;
};

function addUniqueTimestamps(
  intersectingTimestamps: { [segmentId: string]: Set<string> },
  segmentId: string,
  timestamps: string[],
) {
  if (!intersectingTimestamps[segmentId]) {
    intersectingTimestamps[segmentId] = new Set();
  }
  timestamps.forEach((ts) => intersectingTimestamps[segmentId].add(ts));
}

function areIntervalsOverlapping(start1: number, end1: number, start2: number, end2: number): boolean {
  return start1 < end2 && end1 > start2;
}

export const getIntersectingTimestamps = ({
  segmentsMap,
  segmentsIdsToCheck,
  segment: { id, start, end, speaker },
}: TGetIntersectingTimestamps) => {
  const intersectingTimestamps: { [segmentId: TExtendedSegment['id']]: Set<string> } = {};
  const firstStartTime = getTimeFromTimestampStr(start);
  const firstEndTime = getTimeFromTimestampStr(end);

  segmentsIdsToCheck.forEach((segmentId) => {
    const segment = segmentsMap[segmentId];
    const isSameId = segment.id === id;
    const isSameSpeaker = segment.speaker === speaker;

    if (!isSameId && isSameSpeaker) {
      const secondStartTime = getTimeFromTimestampStr(segment.start);
      const secondEndTime = getTimeFromTimestampStr(segment.end);

      if (firstStartTime === secondStartTime && firstEndTime === secondEndTime) {
        addUniqueTimestamps(intersectingTimestamps, id, [end]);
        addUniqueTimestamps(intersectingTimestamps, segmentId, [segment.start]);
      } else if (areIntervalsOverlapping(firstStartTime, firstEndTime, secondStartTime, secondEndTime)) {
        const firstSegmentTimestamps: string[] = [];
        const secondSegmentTimestamps: string[] = [];

        if (firstEndTime > secondStartTime && firstEndTime > secondEndTime && secondStartTime > firstStartTime) {
          firstSegmentTimestamps.push(end);
          secondSegmentTimestamps.push(segment.start, segment.end);
        }

        if (firstStartTime > secondStartTime && secondEndTime > firstStartTime && secondEndTime > firstEndTime) {
          firstSegmentTimestamps.push(start, end);
          secondSegmentTimestamps.push(segment.end);
        }

        if (firstEndTime < secondEndTime && firstEndTime > secondStartTime && secondStartTime > firstStartTime) {
          firstSegmentTimestamps.push(end);
          secondSegmentTimestamps.push(segment.start);
        }

        if (firstStartTime > secondStartTime && firstStartTime < secondEndTime && firstEndTime > secondEndTime) {
          firstSegmentTimestamps.push(start);
          secondSegmentTimestamps.push(segment.end);
        }

        if (firstStartTime === secondStartTime && secondEndTime > firstStartTime) {
          if (secondEndTime > firstEndTime) {
            firstSegmentTimestamps.push(end);
            secondSegmentTimestamps.push(segment.start);
          }

          if (firstEndTime > secondEndTime) {
            firstSegmentTimestamps.push(start);
            secondSegmentTimestamps.push(segment.end);
          }
        }

        if (firstEndTime === secondEndTime && secondEndTime > firstStartTime) {
          if (secondStartTime > firstStartTime) {
            firstSegmentTimestamps.push(end);
            secondSegmentTimestamps.push(segment.start);
          }

          if (secondStartTime < firstStartTime) {
            firstSegmentTimestamps.push(start);
            secondSegmentTimestamps.push(segment.end);
          }
        }

        if (firstSegmentTimestamps.length > 0) {
          addUniqueTimestamps(intersectingTimestamps, id, firstSegmentTimestamps);
        }

        if (secondSegmentTimestamps.length > 0) {
          addUniqueTimestamps(intersectingTimestamps, segmentId, secondSegmentTimestamps);
        }
      }
    }
  });

  const result: { [segmentId: string]: string[] } = {};
  for (const [key, value] of Object.entries(intersectingTimestamps)) {
    result[key] = Array.from(value);
  }

  return result;
};
