export const SUBSCRIPTION_OPTIONS = [
  {
    id: '1',
    label: 'Trial',
  },
  {
    id: '2',
    label: 'Free',
  },
];
export const DEFAULT_CREDITS = 10;
