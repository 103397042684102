import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Customer, CustomerVideoFeedback } from '@/shared/types/Admin';

type InitialState = {
  customers: Customer[];
  feedbacks: CustomerVideoFeedback[];
};
const initialState: InitialState = {
  customers: [],
  feedbacks: [],
};
const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setCustomers: (state, { payload }: PayloadAction<Customer[]>) => {
      state.customers = payload;
    },
    updateCustomerInCustomers: (state, { payload }: PayloadAction<Customer>) => {
      const index = state.customers.findIndex((customer) => customer.id === payload.id);
      if (index !== -1) {
        state.customers[index] = payload;
      }
    },
    sortCustomers: (
      state,
      {
        payload,
      }: PayloadAction<{
        order: 'asc' | 'desc';
      }>,
    ) => {
      const { order } = payload;
      if (order === 'asc') {
        state.customers = state.customers.sort((a, b) => +new Date(b.created_date) - +new Date(a.created_date));
        return;
      }
      if (order === 'desc') {
        state.customers = state.customers.sort((a, b) => -+new Date(a.created_date) - +new Date(b.created_date));
      }
    },
    setCustomersFeedback: (state, { payload }: PayloadAction<CustomerVideoFeedback[]>) => {
      state.feedbacks = payload;
    },
  },
});
export const adminReducer = adminSlice.reducer;
export const { setCustomers, updateCustomerInCustomers, sortCustomers, setCustomersFeedback } = adminSlice.actions;
