import { APP_CONFIG_ENV_MAP, CURRENT_ENV, APP_ENV, IS_PRODUCTION_BUILD } from '@/shared/lib/environments';
import { removePortFromUrl } from '@/shared/lib/utils';
import { appConfig } from './appConfig';

// if not select CURRENT_ENV send requests:
// to docker for local build
// to origin for production build

export const BASE_URL = (() => {
  const defaultUrl = (import.meta.env.VITE_SERVER as string) || appConfig.api.server;

  if (CURRENT_ENV) {
    return APP_CONFIG_ENV_MAP[CURRENT_ENV].baseUrl;
  }

  return defaultUrl || '';
})();

export const BASE_URL_WITHOUT_PORT = removePortFromUrl(BASE_URL);

export const S3_URL = BASE_URL_WITHOUT_PORT;

const isGreenAPI = import.meta.env.VITE_PLATFORM_GREEN_KEY
  ? window.location.origin.includes(import.meta.env.VITE_PLATFORM_GREEN_KEY)
  : false;

export const PLATFORM_API_URL = (() => {
  const viteEnvConfigUrl = (
    isGreenAPI ? import.meta.env.VITE_PLATFORM_GREEN_API : import.meta.env.VITE_PLATFORM_API
  ) as string;

  if (viteEnvConfigUrl) {
    return viteEnvConfigUrl;
  }

  const env = IS_PRODUCTION_BUILD ? APP_ENV.production : CURRENT_ENV;

  if (!env) {
    return '';
  }

  return isGreenAPI ? APP_CONFIG_ENV_MAP[env].greenApiUrl : APP_CONFIG_ENV_MAP[env].apiUrl;
})();

export const LIBRARY_API_URL = `${PLATFORM_API_URL}/api/library/v1/media`;
export const ACCOUNTS_API_URL = `${PLATFORM_API_URL}/api/accounts/v1`;
