import { styled, Box, Typography } from '@mui/material';

export const Container = styled(Box)(({ theme }) => ({
  display: 'grid',
  width: '100%',
  gap: 8,

  [theme.breakpoints.up('mobileLarge')]: {
    gap: 6,
  },
}));

export const Label = styled(Typography)(({ theme }) => ({
  ...theme.typography.textSmMedium,
  color: theme.palette.grey[700],
  gridColumn: '1/-1',
  display: 'block',
}));
