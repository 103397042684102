import { Dialog as MuiDialog, styled, DialogProps as MuiDialogProps } from '@mui/material';

type DialogProps = {
  size?: 'sm' | 'lg';
} & MuiDialogProps;

const StyledDialog = styled(MuiDialog)<DialogProps>(({ size, theme }) => ({
  zIndex: theme.zIndex.dialog,

  '& .MuiDialog-container': {
    alignItems: 'flex-end',

    [theme.breakpoints.up('mobileLarge')]: {
      alignItems: 'center',
    },
  },

  '& .MuiDialog-paper': {
    position: 'relative',
    borderRadius: 24,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    boxShadow: theme.customShadows.xl,
    width: '100%',
    margin: 0,
    verticalAlign: 'bottom',
    [theme.breakpoints.up('mobileLarge')]: {
      borderRadius: 20,
      verticalAlign: 'middle',

      ...(size === 'sm' && {
        maxWidth: 480,
      }),

      ...(size === 'lg' && {
        maxWidth: 800,
      }),
    },

    [theme.breakpoints.up('laptop')]: {
      margin: 32,
    },
  },
}));

/** @deprecated I recommend using the `import { Modal } from '@braskai/ui-kit'` component */
export const Dialog = (props: DialogProps) => {
  return <StyledDialog fullWidth {...props} />;
};
