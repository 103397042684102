import { getMappedEvents } from '../utils/getMappedEvents';

const EVENTS = [
  'saveVoiceCloneFromProject',
  'voiceCloneLibraryTabClick',
  'customVoiceSettingsChangesSaved',
  'createVoiceCloneClick',
  'generateVoiceCloneClick',
  'voiceCloneOpened',
  'voiceClonePreviewPlay',
  'voiceCloneEditSamples',
  'voiceCloneRegenerate',
  'voiceCloneRename',
  'voiceCloneDelete',
  'instantVoiceCloneProjectSelected',
  'voiceCloneLibraryProjectSelected',
  'voiceAIPresetProjectSelected',
  'newCustomizeVoiceOkay',
  'applyInstantVoiceCloneChanges',
  'upgradeSaveVoiceCloneProjectClick',
  'upgradeCustomizeSaveVoiceCloneProjectClick',
  'upgradeCustomVoiceModalShown',
  'upgradeCustomVoiceModalClickPricing',
  'upgradeCustomVoiceModalClickContactUs',
  'generatePreviewInstantVoiceCloneClick',
  'playPreviewInstantVoiceCloneClick',
] as const;

export const voiceEvents = getMappedEvents([...EVENTS], {
  shouldSendSubscriptionData: true,
});
