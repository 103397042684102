import { styled, Stack, Typography } from '@mui/material';
import { DialogContent } from '../../../ui-tmp';

export const ContentContainer = styled(DialogContent)({
  gap: 16,
});

export const PriceContainer = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const Label = styled(Typography)(({ theme }) => ({
  ...theme.typography.textSmMedium,
}));

export const PriceCalculation = styled(Typography)(({ theme }) => ({
  ...theme.typography.textLgMedium,
  color: theme.palette.grey[500],
}));

export const Price = styled(Typography)(({ theme }) => ({
  ...theme.typography.displayMdSemibold,
}));
