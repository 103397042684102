import { AlertColor, SnackbarCloseReason } from '@mui/material';
import { SyntheticEvent, useMemo } from 'react';
import { AlertType, Alert } from '@/shared/types/Alert';
import { Alert as BaseAlert } from '../Alert';
import { Snackbar } from './styled';

type TToastProps = {
  message: Alert['message'];
  severity?: AlertColor;
  onClose: () => void;
  isOpen: Alert['isOpen'];
  duration: Alert['duration'];
  isClickAwayDisabled?: Alert['isClickAwayDisabled'];
};

export const Toast = ({
  isOpen,
  message,
  severity = AlertType.Error,
  onClose,
  duration = 3500,
  isClickAwayDisabled,
}: TToastProps) => {
  const handleClose = (event: SyntheticEvent<unknown> | Event, reason: SnackbarCloseReason) => {
    if (isClickAwayDisabled && reason === 'clickaway') {
      return;
    }
    onClose();
  };

  const messageElement = useMemo(() => {
    if (!message) return '';
    if (typeof message === 'string') {
      return message.split('\n').map((text, idx) => {
        return (
          <span key={text}>
            {idx ? <br /> : null}
            {text}
          </span>
        );
      });
    }
    return message;
  }, [message]);

  return (
    <Snackbar open={isOpen} autoHideDuration={duration} onClose={handleClose}>
      <BaseAlert severity={severity}>{messageElement}</BaseAlert>
    </Snackbar>
  );
};
