import { styled, Typography } from '@mui/material';
import { Button, ButtonProps } from '@braskai/ui-kit';

export const Title = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  maxWidth: 315,

  [theme.breakpoints.up('mobileLarge')]: {
    maxWidth: 410,
  },
}));

export const CancelButton = styled((props: ButtonProps) => (
  <Button variant="outlined" fullWidth size="lg" {...props} />
))('');

export const DeleteButton = styled((props: ButtonProps) => <Button fullWidth size="lg" variant="error" {...props} />)(
  '',
);
