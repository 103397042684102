import { Box, CardMedia, css, Link, Stack, StackProps, styled, Typography } from '@mui/material';
import { Button, ButtonProps } from '@braskai/ui-kit';
import MobilePaywallWallpaperImg from '@/shared/assets/images/mobile-paywall-wallpaper.jpg';

export const SContainer = styled(Box)(
  () => css`
    overflow-x: hidden;
  `,
);

export const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.textLgMedium,
  lineHeight: '24px',
  letterSpacing: '-0.36px',
}));

export const Subtitle = styled((props: StackProps) => <Stack component="span" {...props} />)(({ theme }) => ({
  ...theme.typography.textSmRegular,
  color: theme.palette.grey[700],
  '& b': {
    ...theme.typography.textSmMedium,
  },

  gap: 8,
  marginTop: 8,
  flexDirection: 'row',
  [theme.breakpoints.down('mobileLarge')]: {
    flexDirection: 'column',
    gap: 0,
  },
}));

export const ImageContainer = styled(Box)({
  position: 'relative',
  height: 168,
  width: 632,
});

export const Image = styled((props) => <CardMedia component="img" src={MobilePaywallWallpaperImg} {...props} />)({
  position: 'absolute',
  left: -77,
});

export const Content = styled(Stack)({
  padding: '12px 24px 20px',
});

export const BulletsContainer = styled(Stack)({
  marginTop: 20,
  gap: 12,
  '& svg': {
    width: 20,
    height: 20,
  },
});

export const ActionsContainer = styled(Stack)({
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 24px 24px',
  gap: 12,
});

export const SBullet = styled(Stack)(
  ({ theme }) => css`
    color: ${theme.palette.primary[600]};
    flex-direction: row;
    align-items: center;
    gap: 8px;
  `,
);

export const ChoosePlansButton = styled((props: ButtonProps) => <Button size="lg" fullWidth {...props} />)('');

export const TermsLink = styled(Link)(({ theme }) => ({
  ...theme.typography.textXsRegular,
  color: theme.palette.grey[500],
  textDecoration: 'none',
  cursor: 'pointer',
}));
