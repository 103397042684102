import { createAsyncThunk } from '@reduxjs/toolkit';
import { UNAUTHORIZED_STATUS_CODE } from '@/shared/api/errors';
import { API } from '@/shared/api';
import { CustomError } from '@/shared/types/Error';
import { AUTH_API } from '@/shared/api/amplify';
import { inIframe } from '@/shared/lib/utils';
import { getTiktokSession } from '@/entities/user/tiktok';
import { setAccount } from '../AccountsSlice';
import { createAccount } from './createAccount';

type TGetAccountRequest = {
  referral?: string;
  email?: string;
};

export const getAccount = createAsyncThunk(
  'accounts/getAccount',
  async ({ referral, email }: TGetAccountRequest, { dispatch, rejectWithValue }) => {
    try {
      const user = await API.getAccount();

      dispatch(setAccount(user));

      return user;
    } catch (e) {
      const error = e as CustomError;

      if (error.status === UNAUTHORIZED_STATUS_CODE) {
        const token = inIframe() ? ((await getTiktokSession()) as string) : await AUTH_API.getTokenId();

        await dispatch(createAccount({ token, referral_id: referral || null, email }));
      } else {
        rejectWithValue(e);
      }
    }
  },
);
