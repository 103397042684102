type FormValues = {
  [key: string]:
    | {
        option: string | string[];
        text: string;
      }
    | boolean
    | string;
};
export const getSurveyDataForMixpanel = (formValues: FormValues) => {
  return Object.entries(formValues).reduce(
    (
      prev: {
        [key: string]: string | boolean;
      },
      [key, data],
    ) => {
      let value: string | boolean = '';
      if (data instanceof Object) {
        if (Array.isArray(data.option)) {
          value = data.option.join(', ');
        } else {
          value = data.option;
        }
      }
      if (typeof data === 'boolean' || typeof data === 'string' || typeof data === 'number') {
        value = data;
      }
      return { ...prev, [key]: value };
    },
    {},
  );
};
