import { useRef } from 'react';
import { useClickAway } from 'react-use';
import { CloseIcon } from '@braskai/icons';
import { Loader } from '@/shared/ui-tmp';
import { useHubSpotChatStore } from '../hooks/useHubSpotChatStore';
import { useInitHubSpotChat } from '../hooks/useInitHubSpotChat';
import { SHubSpotWrapper, SCloseButton, SLoadingHeader } from './styled';

export const HubSpotChat = () => {
  const { isOpen, isLoading, hideChat } = useHubSpotChatStore();
  const wrapperRef = useRef<HTMLDivElement>(null);

  useClickAway(wrapperRef, hideChat);
  useInitHubSpotChat();

  return (
    <SHubSpotWrapper ref={wrapperRef} $isOpen={isOpen} id="hubspot-conversations-inline-embed-selector">
      <SCloseButton size="lg" onClick={hideChat}>
        <CloseIcon />
      </SCloseButton>
      {isLoading ? <SLoadingHeader /> : null}
      {isLoading ? <Loader /> : null}
    </SHubSpotWrapper>
  );
};
