import { Control, FieldPath } from 'react-hook-form';
import { ControlledTextField } from '../../../../controlled/ControlledTextField';
import { FormValues } from '../../types';

type InputProps = {
  control: Control<FormValues>;
  disabled: boolean;
  controller: FieldPath<FormValues>;
  label: string;
};

export const Input = ({ control, label, disabled, controller }: InputProps) => {
  return (
    <ControlledTextField
      control={control}
      placeholder=""
      fieldName={controller}
      label={label}
      type="number"
      disabled={disabled}
      notRequired
    />
  );
};
