import { MixpanelEvents } from '@/shared/thirdParty/mixpanel';
import VoicePresetsPlaceholder from '@/shared/assets/images/voice-presets.png';
import { LimitedFeatureContent, ModalContent } from '../types';
import { BUTTON_TEXTS, BULLETS_HEADER_TEXT } from './texts';
import { getBullets } from './bullets';

const BASE_CONTENT: ModalContent = {
  title: 'Unlock Voice Clone library',
  subtitle: 'Upgrade to Creator Pro to create a Сustom Voice Clone',
  bulletsHeader: BULLETS_HEADER_TEXT.withCreatorPro,
  features: getBullets([
    'translate100',
    'voicePresets',
    'voiceCloneSettings',
    'lipSync',
    'srtUploadDownload',
    'subtitles',
    'aiRewrite',
  ]),
  media: {
    type: 'image',
    src: VoicePresetsPlaceholder,
  },
  mixpanelOpeningEvent: MixpanelEvents.upgradeCustomVoiceModalShown,
  mixpanelButtonClickEvent: MixpanelEvents.upgradeCustomVoiceModalClickPricing,
  buttonText: BUTTON_TEXTS.toCreatorPro,
};

const TRIAL_PLAN_CONTENT = {
  ...BASE_CONTENT,
};

const STARTER_PLAN_CONTENT = {
  ...BASE_CONTENT,
  bulletsHeader: BULLETS_HEADER_TEXT.inStarter,
};

const CREATOR_PLAN_CONTENT = {
  ...BASE_CONTENT,
  bulletsHeader: BULLETS_HEADER_TEXT.inCreator,
};

const CREATOR_PRO_PLAN_CONTENT = {
  ...BASE_CONTENT,
  title: 'Unlock more Voice Clones',
  subtitle: 'Upgrade to Business to save up to 10 Custom Voice Clones',
  bulletsHeader: BULLETS_HEADER_TEXT.inCreatorPro,
  features: getBullets(['voicePresets10', 'translate500', 'glossary', 'simultaneousMultiLanguageTranslation', 'api']),
  buttonText: BUTTON_TEXTS.toBusiness,
};

const BUSINESS_PLAN_CONTENT = {
  ...CREATOR_PRO_PLAN_CONTENT,
  subtitle: 'Upgrade to Enterprise to save unlimited Custom Voice Clones',
  bulletsHeader: BULLETS_HEADER_TEXT.inBusiness,
  features: getBullets([
    'voicePresetsUnlimited',
    'translate2000',
    'customPriceForMins',
    'humanInTheLoop',
    'businessContract',
    'personalManager',
  ]),
  buttonText: BUTTON_TEXTS.contactUs,
  mixpanelButtonClickEvent: MixpanelEvents.upgradeCustomVoiceModalClickContactUs,
};

export const VOICE_PRESETS_CONTENT: LimitedFeatureContent = {
  trial: TRIAL_PLAN_CONTENT,
  starter: STARTER_PLAN_CONTENT,
  creator: CREATOR_PLAN_CONTENT,
  creatorPro: CREATOR_PRO_PLAN_CONTENT,
  business: BUSINESS_PLAN_CONTENT,
};
