import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TAccountsState } from '@/shared/store-tmp/accounts/types';

const initialState = {} as TAccountsState;

// TODO: While there is no understanding of the future architecture, I did everything as it was
const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    setAccount: (state, { payload }: PayloadAction<TAccountsState>) => {
      return { ...state, ...payload };
    },
  },
});
export const accountsReducer = accountsSlice.reducer;

export const { setAccount } = accountsSlice.actions;
