import { APP_CONFIG_ENV_MAP, CURRENT_ENV, IS_PRODUCTION_BUILD } from '@/shared/lib/environments';

const ENV_FILE_CONFIG = {
  auth: {
    region: import.meta.env.VITE_REGION,
    userPoolId: import.meta.env.VITE_USER_POOL_ID,
    userPoolWebClientId: import.meta.env.VITE_USER_POOL_WEB_ID,
  },
  oauth: {
    domain: import.meta.env.VITE_DOMAIN,
    clientId: import.meta.env.VITE_CLIENT_ID,
  },
};

const DEFAULT_CONFIG = {
  auth: {
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
  oauth: {
    redirectSignIn: window.location.origin,
    redirectSignOut: `${window.location.origin}/auth`,
    responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
  },
};

export const AUTH_CONFIG = (() => {
  // IS_PRODUCTION_BUILD - overhead check, but passed for security
  const config = IS_PRODUCTION_BUILD || !CURRENT_ENV ? ENV_FILE_CONFIG : APP_CONFIG_ENV_MAP[CURRENT_ENV];

  return {
    Auth: {
      ...DEFAULT_CONFIG.auth,
      ...config.auth,
    },
    oauth: {
      ...DEFAULT_CONFIG.oauth,
      ...config.oauth,
    },
  };
})();
