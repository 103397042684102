import { Box, Stack, styled } from '@mui/material';
import { CheckCircleIcon } from '@braskai/icons';
import { Button, ButtonProps } from '@braskai/ui-kit';
import EditorImg from '@/shared/assets/images/editor.png';
import { DialogActions, DialogTitle } from '../../../ui-tmp';

export const Title = styled(DialogTitle)({
  textAlign: 'left',
  zIndex: 1,
});

export const List = styled(Stack)({
  gap: 12,
  marginBottom: 24,
});

export const ListItem = styled(Stack)({
  flexDirection: 'row',
  gap: 8,
  alignItems: 'center',
});

export const CheckIcon = styled(CheckCircleIcon)(({ theme }) => ({
  flexShrink: 0,
  alignSelf: 'flex-start',
  color: theme.palette.primary[600],
}));

export const ListItemText = styled(Stack)(({ theme }) => ({
  ...theme.typography.textMdRegular,
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: 5,

  [theme.breakpoints.up('tablet')]: {
    ...theme.typography.textSmRegular,
  },
}));

export const LightGreyText = styled('span')(({ theme }) => ({
  color: theme.palette.grey[500],
}));

export const AdditionalInfo = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary[25],

  marginLeft: 16,
  marginRight: 16,
  borderRadius: 16,

  [theme.breakpoints.up('tablet')]: {
    display: 'block',
    position: 'absolute',
    right: 0,
    width: 400,
    height: '100%',
    padding: '74px 0 0 24px',
    marginRight: 0,
    overflow: 'hidden',
  },
}));

export const EditorImage = styled((props) => <img src={EditorImg} alt="project editor" {...props} />)(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.up('tablet')]: {
    display: 'block',
    marginTop: 28,
    width: 376,
  },
}));

export const Actions = styled(DialogActions)(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  width: '100%',
  backgroundColor: theme.palette.common.white,

  [theme.breakpoints.up('tablet')]: {
    backgroundColor: 'transparent',
  },
}));

export const ViewPlansButton = styled((props: ButtonProps) => <Button variant="primary" size="lg" {...props} />)(
  ({ theme }) => ({
    padding: '12px 32px',
    width: '100%',

    [theme.breakpoints.up('tablet')]: {
      width: 'auto',
    },
  }),
);
