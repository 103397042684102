import { styled, css, Box } from '@mui/material';
import { IconButton, styledOmitCustomProps } from '@braskai/ui-kit';

const Z_INDEX = 9990;

export const SHubSpotWrapper = styled(
  'div',
  styledOmitCustomProps,
)<{ $isOpen: boolean }>(
  ({ theme, $isOpen }) => css`
    display: ${$isOpen ? 'block' : 'none'};
    background-color: ${theme.palette.common.white};
    position: fixed;
    overflow: hidden;
    z-index: ${Z_INDEX};
    right: 16px;
    bottom: 16px;
    min-height: 100px;
    min-width: 100px;
    width: calc(100% - 32px);
    height: calc(100svh - 32px);
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 16px;

    ${theme.breakpoints.up('mobileLarge')} {
      width: 408px;
      height: 638px;
    }

    #hubspot-conversations-inline-parent {
      width: 100%;
      height: 100%;
    }

    #hubspot-conversations-inline-iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
  `,
);

export const SCloseButton = styled(IconButton)(
  ({ theme }) => css`
    position: absolute;
    top: 14px;
    right: 14px;
    border-radius: 3px;
    background-color: transparent;
    z-index: ${Z_INDEX + 2};
    color: ${theme.palette.common.white};

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  `,
);

export const SLoadingHeader = styled(Box)(
  () => css`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 68px;
    background-color: rgb(0, 53, 229);
    z-index: ${Z_INDEX + 1};
  `,
);
