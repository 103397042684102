import { GenericAbortSignal } from 'axios';
import { Project, VideoInfo } from '@/shared/types/Project';
import { get, post } from '../../lib/api';
import { BASE_URL, S3_URL } from '../constants';
import { SummarizeRequestData } from './types';

const URL = `${BASE_URL}/api/tools`;

export const OTHER_CALLS_API = {
  // Get video metadata when uploading videos from Youtube
  getVideoInfo: (data: { url: Project['source_url'] }, signal?: GenericAbortSignal) =>
    post<VideoInfo>(`${URL}/video_info`, { data, signal }),
  // Get rewritten text with ChatGPT
  rewriteTextWithChatGPT: (data: SummarizeRequestData, signal: GenericAbortSignal) =>
    post<{
      text: string;
    }>(`${URL}/summarize`, { data, signal }),
  getS3Data: <T>(path: string) => get<T>(`${S3_URL}${path}`),
};
