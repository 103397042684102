import { IGetLatestPaymentInfoResponse } from '@/shared/api/billing/types';
import { User } from '@/shared/types/User';
import { IS_GTM_DEBUGGER_ENABLED } from './config';
import { BeginCheckoutEventData, RegistrationSurveyEventData, ViewItemListEventData } from './types';

window.dataLayer = window.dataLayer || [];

export const pushEvent = ({ eventName, properties }: { eventName: string; properties?: object }) => {
  window.dataLayer.push({
    event: eventName,
    ...properties,
  });
  if (IS_GTM_DEBUGGER_ENABLED) {
    console.info('%cGTM debugger event:', 'color: #F79009');
    console.info({ eventName, ...(properties || {}) });
  }
};

export const pushVariables = (variables: { [key: string]: string | undefined }) => {
  window.dataLayer.push({
    ...variables,
  });
  if (IS_GTM_DEBUGGER_ENABLED) {
    console.info('%cGTM debugger set variables:', 'color: #F79009');
    console.info({ ...variables });
  }
};

export const GTMEvents = {
  setUserId: (userId: User['id'] | undefined) => {
    pushVariables({ user_id: userId });
  },

  signUpPageShown: () => {
    pushEvent({ eventName: 'signUpPageShown' });
  },

  registration: () => {
    pushEvent({ eventName: 'registration' });
  },

  login: () => {
    pushEvent({ eventName: 'login' });
  },

  videoUpload: () => {
    pushEvent({ eventName: 'videoUpload' });
  },

  purchase: (latestPaymentInfo: IGetLatestPaymentInfoResponse) => {
    pushEvent({ eventName: 'purchase', properties: { ecommerce: latestPaymentInfo } });
  },

  customerSurvey: (eventData: RegistrationSurveyEventData) => {
    pushEvent({ eventName: 'customerSurvey', properties: eventData });
  },

  subscriptionPageOpened: () => {
    pushEvent({ eventName: 'subscriptionPageOpened' });
  },

  viewItemList: (eventData: ViewItemListEventData) => {
    pushEvent({ eventName: 'view_item_list', properties: eventData });
  },

  beginCheckout: (eventData: BeginCheckoutEventData) => {
    pushEvent({ eventName: 'begin_checkout', properties: eventData });
  },
};
