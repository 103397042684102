import { CardMedia, CardMediaProps } from '@mui/material';
import { CSSProperties } from 'react';

type CountryIconProps = {
  countryCode?: string;
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
  disabled?: boolean;
} & CardMediaProps;

export const CountryIcon = ({
  countryCode,
  width = 'auto',
  height = 20,
  disabled = false,
  ...props
}: CountryIconProps) => {
  if (!countryCode) {
    return null;
  }

  return (
    <CardMedia
      component="img"
      alt="Flag"
      src={`https://flagcdn.com/${countryCode?.toLowerCase()}.svg`}
      sx={{
        width,
        height,
        borderRadius: 3,
        disabled: disabled ? 0.5 : 1,
        transition: 'opacity 0.3s',
      }}
      {...props}
    />
  );
};
