import { createModalStore } from '@braskai/utils';

type RenameGlossaryModalState = {
  glossaryId: string;
  name: string;
};

const initialState: RenameGlossaryModalState = {
  glossaryId: '',
  name: '',
};

export const useRenameGlossaryModalStore = createModalStore(initialState);
