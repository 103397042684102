import { createContext } from 'react';

import { noop } from '@braskai/ui-kit';
import { TModalContext, TModalProviderProps } from './types';
import { useModalProvider } from './hooks/useModalProvider';

export const ModalContext = createContext<TModalContext>({
  type: null,
  openModal: noop,
  closeModal: noop,
});

export const ModalProvider = ({ children }: TModalProviderProps) => {
  const value = useModalProvider();

  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
};
