import { css, Stack, styled } from '@mui/material';
import { CheckCircleIcon } from '@braskai/icons';

export const Container = styled(Stack)({
  gap: 16,
  marginTop: 24,
});

export const Bullet = styled(Stack)(({ theme }) => ({
  ...theme.typography.textSmRegular,
  flexDirection: 'row',
  alignItems: 'flex-start',
  gap: 10,
  color: theme.palette.grey[500],
}));

export const CheckIcon = styled(CheckCircleIcon)(
  ({ theme }) => css`
    flex-shrink: 0;
    color: ${theme.palette.primary[600]};
  `,
);
