import { createModalStore } from '@braskai/utils';
import { FileWithPath } from 'react-dropzone';

type CreateProjectModalProps = {
  isSample: boolean;
  isFirstProject?: boolean;
  files?: FileWithPath[];
};

const initialState: CreateProjectModalProps = {
  isSample: false,
  isFirstProject: false,
  files: undefined,
};

export const useCreateProjectModalStore = createModalStore<CreateProjectModalProps>(initialState, false);
