import { ChangeEvent, useState } from 'react';
import { TextField } from '../../../../../ui-tmp';
import { Benefits } from '../Benefits';
import { DATA_TEST_IDS } from '../../lib/constants';
import {
  Container,
  Label,
  Slider,
  SliderContainer,
  TotalAndPriceContainer,
  TotalContainer,
  PriceContainer,
  Price,
} from './styled';
import { CREDIT_MAX_VALUE, CREDIT_MIN_VALUE, INPUT_SETTINGS, SLIDER_MARKS } from './constants';
import { handleCreditInputKeyDown } from './utils';

type CreditSliderProps = {
  onChange: (newPrice: number | number[]) => void;
  pricePerMin: number;
};

export const CreditSlider = ({ onChange, pricePerMin }: CreditSliderProps) => {
  const [value, setValue] = useState<number>(CREDIT_MIN_VALUE);

  /**  MUI Slider требует указать тип number | number[] */
  const handleSliderChange = (_: Event, newValue: number | number[]) => {
    setValue(newValue as number);
    onChange(newValue);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const positiveValue = Math.abs(Number(inputValue.replace(/\D/g, '')));

    /** Для предотвращения ввода 012  */
    // eslint-disable-next-line no-param-reassign
    event.target.value = String(positiveValue);

    if (Number(positiveValue) <= CREDIT_MAX_VALUE) {
      setValue(inputValue === '' ? 0 : positiveValue);
      onChange(Number(positiveValue));
      return;
    }

    if (Number(positiveValue) > CREDIT_MAX_VALUE) {
      setValue(CREDIT_MAX_VALUE);
      onChange(CREDIT_MAX_VALUE);
      return;
    }

    setValue(CREDIT_MIN_VALUE);
    onChange(CREDIT_MIN_VALUE);
  };

  const handleBlur = () => {
    if (Number(value) < CREDIT_MIN_VALUE) {
      setValue(0);
    } else if (Number(value) > CREDIT_MAX_VALUE) {
      setValue(CREDIT_MAX_VALUE);
    }
  };

  return (
    <Container>
      <SliderContainer>
        <Slider
          marks={SLIDER_MARKS}
          min={CREDIT_MIN_VALUE}
          max={CREDIT_MAX_VALUE}
          value={value}
          onChange={handleSliderChange}
        />
      </SliderContainer>

      <TotalAndPriceContainer>
        <TotalContainer>
          <Label>Minutes</Label>
          <TextField
            fullWidth
            value={value}
            variant="outlined"
            onChange={handleInputChange}
            onBlur={handleBlur}
            onKeyDown={handleCreditInputKeyDown}
            inputProps={INPUT_SETTINGS}
            data-test-id={DATA_TEST_IDS.minutesInput}
          />
        </TotalContainer>

        <PriceContainer>
          <Label>Price</Label>
          <Price>${pricePerMin}/min</Price>
        </PriceContainer>
      </TotalAndPriceContainer>

      <Benefits />
    </Container>
  );
};
