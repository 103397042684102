import { Control } from 'react-hook-form';
import { ControlledSelect } from '../../../../controlled/ControlledSelect';
import { FormValues } from '../../types';
import { Option } from './styled';

type OptionType = {
  id: string;
  label: string;
};

type SelectProps = {
  control: Control<FormValues>;
  label: string;
  options: OptionType[];
  controller: string;
  disabled: boolean;
};

export const Select = ({ options, controller, control, label, disabled }: SelectProps) => {
  return (
    <ControlledSelect
      controllerName={controller}
      control={control}
      label={label}
      disabled={disabled}
      labelSize="sm"
      isMenuInModal
      fullwidth
    >
      {options.map(({ id, label: optionLabel }) => (
        <Option key={id} value={optionLabel}>
          {optionLabel}
        </Option>
      ))}
    </ControlledSelect>
  );
};
