import { Customer, CustomerVideoFeedback, CustomersType } from '@/shared/types/Admin';
import { BASE_URL } from '../constants';
import { patch, get } from '../../lib/api';
import {
  GetCSVRequestData,
  GetCustomersFeedbackRequestData,
  GetCustomersRequestData,
  UpdateCustomerRequestData,
} from './types';

const URL = `${BASE_URL}/api/admin`;
export const ADMIN_API = {
  // Get, search and update customers
  getCustomers: ({ startDate, endDate, customerType, limit = 20 }: GetCustomersRequestData) =>
    get<Customer[]>(
      `${URL}/users?date_gt=${startDate}&date_lt=${endDate}${
        customerType === CustomersType.All ? '' : `&subscription_type=${customerType}`
      }&limit=${limit}`,
    ),
  searchCustomer: (email: string) => get<Customer[]>(`${BASE_URL}/api/admin/users?email=${email}`),
  updateCustomer: ({ customerId, ...rest }: Partial<UpdateCustomerRequestData>) =>
    patch<Customer>(`${URL}/users/${customerId}`, { data: rest }),
  // Get CSV data on donwload CSV click
  getCustomersCSV: ({ startDate, endDate, email }: GetCSVRequestData) =>
    get<Blob>(`${URL}/users/csv?${email ? `&email=${email}` : `date_gt=${startDate}&date_lt=${endDate}`}`),
  // Get all feedbacks
  getFeedback: ({ startDate, endDate, limit = 20 }: GetCustomersFeedbackRequestData) =>
    get<CustomerVideoFeedback[]>(`${URL}/feedback?date_gt=${startDate}&date_lt=${endDate}&limit=${limit}`),
  searchCustomerFeedbacks: (email: string) =>
    get<CustomerVideoFeedback[]>(`${BASE_URL}/api/admin/feedback?email=${email}`),
  getCustomersFeedbacksCSV: ({ startDate, endDate, email }: GetCSVRequestData) =>
    get<Blob>(`${URL}/feedback/csv?${email ? `&email=${email}` : `date_gt=${startDate}&date_lt=${endDate}`}`),
};
