import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { LipSyncInfo, Project, ProjectAdaptedData } from '@/shared/types/Project';

type ProjectsStore = {
  project: Project | null;
};

const initialState: ProjectsStore = {
  project: null,
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setProject: (state, { payload }: PayloadAction<Project | null>) => {
      state.project = payload;
      return state;
    },
    updateLipSyncInfo: (
      state,
      {
        payload,
      }: PayloadAction<{
        lipSyncInfo: Partial<LipSyncInfo>;
        adaptedData?: {
          lipSyncResultsLink: ProjectAdaptedData['lipSyncResultsLink'];
        };
      }>,
    ) => {
      if (state.project?.lipsync_info) {
        state.project.lipsync_info = {
          ...state.project.lipsync_info,
          ...payload.lipSyncInfo,
        };
        if (payload.adaptedData) {
          state.project.adaptedData = {
            ...state.project.adaptedData,
            ...payload.adaptedData,
          };
        }
      }
    },
  },
});
export const projectReducer = projectSlice.reducer;
export const { setProject, updateLipSyncInfo } = projectSlice.actions;
