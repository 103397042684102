import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  TServerSegment,
  TAutoSavingSegmentData,
  SegmentLocation,
  TExtendedSegment,
  SegmentStatus,
  TSegmentLocation,
} from '@/shared/types/Editor';
import { getIntersectingTimestamps } from './utils/getIntersectingTimestamps';

type TNewSegmentData = {
  location: TSegmentLocation;
  parentSegmentId: TServerSegment['id'];
  segment: TServerSegment;
};

type TSegmentDataToSave = {
  [segmentId: TExtendedSegment['id']]: TAutoSavingSegmentData;
};

type TEditorStore = {
  // Actual data from server
  segmentsFromServerMap: {
    [segmentId: TServerSegment['id']]: TServerSegment;
  };
  // Changeable data
  segmentsMap: {
    [segmentId: TExtendedSegment['id']]: TExtendedSegment;
  };
  segmentsIds: TExtendedSegment['id'][];
  // The storage for auto-saving data
  segmentsDataToSave: TSegmentDataToSave;
  focusedSegmentId: TServerSegment['id'] | null;
  newSegmentsData: TNewSegmentData[];
  isVoiceSuggesting: boolean;
  isAutoSaving: boolean;
};

const initialState: TEditorStore = {
  segmentsFromServerMap: {},
  segmentsMap: {},
  segmentsIds: [],
  segmentsDataToSave: {},
  focusedSegmentId: null,
  newSegmentsData: [],
  isVoiceSuggesting: false,
  isAutoSaving: false,
};

const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    setAllSegments: (state, { payload }: PayloadAction<TServerSegment[]>) => {
      const segmentsMap: Record<string, TServerSegment> = {};

      for (const segment of payload) {
        segmentsMap[segment.id] = segment;
      }

      state.segmentsFromServerMap = segmentsMap;
      state.segmentsMap = segmentsMap;
      state.segmentsIds = payload.map(({ id }) => id);
    },

    setServerSegments: (state, { payload }: PayloadAction<TServerSegment[]>) => {
      const segmentsMap: Record<string, TServerSegment> = {};

      for (const segment of payload) {
        segmentsMap[segment.id] = segment;
      }

      state.segmentsFromServerMap = segmentsMap;
    },

    removeSegment: (
      state,
      { payload }: PayloadAction<{ id: TServerSegment['id']; leaveInSegmentsFromServer?: boolean }>,
    ) => {
      state.segmentsIds = state.segmentsIds.filter((id) => id !== payload.id);

      delete state.segmentsMap[payload.id];
      delete state.segmentsDataToSave[payload.id];

      if (!payload.leaveInSegmentsFromServer) {
        delete state.segmentsFromServerMap[payload.id];
      }
    },

    insertSegment: (state, { payload }: PayloadAction<TNewSegmentData>) => {
      const parentSegmentIdx = state.segmentsIds.findIndex((id) => id === payload.parentSegmentId);

      if (parentSegmentIdx !== -1) {
        const newSegmentIndex = payload.location === SegmentLocation.Top ? parentSegmentIdx : parentSegmentIdx + 1;

        state.segmentsMap[payload.segment.id] = payload.segment;
        state.segmentsIds.splice(newSegmentIndex, 0, payload.segment.id);
      }
    },

    setStatus: (state, { payload }: PayloadAction<{ id: TServerSegment['id']; status: TServerSegment['status'] }>) => {
      state.segmentsMap[payload.id].status = payload.status;
    },

    updSegmentFromServer: (state, { payload }: PayloadAction<TServerSegment>) => {
      state.segmentsFromServerMap[payload.id] = payload;
    },

    updSegment: (state, { payload }: PayloadAction<Partial<TExtendedSegment> & { id: TExtendedSegment['id'] }>) => {
      if (state.segmentsMap[payload.id]) {
        state.segmentsMap[payload.id] = { ...state.segmentsMap[payload.id], ...payload };
      }
    },

    updSegmentIsSavingError: (
      state,
      { payload }: PayloadAction<Partial<TExtendedSegment> & { id: TExtendedSegment['id'] }>,
    ) => {
      const segment = state.segmentsMap[payload.id];

      if (segment.isSavingError !== payload.isSavingError) {
        segment.isSavingError = payload.isSavingError;
      }
    },

    updSegmentAfterProcessing: (state, { payload }: PayloadAction<TServerSegment[]>) => {
      payload.forEach((updatedSegment) => {
        const segmentFromServer = state.segmentsFromServerMap[updatedSegment.id];
        const isFinalStatus = updatedSegment.status !== SegmentStatus.Processing;

        if (segmentFromServer && isFinalStatus) {
          state.segmentsFromServerMap[updatedSegment.id] = updatedSegment;

          state.segmentsMap[updatedSegment.id].status = updatedSegment.status;
          state.segmentsMap[updatedSegment.id].src = updatedSegment.src;
          state.segmentsMap[updatedSegment.id].dst = updatedSegment.dst;

          if (updatedSegment.status === SegmentStatus.Error) {
            state.segmentsMap[updatedSegment.id].isSavingError = true;
          }
        }
      });
    },

    // Auto save logic
    setSegmentDataToSave: (
      state,
      { payload }: PayloadAction<{ id: TServerSegment['id'] } & TAutoSavingSegmentData>,
    ) => {
      state.segmentsDataToSave[payload.id] = state.segmentsDataToSave[payload.id]
        ? { ...state.segmentsDataToSave[payload.id], ...payload }
        : payload;
    },

    removeSegmentDataToSave: (
      state,
      { payload }: PayloadAction<{ segmentId: TServerSegment['id']; changedDataKey?: keyof TAutoSavingSegmentData }>,
    ) => {
      if (!state.segmentsDataToSave[payload.segmentId]) {
        return;
      }

      // We can remove a particular field in the segmentsDataToSave object:
      if (payload.changedDataKey) {
        delete state.segmentsDataToSave[payload.segmentId][payload.changedDataKey];

        const isEmptyObject = Object.keys(state.segmentsDataToSave[payload.segmentId]).length === 0;

        if (isEmptyObject) {
          delete state.segmentsDataToSave[payload.segmentId];
        }
      } else {
        // Or remove the whole segmentsDataToSave object
        delete state.segmentsDataToSave[payload.segmentId];
      }
    },

    setIsAutoSaving: (state, { payload }: PayloadAction<boolean>) => {
      state.isAutoSaving = payload;
    },

    // Focused segment logic
    setFocusedSegmentId: (state, { payload }: PayloadAction<TEditorStore['focusedSegmentId']>) => {
      state.focusedSegmentId = payload;
    },

    // New segments logic
    addNewSegment: (
      state,
      {
        payload,
      }: PayloadAction<{ parentSegment: TServerSegment; location: TSegmentLocation; segment: TServerSegment }>,
    ) => {
      state.newSegmentsData.push({
        parentSegmentId: payload.parentSegment.id,
        segment: payload.segment,
        location: payload.location,
      });
    },

    considerNewSegmentCreated: (
      state,
      {
        payload,
      }: PayloadAction<{
        temporarySegmentId: TServerSegment['id'];
      }>,
    ) => {
      state.newSegmentsData = state.newSegmentsData.filter(
        (segment) => segment.segment.id !== payload.temporarySegmentId,
      );
    },

    // Overlapped timestamps logic
    recalculateOverlappedTimestamps: (state) => {
      state.segmentsIds.forEach((id) => {
        delete state.segmentsMap[id].overlappedTimestamps;
      });

      // segmentsIdsToCheck is for optimizing loop iterations
      const segmentsIdsToCheck = [...state.segmentsIds];

      state.segmentsIds.forEach((id) => {
        const overlappedTimestamps = getIntersectingTimestamps({
          segmentsMap: state.segmentsMap,
          segmentsIdsToCheck,
          segment: state.segmentsMap[id],
        });

        segmentsIdsToCheck.shift();

        Object.keys(overlappedTimestamps).forEach((segmentId) => {
          const segment = state.segmentsMap[segmentId];
          const isAlreadyExist = Boolean(segment?.overlappedTimestamps?.length);

          if (isAlreadyExist) {
            state.segmentsMap[segmentId].overlappedTimestamps = Array.from(
              new Set([
                ...(state.segmentsMap[segmentId].overlappedTimestamps || []),
                ...overlappedTimestamps[segmentId],
              ]),
            );
            return;
          }

          if (state.segmentsMap[segmentId]) {
            state.segmentsMap[segmentId].overlappedTimestamps = overlappedTimestamps[segmentId];
          }
        });
      });
    },

    // Voice suggest logic
    setIsVoiceSuggesting: (state, { payload }: PayloadAction<boolean>) => {
      state.isVoiceSuggesting = payload;
    },

    // State resetting
    resetEditorState: () => initialState,
  },
});

export const editorReducer = editorSlice.reducer;

export const {
  setAllSegments,
  setServerSegments,
  removeSegment,
  insertSegment,
  setStatus,
  updSegmentFromServer,
  updSegment,
  updSegmentIsSavingError,
  updSegmentAfterProcessing,
  setSegmentDataToSave,
  removeSegmentDataToSave,
  setIsAutoSaving,
  setFocusedSegmentId,
  addNewSegment,
  considerNewSegmentCreated,
  recalculateOverlappedTimestamps,
  setIsVoiceSuggesting,
  resetEditorState,
} = editorSlice.actions;
