import { CircularProgress, css, Link as MuiLink, Stack, styled } from '@mui/material';

type TContainerProps = {
  isDisabled: boolean;
};

export const Container = styled(MuiLink, { shouldForwardProp: (prop) => prop !== 'isDisabled' })<TContainerProps>(
  ({ isDisabled, theme }) => ({
    ...theme.typography.textSmMedium,
    color: theme.palette.grey[500],
    display: 'flex',
    cursor: isDisabled ? 'default' : 'pointer',
    textDecoration: 'none',
    alignSelf: 'center',
    opacity: isDisabled ? 0.5 : 1,
    transition: 'opacity 150ms linear',
  }),
);

export const LoaderContainer = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  opacity: 0.5,
  gap: 6,
});

export const Loader = styled(({ ...props }) => <CircularProgress size={14} {...props} />)(({ theme }) => ({
  color: theme.palette.grey[600],
}));

export const SLinkContainer = styled(Stack)(
  () => css`
    flex-direction: row;
    align-items: center;
  `,
);
