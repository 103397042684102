import { ENV_CONFIG } from './config';

export const APP_ENV = {
  development: 'development',
  staging: 'staging',
  production: 'production',
  prodWhite: 'prodWhite',
  qa: 'qa',
  tst: 'tst',
  tstWhite: 'tstWhite',
  tst1: 'tst1',
  tst2: 'tst2',
  stg: 'stg',
} as const;

export type AppEnv = keyof typeof APP_ENV;

export const BUILD_MODE = {
  development: 'development',
  production: 'production',
};

export const IS_PRODUCTION_BUILD = import.meta.env.VITE_MODE !== BUILD_MODE.development;
export const IS_LOCAL_BUILD = import.meta.env.MODE === BUILD_MODE.development;

export const APP_CONFIG_ENV_MAP = {
  [APP_ENV.qa]: ENV_CONFIG.QA,
  [APP_ENV.tst]: ENV_CONFIG.TST,
  [APP_ENV.tstWhite]: ENV_CONFIG.TST_WHITE,
  [APP_ENV.tst1]: ENV_CONFIG.TST_1,
  [APP_ENV.tst2]: ENV_CONFIG.TST_2,
  [APP_ENV.development]: ENV_CONFIG.DEV,
  [APP_ENV.staging]: ENV_CONFIG.STAGE,
  [APP_ENV.production]: ENV_CONFIG.PROD,
  [APP_ENV.prodWhite]: ENV_CONFIG.PROD_WHITE,
  [APP_ENV.stg]: ENV_CONFIG.STG,
} as const;

export const LS_ENV_KEY = 'env';

export const CURRENT_ENV = (() => {
  if (IS_PRODUCTION_BUILD) {
    return '';
  }

  const lsEnv = (window.localStorage.getItem(LS_ENV_KEY) ?? '') as keyof typeof APP_ENV;

  if (lsEnv || IS_LOCAL_BUILD) return lsEnv;

  return (import.meta.env.VITE_APP_ENV as AppEnv) || APP_ENV.staging;
})();

// TODO move all 3d party flags to abstraction slice [hash234123]
export const IS_MIXPANEL_DEV_MODE_ENABLED = sessionStorage.getItem('enableMixpanel') === '1';
export const IS_LOGROCKET_DEV_MODE_ENABLED = sessionStorage.getItem('enableLogRocket') === '1';
