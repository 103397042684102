import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Alert, AlertType } from '@/shared/types/Alert';

const initialState: Alert = {
  type: AlertType.Error,
  message: '',
  isOpen: false,
  duration: 3500,
  isClickAwayDisabled: false,
};

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    showAlert: (
      state,
      { payload }: PayloadAction<Pick<Alert, 'type' | 'message' | 'duration' | 'isClickAwayDisabled'>>,
    ) => {
      state.isOpen = true;
      state.type = payload.type;
      state.message = payload.message;
      state.duration = payload.duration;
      state.isClickAwayDisabled = payload.isClickAwayDisabled || false;
    },

    hideAlert: (state) => {
      state.isOpen = initialState.isOpen;
    },
  },
});

export const alertReducer = alertSlice.reducer;
export const { showAlert, hideAlert } = alertSlice.actions;
