import { useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PATHS } from '@/shared/routes/paths';
import { GlobalErrorType } from '@/shared/types/GlobalErrors';
import { resetGlobalError } from '@/shared/store-tmp/GlobalErrorsSlice';
import { logout } from '@/shared/utils-tmp/logout';
import { resetStore } from '@/shared/store-tmp/RootReducer';
import { useModal } from '@/shared/components-tmp/modals/hooks/useModal/useModal';
import { useAppDispatch, useAppSelector } from './reduxToolkitHooks';
import { useBillingStore } from './reduxHooks/useBillingStore';

export const useGlobalErrors = () => {
  const { openBuyMinutesModal, openEnterprisePaymentRequiredModal } = useModal();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const {
    balance: { hasActiveSubscription, hasArchivedSubscription, canBuyMinutes },
    enterprise: { hasEnterprisePlan },
  } = useBillingStore();

  const error = useAppSelector((state) => state.globalErrors);

  const handleLogout = useCallback(async () => {
    await logout();
    void dispatch(resetStore());
  }, [dispatch]);

  const navigateToPricingPage = useCallback(() => {
    navigate(PATHS.pricing, { state: { path: location.pathname } });
  }, [location.pathname, navigate]);

  useEffect(() => {
    if (error.type === GlobalErrorType.PaymentRequired) {
      if (hasActiveSubscription && !hasEnterprisePlan && canBuyMinutes && hasArchivedSubscription) {
        openBuyMinutesModal();
        dispatch(resetGlobalError());
        return;
      }

      if (hasEnterprisePlan) {
        openEnterprisePaymentRequiredModal();
        dispatch(resetGlobalError());
        return;
      }

      navigateToPricingPage();
    }

    if (error.type === GlobalErrorType.InactiveUser) {
      void handleLogout();
    }

    dispatch(resetGlobalError());
  }, [
    hasArchivedSubscription,
    canBuyMinutes,
    error.type,
    hasActiveSubscription,
    hasEnterprisePlan,
    dispatch,
    openBuyMinutesModal,
    handleLogout,
    navigateToPricingPage,
    openEnterprisePaymentRequiredModal,
  ]);
};
