import { useAppSelector } from '@/shared/hooks-tmp/reduxToolkitHooks';
import { useProducts } from '@/entities/pricing';
import { PlanCategory } from '@/shared/types/Billing';

export const useStarterPlan = () => {
  const { products } = useProducts();
  const currentPlan = useAppSelector((state) => state.user?.subscription?.plan);
  const starterPlan = products?.plan?.find((plan) => plan.category === PlanCategory.Starter);
  const isCurrentStarterPlan = currentPlan?.product_id === starterPlan?.id;
  const isCurrentOtherPlan = currentPlan && !isCurrentStarterPlan;

  return {
    starterPlan,
    isStarterPlanAvailable: Boolean(starterPlan),
    isCurrentStarterPlan,
    isCurrentOtherPlan,
  };
};
