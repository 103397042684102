import { Language } from '@/shared/types/Settings';
import { AUTODETECT_LANGUAGE_OPTION } from '@/shared/constants-tmp/autodetectLanguageOption';
import { Icon } from '../Icon';
import { Content, Label, SCheckIcon } from './styled';

type OptionProps = {
  option: Language;
  selectedOption: boolean;
};

export const Option = ({ selectedOption, option }: OptionProps) => {
  return (
    <>
      <Content>
        <Icon
          isDisabled={false}
          countryCode={option.country_code}
          shouldRenderAutodetect={option.id === AUTODETECT_LANGUAGE_OPTION.id}
        />

        <Label>{option.label}</Label>
      </Content>
      {selectedOption ? <SCheckIcon width={12} /> : null}
    </>
  );
};
