import { Box } from '@mui/material';

type HubSpotHiddenFormProps = {
  id: string;
};

export const HubSpotHiddenForm = ({ id }: HubSpotHiddenFormProps) => {
  return (
    <Box display="none">
      <form id={id} />
    </Box>
  );
};
