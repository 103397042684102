import { queryClient } from '@/shared/lib/config';
import { AUTH_API } from '@/shared/api/amplify';
import { clearUserChurnkeyState } from '@/shared/thirdParty/churnkey';
import { shutdownMixpanel } from '@/shared/thirdParty/mixpanel';
import { GTMEvents } from '@/shared/thirdParty/gtm';
import { LS_DUBBING_WARNING_MODAL_DISABLED } from '@/entities/project';

export const logout = async () => {
  localStorage.removeItem(LS_DUBBING_WARNING_MODAL_DISABLED);

  clearUserChurnkeyState();
  shutdownMixpanel();

  GTMEvents.setUserId(undefined);
  queryClient.clear();
  await AUTH_API.signOut();
};
