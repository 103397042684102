import { Control } from 'react-hook-form';
import { ControlledSelect } from '../../../../../../controlled/ControlledSelect';
import { ControlledTextField } from '../../../../../../controlled/ControlledTextField';
import { Option } from '../../../../../../../ui-tmp';
import { FormValues, FormValuesOption, Option as OptionType } from '../../../../types';
import { OTHER } from '../../../../constants';
import { Container } from './styled';

type SelectProps = {
  control: Control<FormValues>;
  controller: OptionType['controller'];
  label: OptionType['label'];
  options: OptionType['options'];
  value: FormValuesOption;
  disabled: boolean;
  onResetTextField: () => void;
};

export const Select = ({ onResetTextField, value, label, options, control, controller, disabled }: SelectProps) => {
  const isTextField = value.option.toLowerCase() === OTHER;

  return (
    <Container hasGap={isTextField}>
      <ControlledSelect
        controllerName={`${controller}.option`}
        control={control}
        label={label}
        isMenuInModal
        disabled={disabled}
      >
        {options.map((option) => (
          <Option key={option} value={option} onClick={onResetTextField}>
            {option}
          </Option>
        ))}
      </ControlledSelect>

      {isTextField ? (
        <ControlledTextField
          control={control}
          disabled={disabled}
          fieldName={`${controller}.text`}
          placeholder="Your option"
        />
      ) : null}
    </Container>
  );
};
