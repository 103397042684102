import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/shared/hooks-tmp/reduxToolkitHooks';
import { deleteSegment } from '@/shared/store-tmp/editor/thunks/deleteSegment';
import { getProjectById } from '@/shared/store-tmp/project/thunks/getProjectById';

import { Dialog, DialogActions, DialogTitle } from '../../../ui-tmp';
import { CancelButton, DeleteButton, Title } from './styled';

type TDeleteSegmentModalProps = {
  onClose: () => void;
  onSuccess?: () => void;
  segmentId?: string;
};

export const DeleteSegmentModal = ({ onClose, onSuccess, segmentId }: TDeleteSegmentModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  const { project } = useAppSelector((state) => state.project);

  const handleDeleteSection = async () => {
    try {
      if (!project || !segmentId) {
        return;
      }
      setIsLoading(true);

      const requestPayload = {
        projectId: project.id,
        segmentId,
      };

      await dispatch(deleteSegment(requestPayload)).unwrap();

      await dispatch(getProjectById({ id: project.id }));

      if (onSuccess) {
        onSuccess();
      }
      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open onClose={isLoading ? undefined : onClose} size="sm">
      <DialogTitle
        onClose={onClose}
        buttonsDisabled={isLoading}
        renderTitle={() => <Title>Would you like to delete this segment?</Title>}
      />

      <DialogActions paddingTop={0}>
        <CancelButton onClick={onClose} disabled={isLoading}>
          Cancel
        </CancelButton>

        <DeleteButton onClick={handleDeleteSection} disabled={isLoading} isLoading={isLoading}>
          Delete
        </DeleteButton>
      </DialogActions>
    </Dialog>
  );
};
