import { useState } from 'react';
import { useForm } from 'react-hook-form';
import dayjs, { Dayjs } from 'dayjs';
import { useAppDispatch } from '@/shared/hooks-tmp/reduxToolkitHooks';
import { Customer } from '@/shared/types/Admin';
import { useSubscription } from '@/shared/hooks-tmp/useSubscription';
import { updateCustomer } from '@/shared/store-tmp/admin/thunks/customers';
import { showErrorAlert } from '@/shared/utils-tmp/alert/showErrorAlert';
import { DateRangePicker } from '../../../../pages/AdminPage/components/Content/components/common/DateRangePicker';
import { ControlledTextField } from '../../controlled/ControlledTextField';
import { Dialog, DialogTitle } from '../../../ui-tmp';
import { FormValues } from './types';
import { DEFAULT_CREDITS, SUBSCRIPTION_OPTIONS } from './constants';
import { Select } from './components/Select';
import { Actions, CancelButton, Content, Subtitle, Title, UpdateButton } from './styled';

type SetupCustomerSubscriptionProps = {
  onClose: () => void;
  customer?: Customer;
};

export const SetupCustomerSubscription = ({ onClose, customer = {} as Customer }: SetupCustomerSubscriptionProps) => {
  const startDate = dayjs();
  const endDate = dayjs().add(1, 'month');

  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const { subscription } = customer;

  const { hasFreeSubscription } = useSubscription(customer);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { isDirty },
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      subscription: {
        type: hasFreeSubscription ? 'Free' : 'Trial',
        subscriptionStartDate: startDate,
        subscriptionEndDate: endDate,
        credits: {
          minutes: subscription?.credits.minutes || DEFAULT_CREDITS,
        },
      },
    },
  });

  const isFreeSubscriptionSelected = watch('subscription.type') === 'Free';

  const onSubmit = async (input: FormValues) => {
    try {
      setIsLoading(true);
      const {
        subscription: { type, credits, subscriptionStartDate, subscriptionEndDate },
      } = input;

      const data = {
        customerId: customer.id,
        subscription: {
          type: type.toLowerCase(),
          ...(isFreeSubscriptionSelected && {
            current_period_start: dayjs(subscriptionStartDate).format(),
            current_period_end: dayjs(subscriptionEndDate).format(),
            credits: {
              minutes: +credits.minutes,
            },
          }),
        },
      };

      await dispatch(updateCustomer(data));

      onClose();
    } catch (e) {
      showErrorAlert(e);
    }
    setIsLoading(false);
  };

  const handleStartDateChange = (newStartDate: Dayjs | null) => {
    setValue('subscription.subscriptionStartDate', newStartDate, { shouldDirty: true });
  };

  const handleEndDateChange = (newEndDate: Dayjs | null) => {
    setValue('subscription.subscriptionEndDate', newEndDate, { shouldDirty: true });
  };

  return (
    <Dialog open onClose={isLoading ? undefined : onClose} size="sm">
      <DialogTitle
        onClose={isLoading ? undefined : onClose}
        renderTitle={() => <Title>Set up subscription</Title>}
        renderSubtitle={() => <Subtitle>Change subscription {customer.email}</Subtitle>}
      />

      <Content>
        <Select
          control={control}
          label="Type"
          options={SUBSCRIPTION_OPTIONS}
          controller="subscription.type"
          disabled={false}
        />

        {isFreeSubscriptionSelected ? (
          <>
            <ControlledTextField
              control={control}
              placeholder=""
              fieldName="subscription.credits.minutes"
              label="Subsciption credits"
              type="number"
              disabled={false}
              notRequired
            />
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              onStartDateChange={handleStartDateChange}
              onEndDateChange={handleEndDateChange}
              disablePastEndDate
              disablePastStartDate
              disabled={false}
            />
          </>
        ) : null}
      </Content>

      <Actions>
        <CancelButton onClick={onClose} disabled={isLoading}>
          Cancel
        </CancelButton>

        <UpdateButton onClick={handleSubmit(onSubmit)} isLoading={isLoading} disabled={isLoading || !isDirty}>
          Update
        </UpdateButton>
      </Actions>
    </Dialog>
  );
};
