type Data<T> = T[];
export const sortByCreatedDate = <
  T extends {
    created_date: Date;
  },
>(
  data: Data<T>,
) => {
  const deepClonedData: T[] = structuredClone<T[]>(data);
  return deepClonedData.sort((a, b) => +new Date(b.created_date) - +new Date(a.created_date));
};
export const sortByDate = <
  T extends {
    date: Date;
  },
>(
  data: Data<T>,
) => {
  const deepClonedData: T[] = structuredClone<T[]>(data);
  return deepClonedData.sort((a, b) => +new Date(b.date) - +new Date(a.date));
};
