import { FC } from 'react';
import { EModalType } from '@/shared/types/Modal';
import { useModalContext } from './hooks/useModal/useModalContext';
import { SplitSegmentModal } from './SplitSegmentModal';
import { BuyMinutesModal } from './BuyMinutesModal';
import { SetupCustomerModal } from './SetupCustomerModal';
import { DeleteSegmentModal } from './DeleteSegmentModal';
import { SetupCustomerSubscription } from './SetupCustomerSubscription';
import { SetupCustomerCredits } from './SetupCustomerCredits';
import { ChangePasswordModal } from './ChangePasswordModal';
import { DeleteAccountModal } from './DeleteAccountModal';
import { UpgradeFromTrialModal } from './UpgradeFromTrialModal';
import { MobileNoticeModal } from './MobileNoticeModal';
import { PaywallModal } from './PaywallModal';
import { MobilePaywallModal } from './MobilePaywallModal';
import { EnterprisePaymentRequiredModal } from './EnterprisePaymentRequiredModal';
import { MultilanguageModal } from './MultilanguageModal';
import { SuccessfulSubscriptionRenewalModal } from './SuccessfulSubscriptionRenewalModal';
import { ChooseSpeakerModal } from './ChooseSpeakerModal';

type TModalProps = { onClose: () => void; [key: string]: unknown };

const modalComponent: Record<EModalType, FC<TModalProps>> = {
  [EModalType.BuyMinutes]: BuyMinutesModal,
  [EModalType.DeleteSegment]: DeleteSegmentModal,
  [EModalType.SetupCustomer]: SetupCustomerModal,
  [EModalType.SetupCustomerSubscription]: SetupCustomerSubscription,
  [EModalType.SetupCustomerCredits]: SetupCustomerCredits,
  [EModalType.PasswordConfirmation]: ChangePasswordModal,
  [EModalType.DeleteAccountConfirmation]: DeleteAccountModal,
  [EModalType.UpgradeFromTrial]: UpgradeFromTrialModal,
  [EModalType.MobileNotice]: MobileNoticeModal,
  [EModalType.Paywall]: PaywallModal,
  [EModalType.MobilePaywall]: MobilePaywallModal,
  [EModalType.EnterprisePaymentRequired]: EnterprisePaymentRequiredModal,
  [EModalType.Multilanguage]: MultilanguageModal,
  [EModalType.SuccessfulSubscriptionRenewal]: SuccessfulSubscriptionRenewalModal,
  [EModalType.SplitSegment]: SplitSegmentModal,
  [EModalType.ChooseSpeaker]: ChooseSpeakerModal,
};

export const Modals = () => {
  const { type, closeModal, ...props } = useModalContext();

  if (!type) {
    return null;
  }

  const ModalComponent = modalComponent[type];
  return <ModalComponent onClose={closeModal} {...props} />;
};
