import { VoicePreset } from './VoicePreset';
import { Language } from './Settings';
import { Status } from './Status';
import { User } from './User';
import { Voice } from './Voice';

const VideoFeedback = {
  Translation: 'translation',
  RaskTtsTranslation: 'rask_tts_translation',
  Lipsync: 'lipsync',
} as const;

export type TVideoFeedback = (typeof VideoFeedback)[keyof typeof VideoFeedback];

export type SpeakersVoices = {
  [speakerId: string]: Voice['id'];
};

export const LIP_SYNC_STATUS = {
  Started: 'started',
  Done: 'done',
  Failed: 'failed',
  Outdated: 'outdated',
} as const;

export type TLipSyncStatus = (typeof LIP_SYNC_STATUS)[keyof typeof LIP_SYNC_STATUS];

export type TLipSyncCheckFaceStatus = Exclude<TLipSyncStatus, 'outdated'>;

export type TSubtitlesBurnStatuses = 'queued' | 'in_progress' | 'completed' | 'failed' | null;

export type TBurnSubtitlesInfo = {
  banner_was_displayed: boolean;
  banner_was_displayed_lipsync: boolean;
  is_outdated: boolean;
  is_outdated_lipsync: boolean;
  subtitles_burn_result_path: string | null;
  subtitles_burn_status: TSubtitlesBurnStatuses | null;
  subtitles_lipsync_burn_result_path: string | null;
  subtitles_lipsync_burn_status: TSubtitlesBurnStatuses;
};

export type LipSyncInfo = {
  check_face_task_status: TLipSyncCheckFaceStatus | null;
  video_has_face: boolean | null;
  lipsync_task_status: TLipSyncStatus | null;
  tasks_in_lipsync_queue: number | null;
  lipsync_result_path: string | null;
  is_video_trimmed?: boolean;
  lipsync_task_progress: number | null;
  free_lipsync_done: boolean;
  paid_lipsync_done: boolean;
};

export type ProjectAdaptedData = {
  created_date: string;
  cover?: string | null;
  dstVideo?: string | null;
  srcVideo?: string | null;
  voiceover?: string | null;
  lipSyncResultsLink?: string | null;
  translatedVideoSubtitles?: string;
  lipSyncedVideoSubtitles?: string;
  srt?: string;
  vtt?: string;
  translatedAudio?: string | null;
};

export type TAudioPlotMetadata = {
  original_audio_plot_status: 'in_progress' | 'completed' | 'failed';
  original_audio_plot_values_path: string | null;
};

type SpeakerVoicePresets = Partial<{
  [speakerId: string]: {
    id: string;
    is_temporary: boolean;
    similarity_boost: number;
    stability: number;
    status: VoicePreset['status'];
  };
}>;

export type Project = {
  user: User;
  id: string;
  name: string;
  source_url: string;
  source_type: string;
  status: Status;
  status_date: string;
  created_date: string;
  cover?: string | null;
  src_lang: Language['value'] | null;
  dst_lang: Language['value'] | null;
  voiceover?: string | null;
  original_video?: string | null;
  translated_video?: string | null;
  voice: SpeakersVoices | null;
  duration: number | null;
  original_duration: number | null;
  translated_video_exists: boolean;
  num_speakers?: number;
  is_autogeneration: boolean;
  transcript_id: string | null;
  src_srt_uploaded: boolean;
  dst_srt_uploaded: boolean;
  lipsync_info?: LipSyncInfo;
  adaptedData: ProjectAdaptedData;
  translation_srt_path: string | null;
  translation_vtt_path: string | null;
  feedbacks: {
    feedback_type: TVideoFeedback;
    feedback_id?: string;
  }[];
  subtitles_info: TBurnSubtitlesInfo;
  free_dubbing: boolean;
  used_rask_tts: boolean;
  translated_audio?: string | null;
  audio_plot_data?: TAudioPlotMetadata;
  glossary_id?: string;
  glossary_version?: string;
  skip_updated_glossary_popup?: boolean;
  processing_voice_presets: SpeakerVoicePresets;
  instant_voice_presets: SpeakerVoicePresets;
  rask_tts_experiment: boolean;
};

export type VideoInfo = {
  title: Project['name'];
  duration: Project['duration'];
  cover: string;
};

export enum ProjectTaskType {
  VideoUploading = 'videoUploading',
  VideoUploadingWithGenerating = 'videoUploadingWithGenerating',
  VideoWithSrcSrtUploading = 'videoWithSrcSrtUploading',
  VideoWithDstSrtUploading = 'videoWithDstSrtUploading',
  VideoWithSrcSrtUploadingWithGenerating = 'videoWithSrcSrtUploadingWithGenerating',
  VideoWithDstSrtUploadingWithGenerating = 'videoWithDstSrtUploadingWithGenerating',
  VoiceUpdate = 'voiceUpdate',
  VideoGenerating = 'videoGenerating',
}

export type NormalizedProject = {
  id: string;
  image?: string | null;
  date: string;
  name: string;
  progress: number;
  label: string;
  countryCode?: string;
  loadingIconType: 'done' | 'process' | 'warning';
  isError: boolean;
  isFatalError?: boolean;
  isDone: boolean;
  createdAt: string;
  isDownloadingDisabled: boolean;
  isHideContextMenu?: boolean;
  canNavigate: boolean;
};

export const MEDIA_TYPE = {
  videoDownload: 'video_download',
  videoWithLipsyncDownload: 'video_with_lipsync_download',
  videoWithSubtitlesDownload: 'video_with_subtitles_download',
  videoWithSubtitlesAndLipsyncDownload: 'video_with_subtitles_and_lipsync_download',
  audioWithVoiceDownload: 'audio_with_voice_download',
  audioWithVoiceAndBackgroundDownload: 'audio_with_voice_and_background_download',
  raskTtsVideoDownload: 'rask_tts_video_download',
} as const;

export type MediaType = (typeof MEDIA_TYPE)[keyof typeof MEDIA_TYPE];
