import { ModalHeader } from '@braskai/ui-kit';
import { Stack } from '@mui/material';
import { ModalHeaderProps } from '../../lib/types';

const { Container, Subtitle, Title } = ModalHeader;

export const ModalHeaderDefault = ({
  title,
  subtitle,
  maxLinesSubtitle,
  maxLinesTitle,
  ...props
}: ModalHeaderProps) => (
  <Container {...props}>
    <Stack gap={4}>
      <Title maxLines={maxLinesTitle} mr={40}>
        {title}
      </Title>
      {subtitle && <Subtitle maxLines={maxLinesSubtitle}>{subtitle}</Subtitle>}
    </Stack>
  </Container>
);
