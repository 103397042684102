import { create } from 'zustand';
import { v4 as uuidv4 } from 'uuid';
import { VideoInfo } from '@/shared/types/Project';
import { Prettify } from '@/shared/lib/utils';

export const UPLOAD_STATUSES = {
  ready: 'ready',
  uploading: 'uploading',
  pending: 'pending',
  done: 'done',
  error: 'error',
} as const;

type UploadStatus = (typeof UPLOAD_STATUSES)[keyof typeof UPLOAD_STATUSES];

export type LocalFile = {
  id: string;
  file: File;
  name: string;
  status: UploadStatus;
  error?: string | null;
};

export type FileByLink = Prettify<
  Omit<LocalFile, 'file'> & {
    videoInfo: VideoInfo;
    url: string;
  }
>;

export type UploadFile = Prettify<LocalFile | FileByLink>;

type State = {
  files: LocalFile[];
  fileByLink: FileByLink | null;
  filesProgress: Record<string, number>;
};

type Actions = {
  addFiles: (files: File[]) => void;
  addPendingFileByLink: (url: string) => void;
  addFileByLinkInfo: (videoInfo: VideoInfo) => void;
  addFileByLinkError: (error: string) => void;
  addFileByIdError: (id: string, error: string | null) => void;
  startFilesUpload: () => void;
  updateFileUploadProgress: (id: string, progress: number) => void;
  resetFileUploadProgress: (id: string) => void;
  deleteFile: (id: string) => void;
  resetFiles: () => void;
};

const initialState: State = {
  files: [],
  fileByLink: null,
  filesProgress: {},
};

export const useUploadFileStore = create<State & Actions>((set, get) => ({
  ...initialState,

  addFiles: (files) => {
    const newFiles = files.map((file) => ({
      id: uuidv4(),
      file,
      name: file.name,
      progress: 0,
      status: UPLOAD_STATUSES.ready,
      error: null,
    }));

    set((state) => ({
      files: [...state.files, ...newFiles],
    }));
  },

  addPendingFileByLink: (url) =>
    set((state) => ({
      ...state,
      fileByLink: {
        id: url,
        url,
        videoInfo: { title: 'File by link', duration: 0, cover: '' },
        name: 'File by link',
        progress: 0,
        status: UPLOAD_STATUSES.pending,
        error: null,
      },
    })),

  addFileByLinkInfo: (videoInfo) => {
    const currentFileByLink = get().fileByLink;

    if (!currentFileByLink) {
      return;
    }

    set((state) => ({
      ...state,
      fileByLink: {
        ...currentFileByLink,
        videoInfo,
        name: videoInfo.title,
        progress: 0,
        status: UPLOAD_STATUSES.ready,
        error: null,
      },
    }));
  },

  addFileByLinkError: (error) => {
    const currentFileByLink = get().fileByLink;

    if (!currentFileByLink) {
      return;
    }

    set((state) => ({
      ...state,
      fileByLink: {
        ...currentFileByLink,
        status: UPLOAD_STATUSES.error,
        error,
      },
    }));
  },

  startFilesUpload: () =>
    set((state) => ({
      ...state,
      files: state.files.map((file) => ({
        ...file,
        status: UPLOAD_STATUSES.uploading,
      })),
      filesProgress: state.files.reduce(
        (acc, file) => ({
          ...acc,
          [file.id]: 0,
        }),
        {},
      ),
    })),

  updateFileUploadProgress: (id, progress) => {
    const currentFile = get().files.find((file) => file.id === id);
    const currentProgress = get().filesProgress[id];
    const newStatus = progress === 100 ? UPLOAD_STATUSES.done : UPLOAD_STATUSES.uploading;
    const isStatusUpdated = currentFile?.status !== newStatus;

    if (!currentFile || currentProgress === progress) {
      return;
    }

    set((state) => ({
      ...state,
      ...(isStatusUpdated
        ? { files: state.files.map((file) => (file.id === id ? { ...file, progress, status: newStatus } : file)) }
        : undefined),
      filesProgress: {
        ...state.filesProgress,
        [id]: progress,
      },
    }));
  },

  addFileByIdError: (id, error) => {
    const currentFile = get().files.find((file) => file.id === id);

    if (!currentFile) {
      return;
    }

    set((state) => ({
      ...state,
      files: state.files.map((file) => (file.id === id ? { ...file, status: UPLOAD_STATUSES.error, error } : file)),
      filesProgress: {
        ...state.filesProgress,
        [id]: 0,
      },
    }));
  },

  resetFileUploadProgress: (id) => {
    const currentFile = get().files.find((file) => file.id === id);

    if (!currentFile) {
      return;
    }

    set((state) => ({
      ...state,
      files: state.files.map((file) => (file.id === id ? { ...file, status: UPLOAD_STATUSES.ready } : file)),
      filesProgress: {
        ...state.filesProgress,
        [id]: 0,
      },
    }));
  },

  deleteFile: (id) =>
    set((state) => ({
      ...state,
      files: state.files.filter((file) => file.id !== id),
    })),

  resetFiles: () => set({ ...initialState }),
}));

export const hasFileUploadErrorSelector = (state: State) =>
  state.files.some((file) => file.status === UPLOAD_STATUSES.error);

export const isBulkUploadSelector = (state: State) => state.files.length > 1;
