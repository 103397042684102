import { Subscription, SubscriptionStatus } from '@/shared/types/Billing';
import { isSubscriptionExpired } from './isSubscriptionExpired';

const ACTIVE_SUBSCRIPTION_STATUSES = [
  SubscriptionStatus.Active,
  SubscriptionStatus.Trialing,
  SubscriptionStatus.Incomplete,
  SubscriptionStatus.IncompleteExpired,
];
export const isSubscriptionActive = (subscription: Subscription) => {
  return (
    ACTIVE_SUBSCRIPTION_STATUSES.includes(subscription.status) &&
    !isSubscriptionExpired(subscription.current_period_end) &&
    !subscription?.payments_collection_paused
  );
};
