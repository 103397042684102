import { getMappedEvents } from '../utils/getMappedEvents';

const EVENTS_WITH_SUBSCRIPTION_DATA = [
  'lipsyncFreeMinuteClick',
  'lipsyncFullVideoClick',
  'lipsyncFullVideoPlayerClick',
  'lipsyncButtonPressed',
  'lipsyncConditionsModalShown',
  'lipsyncUpgradeModalShown',
  'lipsyncUpgradeChoosePlanModalClick',
  'lipsyncUpgradeClickDownload',
  'lipsyncUpgradeClickTry',
  'upgradeOneMinLipSync',
] as const;

const EVENTS_WITH_SUBSCRIPTION_DATA_AND_TTS = ['lipsyncButtonStart', 'lipsyncVideoDownload'] as const;

const lipSyncEventsWithSubscriptionData = getMappedEvents([...EVENTS_WITH_SUBSCRIPTION_DATA], {
  shouldSendSubscriptionData: true,
});

const lipSyncEventsWithSubscriptionDataAndTTS = getMappedEvents([...EVENTS_WITH_SUBSCRIPTION_DATA_AND_TTS], {
  shouldSendSubscriptionData: true,
  shouldSendRaskTTSData: true,
});

export const lipSyncEvents = [...lipSyncEventsWithSubscriptionData, ...lipSyncEventsWithSubscriptionDataAndTTS];
