import { AI_REWRITE_CONTENT } from './aiRewrite';
import { LIP_SYNC_CONTENT } from './lipSync';
import { MULTILANGUAGE_CONTENT } from './multilanguage';
import { SRT_DOWNLOAD_CONTENT } from './srtDownload';
import { SRT_UPLOAD_CONTENT } from './srtUpload';
import { VIDEO_SUBTITLES_CONTENT } from './videoSubtitles';
import { VOICE_PRESETS_CONTENT } from './voicePresets';
import { AUDIO_DOWNLOAD_CONTENT } from './audioDownload';
import { GLOSSARY_CONTENT } from './glossary';

export const CONTENT = {
  srtUpload: SRT_UPLOAD_CONTENT,
  srtDownload: SRT_DOWNLOAD_CONTENT,
  lipSync: LIP_SYNC_CONTENT,
  multilanguage: MULTILANGUAGE_CONTENT,
  aiRewrite: AI_REWRITE_CONTENT,
  videoSubtitles: VIDEO_SUBTITLES_CONTENT,
  voicePresets: VOICE_PRESETS_CONTENT,
  audioDownload: AUDIO_DOWNLOAD_CONTENT,
  glossary: GLOSSARY_CONTENT,
};
