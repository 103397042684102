export const BULLETS = [
  'Priority queue without waiting',
  'Translate videos into 130+ languages',
  'Use VoiceClone for 28 languages',
  'Edit generated video with advanced editor',
  'Upload SRT file along with video',
  'Apply Lip-sync feature (beta)',
];

export const DATA_TEST_IDS = {
  viewPlansButton: 'paywall-modal-view-plans-button',
};
