import { css, Stack, styled } from '@mui/material';
import { squareBlockCSS } from '@braskai/ui-kit';

export const SIconWrapper = styled(Stack)(
  ({ theme }) => css`
    ${squareBlockCSS(56)};
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    border: 8px solid ${theme.palette.primary[50]};
    background-color: ${theme.palette.primary[100]};
    color: ${theme.palette.primary[600]};
  `,
);

export const SContent = styled(Stack)(
  () => css`
    padding: 0 24px 20px;
    gap: 8px;
  `,
);

export const SCheckboxContainer = styled(Stack)(
  () => css`
    margin-top: 12px;
  `,
);

export const SActionsContainer = styled(Stack)(
  () => css`
    flex-direction: row;
    padding: 0 24px 24px;
    gap: 12px;
  `,
);
