import { styled } from '@mui/material';
import { StarsExtraIcon } from '@braskai/icons';

type TAutodetectIconProps = {
  isDisabled: boolean;
};

export const AutodetectIcon = styled(StarsExtraIcon, {
  shouldForwardProp: (prop) => prop !== 'isDisabled',
})<TAutodetectIconProps>(({ isDisabled, theme }) => ({
  opacity: isDisabled ? 0.5 : 1,
  transition: 'opacity 100ms linear',
  color: theme.palette.grey[500],
}));
