import { MixpanelEvents } from '@/shared/thirdParty/mixpanel';
import SrtUploadPlaceholder from '@/shared/assets/images/srt-upload.png';
import { LimitedFeatureContent, ModalContent } from '../types';
import { BUTTON_TEXTS, BULLETS_HEADER_TEXT } from './texts';
import { getBullets } from './bullets';

const BASE_CONTENT: ModalContent = {
  title: 'Unlock SRT upload feature',
  subtitle:
    'Upgrade to Creator Pro plan to effortlessly pair your video with existing subtitles for accurate dubbing results',
  bulletsHeader: BULLETS_HEADER_TEXT.withCreatorPro,
  features: getBullets([
    'translate100',
    'lipSync',
    'voiceCloneSettings',
    'voicePresets',
    'srtUploadDownload',
    'subtitles',
    'aiRewrite',
  ]),
  media: {
    type: 'image',
    src: SrtUploadPlaceholder,
  },
  mixpanelOpeningEvent: MixpanelEvents.srtUpgradeModal,
  mixpanelButtonClickEvent: MixpanelEvents.srtUpgradeChoosePlanModalClick,
  buttonText: BUTTON_TEXTS.toCreatorPro,
};

const TRIAL_PLAN_CONTENT = {
  ...BASE_CONTENT,
};

const STARTER_PLAN_CONTENT = {
  ...BASE_CONTENT,
  bulletsHeader: BULLETS_HEADER_TEXT.inStarter,
};

const CREATOR_PLAN_CONTENT = {
  ...BASE_CONTENT,
  bulletsHeader: BULLETS_HEADER_TEXT.inCreator,
};

export const SRT_UPLOAD_CONTENT: LimitedFeatureContent = {
  trial: TRIAL_PLAN_CONTENT,
  starter: STARTER_PLAN_CONTENT,
  creator: CREATOR_PLAN_CONTENT,
};
