import { SNativeVideoPlayer } from './styled';
import { NativeVideoPlayerProps } from './types';

export const NativeVideoPlayer = ({ controls = true, ...rest }: NativeVideoPlayerProps) => {
  return (
    <SNativeVideoPlayer
      controls={controls}
      disablePictureInPicture
      component="video"
      controlsList="noplaybackrate nodownload"
      type="video/mp4"
      playsInline
      muted
      {...rest}
    />
  );
};
