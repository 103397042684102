import { Alert as MuiAlert, AlertProps as MuiAlertProps, styled } from '@mui/material';
import { forwardRef } from 'react';

type AlertProps = MuiAlertProps;

const StyledAlert = styled(MuiAlert)<AlertProps>(({ theme }) => ({
  ...theme.typography.textSmMedium,
  borderRadius: 8,
  background: theme.palette.common.white,
  alignItems: 'center',
  border: '1px solid',

  '&.MuiPaper-root': {
    padding: 16,
  },

  '& .MuiAlert-message': {
    padding: 0,
  },

  '& .MuiSvgIcon-root': {
    width: 20,
    height: 20,
  },

  '& .MuiAlert-icon': {
    padding: 0,
  },

  '&.MuiAlert-standardInfo': {
    backgroundColor: theme.palette.primary[25],
    color: theme.palette.primary[700],
    borderColor: theme.palette.primary[300],
    boxShadow: `0px 4px 30px 0px #6941C61A`,
    minWidth: 200,

    '& .MuiSvgIcon-root': {
      color: theme.palette.primary[600],
    },
  },

  '&.MuiAlert-standardWarning': {
    backgroundColor: theme.palette.warning[25],
    color: theme.palette.warning[700],
    borderColor: theme.palette.warning[300],
    boxShadow: '0px 4px 30px 0px #B547081A',

    '& .MuiSvgIcon-root': {
      color: theme.palette.warning[600],
    },
  },

  '&.MuiAlert-standardSuccess': {
    backgroundColor: theme.palette.success[25],
    color: theme.palette.success[700],
    borderColor: theme.palette.success[300],
    boxShadow: '0px 4px 30px 0px #027A481A',

    '& .MuiSvgIcon-root': {
      color: theme.palette.success[600],
    },
  },

  '&.MuiAlert-standardError': {
    backgroundColor: theme.palette.error[25],
    color: theme.palette.error[700],
    borderColor: theme.palette.error[300],
    boxShadow: '0px 4px 30px 0px #FF00001A',

    '& .MuiSvgIcon-root': {
      color: theme.palette.error[600],
    },
  },
}));

export const Alert = forwardRef<HTMLDivElement, AlertProps>(({ children, ...props }: AlertProps, ref) => {
  return (
    <StyledAlert ref={ref} {...props}>
      {children}
    </StyledAlert>
  );
});
