import { createContext, forwardRef, ComponentPropsWithoutRef, useId, useContext } from 'react';
import { Typography } from '@braskai/ui-kit';
import { FormControl as MuiFormControl, Stack } from '@mui/material';
import { Controller, ControllerProps, FieldPath, FieldValues, FormProvider, useFormContext } from 'react-hook-form';
import { SInputLabel } from './styled';

const Form = FormProvider;

type FormFieldContextValue<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  name: TName;
};

const FormFieldContext = createContext<FormFieldContextValue>({} as FormFieldContextValue);

const FormField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  ...props
}: ControllerProps<TFieldValues, TName>) => {
  return (
    <FormFieldContext.Provider value={{ name: props.name }}>
      <Controller {...props} />
    </FormFieldContext.Provider>
  );
};

type FormItemContextValue = {
  id: string;
};

const FormItemContext = createContext<FormItemContextValue>({} as FormItemContextValue);

const FormItem = forwardRef<HTMLDivElement, ComponentPropsWithoutRef<typeof Stack>>(({ ...props }, ref) => {
  const id = useId();

  return (
    <FormItemContext.Provider value={{ id }}>
      <Stack ref={ref} gap={6} {...props} />
    </FormItemContext.Provider>
  );
});
FormItem.displayName = 'FormItem';

const useFormField = () => {
  const fieldContext = useContext(FormFieldContext);
  const itemContext = useContext(FormItemContext);
  const { getFieldState, formState } = useFormContext();

  const fieldState = getFieldState(fieldContext.name, formState);

  if (!fieldContext) {
    throw new Error('useFormField should be used within <FormField>');
  }

  const { id } = itemContext;

  return {
    id,
    name: fieldContext.name,
    formItemId: `${id}-form-item`,
    formDescriptionId: `${id}-form-item-description`,
    formMessageId: `${id}-form-item-message`,
    ...fieldState,
  };
};

const FormLabel = forwardRef<HTMLLabelElement, ComponentPropsWithoutRef<typeof SInputLabel>>(
  ({ children, ...props }, ref) => {
    const { formItemId } = useFormField();

    return (
      <SInputLabel ref={ref} htmlFor={formItemId} {...props}>
        {children}
      </SInputLabel>
    );
  },
);
FormLabel.displayName = 'FormLabel';

const FormControl = forwardRef<HTMLDivElement, ComponentPropsWithoutRef<typeof MuiFormControl>>(
  ({ children, ...props }, ref) => {
    const { error } = useFormField();

    return (
      <MuiFormControl ref={ref} fullWidth error={!!error} {...props}>
        {children}
      </MuiFormControl>
    );
  },
);
FormControl.displayName = 'FormControl';

const FormDescription = forwardRef<HTMLParagraphElement, ComponentPropsWithoutRef<typeof Typography>>(
  ({ ...props }, ref) => {
    const { formDescriptionId } = useFormField();

    return <Typography ref={ref} id={formDescriptionId} component="p" variant="body-2" color="grey.500" {...props} />;
  },
);
FormDescription.displayName = 'FormDescription';

const FormMessage = forwardRef<HTMLParagraphElement, ComponentPropsWithoutRef<typeof Typography>>(
  ({ children, ...props }, ref) => {
    const { error, formMessageId } = useFormField();
    const body = error ? String(error?.message) : children;

    if (!body) {
      return null;
    }

    return (
      <Typography ref={ref} id={formMessageId} component="p" variant="body-2" color="error.500" {...props}>
        {body}
      </Typography>
    );
  },
);
FormMessage.displayName = 'FormMessage';

export { useFormField, Form, FormItem, FormLabel, FormControl, FormDescription, FormMessage, FormField };
