// TODO: type needs to be corrected
/* eslint-disable @typescript-eslint/no-unsafe-return */

import { Auth } from 'aws-amplify';
import type { CognitoUser } from 'amazon-cognito-identity-js';
import type { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

export const AUTH_API = {
  signIn: async (username: string, password: string) => {
    try {
      return Auth.signIn(username, password);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  signOut: async () => {
    try {
      return await Auth.signOut();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  signUp: async (username: string, password: string, email: string) => {
    try {
      const { user } = await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
        },
        autoSignIn: {
          // optional - enables auto sign in after user is confirmed
          enabled: true,
        },
      });
      return user;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  confirmSignUp: async (username: string, code: string) => {
    try {
      return await Auth.confirmSignUp(username, code);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  resendConfirmationCode: async (username: string) => {
    try {
      return await Auth.resendSignUp(username);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  forgotPassword: async (username: string) => {
    try {
      return await Auth.forgotPassword(username);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  forgotPasswordSubmit: async (username: string, code: string, newPassword: string) => {
    try {
      return await Auth.forgotPasswordSubmit(username, code, newPassword);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  changePassword: async (oldPassword: string, newPassword: string) => {
    try {
      // TODO: type needs to be corrected
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const user = await AUTH_API.checkUser();
      return await Auth.changePassword(user, oldPassword, newPassword);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteAccount: async () => {
    try {
      return await Auth.deleteUser();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  socialSignIn: async (socialNetwork: CognitoHostedUIIdentityProvider) => {
    try {
      return await Auth.federatedSignIn({
        provider: socialNetwork,
      });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  checkUser: async (): Promise<CognitoUser> => {
    try {
      return await Auth.currentAuthenticatedUser();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getAccessToken: async () => {
    try {
      const session = await Auth.currentSession();
      return session.getAccessToken().getJwtToken();
    } catch (e) {
      return Promise.reject(e);
    }
  },
  getTokenId: async () => {
    try {
      const session = await Auth.currentSession();
      return session.getIdToken().getJwtToken();
    } catch (e) {
      return Promise.reject(e);
    }
  },
  getUserInfo: async () => {
    try {
      return await Auth.currentUserInfo();
    } catch (e) {
      return Promise.reject(e);
    }
  },
};
