import { DEFAULT_ENTERPRISE_SUBSCRIPTION_STATE } from '@/entities/user';
import { Plan, PlanCategory, SubscriptionStatus } from '@/shared/types/Billing';
import { User } from '@/shared/types/User';

export const getEnterpriseSubscriptionState = (user: User | null, plans: Plan[]) => {
  if (!user || !user?.subscription) {
    return DEFAULT_ENTERPRISE_SUBSCRIPTION_STATE;
  }

  const hasEnterprisePlan =
    plans.filter((plan) => plan.id === user.subscription?.plan.product_id && plan.category === PlanCategory.Enterprise)
      .length === 1;
  const hasActiveEnterprisePlan = hasEnterprisePlan && user.subscription?.status === SubscriptionStatus.Active;
  const hasTrialingEnterprisePlan = hasEnterprisePlan && user.subscription?.status === SubscriptionStatus.Trialing;

  return {
    hasEnterprisePlan,
    hasActiveEnterprisePlan,
    hasTrialingEnterprisePlan,
  };
};
