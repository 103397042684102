import { css, Stack, styled, Typography } from '@mui/material';
import { CheckIcon } from '@braskai/icons';

export const Label = styled(Typography)(({ theme }) => ({
  ...theme.typography.textMdRegular,
}));

export const Content = styled(Stack)({
  flexDirection: 'row',
  gap: 8,
  alignItems: 'center',
});

export const SCheckIcon = styled(CheckIcon)(
  ({ theme }) => css`
    color: ${theme.palette.primary[600]};
  `,
);
