import { createQueryParams } from '@/shared/lib/utils';
import { del, get, patch, post } from '../../lib/api';
import { BASE_URL } from '../constants';
import {
  CreateGlossaryRequestData,
  Glossary,
  GlossaryLanguagesResponse,
  GlossaryRequestData,
  GlossaryWithEntries,
  UpdateGlossaryRequestData,
} from './types';

const GLOSSARY_URL = `${BASE_URL}/api/glossary/`;

export const GLOSSARY_API = {
  getGlossaries: ({ src_lang, dst_lang }: GlossaryRequestData) =>
    get<Glossary[]>(`${GLOSSARY_URL}${createQueryParams({ source_lang: src_lang, target_lang: dst_lang })}`),

  createGlossary: (data: CreateGlossaryRequestData) => post<Glossary>(GLOSSARY_URL, { data }),

  deleteGlossary: (glossaryId: string) =>
    del<{
      ok: boolean;
    }>(`${GLOSSARY_URL}?glossary_id=${glossaryId}`),

  glossaryUpdate: ({ glossaryId, ...data }: UpdateGlossaryRequestData) =>
    patch<Glossary>(`${GLOSSARY_URL}${glossaryId}`, { data }),

  getGlossary: (glossaryId: string) => get<GlossaryWithEntries>(`${GLOSSARY_URL}${glossaryId}`),

  getGlossaryLanguages: () => get<GlossaryLanguagesResponse>(`${GLOSSARY_URL}get_languages`),
};
