import { ComponentType, JSX } from 'react';
import { TAny } from '@braskai/utils';

const checkChunk = (text: string, startsWith: string, endsWith: string) => {
  return text.startsWith(startsWith) && text.endsWith(endsWith);
};

const checkIsLink = (text: string) => {
  return checkChunk(text, '[', ']');
};

const checkIsLinkTitle = (text: string) => {
  return checkChunk(text, '(', ')');
};

const checkIsBold = (text: string) => {
  return checkChunk(text, '*', '*');
};

const checkIsGray = (text: string) => {
  return checkChunk(text, '%', '%');
};

type TParsedChunk = {
  text: string;
  href?: string;
  isBold?: boolean;
  isGray?: boolean;
  className?: string;
};

const parseText = (text?: string): TParsedChunk[] => {
  if (!text) {
    return [];
  }

  const linksAndBoldAndGrayText = /(\(\b[^)]*?\)|\[[^[\]]*?\]|\*[^*]*?\*|\[[^[\]]*?\]|%[^*]*?%)/g;
  const splitText = text.split(linksAndBoldAndGrayText).filter(Boolean);

  if (!splitText.length) {
    return [];
  }

  const parsedChunks: TParsedChunk[] = [];

  splitText.forEach((chunk, index) => {
    const prevItem = splitText[index - 1] || '';
    const nextItem = splitText[index + 1] || '';

    const isLink = checkIsLink(chunk);
    const isLinkTitle = checkIsLinkTitle(chunk) && checkIsLink(nextItem);

    const parsedText = chunk.slice(1, -1);

    if (isLinkTitle) {
      return;
    }

    if (isLink) {
      const hasTitle = checkIsLinkTitle(prevItem);
      const titleWithoutBrackets = prevItem.replace(/[()]/g, '');

      parsedChunks.push({
        text: hasTitle ? titleWithoutBrackets : parsedText,
        href: parsedText,
      });
      return;
    }

    const isBold = checkIsBold(chunk);
    const isGray = checkIsGray(chunk);

    parsedChunks.push({
      text: isBold || isGray ? parsedText : chunk,
      isBold,
      isGray,
      className: isBold ? 'parsedBoldText' : 'parsedDefaultText',
    });
  });

  return parsedChunks;
};

export const createTextParser = (
  Link: ComponentType<TAny>,
  DescriptionText: ComponentType<TAny>,
): ((text?: string) => JSX.Element | string) => {
  return function (text?: string): JSX.Element | string {
    if (!text) {
      return '';
    }

    const parsedChunks = parseText(text);

    return (
      <>
        {parsedChunks.map((chunk) => {
          if (chunk.href) {
            return (
              <Link target="_blank" key={chunk.href} to={chunk.href} href={chunk.href}>
                {chunk.text}
              </Link>
            );
          }

          return (
            <DescriptionText className={chunk.className} key={chunk.text} $isGray={chunk.isGray} $isBold={chunk.isBold}>
              {chunk.text}
            </DescriptionText>
          );
        })}
      </>
    );
  };
};
