import { Slider as MuiSlider, SliderProps, styled, Box, Typography, Stack } from '@mui/material';

export const Container = styled(Box)(({ theme }) => ({
  borderRadius: 12,
  outline: `1px solid ${theme.palette.grey[200]}`,
  padding: '20px 16px 16px',
}));

export const SliderContainer = styled(Box)({
  padding: '20px 16px 16px',
});

export const Slider = styled((props: SliderProps) => <MuiSlider {...props} />)(({ theme }) => ({
  height: 8,
  borderRadius: 4,
  margin: 0,
  paddingBottom: 6,

  '& .MuiSlider-thumb': {
    height: 22,
    width: 22,
    border: `2px solid ${theme.palette.primary[600]}`,
    backgroundColor: theme.palette.common.white,

    '&:focus, &:hover, &.Mui-active': {
      boxShadow: theme.customShadows.md,
    },
  },

  '& .MuiSlider-markLabel': {
    ...theme.typography.textSmMedium,

    top: -20,
    color: theme.palette.grey[700],
    '&:before': {
      display: 'none',
    },
    '& *': {
      background: 'transparent',
    },

    '&[data-index="0"]': {},

    '&[data-index="4"]': {
      right: -50,
    },
  },

  '& .MuiSlider-track': {
    border: 'none',
    background: theme.palette.primary[600],
  },

  '& .MuiSlider-rail': {
    opacity: 1,
    backgroundColor: theme.palette.grey[200],
  },

  '& .MuiSlider-mark': {
    backgroundColor: '#fff',
    height: 6,
    width: 6,
    opacity: 1,
    borderRadius: '50%',

    '&[data-index="0"], &[data-index="4"]': {
      background: 'transparent',
    },
  },
}));

export const TotalAndPriceContainer = styled(Stack)({
  flexDirection: 'row',
  gap: 8,
  width: '100%',
});

export const TotalContainer = styled(Stack)({
  gap: 5,
  flexGrow: 1,
});

export const PriceContainer = styled(Stack)({
  gap: 5,
});

export const Label = styled(Typography)(({ theme }) => ({
  ...theme.typography.textSmMedium,
  display: 'block',
}));

export const Price = styled(Box)(({ theme }) => ({
  ...theme.typography.textMdRegular,
  padding: '10px 14px',
  backgroundColor: theme.palette.grey[50],
  borderRadius: 8,
}));
