import { useCallback } from 'react';
import { EModalType } from '@/shared/types/Modal';
import {
  DeleteSegmentModalPayload,
  SetupCustomerCreditsModalPayload,
  SetupCustomerModalPayload,
  SetupCustomerSubscriptionModalPayload,
  TChooseSpeakerModalPayload,
  TSplitSegmentModalPayload,
} from './types';
import { useModalContext } from './useModalContext';

export const useModal = () => {
  const { openModal, closeModal } = useModalContext();

  const openBuyMinutesModal = useCallback(() => {
    openModal({ type: EModalType.BuyMinutes });
  }, [openModal]);

  const openDeleteSegmentModal = useCallback(
    (payload: DeleteSegmentModalPayload) => {
      openModal({ type: EModalType.DeleteSegment, ...payload });
    },
    [openModal],
  );

  const openSetupCustomerModal = useCallback(
    (payload: SetupCustomerModalPayload) => {
      openModal({ type: EModalType.SetupCustomer, ...payload });
    },
    [openModal],
  );

  const openSetupCustomerSubscriptionModal = useCallback(
    (payload: SetupCustomerSubscriptionModalPayload) => {
      openModal({ type: EModalType.SetupCustomerSubscription, ...payload });
    },
    [openModal],
  );

  const openSetupCustomerCreditsModal = useCallback(
    (payload: SetupCustomerCreditsModalPayload) => {
      openModal({ type: EModalType.SetupCustomerCredits, ...payload });
    },
    [openModal],
  );

  const openPasswordConfirmationModal = useCallback(() => {
    openModal({ type: EModalType.PasswordConfirmation });
  }, [openModal]);

  const openDeleteAccountConfirmationModal = useCallback(() => {
    openModal({ type: EModalType.DeleteAccountConfirmation });
  }, [openModal]);

  const openUpgradeFromTrialModal = useCallback(() => {
    openModal({ type: EModalType.UpgradeFromTrial });
  }, [openModal]);

  const openMobileNoticeModal = useCallback(() => {
    openModal({ type: EModalType.MobileNotice });
  }, [openModal]);

  const openPaywallModal = useCallback(() => {
    openModal({ type: EModalType.Paywall });
  }, [openModal]);

  const openMobilePaywallModal = useCallback(() => {
    openModal({ type: EModalType.MobilePaywall });
  }, [openModal]);

  const openEnterprisePaymentRequiredModal = useCallback(() => {
    openModal({ type: EModalType.EnterprisePaymentRequired });
  }, [openModal]);

  const openMultilanguageModal = useCallback(() => {
    openModal({ type: EModalType.Multilanguage });
  }, [openModal]);

  const openSuccessfulSubscriptionRenewalModal = useCallback(() => {
    openModal({ type: EModalType.SuccessfulSubscriptionRenewal });
  }, [openModal]);

  const openSplitSegmentModal = useCallback(
    (payload: TSplitSegmentModalPayload) => {
      openModal({ type: EModalType.SplitSegment, ...payload });
    },
    [openModal],
  );

  const openChooseSpeakerModal = useCallback(
    (payload: TChooseSpeakerModalPayload) => {
      openModal({ type: EModalType.ChooseSpeaker, ...payload });
    },
    [openModal],
  );

  return {
    closeModal,
    openUpgradeFromTrialModal,
    openBuyMinutesModal,
    openDeleteSegmentModal,
    openSetupCustomerCreditsModal,
    openSetupCustomerModal,
    openSetupCustomerSubscriptionModal,
    openPasswordConfirmationModal,
    openDeleteAccountConfirmationModal,
    openMobileNoticeModal,
    openPaywallModal,
    openMobilePaywallModal,
    openEnterprisePaymentRequiredModal,
    openMultilanguageModal,
    openSuccessfulSubscriptionRenewalModal,
    openSplitSegmentModal,
    openChooseSpeakerModal,
  };
};
