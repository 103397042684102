import { queryClient } from '@/shared/lib/config';
import { Products } from '@/shared/types/Billing';
import { getPlanName } from '@/shared/utils-tmp/subscription/getPlanName';
import { getPlanPeriodLabel } from '@/shared/utils-tmp/subscription/getPlanPeriodLabel';
import { store } from '../../../store-tmp';

export const getMixpanelSubscriptionData = () => {
  const {
    user,
    billing: {
      balance: { hasActiveSubscription, hasTrialPlan, hasFreeSubscription },
    },
  } = store.getState();

  const products = queryClient.getQueryData(['products']) as Products;

  const planName = getPlanName({ user, products, hasFreeSubscription, hasTrialPlan });
  const periodLabel = getPlanPeriodLabel(user);

  return {
    hasActiveSubscription,
    hasTrialPlan,
    ...(hasActiveSubscription && { subscription: `${planName} ${periodLabel}` }),
  };
};
