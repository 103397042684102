import ACApp from '@tiktok-service/app-center-connect';

const { VITE_TIKTOK_APP_ID } = import.meta.env;

const app = new ACApp(VITE_TIKTOK_APP_ID);

export const redirectToAuthTiktok = () => {
  app.redirectToAuth('CMAPI');
};

export const getTiktokSession = async () => {
  try {
    return app.getSessionToken();
  } catch (error) {
    console.error('tiktok-error', error);
    redirectToAuthTiktok();
  }
};
