import { css, styled } from '@mui/material';
import { styledOmitCustomProps, Typography, TypographyProps } from '@braskai/ui-kit';
import { NativeVideoPlayer } from '@/shared/ui';

type TSImageProps = {
  $isGif?: boolean;
} & TypographyProps;

export const SImage = styled(
  'img',
  styledOmitCustomProps,
)<TSImageProps>(
  ({ $isGif, theme }) => css`
    width: 100%;
    border-radius: 12px;
    border: ${$isGif ? `1px solid ${theme.palette.grey[200]}` : 'none'};
  `,
);

export const SNativeVideoPlayer = styled(NativeVideoPlayer)(
  () => css`
    max-height: 243px;
  `,
);

type TSDescriptionTextProps = {
  $isGray?: boolean;
  $isBold?: boolean;
} & TypographyProps;

export const SDescriptionText = styled(
  Typography,
  styledOmitCustomProps,
)<TSDescriptionTextProps>(
  ({ $isGray, $isBold, theme }) => css`
    display: inline;
    color: ${$isGray ? theme.palette.grey[400] : theme.palette.grey[700]};
    font-weight: ${$isBold ? 500 : 'inherit'};
  `,
);
