export type TSrc = {
  text: string;
  lang: string;
};

export type TDst = TSrc;

export const SegmentStatus = {
  Done: 'done',
  Processing: 'processing',
  Error: 'error',
  Updated: 'updated',
} as const;

export type TSegmentStatus = (typeof SegmentStatus)[keyof typeof SegmentStatus];

export type TServerSegment = {
  id: string;
  status: TSegmentStatus;
  speaker: string;
  start: string;
  end: string;
  src: TSrc;
  dst: TDst;
};

export type TExtendedSegment = TServerSegment & {
  isSavingError?: boolean;
  isEditingSrc?: boolean;
  isMerging?: boolean;
  overlappedTimestamps?: (TServerSegment['start'] | TServerSegment['end'])[];
  isRewriting?: boolean;
  rewritingResult?: string;
};

export type TAutoSavingSegmentData = {
  speaker?: TExtendedSegment['speaker'];
  start?: TExtendedSegment['start'];
  end?: TExtendedSegment['end'];
  dst?: TExtendedSegment['dst'];
};

export type TUpdatingSegmentData = Pick<TExtendedSegment, 'id'> &
  (TAutoSavingSegmentData | { src: TExtendedSegment['src']; speaker: TExtendedSegment['speaker'] });

export type ParsedSrt = {
  id: string;
  text: string;
  startTime: string;
  endTime: string;
  startSeconds: number;
  endSeconds: number;
  speakerId: string | null;
};

export const SegmentLocation = {
  Top: 'top',
  Bottom: 'bottom',
} as const;

export type TSplittingSegmentData = Pick<TServerSegment, 'start' | 'end'> & {
  src?: TServerSegment['src'];
  dst?: TServerSegment['dst'];
};
export type TSegmentLocation = (typeof SegmentLocation)[keyof typeof SegmentLocation];
