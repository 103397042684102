import { useState } from 'react';
import { ACCOUNTS_API } from '@/shared/api/accounts';
import { useAppDispatch } from '@/shared/hooks-tmp/reduxToolkitHooks';
import { AUTH_API } from '@/shared/api/amplify';
import { logout } from '@/shared/utils-tmp/logout';
import { API } from '@/shared/api';
import { resetStore } from '@/shared/store-tmp/RootReducer';
import { setAuthForm } from '@/entities/viewer';
import { AuthForm } from '@/shared/types/Cognito';
import { showErrorAlert } from '@/shared/utils-tmp/alert/showErrorAlert';
import { inIframe } from '@/shared/lib/utils';
import { redirectToAuthTiktok } from '@/entities/user/tiktok';
import { Dialog, DialogActions, DialogTitle } from '../../../ui-tmp';
import { CancelButton, DeleteButton, Subtitle, Title } from './styled';

type TAccountSettingsModalProps = {
  onClose: () => void;
};

export const DeleteAccountModal = ({ onClose }: TAccountSettingsModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  const deleteAccount = async () => {
    try {
      setIsLoading(true);

      if (inIframe()) {
        const [localizationResponse] = await Promise.all([API.deleteUser(), await ACCOUNTS_API.deleteAccount()]);

        if (localizationResponse.ok) {
          await dispatch(resetStore());
          redirectToAuthTiktok();
          onClose();
        }
      } else {
        const [cognitoResponse, backendResponse] = await Promise.all([
          AUTH_API.deleteAccount(),
          API.deleteUser(),
          await ACCOUNTS_API.deleteAccount(),
        ]);

        if (cognitoResponse && backendResponse.ok) {
          await logout();
          await dispatch(resetStore());
          onClose();
          setAuthForm(AuthForm.Signup);
        }
      }
    } catch (e) {
      showErrorAlert(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open onClose={isLoading ? undefined : onClose} size="sm">
      <DialogTitle
        onClose={isLoading ? undefined : onClose}
        renderTitle={() => <Title>Are you sure you want to delete your account?</Title>}
        renderSubtitle={() => <Subtitle>All your data will be permanently deleted.</Subtitle>}
        buttonsDisabled={isLoading}
      />

      <DialogActions paddingTop={0}>
        <CancelButton onClick={onClose} disabled={isLoading}>
          Cancel
        </CancelButton>

        <DeleteButton onClick={deleteAccount} isLoading={isLoading} disabled={isLoading}>
          Delete
        </DeleteButton>
      </DialogActions>
    </Dialog>
  );
};
