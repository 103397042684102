import { Control } from 'react-hook-form';
import { IS_WHITE_LABEL } from '@/shared/lib/environments';
import { DialogActions, DialogTitle, Link } from '@/shared/ui-tmp';
import { MULTILANGUAGE_DOC_LINK } from '@/shared/lib/constants/docLinks';
import { Languages } from '@/shared/types/Settings';
import { ControlledLanguageAutocomplete } from '../../../../controlled/ControlledLanguageAutocomplete';
import { SButton as STranslateButton, SSubtitle, STitle } from '../../styled';
import { IFormValues } from '../../hooks/useMultilanguage';
import { DATA_TEST_IDS } from '../../lib/constants';
import { SContent } from './styled';

type IMultilanguageFormProps = {
  isSubmitting: boolean;
  languages: Languages;
  control: Control<IFormValues>;
  isFormInvalid: boolean;
  onSubmit: () => Promise<void>;
  onClose: () => void;
};

export const MultilanguageForm = ({
  isFormInvalid,
  isSubmitting,
  languages,
  onSubmit,
  onClose,
  control,
}: IMultilanguageFormProps) => {
  return (
    <>
      <DialogTitle
        buttonsDisabled={isSubmitting}
        renderTitle={() => <STitle>Choose languages for translation</STitle>}
        renderSubtitle={() => (
          <SSubtitle>
            We'll reuse the original video transcription so you don't need to proofread it twice.{' '}
            {!IS_WHITE_LABEL && (
              <>
                <Link variant="textSmMedium" href={MULTILANGUAGE_DOC_LINK}>
                  Learn more
                </Link>{' '}
                how to effectively utilize this feature.
              </>
            )}
          </SSubtitle>
        )}
        onClose={onClose}
      />

      <SContent>
        <ControlledLanguageAutocomplete
          control={control}
          options={languages}
          controller="dst_lang"
          isDisabled={isSubmitting}
          label="Translate to"
          type="dst_lang"
          data-test-id={DATA_TEST_IDS.translateLanguageSelect}
        />
      </SContent>

      <DialogActions paddingTop={0}>
        <STranslateButton
          isLoading={isSubmitting}
          disabled={isSubmitting || isFormInvalid}
          onClick={onSubmit}
          data-test-id={DATA_TEST_IDS.translateButton}
        >
          {isSubmitting ? 'Submitting...' : 'Translate'}
        </STranslateButton>
      </DialogActions>
    </>
  );
};
