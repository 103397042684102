import { TextField, useTheme, TextFieldProps, Typography, styled } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { forwardRef, JSX } from 'react';
import type { TAny } from '@braskai/utils';

type SelectProps = {
  width?: number;
  borderless?: boolean;
  multiple?: boolean;
  renderValue?: () => string | JSX.Element;
  tiny?: boolean;
  isMenuInModal?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  open?: boolean;
} & TextFieldProps;

const StyledPlaceholder = styled(Typography)(({ theme }) => ({
  ...theme.typography.textMdRegular,
  color: theme.palette.grey[500],
}));

const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'borderless' && prop !== 'tiny',
})<SelectProps>(({ borderless, width, tiny, theme }) => ({
  border: 'none',
  width: width || undefined,
  ...theme.typography.textMdRegular,

  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: 8,
      border: borderless ? 'none' : `1px solid ${theme.palette.grey[300]}`,
    },

    '&:hover:not(.Mui-disabled):not(.Mui-error) fieldset': {
      border: borderless ? 'none' : `1px solid ${theme.palette.grey[300]}`,
    },

    '&.Mui-focused fieldset': {
      border: borderless ? 'none' : `1px solid ${theme.palette.grey[300]}`,
      boxShadow: borderless ? 'none' : `0px 0px 0px 4px ${theme.palette.primary[100]}`,
    },
    '& .MuiOutlinedInput-input': {
      padding: tiny ? '5px 32px 5px 0' : '10px 14px',
      color: tiny ? theme.palette.grey[500] : theme.palette.grey[900],
      '&::placeholder': { color: theme.palette.grey[500], ...theme.typography.textMdRegular },
    },

    ...(!borderless && {
      '&.Mui-disabled fieldset': {
        border: `1px solid ${theme.palette.grey[300]}`,
      },
    }),

    ...(tiny && {
      '& .MuiSelect-select.MuiSelect-outlined': {
        ...theme.typography.textXsMedium,
        paddingRight: 24,

        '&:hover': {
          color: theme.palette.grey[900],
        },
      },

      '& .MuiSvgIcon-root': {
        width: 16,
        height: 16,
        top: 7,
      },
    }),

    '&.Mui-error > .MuiSelect-icon': {
      color: theme.palette.error[500],
    },
  },

  '.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${theme.palette.error[300]}`,
    boxShadow: `0px 0px 0px 4px ${theme.palette.error[100]}`,
  },

  '&:hover .Mui-error .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${theme.palette.error[500]}`,
  },

  '& .MuiFormHelperText-root': {
    ...theme.typography.textSmRegular,
    margin: '8px 0 0',
    color: theme.palette.grey[500],

    '&.Mui-error': {
      color: theme.palette.error[500],
    },
  },
}));

// todo: пропсы компонента сильно раздулись, отрефакторить и мб разбить на файлы
export const Select = forwardRef<HTMLInputElement, SelectProps>(
  ({ multiple = false, renderValue, tiny, children, onOpen, onClose, open, isMenuInModal, ...props }, ref) => {
    const theme = useTheme();

    const handleRenderValue =
      renderValue ||
      ((selected: TAny) => {
        if (!selected || (selected as string).length === 0) {
          return <StyledPlaceholder>Choose</StyledPlaceholder>;
        }

        if (multiple) {
          return (selected as string[]).join(', ');
        }

        // TODO: type needs to be corrected
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return selected;
      });

    return (
      <StyledTextField
        ref={ref}
        tiny={tiny}
        {...props}
        select
        size="small"
        SelectProps={{
          onOpen,
          onClose,
          open,
          multiple,
          displayEmpty: true,
          IconComponent: KeyboardArrowDownIcon,
          renderValue: handleRenderValue,
          MenuProps: {
            sx: {
              zIndex: isMenuInModal ? theme.zIndex.dialog : theme.zIndex.popover,
            },
            PaperProps: {
              sx: {
                borderRadius: 8,
                border: `1px solid ${theme.palette.grey[300]}`,
                boxShadow: theme.customShadows.lg,
                marginTop: 5,
                maxHeight: 400,

                '& .MuiMenuItem-root': {
                  padding: '10px 14px',

                  '&:hover': {
                    backgroundColor: theme.palette.grey[50],
                  },
                },

                ...(tiny
                  ? {
                      '& .MuiMenu-list': {
                        padding: 4,
                      },
                    }
                  : {}),
              },
            },
          },
        }}
      >
        {children}
      </StyledTextField>
    );
  },
);
