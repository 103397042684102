import { Stack, styled, Typography } from '@mui/material';
import { Button, ButtonProps } from '@braskai/ui-kit';
import { DialogActionsProps } from '@/shared/ui-tmp/dialog/DialogActions';
import { DialogActions } from '../../../ui-tmp';

export const Title = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  maxWidth: 315,

  [theme.breakpoints.up('mobileLarge')]: {
    maxWidth: 410,
  },
}));

export const Subtitle = styled('span')({
  display: 'block',
  textAlign: 'left',
  color: 'inherit',
});

export const CancelButton = styled((props: ButtonProps) => (
  <Button variant="outlined" fullWidth size="lg" {...props} />
))('');

export const DeleteButton = styled((props: ButtonProps) => <Button fullWidth size="lg" variant="error" {...props} />)(
  '',
);

export const ActionsContainer = styled((props: DialogActionsProps) => <DialogActions paddingTop={25} {...props} />)({
  flexDirection: 'column',
});

export const ActionButton = styled(Stack)(({ theme }) => ({
  ...theme.typography.textSmMedium,
  flexDirection: 'row',
  alignItems: 'center',
  gap: 9,
  color: theme.palette.grey[500],
  cursor: 'pointer',

  '& path': {
    stroke: theme.palette.grey[500],
  },
}));
