import { useCreateProjectModalStore } from '@/features/project';
import { Dialog, DialogTitle } from '../../../ui-tmp';

import { BackButton, Content, Message } from './styled';

type MobileNoticeModalProps = {
  onClose: () => void;
};
export const MobileNoticeModal = ({ onClose }: MobileNoticeModalProps) => {
  const { open: openCreateProjectModal } = useCreateProjectModalStore();

  const handleBackButtonClick = () => {
    openCreateProjectModal();
  };

  return (
    <Dialog open onClose={onClose} size="sm">
      <DialogTitle onClose={onClose} renderTitle={() => '📱Mobile Notice'} />
      <Content>
        <Message>To use this feature, please switch to a desktop device and view this page in full screen</Message>

        <BackButton onClick={handleBackButtonClick}>Back</BackButton>
      </Content>
    </Dialog>
  );
};
