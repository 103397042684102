import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useState } from 'react';
import { Control, FieldPath, FieldValues } from 'react-hook-form';
import { validatePassword } from '@/shared/utils-tmp/validators/inputValidators';
import { ControlledTextField } from '../../controlled/ControlledTextField';
import { VisibilityIconContainer } from './styled';

type PasswordInputProps<T extends FieldValues> = {
  control: Control<T>;
  label?: string;
  placeholder?: string;
  isDisabled: boolean;
  autocomplete?: string;
  fieldName?: FieldPath<T> | string;
  isSmallVisibilityIcon?: boolean;
} & FieldValues;

export const PasswordInput = <T extends FieldValues>({
  control,
  label,
  placeholder,
  isDisabled,
  autocomplete,
  fieldName = 'password',
  isSmallVisibilityIcon,
}: PasswordInputProps<T>) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <ControlledTextField
      fieldName={fieldName as FieldPath<T>}
      control={control}
      label={label || 'Password'}
      placeholder={placeholder || ''}
      disabled={isDisabled}
      validator={validatePassword}
      inputProps={{
        autoComplete: autocomplete || 'off',
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={{
        endAdornment: (
          <VisibilityIconContainer
            onClick={handleClickShowPassword}
            edge="end"
            isSmallVisibilityIcon={isSmallVisibilityIcon}
          >
            {showPassword ? (
              <VisibilityOffOutlinedIcon fontSize={isSmallVisibilityIcon ? 'small' : 'inherit'} />
            ) : (
              <VisibilityOutlinedIcon fontSize={isSmallVisibilityIcon ? 'small' : 'inherit'} />
            )}
          </VisibilityIconContainer>
        ),
      }}
      showEndAdornmentOnError={false}
      type={showPassword ? 'text' : 'password'}
    />
  );
};
