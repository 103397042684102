import { styled, FormControlLabel, FormControlProps, css } from '@mui/material';

type ContainerProps = Omit<FormControlProps, 'size'> & {
  variant?: 'outlined';
  size?: 'md';
};

export const Container = styled(FormControlLabel)<ContainerProps>(
  ({ size, variant, theme }) => css`
    gap: 8px;
    box-sizing: border-box;
    margin: 0;

    ${variant === 'outlined' &&
    css`
      border: 1px solid ${theme.palette.grey[200]};
    `}

    ${size === 'md' &&
    css`
      padding: 12px;
      border-radius: 10px;
    `}

    .MuiFormControlLabel-label {
      display: flex;
    }
  `,
);
