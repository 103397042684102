import { AttentionIcon } from '../../../../../ui-tmp';
import { TitleContent, Container } from './styled';

export const Title = () => {
  return (
    <Container>
      <AttentionIcon />

      <TitleContent>
        The queue for free users is
        <br /> currently overloaded
      </TitleContent>
    </Container>
  );
};
