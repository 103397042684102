import { styled, Typography, TypographyProps, StackProps, Stack } from '@mui/material';

type ContainerProps = {
  fullwidth?: boolean;
} & StackProps;

export const Container = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'fullwidth',
})<ContainerProps>(({ fullwidth }) => ({
  width: fullwidth ? '100%' : 'auto',
  alignItems: 'center',
  gap: 6,
}));

type LabelProps = {
  labelSize?: 'sm' | 'md';
} & TypographyProps;

export const Label = styled(Typography, { shouldForwardProp: (prop) => prop !== 'labelSize' })<LabelProps>(
  ({ labelSize = 'md', theme }) => ({
    ...(labelSize === 'sm' && {
      ...theme.typography.textSmMedium,
    }),

    ...(labelSize === 'md' && {
      ...theme.typography.textMdMedium,
    }),

    color: theme.palette.grey[700],
    textAlign: 'left',
    width: '100%',
  }),
);
