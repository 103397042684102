import axios, { AxiosError, Method, AxiosProgressEvent, GenericAbortSignal, ParamsSerializerOptions } from 'axios';
import { SERVER_ERROR_MESSAGE } from '@/shared/api/errors';

const GENERIC_AXIOS_ERRORS = {
  tooManyRedirectsError: 'ERR_FR_TOO_MANY_REDIRECTS',
  badOptionValueError: 'ERR_BAD_OPTION_VALUE',
  badOptionError: 'ERR_BAD_OPTION',
  networkError: 'ERR_NETWORK',
  deprecatedError: 'ERR_DEPRECATED',
  badResponseError: 'ERR_BAD_RESPONSE',
  badRequestError: 'ERR_BAD_REQUEST',
  notSupportError: 'ERR_NOT_SUPPORT',
  invalidURLError: 'ERR_INVALID_URL',
  canceledError: 'ERR_CANCELED',
  connAbortedError: 'ECONNABORTED',
  timeoutError: 'ETIMEDOUT',
} as const;

type TRequestClosure<ResponseData, Params extends object = object, Data = object> = {
  data?: Data;
  params?: Params;
  type?: string;
  signal?: GenericAbortSignal;
  adapter?: (data: ResponseData) => ResponseData;
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
  paramsSerializer?: ParamsSerializerOptions;
};

const requestClosure =
  (method: Method) =>
  <ResponseData, Params extends object = object, Data = object>(
    url: string,
    {
      data,
      params,
      type = 'application/json',
      signal,
      adapter,
      onUploadProgress,
      paramsSerializer,
    }: TRequestClosure<ResponseData, Params, Data> = {},
  ) => {
    const headers = {
      ...(method === 'post' || method === 'put' || method === 'patch' ? { 'Content-Type': type } : {}),
    };

    return new Promise<ResponseData>((resolve, reject) => {
      axios
        .request<ResponseData>({
          headers,
          method,
          url,
          params,
          signal,
          data,
          onUploadProgress,
          paramsSerializer,
        })
        .then((response) => adapter?.(response.data) ?? response.data)
        .then((response) => resolve(response))
        .catch((error: AxiosError) => {
          if (error.response) {
            const { data: errorPayload, status, statusText } = error.response;
            reject({ status, statusText, data: errorPayload });
            return;
          }

          if (!error.response && error.request && error.code === GENERIC_AXIOS_ERRORS.networkError) {
            reject({ status: 504, statusText: 'Gateway Timeout', data: null });
            return;
          }

          // Подстраховка: мы не ожидаем, что попадаем в этот reject
          // когда-либо, но если это случится то выбросим стандартную ошибку
          // и showErrorAlert успешно обработает ее
          reject(new Error(SERVER_ERROR_MESSAGE));
        });
    });
  };
export const post = requestClosure('post');
export const get = requestClosure('get');
export const put = requestClosure('put');
export const patch = requestClosure('patch');
export const del = requestClosure('delete');
