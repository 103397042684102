import './index.css';
import './polyfills';

import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import App from './app';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);
