import { useAppSelector } from '@/shared/hooks-tmp/reduxToolkitHooks';
import { useProducts } from '@/entities/pricing';
import { LimitedFeature, PLAN_CATEGORIES } from '../lib/types';
import { CONTENT } from '../lib/limitedFeatures';

export const useUpgradePlanModalContent = (limitedFeature: LimitedFeature) => {
  const { products } = useProducts();

  const user = useAppSelector((state) => state.user);

  const userPlanCategory =
    products?.plan.find((plan) => plan.id === user?.subscription?.plan.product_id)?.category ?? 'trial';

  const planCategory = PLAN_CATEGORIES[userPlanCategory];

  return {
    planCategory,
    content: CONTENT[limitedFeature][planCategory] ?? CONTENT[limitedFeature]['trial'],
  };
};
