export const PATHS = {
  any: '*',
  homepage: '/',
  auth: 'auth',
  project: 'project',
  voices: 'voices',
  pricing: 'pricing',
  account: 'account',
  checkout: 'checkout',
  admin: {
    homepage: '/kh39sk39_dsf952',
    customers: 'customers',
    feedback: 'feedback',
    survey: 'survey',
  },
  glossaries: 'glossaries',
} as const;
