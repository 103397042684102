import { getMappedEvents } from '../utils/getMappedEvents';

const EVENTS = [
  'uploadVideoButtonPressed',
  'uploadVideoButtonTrialEndClick',
  'tryOurSampleUploaded',
  'ttsUploadVideo',
  'limitsMessageShown',
  'limitsUpgradeClick',
] as const;

export const uploadVideoEvents = getMappedEvents([...EVENTS], { shouldSendSubscriptionData: true });
