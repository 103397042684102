import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdaptedSubscriptionStatus } from '@/shared/types/Billing';
import { DEFAULT_ENTERPRISE_SUBSCRIPTION_STATE, DEFAULT_SUBSCRIPTION_STATE } from '@/entities/user';

type TBillingStore = {
  isBillingModalOpen: boolean;
  hasEnoughMinutes: boolean;
  balance: {
    subscriptionStatus: AdaptedSubscriptionStatus | null;
    hasTrialPlan: boolean;
    hasActiveSubscription: boolean;
    hasPausedSubscription: boolean;
    hasExpiredSubscription: boolean;
    hasFreeSubscription: boolean;
    hasCanceledSubscription: boolean;
    hasSubscriptionCancelAtPeriodEnd: boolean;
    hasUnpaidSubscription: boolean;
    hasPastDueSubscription: boolean;
    hasMinutes: boolean;
    hasFreeVideos: boolean;
    hasFreeLipSyncMinutes: boolean;
    availableMinutes: number;
    availableFreeVideos: number;
    canBuyMinutes: boolean;
    hasArchivedSubscription: boolean;
  };
  enterprise: {
    hasEnterprisePlan: boolean;
    hasActiveEnterprisePlan: boolean;
    hasTrialingEnterprisePlan: boolean;
  };
};

const initialState: TBillingStore = {
  isBillingModalOpen: false,
  hasEnoughMinutes: false,
  balance: DEFAULT_SUBSCRIPTION_STATE,
  enterprise: DEFAULT_ENTERPRISE_SUBSCRIPTION_STATE,
};

const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    setIsNeedToPay: (state, { payload }: PayloadAction<TBillingStore['hasEnoughMinutes']>) => {
      state.hasEnoughMinutes = payload;
    },

    setBalance: (state, { payload }: PayloadAction<Partial<TBillingStore['balance']>>) => {
      state.balance = {
        ...state.balance,
        ...payload,
      };
    },

    setEnterpriseSubscriptionState: (state, { payload }: PayloadAction<TBillingStore['enterprise']>) => {
      state.enterprise = payload;
    },
  },
});

export const billingReducer = billingSlice.reducer;
export const { setIsNeedToPay, setBalance, setEnterpriseSubscriptionState } = billingSlice.actions;
