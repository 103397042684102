import { styled, Typography, TypographyProps } from '@mui/material';
import { ButtonProps, Button as BaseButton } from '@braskai/ui-kit';

export const STitle = styled((props: TypographyProps) => (
  <Typography variant="textLgMedium" textAlign="left" display="block" {...props} />
))({});

export const SSubtitle = styled((props: TypographyProps) => (
  <Typography variant="textSmRegular" component="span" textAlign="left" display="block" {...props} />
))(({ theme }) => ({
  color: theme.palette.grey[500],
}));

export const SButton = styled((props: ButtonProps) => <BaseButton size="xl" fullWidth {...props} />)({});
