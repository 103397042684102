import { SERVER_ERROR_MESSAGE } from '@/shared/api/errors';
import { showAlert } from '@/shared/store-tmp/AlertSlice';
import { CustomError } from '@/shared/types/Error';
import { store } from '../../store-tmp';

export const showErrorAlert = (error?: unknown) => {
  if (error instanceof Error) {
    store.dispatch(showAlert({ message: error.message }));
    return;
  }

  if (typeof error === 'object' && error !== null) {
    const { data, statusText } = error as CustomError;
    if (data && typeof data === 'object' && data !== null && typeof data.detail === 'string') {
      store.dispatch(showAlert({ message: data.detail }));
      return;
    }

    if (data && typeof data === 'string') {
      return store.dispatch(showAlert({ message: data }));
    }

    if (!data || (typeof data === 'object' && Array.isArray(data.detail))) {
      store.dispatch(showAlert({ message: statusText }));
      return;
    }
  }
  store.dispatch(showAlert({ message: SERVER_ERROR_MESSAGE }));
};
