import { Products } from '@/shared/types/Billing';
import { get, post } from '../../lib/api';
import { BASE_URL } from '../constants';
import {
  IApplyPromoRequest,
  IApplyPromoResponse,
  IGetCheckoutSessionResponse,
  IGetLatestPaymentInfoResponse,
  IGetPaymentSessionRequestData,
  ICreateSubscriptionIntentRequest,
  ICreateSubscriptionIntentResponse,
  IGetSubscriptionSessionRequestData,
} from './types';

const URL = `${BASE_URL}/api/payments`;

export const BILLING_API = {
  // Get all plans
  getPlans: () => get<Products>(`${URL}/products`),

  // Buy subscription or minutes
  getBuySubscriptionCheckoutUrl: ({ id, success_url, cancel_url }: IGetSubscriptionSessionRequestData) =>
    get<IGetCheckoutSessionResponse>(
      `${URL}/create_checkout_session_subscription?price_id=${id}&success_url=${success_url}&cancel_url=${cancel_url}`,
    ),

  getBuyCreditsCheckoutUrl: ({ id, success_url, cancel_url, quantity }: IGetPaymentSessionRequestData) =>
    get<IGetCheckoutSessionResponse>(
      `${URL}/create_checkout_session_payment?price_id=${id}&quantity=${quantity}&success_url=${success_url}&cancel_url=${cancel_url}`,
    ),

  // Update customer billing details
  updateCustomerBillingDetails: (data: { [key: string]: string | object }) =>
    post<{
      ok: boolean;
    }>(`${URL}/update_customer_billing_details`, { data }),

  // Get link to Customer Portal
  getCustomerPortal: (url: string) => get<{ billing_portal_url: string }>(`${URL}/billing_portal?return_url=${url}`),

  // Other
  getLatestPaymentInfo: () => get<IGetLatestPaymentInfoResponse>(`${URL}/latest_payment_info`),

  // Cancel Subscription Cancellation (Pending Cancellation state)
  cancelSubscriptionCancellation: () => post<{ ok: boolean }>(`${URL}/cancel_subscription_cancellation`),

  createSubscriptionIntent: (data: ICreateSubscriptionIntentRequest) =>
    post<ICreateSubscriptionIntentResponse>(`${URL}/subscription`, { data }),

  applyPromoCode: (data: IApplyPromoRequest) => post<IApplyPromoResponse>(`${URL}/promotion_code`, { data }),
};
