import {
  AmazonIcon,
  Container,
  FameplayIcon,
  MengmiuIcon,
  PartnersIconsContainer,
  PartnersTitle,
  PublicisIcon,
  SemrushIcon,
} from './styled';

export type PartnersProps = {
  isSmallFromTablet?: boolean;
};

export const Partners = ({ isSmallFromTablet = false }: PartnersProps) => (
  <Container isSmallFromTablet={isSmallFromTablet}>
    <PartnersTitle isSmallFromTablet={isSmallFromTablet}>Trusted by 1,500,000+ users</PartnersTitle>
    <PartnersIconsContainer isSmallFromTablet={isSmallFromTablet}>
      <FameplayIcon isSmallFromTablet={isSmallFromTablet} />
      <AmazonIcon isSmallFromTablet={isSmallFromTablet} />
      <SemrushIcon isSmallFromTablet={isSmallFromTablet} />
      <MengmiuIcon isSmallFromTablet={isSmallFromTablet} />
      <PublicisIcon isSmallFromTablet={isSmallFromTablet} />
    </PartnersIconsContainer>
  </Container>
);
