import { Stack, styled, StackProps } from '@mui/material';

const Content = styled(Stack)(({ theme }) => ({
  paddingRight: 16,
  paddingLeft: 16,

  [theme.breakpoints.up('mobileLarge')]: {
    paddingRight: 24,
    paddingLeft: 24,
  },
}));

/** @deprecated I recommend using the `import { ModalContent } from '@braskai/ui-kit'` component */
export const DialogContent = (props: StackProps) => {
  return <Content {...props} />;
};
