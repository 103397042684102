import { Controller } from 'react-hook-form';
import { TextField } from '@braskai/ui-kit';
import { SControlledTextFieldLabel, SControlledTextField } from './styled';
import { ControlledTextFieldProps } from './types';

export const ControlledTextField = ({
  control,
  placeholder,
  label,
  fieldName,
  extraSpace,
  disabled = false,
  validator,
  multiline,
  minRows,
  icon,
  notRequired = false,
  className,
  style,
  ...props
}: ControlledTextFieldProps) => {
  return (
    <SControlledTextField className={className} style={style}>
      {label && (
        <SControlledTextFieldLabel variant="textSmMedium" color="grey.700">
          {label}
        </SControlledTextFieldLabel>
      )}
      <Controller
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            placeholder={placeholder}
            error={!!error}
            helperText={error?.message}
            fullWidth
            extraHeight={extraSpace}
            disabled={disabled}
            startAdornment={icon}
            multiline={multiline}
            minRows={minRows}
            {...props}
          />
        )}
        name={fieldName}
        /* TODO: type needs to be corrected */
        /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
        control={control}
        rules={{
          required: notRequired ? undefined : 'Field is required',
          minLength: notRequired ? 0 : 1,
          validate: (value: string) => {
            if (!notRequired && !value.trim()) {
              return 'Field is required';
            }

            return validator && validator(value);
          },
        }}
      />
    </SControlledTextField>
  );
};
