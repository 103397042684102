import { Checkbox as MuiCheckbox, css, styled } from '@mui/material';
import { CheckboxBaseIcon, CheckboxFilledIcon } from '@braskai/icons';

export const BaseCheckbox = styled(MuiCheckbox)(
  () => css`
    &.Mui-disabled {
      opacity: 0.3;
    }
  `,
);

export const SCheckboxBaseIcon = styled(CheckboxBaseIcon)(
  ({ theme }) => css`
    color: ${theme.palette.grey[300]};
  `,
);

export const SCheckboxFilledIcon = styled(CheckboxFilledIcon)(
  ({ theme }) => css`
    color: ${theme.palette.primary[600]};
  `,
);
