import { MixpanelEvents } from '@/shared/thirdParty/mixpanel';
import LipSyncPreviewImg from '@/shared/assets/images/lip-sync-preview.jpg';
import { LimitedFeatureContent, ModalContent } from '../types';
import { BUTTON_TEXTS, BULLETS_HEADER_TEXT } from './texts';
import { getBullets } from './bullets';

const LIP_SYNC_DEMO_VIDEO_SRC = 'https://static.rask.ai/MLlips.mp4';

const BASE_CONTENT: ModalContent = {
  title: 'Unlock Lip-sync feature',
  subtitle: 'Upgrade to Creator Pro plan to add lip-sync to your videos and experience top-notch translation quality',
  bulletsHeader: BULLETS_HEADER_TEXT.withCreatorPro,
  features: getBullets([
    'translate100',
    'lipSync',
    'voiceCloneSettings',
    'voicePresets',
    'srtUploadDownload',
    'subtitles',
    'aiRewrite',
  ]),
  buttonText: BUTTON_TEXTS.toCreatorPro,
  media: {
    type: 'video',
    src: LIP_SYNC_DEMO_VIDEO_SRC,
    poster: LipSyncPreviewImg,
    videoOptions: {
      loop: true,
      autoPlay: true,
      controls: true,
    },
  },
  mixpanelOpeningEvent: MixpanelEvents.lipsyncUpgradeModalShown,
  mixpanelButtonClickEvent: MixpanelEvents.lipsyncUpgradeChoosePlanModalClick,
};

const TRIAL_PLAN_CONTENT = {
  ...BASE_CONTENT,
};

const STARTER_PLAN_CONTENT = {
  ...BASE_CONTENT,
  bulletsHeader: BULLETS_HEADER_TEXT.inStarter,
};

const CREATOR_PLAN_CONTENT = {
  ...BASE_CONTENT,
  bulletsHeader: BULLETS_HEADER_TEXT.inCreator,
};

export const LIP_SYNC_CONTENT: LimitedFeatureContent = {
  trial: TRIAL_PLAN_CONTENT,
  starter: STARTER_PLAN_CONTENT,
  creator: CREATOR_PLAN_CONTENT,
};
