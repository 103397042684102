import { CardMedia, CardMediaProps, Stack, LinearProgress, linearProgressClasses, css, styled } from '@mui/material';
import { styledOmitCustomProps, Typography } from '@braskai/ui-kit';

const fullSizeAbsolute = css`
  position: absolute;
  inset: 0;
`;

type SBaseCardProps = {
  $isHovered?: boolean;
};

const SCardBase = styled(
  Stack,
  styledOmitCustomProps,
)<SBaseCardProps>(
  () => css`
    position: relative;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    aspect-ratio: 16 / 9;
  `,
);

export const SPreviewCard = styled(SCardBase)(
  ({ theme }) => css`
    background-color: ${theme.palette.grey[100]};
  `,
);

export const SPreviewOverlay = styled(Stack)(
  ({ theme }) => css`
    ${fullSizeAbsolute}
    z-index: ${theme.zIndex.basic};
    border-radius: 4px;
    backdrop-filter: blur(2px);
  `,
);

export const SPreviewBackdrop = styled(Stack)(
  ({ theme }) => css`
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: ${theme.palette.common.black};
    opacity: 0.5;
    padding: 4px 6px;
    justify-content: flex-end;
  `,
);

export const SErrorCard = styled(SCardBase)(
  ({ theme }) => css`
    padding: 6px 4px;
    background-color: ${theme.palette.error[50]};
    color: ${theme.palette.error[500]};
    border: 1px solid ${theme.palette.error[200]};
  `,
);

export const VideoPreview = styled('video')`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const SUploadingBlurOverlay = styled(Stack)(
  ({ theme }) => css`
    ${fullSizeAbsolute}
    z-index: ${theme.zIndex.basic};
    width: 100%;
    height: 100%;
    border-radius: 4px;
    backdrop-filter: blur(1px);
    overflow: hidden;
  `,
);

export const SProgress = styled(LinearProgress)(
  ({ theme }) => css`
    ${fullSizeAbsolute}
    height: 100%;
    width: 100%;
    background-color: ${theme.palette.common.black};
    opacity: 0.5;

    .${linearProgressClasses.bar1Determinate} {
      background-color: ${theme.palette.common.white};
      opacity: 0.3;
    }
  `,
);

export const SProgressPercentage = styled(Typography)(
  () => css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `,
);

export const SPreviewImage = styled(CardMedia)<CardMediaProps>(
  () => css`
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    object-fit: cover;
    border-radius: 4px;
  `,
);

export const SDeleteButton = styled(Stack)(
  ({ theme }) => css`
    position: absolute;
    top: -4px;
    right: -4px;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
    background-color: ${theme.palette.common.white};
    color: ${theme.palette.grey[700]};
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    z-index: ${theme.zIndex.sidebar};
  `,
);

export const SFileName = styled(Typography)(
  ({ theme }) => css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    z-index: ${theme.zIndex.popover};
  `,
);
