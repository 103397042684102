import { useMemo } from 'react';
import { theme } from '@braskai/ui-kit';
import { useMediaQuery } from '@mui/material';
import { useAppSelector } from '@/shared/hooks-tmp/reduxToolkitHooks';
import { SCard, SContainer, SLabel, SText } from './styled';

export const MailCard = () => {
  const user = useAppSelector((state) => state.user);
  const isMobileLarge = useMediaQuery(theme.breakpoints.up('mobileLarge'));

  const cardText = useMemo(() => {
    const { email } = user ?? {};

    if (!email) {
      return '';
    }

    const needTruncate = email.length > 26;

    if (!needTruncate) {
      return email;
    }

    const [firstPart, secondPart] = email.split('@');

    return `${firstPart.slice(0, isMobileLarge ? 25 : 5)}...@${secondPart}`;
  }, [isMobileLarge, user]);

  return (
    <SContainer>
      <SLabel variant="textSmMedium">Mail</SLabel>
      <SCard>
        <SText variant="textMdRegular">{cardText}</SText>
      </SCard>
    </SContainer>
  );
};
