import { Container, Icon } from './styled';
import { AttentionIconSeverity } from './types';

type AttentionIconProps = {
  severity?: AttentionIconSeverity;
};

export const AttentionIcon = ({ severity = 'info' }: AttentionIconProps) => {
  return (
    <Container severity={severity}>
      <Icon severity={severity} />
    </Container>
  );
};
