import { useTheme, useMediaQuery } from '@mui/material';

export const useDeviceType = ({ between = [0, 0] } = {}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between(0, 'tablet'));
  const isMobileLarge = useMediaQuery(theme.breakpoints.between('mobileLarge', 'tablet'));
  const isTablet = useMediaQuery(theme.breakpoints.between('tablet', 'laptop'));
  const isLaptop = useMediaQuery(theme.breakpoints.between('laptop', 'desktop'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
  const isScreenWidthBetween = useMediaQuery(theme.breakpoints.between(between[0], between[1]));

  return {
    isMobile,
    isMobileLarge,
    isLaptop,
    isDesktop,
    isTablet,
    isScreenWidthBetween,
  };
};
