import { SCheckCircleIcon, SContainer } from './styled';

export const Bullet = ({ children }: { children: string }) => {
  return (
    <SContainer>
      <SCheckCircleIcon width={20} height={20} />
      {children}
    </SContainer>
  );
};
