import { ReactNode, useState, CSSProperties, useEffect } from 'react';
import { Box, Stack, ClickAwayListener, TooltipProps } from '@mui/material';
import { useDeviceType } from '@braskai/ui-kit';
import { SContent, STooltip } from './styled';
import { Title } from './Title';
import { Description } from './Description';
import { Content } from './Content';
import { Image } from './Image';
import { Link } from './Link';
import { CloseButton } from './CloseButton';

export type HoverCardProps = {
  children: ReactNode;
  onTooltipOpen?: () => void;
  placement?: TooltipProps['placement'];
  placementMobile?: TooltipProps['placement'];
  content: ReactNode;
  cursor?: CSSProperties['cursor'];
  width?: CSSProperties['width'];
  isOpen?: boolean;
  handleOpen?: () => void;
  handleClose?: () => void;
};

export const HoverCard = ({
  onTooltipOpen,
  children,
  placement = 'right',
  content,
  cursor = 'default',
  width,
  isOpen,
  handleOpen,
  handleClose,
  placementMobile = 'bottom',
}: HoverCardProps) => {
  const { isMobile, isTablet } = useDeviceType();
  const isMobileOrTablet = isMobile || isTablet;

  const isControlled = isOpen !== undefined;

  const [isOpenPopover, setIsOpenPopover] = useState(false);

  const onToggle = () => {
    setIsOpenPopover((prevState) => !prevState);

    if (isOpenPopover) {
      handleClose?.();
    } else {
      handleOpen?.();
    }
  };

  const onOpen = () => {
    setIsOpenPopover(true);
    onTooltipOpen?.();
    handleOpen?.();
  };

  const onClose = () => {
    setIsOpenPopover(false);
    handleClose?.();
  };

  useEffect(() => {
    if (isControlled) {
      setIsOpenPopover(isOpen);
    }
  }, [isControlled, isOpen]);

  if (isMobileOrTablet) {
    return (
      <ClickAwayListener onClickAway={onClose}>
        <Stack direction="row">
          <STooltip
            title={<SContent>{content}</SContent>}
            placement={placementMobile}
            onClose={onClose}
            open={isControlled ? isOpen : isOpenPopover}
            disableFocusListener
            disableHoverListener
            disableTouchListener
          >
            <Box sx={{ cursor, width }} onClick={onToggle}>
              {children}
            </Box>
          </STooltip>
        </Stack>
      </ClickAwayListener>
    );
  }

  return (
    <Stack direction="row">
      <STooltip
        title={<SContent>{content}</SContent>}
        placement={placement}
        arrow
        open={isControlled ? isOpen : undefined}
        onOpen={onOpen}
        onClose={onClose}
        disableFocusListener={isControlled}
        disableHoverListener={isControlled}
        disableTouchListener={isControlled}
      >
        <Box sx={{ cursor, width }} onClick={isControlled ? onToggle : undefined}>
          {children}
        </Box>
      </STooltip>
    </Stack>
  );
};

HoverCard.Content = Content;
HoverCard.Title = Title;
HoverCard.Description = Description;
HoverCard.Link = Link;
HoverCard.Image = Image;
HoverCard.CloseButton = CloseButton;
