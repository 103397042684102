import { MixpanelEvents } from '@/shared/thirdParty/mixpanel';
import VideoCaptionsPlaceholder from '@/shared/assets/images/video-captions-feature.jpg';
import { LimitedFeatureContent, ModalContent } from '../types';
import { BUTTON_TEXTS, BULLETS_HEADER_TEXT } from './texts';
import { getBullets } from './bullets';

const BASE_CONTENT: ModalContent = {
  title: 'Unlock Captions feature',
  subtitle: 'Upgrade to Creator Pro to download videos with subtitles',
  bulletsHeader: BULLETS_HEADER_TEXT.withCreatorPro,
  features: getBullets([
    'translate100',
    'lipSync',
    'voiceCloneSettings',
    'voicePresets',
    'srtUploadDownload',
    'subtitles',
    'aiRewrite',
  ]),
  buttonText: BUTTON_TEXTS.toCreatorPro,
  media: {
    type: 'image',
    src: VideoCaptionsPlaceholder,
  },
  mixpanelOpeningEvent: MixpanelEvents.upgradeCaptionsModalShown,
  mixpanelButtonClickEvent: MixpanelEvents.upgradeCaptionsModalClick,
};

const TRIAL_PLAN_CONTENT = {
  ...BASE_CONTENT,
};

const STARTER_PLAN_CONTENT = {
  ...BASE_CONTENT,
  bulletsHeader: BULLETS_HEADER_TEXT.inStarter,
};

const CREATOR_PLAN_CONTENT = {
  ...BASE_CONTENT,
  bulletsHeader: BULLETS_HEADER_TEXT.inCreator,
};

export const VIDEO_SUBTITLES_CONTENT: LimitedFeatureContent = {
  trial: TRIAL_PLAN_CONTENT,
  starter: STARTER_PLAN_CONTENT,
  creator: CREATOR_PLAN_CONTENT,
};
