import { IconButton, IconButtonProps, styled } from '@mui/material';

type VisibilityIconContainerProps = {
  isSmallVisibilityIcon?: boolean;
} & IconButtonProps;

export const VisibilityIconContainer = styled(({ isSmallVisibilityIcon, ...props }: VisibilityIconContainerProps) => (
  <IconButton size="small" {...props} />
))(({ isSmallVisibilityIcon }) => ({
  height: 'auto',
  marginRight: -8,
  padding: isSmallVisibilityIcon ? 4 : 5,
}));
