import { Link as BaseLink } from '@braskai/ui-kit';
import { ReactNode } from 'react';

type LinkProps = {
  children: ReactNode;
  src: string;
};

export const Link = ({ children, src }: LinkProps) => (
  <BaseLink target="_blank" to={src}>
    {children}
  </BaseLink>
);
