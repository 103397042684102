import axios from 'axios';
import { inIframe } from '@/shared/lib/utils';
import { getTiktokSession } from '@/entities/user/tiktok';
import { AUTH_API } from './amplify';

const getToken = async () => {
  if (inIframe()) {
    return getTiktokSession();
  }

  return AUTH_API.getTokenId();
};

export const addRequestInterceptor = () => {
  axios.interceptors.request.use(
    async (config) => {
      if (!config || !config.headers) {
        return config;
      }

      const token = await getToken();
      config.headers.authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
};
