import { AutocompleteRenderInputParams, InputAdornment } from '@mui/material';
import { useState } from 'react';
import { Language } from '@/shared/types/Settings';
import { AUTODETECT_LANGUAGE_OPTION } from '@/shared/constants-tmp/autodetectLanguageOption';
import { AutocompleteTextField } from '../../../../../ui-tmp';
import { Icon } from '../Icon';

type LanguageAutocompleteTextFieldProps = {
  params: AutocompleteRenderInputParams;
  autocompleteValue?: Language | null;
  error: boolean;
  helperText?: string;
};

export const LanguageAutocompleteTextField = ({
  error,
  helperText,
  autocompleteValue,
  params,
}: LanguageAutocompleteTextFieldProps) => {
  const { InputProps, ...restParams } = params;
  const [focused, setFocused] = useState(false);

  const placeholder = focused ? 'Search for language' : 'Select language';

  const toggleFocus = () => {
    setFocused((prevState) => !prevState);
  };

  return (
    <AutocompleteTextField
      placeholder={placeholder}
      InputProps={{
        ...InputProps,
        startAdornment: (
          <InputAdornment position="start">
            {focused ? null : (
              <Icon
                countryCode={autocompleteValue?.country_code}
                shouldRenderAutodetect={autocompleteValue?.id === AUTODETECT_LANGUAGE_OPTION.id}
                isDisabled={restParams.disabled}
              />
            )}
          </InputAdornment>
        ),
      }}
      error={error}
      helperText={helperText}
      onFocus={toggleFocus}
      onBlur={toggleFocus}
      {...restParams}
    />
  );
};
