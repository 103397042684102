import { useSettingsStore } from '@/entities/user/settings';
import { ScopeSettings } from '@/shared/types/Settings';
import { useDeviceType } from './useDeviceType';

const defaultFeatureFlags = {
  enabled: false,
  visible: false,
  metadata: {},
};

export const useFeatureFlags = (featureFlag: keyof ScopeSettings['available_features']) => {
  const { isMobile, isTablet } = useDeviceType();

  const isMobileScreen = isMobile || isTablet;

  const available_features = useSettingsStore((state) => state.scope_settings.available_features);

  const {
    enabled,
    visible,
    metadata: { mobile, desktop, download_disabled: downloadDisabled },
  } = available_features[featureFlag] || defaultFeatureFlags;

  const featureAvailableForEveryone = enabled && !mobile && !desktop;
  const featureAvailableOnMobile = Boolean(enabled && mobile && isMobileScreen);
  const featureAvailableOnDesktop = Boolean(enabled && desktop);

  if (featureAvailableForEveryone) {
    return {
      featureEnabled: featureAvailableForEveryone,
      featureVisible: visible,
      featureDownloadDisabled: !!downloadDisabled,
    };
  }

  if (featureAvailableOnMobile) {
    return {
      featureEnabled: featureAvailableOnMobile,
      featureVisible: visible,
      featureDownloadDisabled: !!downloadDisabled,
    };
  }

  return {
    featureEnabled: featureAvailableOnDesktop,
    featureVisible: visible,
    featureDownloadDisabled: !!downloadDisabled,
  };
};
