import { noop } from '@braskai/ui-kit';
import { createModalStore } from '@braskai/utils';

type UseAddEmailModalProps = {
  handleSuccess: (email?: string) => Promise<void> | void;
  referral?: string;
};

const initialState: UseAddEmailModalProps = {
  handleSuccess: noop,
  referral: undefined,
};

export const useAddEmailModal = createModalStore<UseAddEmailModalProps>(initialState, false);
