import { getMappedEvents } from '../utils/getMappedEvents';

const EVENTS_WITH_SUBSCRIPTION_DATA = ['tutorialWaitingPageTrialWatched', 'helpCenterClick', 'getHelpClick'] as const;
const EVENTS_WITH_SUBSCRIPTION_DATA_AND_TTS = ['anyResultDownloaded'] as const;

const miscEventsWithSubscriptionData = getMappedEvents([...EVENTS_WITH_SUBSCRIPTION_DATA], {
  shouldSendSubscriptionData: true,
});

const miscEventsWithSubscriptionDataAndTTS = getMappedEvents([...EVENTS_WITH_SUBSCRIPTION_DATA_AND_TTS], {
  shouldSendSubscriptionData: true,
  shouldSendRaskTTSData: true,
});

export const miscEvents = [...miscEventsWithSubscriptionData, ...miscEventsWithSubscriptionDataAndTTS];
