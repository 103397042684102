import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { API } from '@/shared/api';
import { useAppSelector } from '@/shared/hooks-tmp/reduxToolkitHooks';
import { useSettingsStore } from './useSettingsStore';

export const useGetAppSettings = () => {
  const isAuthenticated = useAppSelector((state) => Boolean(state.user));
  const setSettings = useSettingsStore((state) => state.setSettings);

  const query = useQuery({
    queryKey: ['app-settings'],
    queryFn: API.getAppSettings,
    enabled: !isAuthenticated,
  });

  useEffect(() => {
    if (query.data) {
      setSettings(query.data);
    }
  }, [query.data, setSettings]);

  return query;
};
