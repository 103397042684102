import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';
import { useState } from 'react';
import { Autocomplete } from '@/shared/ui-tmp';
import { Language, Languages } from '@/shared/types/Settings';
import { Container, Label } from './styled';
import { LanguageAutocompleteTextField } from './components/LanguageAutocompleteTextField';
import { Option } from './components/Option';
import { OptionGroup } from './components/OptionGroup';
import { getAutocompleteLanguageOptions } from './utils/getAutocompleteLanguageOptions';
import { ExtendedLanguage, VoiceGroup } from './types';

type TLanguageAutocompleteProps<T extends FieldValues> = {
  isDisabled: boolean;
  control: Control<T>;
  controller: FieldPath<T>;
  options: Languages;
  type: 'dst_lang' | 'src_lang';
  label?: string;
  disabledOptionId?: Language['id'];
  isSrtUploadMode?: boolean;
} & FieldValues;

export const ControlledLanguageAutocomplete = <T extends FieldValues>({
  isDisabled,
  controller,
  disabledOptionId,
  control,
  options,
  label,
  type,
  isSrtUploadMode,
}: TLanguageAutocompleteProps<T>) => {
  const [inputValue, setInputValue] = useState('');

  return (
    <Container>
      {label ? <Label>{label}</Label> : null}

      <Controller
        render={({ field: { onChange, value }, formState: { defaultValues }, fieldState: { error } }) => (
          <Autocomplete
            value={value}
            options={getAutocompleteLanguageOptions(type, options)}
            defaultValue={defaultValues?.[controller] as ExtendedLanguage}
            disabled={isDisabled}
            renderInput={(params) => (
              <LanguageAutocompleteTextField
                params={params}
                autocompleteValue={value}
                error={!!error}
                helperText={error?.message}
              />
            )}
            isOptionEqualToValue={(option, selectedValue) => option.id === selectedValue.id}
            getOptionDisabled={(option) => option.id === disabledOptionId}
            groupBy={(option: ExtendedLanguage) => option.voiceGroup ?? ''}
            renderGroup={({ key, group, ...rest }) => (
              <OptionGroup key={key} group={group as VoiceGroup} {...rest} isSrtUploadMode={isSrtUploadMode} />
            )}
            renderOption={(renderProps, option, { selected }) => (
              <span {...renderProps} key={option.id}>
                <Option key={option.id} option={option} selectedOption={selected} />
              </span>
            )}
            onChange={(_, data) => {
              const { voiceGroup, position, ...rest } = data as ExtendedLanguage;

              onChange(rest);
            }}
            inputValue={inputValue}
            onInputChange={(_, val) => {
              setInputValue(val);
            }}
            onFocus={() => {
              setInputValue('');
            }}
          />
        )}
        name={controller}
        control={control}
        rules={{
          required: 'Choose language',
        }}
      />
    </Container>
  );
};
