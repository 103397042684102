export const SLIDER_MARKS = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 2500,
    label: '2500',
  },
  {
    value: 5000,
    label: '5000',
  },
  {
    value: 7500,
    label: '7500',
  },
  {
    value: 10000,
    label: '10000',
  },
];
export const CREDIT_MIN_VALUE = 0;
export const CREDIT_MAX_VALUE = 10000;
export const INPUT_SETTINGS = {
  min: 0,
  max: CREDIT_MAX_VALUE,
  type: 'number',
};
