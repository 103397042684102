import { createModalStore } from '@braskai/utils';
import { Glossary } from '@/shared/api/glossary/types';
import { Project } from '@/shared/types/Project';
import { LanguagePair } from '@/entities/glossary';

type View = 'createGlossary' | 'editGlossary' | 'retranslateProject';

type UseGlossaryModalStore = {
  view: View;
  glossaryId?: Glossary['id'];
  projectId?: Project['id'];
  srcLang?: LanguagePair['srcLang'];
  dstLang?: LanguagePair['dstLang'];
  shouldSubmitUnsavedChanges?: boolean;
  isEditViewFormUpdated?: boolean;
};

const INITIAL_VALUES = {
  view: 'createGlossary',
  glossaryId: undefined,
  projectId: undefined,
  srcLang: undefined,
  dstLang: undefined,
  shouldSubmitUnsavedChanges: false,
  isEditViewFormUpdated: false,
} as const;

export const useGlossaryModalStore = createModalStore<UseGlossaryModalStore>(INITIAL_VALUES);

export const setGlossaryModalView = (view: View) => useGlossaryModalStore.setState({ view });
export const setGlossaryId = (glossaryId: Glossary['id']) => useGlossaryModalStore.setState({ glossaryId });
export const setShouldSubmitUnsavedChanges = (shouldSubmitUnsavedChanges: boolean) =>
  useGlossaryModalStore.setState({ shouldSubmitUnsavedChanges });
export const setIsEditViewFormUpdated = (isEditViewFormUpdated: boolean) =>
  useGlossaryModalStore.setState({ isEditViewFormUpdated });
