import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '@/shared/api';
import { getIsUserLeftProjectPage } from '@/shared/utils-tmp/location';
import { showAlert } from '@/shared/store-tmp/AlertSlice';
import { TUpdatingSegmentData } from '@/shared/types/Editor';
import { Status } from '@/shared/types/Status';
import { removeSegmentDataToSave, setIsVoiceSuggesting, updSegment, updSegmentFromServer } from '../EditorSlice';
import { getProjectById } from '../../project/thunks/getProjectById';
import { RootState } from '../../index';

type TUpdateSegment = {
  changedData: TUpdatingSegmentData[];
};

export const updateSegments = createAsyncThunk<void, TUpdateSegment, { state: RootState }>(
  'editor/updateSegment',
  async ({ changedData }: TUpdateSegment, { dispatch, getState }) => {
    try {
      const {
        project: { project },
      } = getState();

      if (!project) {
        return;
      }

      const { segments: updatedSegments } = await API.updateSegments(project.id, changedData);

      if (!updatedSegments.length) {
        throw new Error();
      }

      const isUserLeftProject = getIsUserLeftProjectPage(project.id);

      if (isUserLeftProject) {
        return;
      }

      updatedSegments.forEach((segment) => {
        dispatch(updSegmentFromServer(segment));
      });

      if (project.status !== Status.transcriptEdited) {
        // A request for the project is needed to get the 'transcript_edited' status and unlock the 'Dub' button
        // after a specific status, for example, 'merging_done'
        await dispatch(getProjectById({ id: project.id }));
      }
    } catch (e) {
      dispatch(
        showAlert({
          message: "We couldn't save the segment. Please try again",
          duration: 6000,
          isClickAwayDisabled: true,
        }),
      );
      // Add isSavingError to the segment to highlight it
      changedData.forEach((segment) => {
        dispatch(updSegment({ id: segment.id, isSavingError: true }));
        // Removing from the store the data that the user managed to update while the segment was being saved
        dispatch(removeSegmentDataToSave({ segmentId: segment.id }));
      });

      const {
        editor: { segmentsFromServerMap },
      } = getState();

      changedData.forEach((segment) => {
        const segmentFromServer = segmentsFromServerMap[segment.id];

        // Updating the data with the actual information from segmentsFromServerMap
        if (segmentFromServer) {
          dispatch(updSegment(segmentFromServer));
        }

        if ('speaker' in changedData) {
          dispatch(setIsVoiceSuggesting(false));
        }
      });
    }
  },
);
