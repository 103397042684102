import { getMappedEvents } from '../utils/getMappedEvents';

const EVENTS_WITH_SUBSCRIPTION_DATA = [
  'multilanguageUpgradeModalShown',
  'multilanguageUpgradeChoosePlanModalClick',
  'translateAnotherLanguageDone',
] as const;

const EVENTS_WITH_TTS = ['translateAnotherLanguageClick'] as const;

export const multilanguageEventsWithSubscriptionData = getMappedEvents([...EVENTS_WITH_SUBSCRIPTION_DATA], {
  shouldSendSubscriptionData: true,
});

export const multilanguageEventsWithTTS = getMappedEvents([...EVENTS_WITH_TTS], {
  shouldSendRaskTTSData: true,
});

export const multilanguageEvents = [...multilanguageEventsWithSubscriptionData, ...multilanguageEventsWithTTS];
