import { Title } from '../../components/Title/styled';
import { Actions, Content, DeclineButton, Subtitle, ConsentButton } from './styled';

type EmailConsentViewProps = {
  isConsenting: boolean;
  isDeclining: boolean;
  isLoggingOut: boolean;

  onConsent: () => void;
  onDecline: () => void;
};

export const EmailConsentView = ({
  isLoggingOut,
  isConsenting,
  isDeclining,

  onConsent,
  onDecline,
}: EmailConsentViewProps) => {
  const isDisabled = isLoggingOut || isConsenting || isDeclining;

  return (
    <>
      <Content>
        <Title>Do you want to stay updated on our latest features?</Title>
        <Subtitle>Click 'Yes' to get useful updates by email</Subtitle>
      </Content>

      <Actions>
        <DeclineButton disabled={isDisabled} isLoading={isDeclining} onClick={onDecline}>
          {isDeclining ? 'Saving...' : 'No'}
        </DeclineButton>

        <ConsentButton disabled={isDisabled} isLoading={isConsenting} onClick={onConsent}>
          {isConsenting ? 'Saving...' : 'Yes'}
        </ConsentButton>
      </Actions>
    </>
  );
};
