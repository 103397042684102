import { Control } from 'react-hook-form';
import { TOTAL_VIDEO_CONTROLLER, USED_VIDEO_CONTROLLER } from '../../constants';
import { Input } from '../Input';
import { Container } from '../common/styled';
import { FormValues } from '../../types';

type VideoCreditsProps = {
  control: Control<FormValues>;
  disabled: boolean;
};
export const VideoCredits = ({ control, disabled }: VideoCreditsProps) => {
  return (
    <Container>
      <Input controller={TOTAL_VIDEO_CONTROLLER} label="Total videos" control={control} disabled={disabled} />
      <Input controller={USED_VIDEO_CONTROLLER} label="Used videos" control={control} disabled={disabled} />
    </Container>
  );
};
