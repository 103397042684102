import { CountryIcon } from '@/shared/ui';
import { AutodetectIcon } from './styled';

type TIconProps = {
  countryCode?: string;
  shouldRenderAutodetect?: boolean;
  isDisabled: boolean;
};

export const Icon = ({ isDisabled, countryCode, shouldRenderAutodetect }: TIconProps) => {
  if (shouldRenderAutodetect) {
    return <AutodetectIcon width={20} height={20} isDisabled={isDisabled} />;
  }

  return <CountryIcon countryCode={countryCode} width={20} height={14} disabled={isDisabled} />;
};
