import { TAny } from '@braskai/utils';

// structuredClone
(function () {
  if (typeof window.structuredClone === 'function') return false;

  function structuredClone(data: TAny) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return JSON.parse(JSON.stringify(data));
  }

  window.structuredClone = structuredClone;
})();
