import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { API } from '@/shared/api';
import { useAppSelector } from '@/shared/hooks-tmp/reduxToolkitHooks';
import { useSettingsStore } from './useSettingsStore';

export const useGetUserSettings = () => {
  const isAuthenticated = useAppSelector((state) => Boolean(state.user));
  const setSettings = useSettingsStore((state) => state.setSettings);

  const query = useQuery({
    queryKey: ['user-settings'],
    queryFn: API.getUserSettings,
    enabled: isAuthenticated,
    refetchInterval: 1000 * 60 * 5, // 5 minutes
  });

  useEffect(() => {
    if (query.data) {
      setSettings(query.data);
    }
  }, [query.data, setSettings]);

  return query;
};
