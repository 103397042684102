import { getSurvey } from '@/shared/utils-tmp/getSurvey';
import { Survey } from '@/shared/types/Survey';
import { SurveyStatus } from '@/shared/types/User';
import { useSettingsStore } from '@/entities/user/settings';
import { useAppSelector } from './reduxToolkitHooks';

export const useSurvey = (id: Survey['id']) => {
  const surveys = useSettingsStore((state) => state.surveys);
  const user = useAppSelector((state) => state.user);
  const targetSurvey = getSurvey(surveys, id);

  const userSurvey = user?.surveys.find((survey) => survey.id === targetSurvey?.id);
  const isSurveyCompleted = userSurvey?.status === SurveyStatus.Completed;
  const isSurveyRejected = userSurvey?.status === SurveyStatus.Rejected;

  return { isSurveyRejected, isSurveyCompleted, shouldCompleteSurvey: !isSurveyCompleted };
};
