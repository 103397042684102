import { DialogTitleProps } from '@mui/material/DialogTitle/DialogTitle';
import { DialogTitle as MuiDialogTitle, styled, Typography } from '@mui/material';
import { IconButton, IconButtonProps } from '../../IconButton';

export const Container = styled(MuiDialogTitle)<DialogTitleProps>(({ theme }) => ({
  ...theme.typography.textLgMedium,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '24px 16px 20px',
  width: '100%',

  [theme.breakpoints.up('mobileLarge')]: {
    padding: '24px 24px 20px',
  },
}));

export const BackIconButton = styled((props: IconButtonProps) => <IconButton disableBackground {...props} />)(
  ({ theme }) => ({
    position: 'absolute',
    top: 26,
    left: 24,
    display: 'block',

    [theme.breakpoints.down('mobileLarge')]: {
      display: 'none',
    },
  }),
);

export const CloseIconButton = styled((props: IconButtonProps) => <IconButton disableBackground {...props} />)(
  ({ theme }) => ({
    position: 'absolute',
    top: 26,
    right: 24,
    color: theme.palette.grey[500],

    [theme.breakpoints.down('mobileLarge')]: {
      top: 18,
      right: 18,
    },
  }),
);

export const Subtitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.textSmRegular,
  color: theme.palette.grey[500],
  marginTop: 8,
}));
