import { JSX, ReactNode } from 'react';
import { Control, Controller, RegisterOptions } from 'react-hook-form';
import { Select } from '../../../ui-tmp';
import { Container, Label } from './styled';
import type { TAny } from '@braskai/utils';

type ControlledSelectProps = {
  control: Control<TAny>;
  controllerName: string;
  children: ReactNode;
  label?: ReactNode;
  multiple?: boolean;
  borderless?: boolean;
  tiny?: boolean;
  isMenuInModal?: boolean;
  renderValue?: () => string | JSX.Element;
  disabled?: boolean;
  onOpen?: () => void;
  fullwidth?: boolean;
  labelSize?: 'sm';
  isError?: boolean;
  onChange?: (selectedValue: string) => void;
  rules?: Omit<RegisterOptions<TAny, string>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
};

export const ControlledSelect = ({
  children,
  controllerName,
  control,
  label,
  multiple,
  borderless,
  tiny,
  isMenuInModal,
  renderValue,
  disabled,
  onOpen,
  onChange,
  fullwidth,
  labelSize,
  isError,
  rules = {
    required: 'Field is required',
  },
}: ControlledSelectProps) => {
  return (
    <Container fullwidth={fullwidth}>
      {label ? (
        <Label component="div" labelSize={labelSize}>
          {label}
        </Label>
      ) : null}

      <Controller
        render={({ field }) => (
          <Select
            fullWidth
            multiple={multiple}
            borderless={borderless}
            renderValue={renderValue}
            tiny={tiny}
            onOpen={onOpen}
            isMenuInModal={isMenuInModal}
            error={isError}
            {...field}
            onChange={(e) => {
              field.onChange(e);
              onChange?.(e.target.value);
            }}
            disabled={disabled}
          >
            {children}
          </Select>
        )}
        name={controllerName}
        control={control}
        rules={rules}
      />
    </Container>
  );
};
