// time must have format '00:00:00,000'
export const getTimeFromTimestampStr = (time: string) => {
  const hours = Number(time.substring(0, 2));
  const minutes = Number(time.substring(3, 5));
  const seconds = Number(time.substring(6, 8));
  const ms = Number(time.substring(9, 12));

  const totalMilliseconds = (hours * 60 * 60 + minutes * 60 + seconds) * 1000 + ms;

  return totalMilliseconds;
};
