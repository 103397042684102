import { Box, css, styled, Typography } from '@mui/material';

export const SControlledTextField = styled(Box)(
  () => css`
    width: 100%;
  `,
);

export const SControlledTextFieldLabel = styled(Typography)(
  () => css`
    padding-bottom: 6px;
    display: block;
  `,
);
