import { Stack, Typography, css, styled } from '@mui/material';
import { styledOmitCustomProps, lineEllipsisCSS } from '@braskai/ui-kit';

type SCardProps = {
  $variant: 'outlined' | 'filled';
  $active: boolean;
  $cursor: 'default' | 'pointer';
};

export const SCard = styled(
  Stack,
  styledOmitCustomProps,
)<SCardProps>(
  ({ theme, $variant, $active, $cursor }) => css`
    flex-direction: row;
    padding: 12px;
    gap: 8px;
    border-radius: 8px;
    cursor: ${$cursor};

    &:hover {
      background-color: ${theme.palette.grey[25]};
    }

    ${$active &&
    css`
      background-color: ${theme.palette.primary[50]};
    `}

    ${$variant === 'outlined' &&
    css`
      border: 1px solid ${theme.palette.grey[200]};
    `}
  `,
);

export const STextContainer = styled(Stack)(
  () => css`
    width: calc(100% - 42px); // 42px - icon and gap
  `,
);

export const STypography = styled(Typography)(
  () => css`
    ${lineEllipsisCSS}
  `,
);
