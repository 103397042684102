import { LipSyncInfo } from '@/shared/types/Project';
import { BASE_URL_WITHOUT_PORT } from '@/shared/api/constants';

export const adaptLipSyncInfo = (lipSyncInfo: LipSyncInfo) => {
  return {
    ...lipSyncInfo,
    adaptedData: {
      lipSyncResultsLink: lipSyncInfo.lipsync_result_path
        ? BASE_URL_WITHOUT_PORT + lipSyncInfo.lipsync_result_path
        : null,
    },
  };
};
