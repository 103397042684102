import { Stepper, Modal, ModalWrapper } from '@braskai/ui-kit';
import { useStep } from '@/shared/lib/hooks';
import { HubSpotHiddenForm } from '@/shared/thirdParty/hubSpot/HubSpotHiddenForm';
import { useSignupSurveyModalStore } from '@/shared/components-tmp/modals/SignupSurveyModal/hooks/useSignupSurveyModalStore';
import { FormValuesOption } from '@/shared/components-tmp/modals/SignupSurveyModal/types';
import { inIframe } from '@/shared/lib/utils';
import { SContainer, SFooter } from './styled';
import { LogoutLink } from './components/LogoutLink';
import { useSignupSurveyModalForm } from './hooks/useSignupSurveyModalForm';
import { useLogout } from './hooks/useLogout';
import { useSubmitForm } from './hooks/useSubmitForm';
import { SelectFormView } from './views/SelectFormView';
import { EmailConsentView } from './views/EmailConsentView';
import { getFormActiveStep } from './utils';
import { TOTAL_STEPS } from './constants';

const SignupSurveyModalComponent = () => {
  const { close } = useSignupSurveyModalStore();
  const isTiktok = inIframe();

  const { isLoggingOut, logout } = useLogout();

  const { isFirstStep, isLastStep, totalSteps, activeStep, nextStep, previousStep } = useStep({
    totalSteps: TOTAL_STEPS,
  });

  const { controller, control, isFormInvalid, selectedValue, label, options, resetTextField, handleSubmit } =
    useSignupSurveyModalForm({
      activeStep: getFormActiveStep(activeStep, totalSteps),
    });

  const { hiddenFormId, onSubmit, isConsenting, isDeclining } = useSubmitForm({ onClose: close });

  const handleLogoutClick = async () => {
    await logout();
    close();
  };

  return (
    <Modal open size="xs">
      <SContainer noActions={isTiktok}>
        {isLastStep ? (
          <EmailConsentView
            onDecline={handleSubmit((data) => onSubmit(data, false))}
            onConsent={handleSubmit((data) => onSubmit(data, true))}
            isConsenting={isConsenting}
            isDeclining={isDeclining}
            isLoggingOut={isLoggingOut}
          />
        ) : (
          <SelectFormView
            activeStep={activeStep}
            controller={controller}
            control={control}
            selectedValue={selectedValue as FormValuesOption}
            label={label}
            options={options}
            resetTextField={resetTextField}
            onNextStep={nextStep}
            onPreviousStep={previousStep}
            shouldHideBackButton={isFirstStep}
            isFormInvalid={isFormInvalid}
            isLoggingOut={isLoggingOut}
          />
        )}
      </SContainer>

      {!isTiktok && (
        <SFooter>
          <LogoutLink
            onLogout={handleLogoutClick}
            isDisabled={isLoggingOut || isConsenting || isDeclining}
            isLoading={isLoggingOut}
          />

          <Stepper steps={totalSteps} activeStep={activeStep} />
        </SFooter>
      )}

      <HubSpotHiddenForm id={hiddenFormId} />
    </Modal>
  );
};

export const SignupSurveyModal = () => {
  return (
    <ModalWrapper hook={useSignupSurveyModalStore}>
      <SignupSurveyModalComponent />
    </ModalWrapper>
  );
};
