export type TAudioPlotData = {
  plot_values: number[];
  max_value: number;
};

export const AudioPlotDataStatus = {
  Idle: 'idle',
  Loading: 'loading',
  Ready: 'ready',
} as const;

export type TAudioPlotDataStatus = (typeof AudioPlotDataStatus)[keyof typeof AudioPlotDataStatus];
