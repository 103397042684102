import { getMappedEvents } from '../utils/getMappedEvents';

const EVENTS = [
  'enterpriseButtonClick',
  'choosePlan',
  'pricingPageShown',
  'moreMinutesHomePageClick',
  'moreMinutesAccountClick',
  'buyAdditionalMinutesAccount',
  'upgradeCreatorClick',
  'upgradeCreatorProClick',
  'upgradeBusinessClick',
  'switchAnnualCreatorClick',
  'switchAnnualCreatorProClick',
  'switchAnnualBusinessClick',
  'pricingSwitchAnnual',
  'pricingSwitchMonthly',
  'bundleSwitchCreatorPro',
  'bundleSwitchBusiness',
  'hoverMinutesHomePage',
  'hoverPricing135Languages',
  'hoverPricingVoiceClone',
  'hoverPricingLipsync',
  'hoverPricingSrtDownload',
  'hoverPricingSrtUpload',
  'hoverPricingAIScriptAdjustment',
  'hoverPricingViralityScore',
  'hoverPricingAPI',
  'hoverPricingAdvancedEditor',
  'hoverPricingCustomVoiceClone',
  'hoverPricingHumanInTheLoop',
  'hoverPricingTranslationDictionary',
  'hoverPricingMultilanguage',
  'hoverPricingCaptions',
  'hoverPricingVideoGeneration',
  'hoverPricingCustomVoiceSettings',
  'hoverPricingApplyChangesForFree',
  'hoverPricingMinutes',
  'pricingVisitedPaidUser',
  'minutesModalViewPlansClick',
  'payLessMinutesButton',
  'buyMinutesModalShown',
  'buyMinutesButtonHomePageClick',
] as const;

export const pricingEvents = getMappedEvents([...EVENTS], {
  shouldSendSubscriptionData: true,
});
