import { DialogTitleProps as MuiDialogTitleProps } from '@mui/material';
import { ReactNode } from 'react';
import { BackIcon, CloseIcon } from '@braskai/icons';
import { BackIconButton, CloseIconButton, Container, Subtitle } from './styled';

type TDialogTitleProps = {
  renderTitle?: () => ReactNode;
  renderSubtitle?: () => ReactNode;
  onBack?: () => void;
  onClose?: () => void;
  buttonsDisabled?: boolean;
} & MuiDialogTitleProps;

/** @deprecated I recommend using the `import { ModalTitle } from '@braskai/ui-kit'` component */
export const DialogTitle = ({
  onClose,
  onBack,
  buttonsDisabled,
  renderTitle,
  renderSubtitle,
  ...props
}: TDialogTitleProps) => {
  return (
    <Container {...props}>
      {onBack ? (
        <BackIconButton onClick={onBack} disabled={buttonsDisabled}>
          <BackIcon />
        </BackIconButton>
      ) : null}
      {renderTitle ? renderTitle() : null}

      {renderSubtitle ? <Subtitle>{renderSubtitle()}</Subtitle> : null}

      <CloseIconButton onClick={onClose} disabled={buttonsDisabled}>
        <CloseIcon width={24} height={24} />
      </CloseIconButton>
    </Container>
  );
};
