import { useState } from 'react';

type TUseStep = {
  totalSteps: number;
};

export const useStep = ({ totalSteps }: TUseStep) => {
  const [activeStep, setActiveStep] = useState(0);

  const isLastStep = activeStep === totalSteps - 1;
  const isFirstStep = activeStep === 0;

  const nextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const previousStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return { isFirstStep, isLastStep, totalSteps, activeStep, nextStep, previousStep };
};
