import { CardMedia } from '@mui/material';
import { Dialog, DialogTitle } from '@braskai/ui-kit';
import WelcomeBackImage from '@/shared/assets/images/welcome-back.png';
import { SContent } from './styled';

type ISuccessfulSubscriptionRenewalModalProps = {
  onClose: () => void;
};

export const SuccessfulSubscriptionRenewalModal = ({ onClose }: ISuccessfulSubscriptionRenewalModalProps) => {
  return (
    <Dialog open onClose={onClose} size="sm">
      <DialogTitle onClose={onClose} renderTitle="Welcome back!" renderSubtitle="We're happy you're here again!" />

      <SContent>
        <CardMedia component="img" src={WelcomeBackImage} />
      </SContent>
    </Dialog>
  );
};
