import { createAsyncThunk } from '@reduxjs/toolkit';
import { NOT_FOUND_STATUS_CODE } from '@/shared/api/errors';
import { getBalance } from '@/shared/utils-tmp/user/balance/getBalance';
import { setBalance } from '@/shared/store-tmp/billing/BillingSlice';
import { CustomError } from '@/shared/types/Error';
import { inIframe } from '@/shared/lib/utils';
import { hubSpotMethods, setMixpanelUser, SentryRask } from '@/shared/thirdParty';
import { API } from '../../../api';
import { setUser } from '../UserSlice';
import { createUser } from './createUser';

type GetUser = {
  isSocial?: boolean;
  referral?: string;
  handleSuccess?: (email?: string) => Promise<void>;
  tiktokCallback?: () => void;
};

export const getUser = createAsyncThunk(
  'user/getUser',
  async (payload: GetUser | undefined, { dispatch, rejectWithValue }) => {
    try {
      const user = await API.getUser();
      const balance = getBalance(user);

      dispatch(setUser(user));
      dispatch(setBalance(balance));

      setMixpanelUser(user.id, user.email, user.email_type);
      SentryRask.initUser({ email: user.email, id: user.id });

      await hubSpotMethods.initUser(user.email);
      await payload?.handleSuccess?.();

      return user;
    } catch (e) {
      const error = e as CustomError;

      if (error.status === NOT_FOUND_STATUS_CODE) {
        if (inIframe()) {
          payload?.tiktokCallback?.();
        } else {
          try {
            await dispatch(
              createUser({
                isSocial: payload?.isSocial,
                referral: payload?.referral,
              }),
            );

            await payload?.handleSuccess?.();
          } catch (errorUser) {
            rejectWithValue(errorUser);
          }
        }
      } else {
        rejectWithValue(e);
      }
    }
  },
);
