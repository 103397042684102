import { MixpanelEvents } from '@/shared/thirdParty/mixpanel';
import AIRewriterPoster from '@/shared/assets/images/ai-rewriter-poster.jpg';
import { LimitedFeatureContent, ModalContent } from '../types';
import { BUTTON_TEXTS, BULLETS_HEADER_TEXT } from './texts';
import { getBullets } from './bullets';

const AI_REWRITE_DEMO_VIDEO_SRC = 'https://static.rask.ai/IA-2.mp4';

const BASE_CONTENT: ModalContent = {
  title: 'Unlock AI rewrite feature',
  subtitle: 'Upgrade to Creator Pro plan for speed control and AI-driven segment alignment to match your timestamps',
  bulletsHeader: BULLETS_HEADER_TEXT.inCreator,
  features: getBullets([
    'translate100',
    'lipSync',
    'voiceCloneSettings',
    'voicePresets',
    'srtUploadDownload',
    'subtitles',
    'aiRewrite',
  ]),
  buttonText: BUTTON_TEXTS.toCreatorPro,
  media: {
    type: 'video',
    poster: AIRewriterPoster,
    src: AI_REWRITE_DEMO_VIDEO_SRC,
    videoOptions: {
      loop: true,
      autoPlay: true,
      controls: false,
    },
  },
  mixpanelOpeningEvent: MixpanelEvents.upgradeAIRewriteModalShown,
  mixpanelButtonClickEvent: MixpanelEvents.upgradeAIRewriteModalClick,
};

const STARTER_PLAN_CONTENT = {
  ...BASE_CONTENT,
  bulletsHeader: BULLETS_HEADER_TEXT.inStarter,
};

const CREATOR_PLAN_CONTENT: ModalContent = {
  ...BASE_CONTENT,
};

export const AI_REWRITE_CONTENT: LimitedFeatureContent = {
  starter: STARTER_PLAN_CONTENT,
  creator: CREATOR_PLAN_CONTENT,
};
