import { Subscription, SubscriptionStatus } from '@/shared/types/Billing';
import { isSubscriptionActive } from './isSubscriptionActive';
import { isSubscriptionExpired } from './isSubscriptionExpired';

export const getHasSubscriptionState = (subscription: Subscription | null) => {
  const hasTrialPlan = subscription === null;
  const hasPausedSubscription =
    subscription?.payments_collection_paused || subscription?.status === SubscriptionStatus.Paused;
  const hasExpiredSubscription = Boolean(subscription && isSubscriptionExpired(subscription.current_period_end));
  const hasActiveSubscription = Boolean(
    subscription && isSubscriptionActive(subscription) && !hasExpiredSubscription && !hasPausedSubscription,
  );
  const hasCanceledSubscription = subscription?.status === SubscriptionStatus.Canceled;
  const hasUnpaidSubscription = subscription?.status === SubscriptionStatus.Unpaid;
  const hasPastDueSubscription = subscription?.status === SubscriptionStatus.PastDue;
  const hasFreeSubscription = Boolean(subscription?.id?.includes('free_sub')) ?? false;
  const hasSubscriptionCancelAtPeriodEnd = Boolean(subscription?.cancel_at_period_end);
  return {
    hasTrialPlan,
    hasPausedSubscription,
    hasExpiredSubscription,
    hasActiveSubscription,
    hasCanceledSubscription,
    hasFreeSubscription,
    hasSubscriptionCancelAtPeriodEnd,
    hasUnpaidSubscription,
    hasPastDueSubscription,
  };
};
