import { JSX } from 'react';
import { Control, Controller, FieldValues, FieldPath } from 'react-hook-form';
import { Checkbox } from '../../../ui-tmp';
import { Container } from './styled';

export type ControlledCheckboxProps<T extends FieldValues> = {
  control: Control<T>;
  controller: FieldPath<T>;
  disabled?: boolean;
  label: JSX.Element;
  variant?: 'outlined';
  size?: 'md';
} & FieldValues;

export const ControlledCheckbox = <T extends FieldValues>({
  label,
  control,
  controller,
  disabled,
  variant,
  size,
}: ControlledCheckboxProps<T>) => {
  return (
    <Controller
      render={({ field }) => (
        <Container
          variant={variant}
          size={size}
          label={label}
          control={<Checkbox checked={field.value} disabled={disabled} {...field} />}
        />
      )}
      name={controller}
      control={control}
    />
  );
};
