import { JSX } from 'react';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { Controller } from 'react-hook-form';
import { TAny } from '@braskai/utils';
import { TextField } from '../../../ui-tmp';
import { Container, Label } from './styled';

type ControlledTextFieldProps = TextFieldProps & {
  control: TAny;
  fieldName: string;
  placeholder: string;
  label?: string;
  extraSpace?: boolean;
  validator?: (value: string) => string | undefined;
  disabled?: boolean;
  icon?: JSX.Element;
  multiline?: boolean;
  minRows?: number;
  notRequired?: boolean;
  showEndAdornmentOnError?: boolean;
};

export const ControlledTextField = ({
  control,
  placeholder,
  label,
  fieldName,
  extraSpace,
  disabled = false,
  validator,
  multiline,
  minRows,
  icon,
  notRequired = false,
  ...props
}: ControlledTextFieldProps) => {
  return (
    <Container>
      {label ? <Label>{label}</Label> : null}
      <Controller
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            placeholder={placeholder}
            error={!!error}
            helperText={error?.message}
            fullWidth
            extraHeight={extraSpace}
            disabled={disabled}
            startAdornment={icon}
            multiline={multiline}
            minRows={minRows}
            {...props}
          />
        )}
        name={fieldName}
        /* TODO: type needs to be corrected */
        /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
        control={control}
        rules={{
          required: notRequired ? undefined : 'Field is required',
          minLength: notRequired ? 0 : 1,
          validate: (value: string) => {
            if (!notRequired && !value.trim()) {
              return 'Field is required';
            }

            return validator && validator(value);
          },
        }}
      />
    </Container>
  );
};
