export enum Status {
  created = 'created',
  uploading = 'uploading',
  uploaded = 'uploaded',
  separateBackgroundStarted = 'separate_background_started',
  separateBackgroundDone = 'separate_background_done',
  transcribeSegmentsStarted = 'transcribe_segments_started',
  transcribeSegmentsDone = 'transcribe_segments_done',
  determineSpeakersStarted = 'determine_speakers_started',
  determineSpeakersDone = 'determine_speakers_done',
  transcriptionStarted = 'transcription_started',
  transcriptionDone = 'transcription_done',
  voiceSuggestStarted = 'voice_suggest_started',
  voiceSuggestDone = 'voice_suggest_done',
  translationStarted = 'translation_started',
  translationDone = 'translation_done',
  voiceoverStarted = 'voiceover_started',
  voiceoverDone = 'voiceover_done',
  mergingStarted = 'merging_started',
  mergingDone = 'merging_done',
  voiceUpdateStarted = 'voice_update_started',
  voiceUpdateDone = 'voice_update_done',
  transcriptEdited = 'transcript_edited',
  voiceEdited = 'voice_edited',
  failed = 'failed',
  uploadFailed = 'upload_failed',
  separateBackgroundFailed = 'separate_background_failed',
  transcribeSegmentsFailed = 'transcribe_segments_failed',
  determineSpeakersFailed = 'determine_speakers_failed',
  transcriptionFailed = 'transcription_failed',
  voiceSuggestFailed = 'voice_suggest_failed',
  voiceUpdateFailed = 'voice_update_failed',
  translationFailed = 'translation_failed',
  voiceoverFailed = 'voiceover_failed',
  mergingFailed = 'merging_failed',
  noAudio = 'no_audio',
  noWords = 'no_words',
  forbiddenLink = 'forbidden_link',
}
