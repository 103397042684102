import { getMappedEvents } from '../utils/getMappedEvents';

const EVENTS_WITH_SUBSCRIPTION_DATA = ['upgradeCaptionsModalShown', 'upgradeCaptionsModalClick'] as const;

const EVENTS_WITH_SUBSCRIPTION_DATA_AND_TTS = [
  'generateCaptionsTranslatedClick',
  'generateCaptionLipsyncClick',
  'downloadCaptionTranslated',
  'downloadCaptionsLipsync',
  'subtitlesDownloadUpgradeClick',
] as const;

const subtitlesBurningEventsWithSubscriptionData = getMappedEvents([...EVENTS_WITH_SUBSCRIPTION_DATA], {
  shouldSendSubscriptionData: true,
});

const subtitlesBurningEventsWithSubscriptionDataAndTTS = getMappedEvents([...EVENTS_WITH_SUBSCRIPTION_DATA_AND_TTS], {
  shouldSendSubscriptionData: true,
  shouldSendRaskTTSData: true,
});

export const subtitlesBurningEvents = [
  ...subtitlesBurningEventsWithSubscriptionData,
  ...subtitlesBurningEventsWithSubscriptionDataAndTTS,
];
