import { MenuItemProps } from '@mui/material';
import { CheckIcon } from '@braskai/icons';
import { CheckIconContainer, Container } from './styled';

export const Option = ({ children, selected, ...props }: MenuItemProps) => {
  return (
    <Container {...props}>
      {children}
      {selected ? (
        <CheckIconContainer>
          <CheckIcon width={12} />
        </CheckIconContainer>
      ) : null}
    </Container>
  );
};
