import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '@/shared/hooks-tmp/reduxToolkitHooks';
import { Customer } from '@/shared/types/Admin';
import { updateCustomer } from '@/shared/store-tmp/admin/thunks/customers';
import { showErrorAlert } from '@/shared/utils-tmp/alert/showErrorAlert';
import { Dialog, DialogTitle } from '../../../ui-tmp';
import { CancelButton, Content, UpdateButton, Subtitle, Title, Actions } from './styled';
import { FormValues } from './types';
import { ACCESS_OPTIONS, STATUS_OPTIONS } from './constants';
import { Select } from './components/Select';

type SetupUserModalProps = {
  onClose: () => void;
  customer?: Customer;
};

export const SetupCustomerModal = ({ onClose, customer = {} as Customer }: SetupUserModalProps) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const { is_active, is_superuser } = customer;

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      isActive: is_active ? 'Active' : 'Inactive',
      isSuperuser: is_superuser ? 'Admin' : 'User',
    },
  });

  const onSubmit = async (input: FormValues) => {
    try {
      setIsLoading(true);

      const { isActive, isSuperuser } = input;

      const data = {
        customerId: customer.id,
        is_active: isActive === 'Active',
        is_superuser: isSuperuser === 'Admin',
      };

      await dispatch(updateCustomer(data));

      onClose();
    } catch (e) {
      showErrorAlert(e);
    }

    setIsLoading(false);
  };

  return (
    <Dialog open onClose={isLoading ? undefined : onClose} size="sm">
      <DialogTitle
        onClose={isLoading ? undefined : onClose}
        renderTitle={() => <Title>Set up customer</Title>}
        renderSubtitle={() => <Subtitle>{customer.email}</Subtitle>}
      />

      <Content>
        <Select options={STATUS_OPTIONS} controller="isActive" control={control} label="Status" disabled={isLoading} />

        <Select
          options={ACCESS_OPTIONS}
          controller="isSuperuser"
          control={control}
          label="Access"
          disabled={isLoading}
        />
      </Content>

      <Actions>
        <CancelButton onClick={onClose} disabled={isLoading}>
          Cancel
        </CancelButton>

        <UpdateButton onClick={handleSubmit(onSubmit)} isLoading={isLoading} disabled={isLoading || !isDirty}>
          Update
        </UpdateButton>
      </Actions>
    </Dialog>
  );
};
