import { useNavigate } from 'react-router-dom';
import { ListItemIcon } from '@mui/material';
import { ReactNode } from 'react';
import { SListItem, SListItemButton, SListItemText } from './styled';

export type TNavItemProps = {
  title: string;
  rightText?: string;
  icon: ReactNode;
  path?: string;
  selected?: boolean;
  isDisabled?: boolean;
  isMobile?: boolean;
  onClick?: () => void;
};

export const NavItem = ({
  title,
  rightText,
  path,
  icon,
  selected = false,
  isDisabled = false,
  isMobile = false,
  onClick,
}: TNavItemProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (path) {
      navigate(path);
    }

    onClick?.();
  };

  return (
    <SListItem key={path} disablePadding>
      <SListItemButton selected={selected} $isMobile={isMobile} $isDisabled={isDisabled} onClick={handleClick}>
        <ListItemIcon>{icon}</ListItemIcon>
        <SListItemText primary={title} secondary={rightText} />
      </SListItemButton>
    </SListItem>
  );
};
