export enum PlanCategory {
  Starter = 'starter',
  Creator = 'creator',
  CreatorPro = 'creator_pro',
  Business = 'business',
  Enterprise = 'enterprise',
}

export enum PaymentInterval {
  Month = 'month',
  Year = 'year',
}

export enum SubscriptionStatus {
  Active = 'active',
  // Статусы Trialing, Incomplete, IncompleteExpired приравниваются к статусу Active
  Trialing = 'trialing',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  // PastDue — первое неудачное списание с карты юзера.
  // страйп делает попытки списать деньги с юзера
  PastDue = 'past_due',
  // Unpaid — страйп перестает делать попытки списать деньги с карты юзера
  Unpaid = 'unpaid',
  Canceled = 'canceled',
  Paused = 'paused',
}

export enum AdaptedSubscriptionStatus {
  Active = SubscriptionStatus.Active,
  Canceled = SubscriptionStatus.Canceled,
  Paused = SubscriptionStatus.Paused,
  PastDue = SubscriptionStatus.PastDue,
  Unpaid = SubscriptionStatus.Unpaid,
  Expired = 'expired',
}

export type Price = {
  id: string;
  currency: string;
  unit_amount: number;
  recurring: {
    interval: PaymentInterval;
    interval_count: number;
    trial_period_days: number | null;
  } | null;
};

type Metadata = {
  bundle_value: number | string;
  bundle_value_default: boolean;
  bundle_position: number;
  extra_minutes_price: number | string | null;
  label: string | null;
  position: number;
  highlighted_button: string | null;
};

export type Plan = {
  id: string;
  name: string;
  prices: Price[];
  category: PlanCategory;
  description: string;
  metadata: Metadata;
  is_archived: boolean;
  bundles: Plan[] | null;
};

export type TCurrentPlan = {
  amount: Price['unit_amount'];
  currency: Price['currency'];
  id: Price['id'];
  interval: PaymentInterval;
  product_id: Plan['id'];
};

export type Subscription = {
  created: string;
  credits: {
    minutes: number;
  };
  current_period_end: string;
  current_period_start: string;
  cancel_at_period_end: boolean;
  payments_collection_paused: boolean;
  user_id: string;
  id: string;
  plan: TCurrentPlan;
  status: SubscriptionStatus;
};

export type TCredit = Omit<Plan, 'bundles'>;

export type Products = {
  credit: TCredit[];
  plan: Plan[];
};

export enum PromoDuration {
  Once = 'once',
  Forever = 'forever',
  Repeating = 'repeating',
}
