import { TextField as MuitextField, TextFieldProps as MuiTextFieldProps, InputAdornment, styled } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DoneIcon from '@mui/icons-material/Done';
import { forwardRef, JSX } from 'react';

export type TextFieldProps = MuiTextFieldProps & {
  extraHeight?: boolean;
  borderless?: boolean;
  startAdornment?: JSX.Element;
  showEndAdornmentOnError?: boolean;
  success?: boolean;
};

const StyledTextField = styled(
  forwardRef<HTMLDivElement, TextFieldProps>(({ extraHeight, borderless, success, ...rest }: TextFieldProps, ref) => (
    <MuitextField {...rest} ref={ref} />
  )),
)<TextFieldProps>(({ borderless, theme, extraHeight, success }) => ({
  minHeight: extraHeight ? 71 : 'auto',

  'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },

  'input[type=number]': {
    MozAppearance: 'textfield',
  },

  'input:-webkit-autofill': {
    WebkitBoxShadow: `0 0 0px 40rem ${theme.palette.common.white} inset`,
  },

  'input:-webkit-autofill:hover': {
    WebkitBoxShadow: `0 0 0px 40rem ${theme.palette.common.white} inset`,
  },
  'input:-webkit-autofill:focus': {
    WebkitBoxShadow: `0 0 0px 40rem ${theme.palette.common.white} inset`,
  },

  '& .MuiOutlinedInput-root': {
    boxShadow: `0px 0px 0px 4px transparent`,
    gap: 6,
    padding: '9px 12px',

    '& fieldset': {
      borderRadius: 8,

      border: borderless ? 'none' : `1px solid ${success ? theme.palette.success[500] : theme.palette.grey[300]}`,
    },

    '&.Mui-disabled fieldset': {
      border: `1px solid ${theme.palette.grey[300]}`,
    },

    '&:hover:not(.Mui-disabled) fieldset': {
      border: borderless ? 'none' : `1px solid ${theme.palette.grey[300]}`,
    },
    '&.Mui-focused fieldset': {
      border: borderless ? 'none' : `1px solid ${theme.palette.grey[300]}`,
      boxShadow: `0px 0px 0px 4px ${theme.palette.primary[100]}`,
    },
    '& .MuiOutlinedInput-input': {
      ...theme.typography['body-1'],
      height: 'unset',
      padding: 0,
      color: theme.palette.grey[900],
      '&::placeholder': { color: theme.palette.grey[500], opacity: 1, ...theme.typography.textMdRegular },

      '&.MuiInputBase-inputAdornedStart': {
        paddingLeft: 0,
      },
    },
  },

  '.Mui-error .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${theme.palette.error[300]}`,
  },

  '.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${theme.palette.error[300]}`,
    boxShadow: `0px 0px 0px 4px ${theme.palette.error[100]}`,
  },

  '&:hover .Mui-error .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${theme.palette.error[300]}`,
  },

  '& .MuiFormHelperText-root': {
    ...theme.typography.textSmRegular,
    margin: '8px 0 0',
    color: theme.palette.grey[500],

    '&.Mui-error': {
      color: theme.palette.error[500],
    },
  },

  '& .Mui-disabled .MuiSvgIcon-root': {
    color: theme.palette.grey[200],
  },
}));

export const TextField = forwardRef<HTMLDivElement, TextFieldProps>(
  ({ label, startAdornment, showEndAdornmentOnError = true, ...props }, ref) => {
    const textFieldProps = {
      ...props,
      ...(startAdornment
        ? {
            InputProps: {
              startAdornment,
            },
          }
        : {}),
      ...(props.success && {
        InputProps: {
          endAdornment: (
            <InputAdornment position="end">
              <DoneIcon color="success" />
            </InputAdornment>
          ),
        },
      }),
      ...(props.error && showEndAdornmentOnError
        ? {
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <InfoOutlinedIcon color="error" />
                </InputAdornment>
              ),
            },
          }
        : {}),
    };

    return <StyledTextField variant="outlined" {...textFieldProps} ref={ref} />;
  },
);
