import { Outlet } from 'react-router-dom';
import { Stack } from '@mui/material';
import { ServerNotificationBanner } from '@/shared/components-tmp/banners/ServerNotificationBanner';
import { DesktopSidebar } from '../../sidebar';
import { SContainer } from './styled';

export const MainLayout = () => {
  return (
    <SContainer>
      <DesktopSidebar />
      <Stack flexGrow={1}>
        <ServerNotificationBanner />
        <Outlet />
      </Stack>
    </SContainer>
  );
};
