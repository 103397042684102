import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@/shared/hooks-tmp/reduxToolkitHooks';
import { setBalance, setEnterpriseSubscriptionState } from '@/shared/store-tmp/billing/BillingSlice';
import { getEnterpriseSubscriptionState } from '@/shared/utils-tmp/user/balance/getEnterpriseSubscriptionState';
import { useProducts } from '@/entities/pricing';

export const useSubscriptionState = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const { isLoading, products } = useProducts();

  useEffect(() => {
    if (isLoading || !products) return;

    // todo: subscription category should come from backend
    // need to negotiate with backend team to implement it
    const enterpriseSubscriptionState = getEnterpriseSubscriptionState(user, products?.plan);
    const canBuyMinutes = products.credit.length > 0 && products.credit[0].prices.length > 0;
    const hasArchivedSubscription = Boolean(
      products.plan.find(({ id }) => id === user?.subscription?.plan.product_id)?.is_archived,
    );

    dispatch(setBalance({ canBuyMinutes, hasArchivedSubscription }));
    dispatch(setEnterpriseSubscriptionState(enterpriseSubscriptionState));
  }, [dispatch, isLoading, products, user]);
};
