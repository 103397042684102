import { Box, css, MenuItem, styled } from '@mui/material';

export const Container = styled(MenuItem)({
  '&.MuiMenuItem-root': {
    minHeight: 46,
    gap: 4,
  },

  '&.Mui-focusVisible': {
    background: 'none',
  },

  '&.Mui-selected': {
    display: 'flex',
    justifyContent: 'flex-start',
  },
});

export const CheckIconContainer = styled(Box)(
  ({ theme }) => css`
    color: ${theme.palette.primary[600]};
    margin-left: auto;
  `,
);
