import { Modal, ModalWrapper, ModalContent, Button, ModalActions } from '@braskai/ui-kit';
import { useForm } from 'react-hook-form';
import { Stack } from '@mui/material';
import { useState } from 'react';
import { ControlledTextField } from '@/shared/ui/form';
import { validateEmail } from '@/shared/utils-tmp/validators/inputValidators';
import { createUser } from '@/shared/store-tmp/user/thunks/createUser';
import { useAppDispatch } from '@/shared/hooks-tmp/reduxToolkitHooks';
import { showErrorAlert } from '@/shared/utils-tmp/alert/showErrorAlert';
import { ModalHeaderDefault } from '@/shared/ui/modal';
import { useAddEmailModal } from '../model/useAddEmailModal';

type FormValues = {
  email: string;
  confirmEmail: string;
};

const AddEmailModalComponent = () => {
  const handleSuccess = useAddEmailModal((state) => state.handleSuccess);
  const referral = useAddEmailModal((state) => state.referral);

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    watch,
    formState: { isValid },
  } = useForm<FormValues>({
    defaultValues: {
      email: '',
      confirmEmail: '',
    },
  });

  const email = watch('email');

  const onSubmit = async (values: FormValues) => {
    setIsLoading(true);

    try {
      await dispatch(
        createUser({
          email: values.email,
          isSocial: true,
          referral,
        }),
      );

      await handleSuccess?.(values.email);
    } catch (e) {
      showErrorAlert(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal size="xs" open>
      <form onClick={handleSubmit(onSubmit)}>
        <ModalHeaderDefault
          title="Confirm your email address"
          subtitle="Double-checking ensures you can always access your account"
        />
        <ModalContent noPadding>
          <Stack gap={12}>
            <ControlledTextField
              type="email"
              variant="outlined"
              label="Email"
              placeholder="Your email"
              fieldName="email"
              control={control}
              validator={validateEmail}
            />
            <ControlledTextField
              type="email"
              variant="outlined"
              label="Repeat email"
              placeholder="Your email"
              fieldName="confirmEmail"
              control={control}
              validator={(value: string) => {
                if (value.toLowerCase() !== email.toLowerCase()) {
                  return "Email addresses don't match";
                }

                return validateEmail(value);
              }}
            />
          </Stack>
        </ModalContent>
        <ModalActions>
          <Button type="submit" size="xl" fullWidth isLoading={isLoading} disabled={!isValid}>
            Next question
          </Button>
        </ModalActions>
      </form>
    </Modal>
  );
};

export const AddEmailModal = () => (
  <ModalWrapper hook={useAddEmailModal}>
    <AddEmailModalComponent />
  </ModalWrapper>
);
