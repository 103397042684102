import { useLocation, useNavigate } from 'react-router-dom';
import { PATHS } from '@/shared/routes/paths';
import { MixpanelEvents } from '@/shared/thirdParty/mixpanel';
import { Dialog, DialogContent } from '../../../ui-tmp';
import { Partners } from '../../Partners';
import {
  Actions,
  AdditionalInfo,
  CheckIcon,
  EditorImage,
  LightGreyText,
  List,
  ListItem,
  ListItemText,
  Title,
  ViewPlansButton,
} from './styled';
import { DATA_TEST_IDS, FEATURES_LIST } from './constants';

type TUpgradeFromTrialModalProps = {
  onClose: () => void;
};

export const UpgradeFromTrialModal = ({ onClose }: TUpgradeFromTrialModalProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleButtonClick = () => {
    MixpanelEvents.moreFeaturesPopupClick();
    navigate(`/${PATHS.pricing}`, { state: { path: location.pathname } });
    onClose();
  };

  return (
    <Dialog open onClose={onClose} size="lg">
      <Title onClose={onClose} renderTitle={() => 'Upgrade plan to enjoy more:'} />

      <DialogContent>
        <List>
          {FEATURES_LIST.map(({ text, extraText }) => (
            <ListItem key={text}>
              <CheckIcon width={20} />
              <ListItemText>
                {text}
                {extraText ? <LightGreyText>{extraText}</LightGreyText> : null}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </DialogContent>

      <AdditionalInfo>
        <Partners isSmallFromTablet />

        <EditorImage />
      </AdditionalInfo>

      <Actions>
        <ViewPlansButton data-test-id={DATA_TEST_IDS.viewPlans} onClick={handleButtonClick}>
          View plans
        </ViewPlansButton>
      </Actions>
    </Dialog>
  );
};
