import { ReactNode } from 'react';
import { CheckCircleIcon, FileUploadIcon, TranslateIcon, VideoRecorderIcon, VoiceIcon } from '@braskai/icons';

type TBulletItem = {
  title: string;
  icon: ReactNode;
  isBeta?: boolean;
};

export const BULLETS: TBulletItem[] = [
  {
    title: 'Translate video & audio to 130+ languages',
    icon: <TranslateIcon />,
  },
  {
    title: 'VoiceClone for 29 languages',
    icon: <VoiceIcon />,
  },
  {
    title: 'Lip-sync feature',
    icon: <CheckCircleIcon />,
  },
  {
    title: 'Generate captions',
    icon: <VideoRecorderIcon />,
  },
  {
    title: 'SRT upload and download',
    icon: <FileUploadIcon />,
  },
];
