export enum AuthForm {
  Signup = 'signup',
  Signin = 'signin',
  ConfirmEmail = 'confirmEmail',
  ForgotPassword = 'forgotPassword',
  ChangePassword = 'changePassword',
}
export enum CognitoErrors {
  UserNotConfirmed = 'UserNotConfirmedException',
  PasswordResetRequired = 'PasswordResetRequiredException',
  UserAlreadyExistsInAuth0 = 'UserLambdaValidationException',
  UserAlreadyExistsInCognito = 'UsernameExistsException',
  NotAuthorizedException = 'NotAuthorizedException',
  InvalidParameterException = 'InvalidParameterException',
}
