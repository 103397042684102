import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { showErrorAlert } from '@/shared/utils-tmp/alert/showErrorAlert';
import { LS_SPLIT_SEGMENT_MODAL_DISABLED } from '@/entities/project';

type TFormValues = {
  enabled: boolean;
};

type TUseSplitSegmentModalForm = {
  onClose: () => void;
  onSplitSegment?: () => void;
};

export const useSplitSegmentModalForm = ({ onClose, onSplitSegment }: TUseSplitSegmentModalForm) => {
  const [isLoading, setIsLoading] = useState(false);

  const { control, handleSubmit } = useForm<TFormValues>({
    mode: 'onChange',
    defaultValues: {
      enabled: false,
    },
  });

  const onSubmit = (formValues: TFormValues) => {
    try {
      setIsLoading(true);
      const { enabled } = formValues;

      if (enabled) {
        localStorage.setItem(LS_SPLIT_SEGMENT_MODAL_DISABLED, '1');
      }

      onSplitSegment?.();
      onClose();
    } catch (e) {
      showErrorAlert(e);
    }
    setIsLoading(false);
  };

  return { control, isLoading, onSubmit: handleSubmit(onSubmit) };
};
