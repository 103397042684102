import { ReactElement, ReactNode } from 'react';
import { TooltipProps } from '@mui/material';

type FeatureTooltipItem = {
  title?: string;
  description?: string;
  link?: string;
  linkTitle?: string;
  sendMixpanelEvent?: () => void;
  image?: string;
  placement?: TooltipProps['placement'];
};

type TStarterBannerFeature = {
  icon: ReactNode;
  text: string;
  tooltip?: FeatureTooltipItem;
};

export type TStarterBannerProps = {
  title?: ReactNode;
  description?: string;
  features?: TStarterBannerFeature[];
  onClick?: () => void;
  isCurrent?: boolean;
};

export const PLAN_CATEGORIES = {
  trial: 'trial',
  starter: 'starter',
  creator: 'creator',
  creator_pro: 'creatorPro',
  business: 'business',
  enterprise: 'enterprise',
} as const;

const MEDIA_TYPE = {
  image: 'image',
  video: 'video',
  gif: 'gif',
} as const;

export type ModalFeatureProps = {
  icon: ReactElement;
  description: string;
};

type MediaType = keyof typeof MEDIA_TYPE;

export type PlanCategoriesKeys = keyof typeof PLAN_CATEGORIES;
export type PlanCategories = (typeof PLAN_CATEGORIES)[PlanCategoriesKeys];

export type ModalContent = {
  title: string;
  subtitle: string;
  caption?: string;
  bulletsHeader: string;
  features: ModalFeatureProps[];
  buttonText: string;
  media: {
    type: MediaType;
    src: string;
    poster?: string;
    videoOptions?: {
      loop: boolean;
      autoPlay: boolean;
      controls: boolean;
    };
  };
  mixpanelOpeningEvent: () => void;
  mixpanelButtonClickEvent: () => void;
};

export type LimitedFeatureContent = Partial<Record<PlanCategories, ModalContent | null>>;

const LIMITED_FEATURES = {
  srtUpload: 'srtUpload',
  srtDownload: 'srtDownload',
  lipSync: 'lipSync',
  multilanguage: 'multilanguage',
  aiRewrite: 'aiRewrite',
  videoSubtitles: 'videoSubtitles',
  voicePresets: 'voicePresets',
  audioDownload: 'audioDownload',
  glossary: 'glossary',
} as const;

export type LimitedFeature = keyof typeof LIMITED_FEATURES;
