import { ReactNode } from 'react';

export enum AlertType {
  Info = 'info',
  Warning = 'warning',
  Success = 'success',
  Error = 'error',
}

export type Alert = {
  type?: AlertType;
  message: ReactNode;
  isOpen: boolean;
  duration?: number;
  isClickAwayDisabled?: boolean;
};
