import { AdaptedSubscriptionStatus } from '@/shared/types/Billing';
import { User } from '@/shared/types/User';
import { DEFAULT_SUBSCRIPTION_STATE } from '@/entities/user';
import { getHasSubscriptionState } from './getHasSubscriptionState';

export const getBalance = (user: User | null) => {
  if (!user) {
    return DEFAULT_SUBSCRIPTION_STATE;
  }

  const {
    subscription,
    credits: {
      minutes: { total: totalMinutes, used: usedMinutes },
      video: { total: totalFreeVideos, used: usedFreeVideos },
      lipsync_free_minutes: { total: totalFreeLipSyncMinutes, used: usedFreeLipSyncMinutes },
    },
  } = user;

  let status: AdaptedSubscriptionStatus | null = null;

  const {
    hasTrialPlan,
    hasPausedSubscription,
    hasExpiredSubscription,
    hasActiveSubscription,
    hasCanceledSubscription,
    hasFreeSubscription,
    hasSubscriptionCancelAtPeriodEnd,
    hasUnpaidSubscription,
    hasPastDueSubscription,
  } = getHasSubscriptionState(subscription);

  const hasFreeLipSyncMinutes = totalFreeLipSyncMinutes - usedFreeLipSyncMinutes > 0;
  const hasFreeVideos = totalFreeVideos - usedFreeVideos > 0;
  const hasMinutes = totalMinutes - usedMinutes > 0;
  const availableMinutes = totalMinutes - usedMinutes;
  const availableFreeVideos = totalFreeVideos - usedFreeVideos;

  if (hasActiveSubscription) {
    status = AdaptedSubscriptionStatus.Active;
  }

  if (hasPausedSubscription) {
    status = AdaptedSubscriptionStatus.Paused;
  }

  if (hasExpiredSubscription) {
    status = AdaptedSubscriptionStatus.Expired;
  }

  if (hasCanceledSubscription) {
    status = AdaptedSubscriptionStatus.Canceled;
  }

  if (hasUnpaidSubscription) {
    status = AdaptedSubscriptionStatus.Unpaid;
  }

  if (hasPastDueSubscription) {
    status = AdaptedSubscriptionStatus.PastDue;
  }

  return {
    subscriptionStatus: status,
    hasSubscriptionCancelAtPeriodEnd,
    hasTrialPlan,
    hasActiveSubscription,
    hasPausedSubscription,
    hasExpiredSubscription,
    hasCanceledSubscription,
    hasUnpaidSubscription,
    hasPastDueSubscription,
    hasFreeSubscription,
    hasMinutes,
    hasFreeVideos,
    availableMinutes,
    availableFreeVideos,
    hasFreeLipSyncMinutes,
  };
};
