import { getMappedEvents } from '../utils/getMappedEvents';

const EVENTS = [
  'paymentSuccessful',
  'paymentSubscriptionCanceled',
  'paymentCreditsCanceled',
  'paymentSuccessfulSubscription',
  'paymentSuccessfulMinutes',
  'cancelSubscriptionButton',
  'viewPlansButtonTopPanelClick',
  'upgradeButtonBannerClick',
  'upgradeButtonTrialEndsBannerClick',
  'upgradeButtonTrialEndsEditorClick',
  'upgrade1minuteTextClick',
  'upgrade1minuteVideoClick',
  'seeMoreFeaturesBanner',
  'moreFeaturesPopupClick',
  'cancelSubscriptionCompletedChurnKey',
  'renewSubscriptionCancel',
  'renewSubscriptionPauseClick',
  'renewSubscriptionPauseDone',
  'toggleCompanyInfoCheckout',
  'toggleCheckoutYear',
  'toggleCheckoutMonth',
  'checkoutDetailsClick',
  'checkoutPayLessAnnualClick',
] as const;

export const subscriptionEvents = getMappedEvents([...EVENTS], {
  shouldSendSubscriptionData: true,
});
