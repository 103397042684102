import { Typography, css, styled } from '@mui/material';
import { getTextStyles } from '@braskai/ui-kit';
import { Option } from '@/shared/ui-tmp';

export const SLabel = styled(Typography)(
  () => css`
    margin-bottom: 6px;
  `,
);

export const SOption = styled(Option)(
  ({ theme }) => css`
    &.MuiButtonBase-root {
      &.MuiMenuItem-root {
       ${getTextStyles('textMdRegular')}

        &[aria-selected="true"] {
          background-color: ${theme.palette.primary[100]};
        },
      },
    },
  `,
);
