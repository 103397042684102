import { ArrowAngledIcon } from '@braskai/icons';
import { DialogActions, DialogTitle } from '../../../../../ui-tmp';
import { SButton, SSubtitle, STitle } from '../../styled';

type TSuccessMessageProps = {
  onClose: () => void;
  onOpenCopiedProject: () => void;
};

export const SuccessMessage = ({ onOpenCopiedProject, onClose }: TSuccessMessageProps) => {
  return (
    <>
      <DialogTitle
        onClose={onClose}
        renderTitle={() => (
          <STitle>
            New project created{' '}
            <span role="img" aria-label="smile">
              🥳
            </span>
          </STitle>
        )}
        renderSubtitle={() => (
          <SSubtitle>
            We've started translating the videos into your selected
            <br /> language. You can stay in this project or switch to a new one.
          </SSubtitle>
        )}
      />

      <DialogActions paddingTop={0}>
        <SButton variant="outlined" onClick={onClose}>
          Stay here
        </SButton>

        <SButton endIcon={<ArrowAngledIcon width={21} height={20} />} onClick={onOpenCopiedProject}>
          Go to New Project
        </SButton>
      </DialogActions>
    </>
  );
};
