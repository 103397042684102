import { Settings } from '@/shared/types/Settings';
import { User, ApiData, FeatureGroupRequest } from '../../types/User';
import { del, get, patch, post } from '../../lib/api';
import { BASE_URL } from '../constants';
import { CreateUserRequestData, UpdateUserRequestData } from './types';

const USER_URL = `${BASE_URL}/api/user`;
const API_DATA_URL = `${BASE_URL}/api/settings/api/credentials/`;

export const USER_API = {
  getUser: () => get<User>(USER_URL),

  createUser: (data: CreateUserRequestData) => post<User>(USER_URL, { data }),

  updateUser: (data: UpdateUserRequestData) => patch<User>(USER_URL, { data }),

  deleteUser: () =>
    del<{
      ok: boolean;
    }>(USER_URL),

  getUserSettings: () => get<Settings>(`${USER_URL}/settings`),

  sendUserLoginEvent: () => post<{ ok: boolean }>(`${USER_URL}/send_facebook_login_event`),

  // Get API data
  getApiData: () => get<ApiData>(`${API_DATA_URL}`),

  // Generate API data
  generateApiData: () => post<ApiData>(`${API_DATA_URL}`),

  setVoicePresetTutorialDisplayed: () => post<User>(`${USER_URL}/set_voice_preset_tutorial_displayed`),
  addUserToFeatureGroup: (data: FeatureGroupRequest) =>
    post<FeatureGroupRequest>(`${USER_URL}/feature_group`, { data }),
  getUserFeatureGroups: () => get(`${USER_URL}/feature_groups`),
  setVoiceSettingsTooltipDisplayed: () => post<User>(`${USER_URL}/set_instant_voice_clone_tooltip_displayed`),

  getHubspotToken: () => get<{ token: string }>(`${USER_URL}/hubspot-token`),
};
