type TFeature = {
  text: string;
  extraText?: string;
};

export const FEATURES_LIST: TFeature[] = [
  {
    text: 'Upload files without free trial limits',
  },
  {
    text: 'No watermark',
  },
  {
    text: '130+ languages, 29 languages with VoiceClone',
  },
  {
    text: 'Customize voice clone and save it to Library',
  },
  {
    text: 'Apply Lip-sync',
  },
  {
    text: 'Add subtitles to your video',
  },
  {
    text: 'Save translations to Translation Dictionary',
  },
  {
    text: 'Upload SRT file along with video',
  },
  {
    text: 'Download SRT file for subtitles',
  },
];

export const DATA_TEST_IDS = {
  viewPlans: 'upgrade-from-trial-modal-view-plans',
};
