import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { useAppSelector } from '@/shared/hooks-tmp/reduxToolkitHooks';
import { Modals } from '@/shared/components-tmp/modals';
import { UpgradePlanModal } from '@/entities/pricing';
import { useHandlePaymentSuccess } from './hooks/useHandlePaymentSuccess';

export const BaseLayout = () => {
  const user = useAppSelector((state) => state.user);

  useHandlePaymentSuccess(user);

  return (
    <Suspense>
      <Outlet />
      <Modals />
      <UpgradePlanModal />
    </Suspense>
  );
};
