import { styled, Stack, StackProps } from '@mui/material';
import { Button, ButtonProps } from '@braskai/ui-kit';

export const Subtitle = styled((props: StackProps) => <Stack component="span" {...props} />)(({ theme }) => ({
  textAlign: 'left',
  flexDirection: 'row',
  alignSelf: 'flex-start',
}));

export const Content = styled(Stack)({
  padding: '0 24px 20px',
  gap: 12,
});

export const ActionsContainer = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 24px 24px',
  gap: 12,
});

export const ViewPlansButton = styled((props: ButtonProps) => <Button size="lg" fullWidth {...props} />)('');
