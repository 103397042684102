import { useNavigate } from 'react-router-dom';
import { PATHS } from '@/shared/routes/paths';
import { MixpanelEvents } from '@/shared/thirdParty/mixpanel';
import { useExecuteOneTime } from '@/shared/hooks-tmp/useExecuteOneTime';
import { Dialog, DialogTitle } from '../../../ui-tmp';
import { Title } from './components/Title';
import { ActionsContainer, Content, ViewPlansButton, Subtitle } from './styled';
import { BULLETS, DATA_TEST_IDS } from './constants';
import { Bullet } from './components/Bullet';

type PaywallModalProps = {
  onClose: () => void;
};

export const PaywallModal = ({ onClose }: PaywallModalProps) => {
  const navigate = useNavigate();

  const navigateToPricing = () => {
    MixpanelEvents.viewPlanPaywallClick();
    navigate(PATHS.pricing);
    onClose();
  };

  useExecuteOneTime(() => {
    MixpanelEvents.paywallOpened();
  });

  return (
    <Dialog open onClose={onClose} size="sm">
      <DialogTitle
        onClose={onClose}
        renderTitle={() => <Title />}
        renderSubtitle={() => (
          <Subtitle>
            Upgrade to translate with priority access
            <br /> and awesome features:
          </Subtitle>
        )}
      />

      <Content>
        {BULLETS.map((bullet) => (
          <Bullet key={bullet}>{bullet}</Bullet>
        ))}
      </Content>

      <ActionsContainer>
        <ViewPlansButton data-test-id={DATA_TEST_IDS.viewPlansButton} onClick={navigateToPricing}>
          View plans
        </ViewPlansButton>
      </ActionsContainer>
    </Dialog>
  );
};
