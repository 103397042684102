import { AUTODETECT_LANGUAGE_OPTION } from '@/shared/constants-tmp/autodetectLanguageOption';
import { Language, Languages } from '@/shared/types/Settings';
import { ExtendedLanguage, VoiceGroup } from '../types';

const transformOptions = (options: Language[]): Required<ExtendedLanguage>[] =>
  options.map((option) => {
    let voiceGroup = VoiceGroup.None;
    let position = 4;

    if (option.voice_clone) {
      voiceGroup = VoiceGroup.Production;
      position = 1;
    }

    if (option.voice_clone_beta) {
      voiceGroup = VoiceGroup.Beta;
      position = 2;
    }

    if (option.is_free) {
      voiceGroup = VoiceGroup.FreeDubbing;
      position = 3;
    }

    return {
      ...option,
      voiceGroup,
      position,
    };
  });

const sortOptions = (options: Language[]): ExtendedLanguage[] => {
  const transformedOptions = transformOptions(options);
  return transformedOptions.sort((a, b) => a.position - b.position);
};

export const getAutocompleteLanguageOptions = (
  key: 'dst_lang' | 'src_lang',
  languages: Languages,
): ExtendedLanguage[] => {
  if (key === 'src_lang') {
    return [AUTODETECT_LANGUAGE_OPTION, ...languages.transcription];
  }

  return sortOptions(languages.translation);
};
