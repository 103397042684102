import { Bullet, CheckIcon, Container } from './styled';

export const BULLETS = [
  'Unused minutes will remain in your account even if you pause the subscription',
  'We will roll over your unused minutes to the next month',
];

export const Benefits = () => {
  return (
    <Container>
      {BULLETS.map((bullet) => (
        <Bullet key={bullet}>
          <CheckIcon width={20} />
          {bullet}
        </Bullet>
      ))}
    </Container>
  );
};
