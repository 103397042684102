import { AutocompleteProps as MuiAutocompleteProps } from '@mui/material';
import React, { ElementType } from 'react';
import { ChevronIcon, Paper, Autocomplete as StyledAutocomplete } from './styled';

type TAutocompleteProps<T> = MuiAutocompleteProps<
  T,
  boolean | undefined,
  boolean | undefined,
  boolean | undefined,
  ElementType<'div'>
> & {
  options: T[];
};

export const Autocomplete = <T,>({ ...props }: TAutocompleteProps<T>): React.ReactElement => {
  return (
    <StyledAutocomplete
      fullWidth
      blurOnSelect
      disableClearable
      popupIcon={<ChevronIcon disabled={props.disabled} />}
      PaperComponent={Paper}
      {...props}
    />
  );
};
