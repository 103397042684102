export const removeProtocolAndDomain = (url: string) => {
  if (url) {
    const parsedUrl = new URL(url);

    return parsedUrl.pathname + parsedUrl.search;
  }

  return url;
};

export const getIsUserLeftProjectPage = (projectId?: string) => {
  const location = window.location.pathname.split('/').pop();

  return location !== projectId;
};
