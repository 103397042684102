import * as Sentry from '@sentry/react';
import { User } from '@sentry/react';

export const SentryRask = {
  initIfAvailable: () => {
    if (import.meta.env.VITE_SENTRY_DSN) {
      Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration({
            networkDetailAllowUrls: ['https://rst.rask.ai/api/library/v1/media', '/api/project/'],
            networkRequestHeaders: ['X-Custom-Header'],
            networkResponseHeaders: ['X-Custom-Header'],
          }),
        ],
        environment: import.meta.env.VITE_SENTRY_ENV,
        replaysOnErrorSampleRate: 1.0,
      });
    }
  },
  initUser: (user: User) => {
    if (import.meta.env.VITE_SENTRY_DSN) {
      Sentry.setUser(user);
    }
  },
};
