import { getMappedEvents } from '../utils/getMappedEvents';

const EVENTS = [
  'onboardingStepPlayerOkay',
  'onboardingStepEditorGotIt',
  'onboardingStepEditorLearnMore',
  'onboardingStepAudioTrackEditorGotIt',
  'onboardingStepVoiceGotIt',
  'onboardingStepApplyChangesGotIt',
  'onboardingStepDownloadLearnMore',
  'onboardingStepDownloadGotIt',
  'onboardingExitClick',
] as const;

export const onboardingEvents = getMappedEvents([...EVENTS]);
