import { styled, Stack, StackProps } from '@mui/material';
import { Button, ButtonProps } from '@braskai/ui-kit';

type ContainerProps = {
  hasGap: boolean;
} & StackProps;
export const Container = styled(Stack, { shouldForwardProp: (prop) => prop !== 'hasGap' })<ContainerProps>(
  ({ hasGap }) => ({
    gap: hasGap ? 20 : 0,
  }),
);

export const SubmitButton = styled((props: ButtonProps) => <Button size="xl" fullWidth {...props} />)('');

export const BackButton = styled((props: ButtonProps) => <Button size="xl" variant="outlined" {...props} />)(
  ({ theme }) => ({
    '& .MuiButton-startIcon': {
      margin: 0,
    },

    [theme.breakpoints.up('tablet')]: {
      width: '100%',
    },
  }),
);

export const Actions = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  gap: 12,
});
