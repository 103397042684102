import { styled, Stack, Typography, TypographyProps } from '@mui/material';

export const TitleContainer = styled(Stack)({
  gap: 20,
  textAlign: 'left',
});

export const Subtitle = styled((props: TypographyProps) => <Typography component="span" {...props} />)(({ theme }) => ({
  ...theme.typography.textSmMedium,
  display: 'block',
  color: theme.palette.grey[500],
  textAlign: 'left',

  '& a': {
    textDecoration: 'underline',
    color: 'inherit',
  },
}));
