import { MixpanelEvents } from '@/shared/thirdParty/mixpanel';
import AudioDownloadPlaceholder from '@/shared/assets/images/audio-download-feature.jpg';
import { LimitedFeatureContent, ModalContent } from '../types';
import { BUTTON_TEXTS, BULLETS_HEADER_TEXT } from './texts';
import { getBullets } from './bullets';

const BASE_CONTENT: ModalContent = {
  title: 'Unlock Audio download',
  subtitle: 'Upgrade to Creator to download audio with generated voice or audio with voice and background sound',
  bulletsHeader: BULLETS_HEADER_TEXT.withCreator,
  features: getBullets(['translate25', 'downloadGeneratedVoice', 'audioDownload', 'noWatermark']),
  buttonText: BUTTON_TEXTS.toCreator,
  media: {
    type: 'image',
    src: AudioDownloadPlaceholder,
  },
  mixpanelOpeningEvent: MixpanelEvents.audioUpgradeModalShown,
  mixpanelButtonClickEvent: MixpanelEvents.audioUpgradeChoosePlanModalClick,
};

const TRIAL_PLAN_CONTENT = {
  ...BASE_CONTENT,
};

const STARTER_PLAN_CONTENT = {
  ...BASE_CONTENT,
  bulletsHeader: BULLETS_HEADER_TEXT.inStarter,
};

export const AUDIO_DOWNLOAD_CONTENT: LimitedFeatureContent = {
  trial: TRIAL_PLAN_CONTENT,
  starter: STARTER_PLAN_CONTENT,
};
