import { getMappedEvents } from '../utils/getMappedEvents';

const EVENTS = [
  'srtButtonPressed',
  'srtUploadSrc',
  'srtUploadDst',
  'srtErrorMessage',
  'upgradeSrtDownloadModalShown',
  'upgradeSrtDownloadModalClick',
  'srtUpgradeModal',
  'srtUpgradeChoosePlanModalClick',
  'srtFileUploaded',
] as const;

export const srtUploadEvents = getMappedEvents([...EVENTS], { shouldSendSubscriptionData: true });
