import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { API } from '@/shared/api';
import { Products } from '@/shared/types/Billing';
import { showErrorAlert } from '@/shared/utils-tmp/alert/showErrorAlert';

const QUERY_KEY = ['products'];

const getProducts = async () => {
  const response = await API.getPlans();

  const sortedPlans = response.plan.sort((a, b) => a.metadata.position - b.metadata.position);

  return {
    credit: response.credit,
    plan: sortedPlans,
  };
};

export const useProducts = (isInitDataFetched?: boolean) => {
  const { data, isLoading, error } = useQuery<Products>({
    queryFn: getProducts,
    queryKey: QUERY_KEY,
    refetchOnMount: false,
    enabled: !!isInitDataFetched,
  });

  useEffect(() => {
    if (error) {
      showErrorAlert(error);
    }
  }, [error]);

  return {
    products: data,
    isLoading,
    error,
  };
};
