import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GlobalErrorType } from '@/shared/types/GlobalErrors';

type InitialState = {
  type: GlobalErrorType | null;
};
const initialState: InitialState = {
  type: null,
};
const globalErrorSlice = createSlice({
  name: 'globalErrors',
  initialState,
  reducers: {
    setGlobalError: (
      state,
      {
        payload,
      }: PayloadAction<{
        type: GlobalErrorType;
      }>,
    ) => payload,
    resetGlobalError: (state) => {
      state.type = null;
    },
  },
});
export const globalErrorsReducer = globalErrorSlice.reducer;
export const { setGlobalError, resetGlobalError } = globalErrorSlice.actions;
