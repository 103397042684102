import { Language } from '@/shared/types/Settings';

export const AUTODETECT_LANGUAGE_OPTION: Language = {
  id: 'AUTODETECT',
  value: 'Autodetect',
  label: 'Autodetect',
  country_code: '',
  mean_char_per_syllable: 0,
  speech_rate: 0,
  speech_tempo_feature: false,
  voice_clone: false,
  voice_clone_beta: false,
  is_free: false,
};
