import { styled, css } from '@mui/material';
import { ModalActions, ModalContent } from '@braskai/ui-kit';

export const SContainer = styled(ModalContent)(
  () => css`
    display: flex;
    flex-direction: column;
    gap: 20px;
  `,
);

export const SFooter = styled(ModalActions)(
  () => css`
    justify-content: space-between;
  `,
);
