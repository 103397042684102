import { MixpanelEvents } from '@/shared/thirdParty/mixpanel';
import SrtDownloadPlaceholder from '@/shared/assets/images/srt-download.png';
import { LimitedFeatureContent, ModalContent } from '../types';
import { BUTTON_TEXTS, BULLETS_HEADER_TEXT } from './texts';
import { getBullets } from './bullets';

const BASE_CONTENT: ModalContent = {
  title: 'Unlock .SRT download',
  subtitle:
    'Upgrade to Creator Pro plan to download SRT files containing subtitles for both the original and translated videos',
  bulletsHeader: BULLETS_HEADER_TEXT.withCreatorPro,
  features: getBullets([
    'translate100',
    'lipSync',
    'srtUploadDownload',
    'voiceCloneSettings',
    'voicePresets',
    'subtitles',
    'aiRewrite',
  ]),
  buttonText: BUTTON_TEXTS.toCreatorPro,
  media: {
    type: 'image',
    src: SrtDownloadPlaceholder,
  },
  mixpanelOpeningEvent: MixpanelEvents.upgradeSrtDownloadModalShown,
  mixpanelButtonClickEvent: MixpanelEvents.upgradeSrtDownloadModalClick,
};

const TRIAL_PLAN_CONTENT = {
  ...BASE_CONTENT,
};

const STARTER_PLAN_CONTENT = {
  ...BASE_CONTENT,
  bulletsHeader: BULLETS_HEADER_TEXT.inStarter,
};

const CREATOR_PLAN_CONTENT = {
  ...BASE_CONTENT,
  bulletsHeader: BULLETS_HEADER_TEXT.inCreator,
};

export const SRT_DOWNLOAD_CONTENT: LimitedFeatureContent = {
  trial: TRIAL_PLAN_CONTENT,
  starter: STARTER_PLAN_CONTENT,
  creator: CREATOR_PLAN_CONTENT,
};
