// rask.ai docs
const BASE_RASK_DOC_LINK = 'https://www.rask.ai';
export const PRIVACY_POLICY_LINK = `${BASE_RASK_DOC_LINK}/privacy-policy`;
export const TERMS_OF_SERVICE_LINK = `${BASE_RASK_DOC_LINK}/terms-of-service`;
export const RASK_CHAT_LINK = `${BASE_RASK_DOC_LINK}/chat`;

// calendly
export const CALENDLY_LINK = 'https://calendly.com/d/yrv-cpn-ydd/rask-ai-enterprise-call';

// help center docs
export const HELP_CENTER_LINK = `https://25309887.hs-sites-eu1.com/rask-ai`;
export const MULTILANGUAGE_DOC_LINK = `${HELP_CENTER_LINK}/introducing-the-multilingual-projects-feature-rask-help-center`;
export const INVOICES_DOC_LINK = `${HELP_CENTER_LINK}/i-need-to-add-information-about-my-company-to-the-invoice-rask-help-center`;
export const LANGUAGES_DOC_LINK = `${HELP_CENTER_LINK}/to-which-languages-can-i-translate-my-video-rask-help-center`;
export const HOW_TO_EDIT_PROJECT_LINK = `${HELP_CENTER_LINK}/how-to-edit-the-project-in-rask-ai-rask-help-center`;
export const LIP_SYNC_DOC_LINK = `${HELP_CENTER_LINK}/the-lip-sync-feature-rask-help-center`;
export const LIP_SYNC_FEATURE_INTRO = `${HELP_CENTER_LINK}/introducing-the-new-lip-sync-model-with-better-quality-rask-help-center`;
export const VOICE_CLONING_DOC_LINK = `${HELP_CENTER_LINK}/what-is-voice-cloning-feature-rask-help-center`;
export const SRT_DOWNLOAD_DOC_LINK = `${HELP_CENTER_LINK}/what-is-srt-download-rask-help-center`;
export const SRT_UPLOAD_DOC_LINK = `${HELP_CENTER_LINK}/what-is-srt-upload-support-rask-help-center`;
export const AI_REWRITER_DOC_LINK = `${HELP_CENTER_LINK}/what-is-ai-script-adjustment-rask-help-center`;
export const VIDEO_CAPTIONS_DOC_LINK = `${HELP_CENTER_LINK}/introducing-subtitles-rask-help-center`;
export const HOW_TO_USE_GLOSSARY_LINK = `${HELP_CENTER_LINK}/how-to-use-translation-dictionary`;
export const DOWNLOAD_OPTIONS_LINK = `${HELP_CENTER_LINK}/download-options-in-rask-ai-1`;
export const HOW_TO_USE_VOICE_PRESETS_DOC_LINK = `${HELP_CENTER_LINK}/what-is-custom-voice-clone-rask-help-center`;

// api docs
export const API_DOCUMENTATION_LINK = 'https://docs.api.rask.ai/introduction';

// white label
export const WHITE_LABEL_SUPPORT_LINK = import.meta.env.VITE_WHITE_LABEL_EMAIL;
