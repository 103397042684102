import { Stack, StackProps, styled, SvgIconProps } from '@mui/material';
import { AlertCircleIcon } from '@braskai/icons';
import { AttentionIconSeverity } from './types';

type TContainerProps = {
  severity: AttentionIconSeverity;
} & StackProps;

export const Container = styled(Stack)<TContainerProps>(({ severity, theme }) => ({
  width: 48,
  height: 48,
  borderRadius: '50%',

  alignItems: 'center',
  justifyContent: 'center',

  ...(severity === 'info' && {
    border: `8px solid ${theme.palette.primary[100]}`,
    backgroundColor: theme.palette.primary[50],
  }),

  ...(severity === 'warning' && {
    border: `8px solid ${theme.palette.warning[100]}`,
    backgroundColor: theme.palette.warning[50],
  }),

  ...(severity === 'error' && {
    border: `8px solid ${theme.palette.error[100]}`,
    backgroundColor: theme.palette.error[50],
  }),
}));

type TIconProps = {
  severity: AttentionIconSeverity;
} & SvgIconProps;

export const Icon = styled(AlertCircleIcon)<TIconProps>(({ severity = 'info', theme }) => ({
  width: 24,
  height: 24,

  fill: theme.palette.common.white,

  ...(severity === 'info' && {
    color: theme.palette.primary[600],
  }),

  ...(severity === 'warning' && {
    color: theme.palette.warning[600],
  }),

  ...(severity === 'error' && {
    color: theme.palette.error[600],
  }),
}));
