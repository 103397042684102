import { Language } from '@/shared/types/Settings';

export type ExtendedLanguage = {
  position?: number;
  voiceGroup?: string;
} & Language;

export enum VoiceGroup {
  None = 'none',
  Production = 'production',
  FreeDubbing = 'freeDubbing',
  Beta = 'beta',
}
