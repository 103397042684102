import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '@/shared/api';
import { Project } from '@/shared/types/Project';
import { setProject } from '../ProjectSlice';
import { RootState } from '../..';

export const getProjectById = createAsyncThunk<Project, { id: Project['id'] }, { state: RootState }>(
  'project/getProjectById',
  async ({ id }: { id: Project['id'] }, { dispatch, rejectWithValue }) => {
    try {
      const project = await API.getProjectById(id);
      dispatch(setProject(project));
      return project;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);
