import { Price } from '@/shared/types/Billing';

export type RegistrationSurveyEventData = {
  [key: string]: string | boolean;
};
export type ViewItemListItems = {
  item_id: Price['id'];
  item_name: string;
  price: number;
  quantity: number;
};
export enum ViewItemList {
  Plan = 'Plan',
  Minutes = 'Minutes',
}
type EcommerceViewItemListEventData = {
  view_item_list: ViewItemList;
  items: ViewItemListItems[];
};
export type ViewItemListEventData = {
  ecommerce: EcommerceViewItemListEventData;
};
type EcommerceBeginCheckoutEventData = {
  value: number;
  currency: string;
  items: [
    {
      item_id: string;
      item_name: string;
      price: number;
      quantity: number;
    },
  ];
};
export type BeginCheckoutEventData = {
  ecommerce: EcommerceBeginCheckoutEventData;
};
