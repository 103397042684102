import { OnboardingStepKeys } from './Onboarding';
import { Subscription } from './Billing';
import { Survey } from './Survey';

export enum SurveyStatus {
  Rejected = 'rejected',
  Completed = 'completed',
}

export type UserSurvey = {
  id: Survey['id'];
  name: Survey['name'];
  status: SurveyStatus;
  status_date: Date;
};
export type Credits = {
  minutes: {
    total: number;
    used: number;
  };
  video: {
    total: number;
    used: number;
  };
  lipsync_free_minutes: {
    total: number;
    used: number;
  };
};

export type ApiData = {
  id: string;
  client_id: string;
  client_secret: string;
};

type ABTest = {
  id: string;
  name: string | null;
  option: {
    id: '0' | '1'; // 0 — enabled, 1 — disabled
    name: string;
  };
};

export type User = {
  id: string;
  created_date: string;
  email: string;
  minutes_increase_schedule: string[];
  picture?: string;
  customer_id?: string;
  credits: Credits;
  subscription: Subscription | null;
  is_superuser: boolean;
  stripe_id: string;
  onboarding: OnboardingStepKeys[];
  survey_done: boolean;
  surveys: UserSurvey[];
  churnkey_hmac?: string;
  email_type: 'public' | 'corporate';
  email_consent: boolean | null;
  api_credentials_generated: boolean;
  api_data?: ApiData;
  voice_preset_tutorial_displayed: boolean;
  instant_voice_clone_tooltip_displayed: boolean;
  first_lipsync_done: boolean;
  feature_groups: ABTest[];
  received_minute_for_tts_feedback: boolean;
};

export type FeatureGroupRequest = {
  feature_group_id: string;
  feature_group_option_id: string;
};
