import { List, Box, ListSubheader, styled, css } from '@mui/material';
import { getTextStyles, squareBlockCSS, styledOmitCustomProps, Typography } from '@braskai/ui-kit';

export const SLinkContainer = styled(Box)(
  () => css`
    padding: 18px 18px 8px 17px;
    height: 50px;
    width: 100%;

    > a {
      height: 100%;
      display: block;
      overflow: hidden;
      text-decoration: none;

      > svg {
        width: 130px;
        height: 24px;
      }
    }
  `,
);

export const SWhiteLabelLogo = styled(Typography)(
  ({ theme }) => css`
    min-width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    gap: 8px;
    white-space: nowrap;

    &:before {
      ${squareBlockCSS(24)};
      content: '';
      background-color: ${theme.palette.primary[700]};
      border-radius: 4px;
    }
  `,
);

type SListProps = {
  $flexGrow?: number;
};

export const SList = styled(
  List,
  styledOmitCustomProps,
)<SListProps>(
  ({ $flexGrow }) => css`
    padding: 10px 8px;

    ${$flexGrow &&
    css`
      flex-grow: ${$flexGrow};
    `}
  `,
);

export const SListSubheader = styled(ListSubheader)(
  ({ theme }) => css`
    padding: 8px 0px 4px 2px;
    line-height: 12px;
    color: ${theme.palette.grey[500]};
    ${getTextStyles('caption-2-md')}
  `,
);
