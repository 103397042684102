import { MixpanelEvents } from '@/shared/thirdParty/mixpanel';
import { MULTILANGUAGE_DOC_LINK } from '@/shared/lib/constants/docLinks';
import MultilanguagePlaceholder from '@/shared/assets/images/multilanguage.png';
import { LimitedFeatureContent, ModalContent } from '../types';
import { BUTTON_TEXTS, BULLETS_HEADER_TEXT } from './texts';
import { getBullets } from './bullets';

const BASE_CONTENT: ModalContent = {
  title: 'Unlock Multi-language translation feature',
  subtitle:
    'Your plan allows translation to 1 language per upload. Upgrade to translate your video into multiple languages',
  caption: `(Learn more)[${MULTILANGUAGE_DOC_LINK}] how to effectively utilize this feature`,
  buttonText: BUTTON_TEXTS.toBusiness,
  media: {
    type: 'image',
    src: MultilanguagePlaceholder,
  },
  mixpanelOpeningEvent: MixpanelEvents.multilanguageUpgradeModalShown,
  mixpanelButtonClickEvent: MixpanelEvents.multilanguageUpgradeChoosePlanModalClick,
  bulletsHeader: BULLETS_HEADER_TEXT.inCreator,
  features: getBullets([
    'translate500',
    'lipSync',
    'voiceCloneSettings',
    'voicePresets',
    'glossary',
    'srtUploadDownload',
    'subtitles',
    'aiRewrite',
    'simultaneousMultiLanguageTranslation',
    'api',
    'noWatermark',
  ]),
};

const STARTER_PLAN_CONTENT = {
  ...BASE_CONTENT,
  bulletsHeader: BULLETS_HEADER_TEXT.inStarter,
};

const CREATOR_PLAN_CONTENT = {
  ...BASE_CONTENT,
  features: getBullets([
    'translate500',
    'lipSync',
    'voiceCloneSettings',
    'voicePresets',
    'glossary',
    'srtUploadDownload',
    'subtitles',
    'aiRewrite',
    'simultaneousMultiLanguageTranslation',
    'api',
  ]),
};

const CREATOR_PRO_PLAN_CONTENT = {
  ...CREATOR_PLAN_CONTENT,
  bulletsHeader: BULLETS_HEADER_TEXT.inCreatorPro,
  features: getBullets(['translate500', 'glossary', 'voicePresets10', 'simultaneousMultiLanguageTranslation', 'api']),
};

export const MULTILANGUAGE_CONTENT: LimitedFeatureContent = {
  starter: STARTER_PLAN_CONTENT,
  creator: CREATOR_PLAN_CONTENT,
  creatorPro: CREATOR_PRO_PLAN_CONTENT,
};
