import MaintenanceImage from '@/shared/assets/images/maintenance.png';
import { Container, Image, Subtitle, Title } from './styled';

export const MaintenancePage = () => {
  return (
    <Container>
      <Image src={MaintenanceImage} />

      <Title>
        <span role="img" aria-label="Rocket">
          🚀
        </span>{' '}
        Rask Update Alert!{' '}
        <span role="img" aria-label="Rocket">
          🚀
        </span>
      </Title>
      <Subtitle>
        Our service is temporarily down for a thrilling upgrade.
        <br /> We'll be back shortly with an even better video localization experience.
        <br />
        Thanks for your patience! 😊
      </Subtitle>
    </Container>
  );
};
