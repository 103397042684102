import { useState } from 'react';
import { PAYMENT_CREDITS_CANCELED_PARAM, PAYMENT_SUCCESSFUL_PARAM } from '@/shared/routes/searchParams';
import { showErrorAlert } from '@/shared/utils-tmp/alert/showErrorAlert';
import { GTMEvents } from '@/shared/thirdParty/gtm';
import { API } from '@/shared/api';
import { useProducts } from '@/entities/pricing';
import { getBuyMinutesBeginCheckoutEventData } from '../utils/getBuyMinutesBeginCheckoutEventData';

export const useMinutesBuying = (totalMinutes: number, price: number) => {
  const { products } = useProducts();

  const [isLoading, setIsLoading] = useState(false);

  const product = products?.credit[0];

  const priceId = product?.prices[0].id;

  const checkout = async () => {
    try {
      if (!product || !priceId) {
        return;
      }

      setIsLoading(true);
      const url = window.location.href;
      const data = {
        id: priceId,
        quantity: totalMinutes,
        success_url: `${url}?${PAYMENT_SUCCESSFUL_PARAM}`,
        cancel_url: `${url}?${PAYMENT_CREDITS_CANCELED_PARAM}`,
      };

      GTMEvents.beginCheckout(getBuyMinutesBeginCheckoutEventData({ price, totalMinutes, product }));

      const { checkout_url } = await API.getBuyCreditsCheckoutUrl(data);

      if (checkout_url) {
        window.location.href = checkout_url;
      }
    } catch (e) {
      showErrorAlert(e);
    } finally {
      setIsLoading(false);
    }
  };
  return { checkout, isStripeCheckoutLoading: isLoading };
};
