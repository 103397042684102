import { USER_API } from './user';
import { BILLING_API } from './billing';
import { APP_SETTINGS_API } from './config';
import { PROJECT_API } from './project';
import { OTHER_CALLS_API } from './otherCalls';
import { SURVEY_API } from './survey';
import { ADMIN_API } from './admin';
import { ACCOUNTS_API } from './accounts';
import { VOICE_PRESETS_API } from './voicePreset';
import { GLOSSARY_API } from './glossary';

export const API = {
  ...APP_SETTINGS_API,
  ...USER_API,
  ...BILLING_API,
  ...PROJECT_API,
  ...SURVEY_API,
  ...OTHER_CALLS_API,
  ...ADMIN_API,
  ...ACCOUNTS_API,
  ...VOICE_PRESETS_API,
  ...GLOSSARY_API,
};
