import { useNavigate, useSearchParams } from 'react-router-dom';
import { API } from '@/shared/api';
import {
  PAYMENT_CREDITS_CANCELED_PARAM,
  PAYMENT_SUBSCRIPTION_CANCELED_PARAM,
  PAYMENT_SUCCESSFUL_PARAM,
} from '@/shared/routes/searchParams';
import { PATHS } from '@/shared/routes/paths';

import { useExecuteOneTime } from '@/shared/hooks-tmp/useExecuteOneTime';
import { MixpanelEvents } from '@/shared/thirdParty/mixpanel';
import { User } from '@/shared/types/User';
import { GTMEvents } from '@/shared/thirdParty/gtm';
import { useSubscription } from '@/shared/hooks-tmp/useSubscription';
import { useBillingStore } from '@/shared/hooks-tmp/reduxHooks/useBillingStore';
import { getLatestPaymentData } from './utils/getLatestPaymentData';

// Убирает из урла "payment-successful" и отправляет событие в аналитику
export const useHandlePaymentSuccess = (user: User | null) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    balance: { hasActiveSubscription },
  } = useBillingStore();

  const { planName, periodLabel } = useSubscription(user);

  useExecuteOneTime(async () => {
    const hasPaymentSuccessfulParam = searchParams.has(PAYMENT_SUCCESSFUL_PARAM);
    const hasPaymentSubscriptionCancelParam = searchParams.has(PAYMENT_SUBSCRIPTION_CANCELED_PARAM);
    const hasPaymentCreditsCancelParam = searchParams.has(PAYMENT_CREDITS_CANCELED_PARAM);

    if (hasPaymentSubscriptionCancelParam) {
      MixpanelEvents.paymentSubscriptionCanceled();
      searchParams.delete(PAYMENT_SUBSCRIPTION_CANCELED_PARAM);
      setSearchParams(searchParams);
    }

    if (hasPaymentCreditsCancelParam) {
      MixpanelEvents.paymentCreditsCanceled();
      searchParams.delete(PAYMENT_CREDITS_CANCELED_PARAM);
      setSearchParams(searchParams);
    }

    if (hasPaymentSuccessfulParam) {
      if (user?.credits) {
        MixpanelEvents.paymentSuccessful({
          minutes: user.credits.minutes.total,
        });
      }

      searchParams.delete(PAYMENT_SUCCESSFUL_PARAM);
      setSearchParams(searchParams);

      const paramsLength = Array.from(searchParams.keys()).length;
      // Оставляем в урле остальные параметры
      // TODO: type needs to be corrected
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      navigate(PATHS.homepage + (paramsLength ? `?${searchParams}` : ''), { replace: true });

      const latestPaymentInfo = await API.getLatestPaymentInfo();

      if (latestPaymentInfo) {
        const { latestPaymentPeriod, name, quantity, range } = getLatestPaymentData(latestPaymentInfo);

        GTMEvents.purchase(latestPaymentInfo);

        if (latestPaymentPeriod === 'Annual' || latestPaymentPeriod === 'Monthly') {
          const eventData = {
            subscription: `${name} ${latestPaymentPeriod}`,
            hasActiveSubscription,
          };

          MixpanelEvents.paymentSuccessfulSubscription(eventData);
          return;
        }

        const eventData = {
          subscription: `${planName} ${periodLabel}`,
          minutes_range: range,
          minutes: quantity,
          hasActiveSubscription,
        };

        MixpanelEvents.paymentSuccessfulMinutes(eventData);
      }
    }
  });
};
