import { Box, css, Stack, styled } from '@mui/material';

export const SContainer = styled(Box)(
  ({ theme }) => css`
    border-top: 1px solid ${theme.palette.grey[200]};

    &:first-of-type {
      border-top: none;
    }
  `,
);

export const SGroupHeader = styled(Stack)(
  () => css`
    padding: 10px 14px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `,
);

export const SBadgeContainer = styled(Stack)(
  () => css`
    flex-direction: row;
    align-items: center;
    gap: 4px;
  `,
);

export const SBadge = styled(Stack)(
  ({ theme }) => css`
    background: ${theme.palette.success[50]};
    border-radius: 16px;
    padding: 2px 8px;
    gap: 4px;
  `,
);
