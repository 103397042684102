import { useCallback, useEffect } from 'react';
import { wait } from '@braskai/ui-kit';

import {
  HUBSPOT_INPUT_ID_PREFIXES,
  HUBSPOT_TRACKING_CODE,
  IS_HUBSPOT_EMBED_FORMS_DEBUGGER_ENABLED,
} from '../constants';

const HUBSPOT_EMBED_FORMS_ENABLED = !!import.meta.env.VITE_HUBSPOT_EMBED_FORMS_ENABLED;

type HubSpotHiddenFormDebuggerValues = { [key: string]: string | boolean };

type HubSpotHiddenFormValues = Record<(typeof HUBSPOT_INPUT_ID_PREFIXES)[number], string | boolean>;

type UseHubSpotEmbedForm = {
  formId: string;
  inputIdPrefixes: typeof HUBSPOT_INPUT_ID_PREFIXES;
};

export const useHubSpotEmbedForm = ({ formId, inputIdPrefixes }: UseHubSpotEmbedForm) => {
  const renderHiddenEmbedForm = useCallback(() => {
    if (!window.hbspt) {
      return;
    }

    // eslint-disable-next-line
    window.hbspt.forms.create({
      region: 'eu1',
      portalId: HUBSPOT_TRACKING_CODE,
      formId,
      target: `#hubspot-${formId}`,
    });
  }, [formId]);

  const sendDataToHubSpot = async (formValues: HubSpotHiddenFormValues) => {
    const debuggerData: HubSpotHiddenFormDebuggerValues = {};

    const formElement: HTMLFormElement | null = document.querySelector(`form[data-form-id='${formId}']`);

    if (!formElement) {
      return;
    }

    inputIdPrefixes.forEach((inputIdPrefix) => {
      const inputElement: HTMLInputElement | null = document.querySelector(`#${inputIdPrefix}-${formId}`);
      const value = formValues[inputIdPrefix];

      if (!inputElement) {
        return;
      }

      if (typeof value === 'string') {
        inputElement.value = value;
      }

      if (typeof value === 'boolean') {
        inputElement.checked = value;
      }

      debuggerData[`${inputIdPrefix}-${formId}`] = value;
    });

    if (IS_HUBSPOT_EMBED_FORMS_DEBUGGER_ENABLED) {
      console.info('%cHubSpotEmbedForms debugger:', 'color: #D92D20');
      console.table(debuggerData);
      /* eslint-enable */
    }

    formElement.submit();
    await wait(1500);
  };

  useEffect(() => {
    if (!HUBSPOT_EMBED_FORMS_ENABLED) {
      return;
    }

    renderHiddenEmbedForm();
  }, [renderHiddenEmbedForm]);

  return { sendDataToHubSpot, hiddenFormId: `hubspot-${formId}` };
};
