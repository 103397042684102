import { ButtonBaseProps } from '@mui/material';
import { CloseIcon } from '@braskai/icons';
import { SCloseButton } from './styled';

export const CloseButton = (props: ButtonBaseProps) => {
  return (
    <SCloseButton {...props}>
      <CloseIcon width={16} height={16} />
    </SCloseButton>
  );
};
