export enum EModalType {
  BuyMinutes = 'buyMinutes',
  DeleteSegment = 'deleteSegment',
  SetupCustomer = 'setupCustomer',
  SetupCustomerSubscription = 'setupCustomerSubscription',
  SetupCustomerCredits = 'setupCustomerCredits',
  PasswordConfirmation = 'passwordConfirmation',
  DeleteAccountConfirmation = 'deleteAccountConfirmation',
  UpgradeFromTrial = 'UpgradeFromTrial',
  MobileNotice = 'mobileNotice',
  Paywall = 'paywall',
  MobilePaywall = 'mobilePaywall',
  EnterprisePaymentRequired = 'enterprisePaymentRequired',
  Multilanguage = 'multilanguage',
  SuccessfulSubscriptionRenewal = 'successfulSubscriptionRenewal',
  SplitSegment = 'splitSegment',
  ChooseSpeaker = 'chooseSpeaker',
}

export type Modal = {
  type: EModalType;
  [key: string]: unknown;
};
