import { User } from '@/shared/types/User';
import { getPlanName } from '@/shared/utils-tmp/subscription/getPlanName';
import { getPlanPeriodLabel } from '@/shared/utils-tmp/subscription/getPlanPeriodLabel';
import { getBalance } from '@/shared/utils-tmp/user/balance/getBalance';
import { useProducts } from '@/entities/pricing';

export const useSubscription = (user: User | null) => {
  const { products } = useProducts();

  const balance = getBalance(user);
  const { hasFreeSubscription, hasTrialPlan } = balance;

  const planName = getPlanName({ user, products, hasFreeSubscription, hasTrialPlan });
  const periodLabel = getPlanPeriodLabel(user);

  return {
    ...balance,
    planName,
    periodLabel,
  };
};
