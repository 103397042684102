import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AudioPlotDataStatus, TAudioPlotDataStatus } from '@/shared/types/AudioPlotData';
import { getOriginalAudioPlotData } from './thunks/getOriginalAudioPlotData';

type TAudioPlotDataItem = {
  status: TAudioPlotDataStatus;
  data: number[];
  max: number;
};

type TAudioPlotDataState = {
  originalAudioPlotData: TAudioPlotDataItem;
};

const initialState: TAudioPlotDataState = {
  originalAudioPlotData: {
    status: AudioPlotDataStatus.Idle,
    data: [0],
    max: 0,
  },
};

const audioPlotDataSlice = createSlice({
  name: 'audioPlotData',
  initialState,
  reducers: {
    setAudioPlotDataStatus: (state, { payload }: PayloadAction<TAudioPlotDataItem['status']>) => {
      state.originalAudioPlotData.status = payload;
    },

    resetAudioPlotData: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(getOriginalAudioPlotData.pending, (state) => {
        state.originalAudioPlotData.status = AudioPlotDataStatus.Loading;
      })
      .addCase(getOriginalAudioPlotData.fulfilled, (state, { payload }) => {
        state.originalAudioPlotData.status = AudioPlotDataStatus.Ready;
        state.originalAudioPlotData.data = payload.plot_values;
        state.originalAudioPlotData.max = payload.max_value;
      })
      .addCase(getOriginalAudioPlotData.rejected, (state) => {
        state.originalAudioPlotData.status = AudioPlotDataStatus.Ready;
      });
  },
});

export const audioPlotDataReducer = audioPlotDataSlice.reducer;
export const { setAudioPlotDataStatus, resetAudioPlotData } = audioPlotDataSlice.actions;
