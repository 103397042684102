import { Control } from 'react-hook-form';
import { TOTAL_MINUTES_CONTROLLER, USED_MINUTES_CONTROLLER } from '../../constants';
import { Input } from '../Input';
import { Container } from '../common/styled';
import { FormValues } from '../../types';

type MinutesCreditsProps = {
  control: Control<FormValues>;
  disabled: boolean;
};
export const MinutesCredits = ({ control, disabled }: MinutesCreditsProps) => {
  return (
    <Container>
      <Input controller={TOTAL_MINUTES_CONTROLLER} label="Total minutes" control={control} disabled={disabled} />
      <Input controller={USED_MINUTES_CONTROLLER} label="Used minutes" control={control} disabled={disabled} />
    </Container>
  );
};
