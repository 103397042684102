import { Avatar, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LogoutIcon } from '@braskai/icons';
import { IconButton } from '@braskai/ui-kit';
import { IS_WHITE_LABEL } from '@/shared/lib/environments';
import { useAppDispatch, useAppSelector } from '@/shared/hooks-tmp/reduxToolkitHooks';
import { User } from '@/shared/types/User';
import { logout } from '@/shared/utils-tmp/logout';
import { resetStore } from '@/shared/store-tmp/RootReducer';
import { setAuthForm } from '@/entities/viewer';
import { AuthForm } from '@/shared/types/Cognito';
import { showErrorAlert } from '@/shared/utils-tmp/alert/showErrorAlert';
import { PATHS } from '@/shared/routes/paths';
import { useSubscription } from '@/shared/hooks-tmp/useSubscription';
import { useCognitoCheckUser } from '@/entities/user';
import { SCard, STextContainer, STypography } from './styled';

export type ProfileItemProps = {
  variant?: 'outlined' | 'filled';
  active?: boolean;
};

export const ProfileItem = ({ variant = 'filled', active = false }: ProfileItemProps) => {
  const navigate = useNavigate();

  const { data } = useCognitoCheckUser();
  const picture = data?.picture;

  const user = useAppSelector((state) => state.user) as User;

  const dispatch = useAppDispatch();

  const subscription = useSubscription(user);

  const planName = IS_WHITE_LABEL ? 'Account' : subscription?.planName;

  const handleClick = () => {
    if (!IS_WHITE_LABEL) {
      navigate(`/${PATHS.account}`);
    }
  };

  const handleLogout = async () => {
    try {
      await logout();

      void dispatch(resetStore());

      setAuthForm(AuthForm.Signin);

      navigate(`/${PATHS.auth}`, { replace: true });
    } catch (error) {
      showErrorAlert(error);
    }
  };

  return (
    <SCard
      $variant={variant}
      $active={active}
      $cursor={IS_WHITE_LABEL ? 'default' : 'pointer'}
      role="button"
      aria-label="profile"
      onClick={handleClick}
    >
      <Avatar src={picture} alt="Profile image" sx={{ width: 36, height: 36 }} />
      <STextContainer>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <STypography variant="textSmMedium" color="grey.900" title={planName}>
            {planName}
          </STypography>
          {IS_WHITE_LABEL && (
            <IconButton onClick={handleLogout} disableBackground disableFixSizeIcon>
              <LogoutIcon width={20} height={20} />
            </IconButton>
          )}
        </Stack>

        <STypography variant="textXsMedium" color="grey.500" title={user?.email}>
          {user?.email}
        </STypography>
      </STextContainer>
    </SCard>
  );
};
