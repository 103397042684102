import {
  AudioIcon,
  CheckThinIcon,
  CloseIcon,
  FileIcon,
  FolderIcon,
  GlobeIcon,
  StarsIcon,
  SubtitlesDisabledIcon,
  UserCheckIcon,
  UserManagerIcon,
  BookOpenIcon,
  SettingsIcon,
  VoiceIcon,
  FaceWinkIcon,
  TranslateIcon,
  ContainerIcon,
} from '@braskai/icons';

const BULLETS = {
  translate25: {
    icon: <TranslateIcon />,
    description: 'From 25 minutes every month',
  },
  translate100: {
    icon: <TranslateIcon />,
    description: 'From 100 minutes every month',
  },
  translate500: {
    icon: <TranslateIcon />,
    description: 'From 500 minutes every month',
  },
  translate2000: {
    icon: <TranslateIcon />,
    description: 'From 2000 minutes every month',
  },
  customPriceForMins: {
    icon: <CheckThinIcon />,
    description: 'Custom price for minutes',
  },
  humanInTheLoop: {
    icon: <UserCheckIcon />,
    description: 'Human-in-the-loop',
  },
  businessContract: {
    icon: <FileIcon />,
    description: 'Business contract and invoicing',
  },
  voiceCloneSettings: {
    icon: <SettingsIcon />,
    description: 'Voice Clone settings',
  },
  personalManager: {
    icon: <UserManagerIcon />,
    description: 'Personal Customer Manager',
  },
  srtUploadDownload: {
    icon: <FileIcon />,
    description: 'SRT transcript upload and SRT download',
  },
  lipSync: {
    icon: <FaceWinkIcon />,
    description: 'Multispeaker Lip-Sync',
  },
  subtitles: {
    icon: <SubtitlesDisabledIcon />,
    description: 'Automated captions/subtitles',
  },
  aiRewrite: {
    icon: <StarsIcon />,
    description: 'AI script adjustment',
  },
  api: {
    icon: <ContainerIcon />,
    description: 'API access',
  },
  voicePresets: {
    icon: <FolderIcon />,
    description: 'Custom Voice Clone library',
  },
  voicePresets10: {
    icon: <FolderIcon />,
    description: 'Custom Voice Clone library (10 custom voices)',
  },
  voicePresetsUnlimited: {
    icon: <FolderIcon />,
    description: 'Up to unlimited Custom Voice Clones',
  },
  simultaneousMultiLanguageTranslation: {
    icon: <GlobeIcon />,
    description: 'Simultaneous multi-language translation',
  },
  audioDownload: {
    icon: <AudioIcon />,
    description: 'Download generated voice with background sound',
  },
  noWatermark: {
    icon: <CloseIcon />,
    description: 'No watermark',
  },
  glossary: {
    icon: <BookOpenIcon />,
    description: 'Translation dictionaries',
  },
  downloadGeneratedVoice: {
    icon: <VoiceIcon />,
    description: 'Download generated voice',
  },
} as const;

export const getBullets = (keys: (keyof typeof BULLETS)[]) =>
  keys.filter((key) => key in BULLETS).map((key) => BULLETS[key]);
