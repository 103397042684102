import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { Loader } from '@/shared/ui-tmp';
import { useCognitoCheckUser } from '@/entities/user';
import { TITLE_META_TAG } from '@/shared/lib/constants/titleMetaTag';

export const ProtectedRoute = () => {
  const location = useLocation();

  const { data, isPending: isUserAuthorizedChecking } = useCognitoCheckUser();
  const isAuthorized = data?.isAuthorized;

  if (isUserAuthorizedChecking) {
    return <Loader />;
  }

  if (isAuthorized) {
    return (
      <>
        <Helmet>
          <title>{TITLE_META_TAG}</title>
        </Helmet>
        <Outlet />
      </>
    );
  }

  return <Navigate to="/auth" state={{ from: location }} replace />;
};
