import { create } from 'zustand';
import { AuthForm } from '@/shared/types/Cognito';
import { IS_WHITE_LABEL } from '@/shared/lib/environments';

type UseViewerStore = {
  email?: string;
  password?: string;
  authForm: AuthForm;
};

const initialStore: UseViewerStore = {
  email: undefined,
  password: undefined,
  authForm: IS_WHITE_LABEL ? AuthForm.Signin : AuthForm.Signup,
};

export const useViewerStore = create<UseViewerStore>((set) => initialStore);

// TODO: Change the code confirmation to a more secure one
export const setViewerEmail = (email: UseViewerStore['email']) =>
  useViewerStore.setState({
    email,
  });
export const setViewerPassword = (password: UseViewerStore['password']) =>
  useViewerStore.setState({
    password,
  });

// TODO: It would be good to convert it to routes
export const setAuthForm = (authForm: UseViewerStore['authForm']) =>
  useViewerStore.setState({
    authForm,
  });
