import { styled, Stack, Typography } from '@mui/material';
import { Button, ButtonProps } from '@braskai/ui-kit';

export const Title = styled(Typography)({
  textAlign: 'left',
});

export const Subtitle = styled('span')({
  display: 'block',
  textAlign: 'left',
  color: 'inherit',
});

export const Content = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '0 24px 32px',
  gap: 12,
});

export const Actions = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 12,
  padding: '0 24px 24px',
});

export const CancelButton = styled((props: ButtonProps) => (
  <Button variant="outlined" fullWidth size="lg" {...props} />
))('');

export const UpdateButton = styled((props: ButtonProps) => <Button fullWidth size="lg" {...props} />)('');
