import { useEffect } from 'react';
import { IS_WHITE_LABEL } from '@/shared/lib/environments';
import { useSurvey } from '@/shared/hooks-tmp/useSurvey';
import { useSignupSurveyModalStore } from '@/shared/components-tmp/modals/SignupSurveyModal/hooks/useSignupSurveyModalStore';

const SIGNUP_SURVEY_ID = '2';

export const useSignupSurveyModal = (isInitialDataReceived: boolean) => {
  const { shouldCompleteSurvey } = useSurvey(SIGNUP_SURVEY_ID);

  const openSignupSurveyModal = useSignupSurveyModalStore((state) => state.open);

  useEffect(() => {
    if (isInitialDataReceived && shouldCompleteSurvey && !IS_WHITE_LABEL) {
      openSignupSurveyModal();
    }
  }, [shouldCompleteSurvey, openSignupSurveyModal, isInitialDataReceived]);
};
