import { User } from '@/shared/types/User';
import { API } from '@/shared/api';

const CHURNKEY_APP_ID: string = import.meta.env.VITE_CHURNKEY_APP_ID;
const MODE = import.meta.env.VITE_MODE === 'development' ? 'test' : 'live';

export const initChurnkey = () => {
  if (CHURNKEY_APP_ID) {
    // TODO: type needs to be corrected
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (!window.churnkey || !window.churnkey.created) {
      window.churnkey = { created: true };
      const a = document.createElement('script');
      a.src = `https://assets.churnkey.co/js/app.js?appId=${CHURNKEY_APP_ID}`;
      a.async = true;
      const b = document.getElementsByTagName('script')[0];
      b.parentNode?.insertBefore(a, b);
    }
  }
};

const handleSubscriptionChanged = () => {
  setTimeout(() => {
    window.location.reload();
  }, 1000);
};

const getStripeUrlAndRedirect = async () => {
  const { billing_portal_url } = await API.getCustomerPortal(window.location.href);

  if (billing_portal_url) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    window.location.href = billing_portal_url;
  }
};

export const startChurnkeyCancellationFlow = ({
  user,
  onPause,
  onCancel,
}: {
  user: User | null;
  onPause: () => void;
  onCancel: () => void;
}) => {
  if (!CHURNKEY_APP_ID) {
    return;
  }

  const customerId = user?.customer_id;
  const subscriptionId = user?.subscription?.id;
  const authHash = user?.churnkey_hmac;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
  window.churnkey.init('show', {
    subscriptionId,
    customerId,
    authHash,
    appId: CHURNKEY_APP_ID,
    mode: MODE,
    record: false,
    provider: 'stripe',
    onPause,
    onCancel,
    onPlanChange: handleSubscriptionChanged,
    onGoToAccount: getStripeUrlAndRedirect,
    onError: getStripeUrlAndRedirect,
    handleSupportRequest: () => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      window.churnkey.hide();
    },
  });
};

export const checkChurnkey = ({ user, onResume }: { user: User | null; onResume: () => void }) => {
  if (!CHURNKEY_APP_ID) {
    return;
  }

  const customerId = user?.customer_id;
  const subscriptionId = user?.subscription?.id;
  const authHash = user?.churnkey_hmac;

  if (!customerId || !authHash || !subscriptionId) {
    void getStripeUrlAndRedirect();
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
  window.churnkey.check('pause', {
    subscriptionId,
    customerId,
    authHash,
    appId: CHURNKEY_APP_ID,
    mode: MODE,
    provider: 'stripe',
    softWall: true,
    onResume,
    onCancel: handleSubscriptionChanged,
  });
};

export const clearUserChurnkeyState = () => {
  if (!CHURNKEY_APP_ID) {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
  if (window.churnkey?.clearState) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    window.churnkey.clearState();
  }
};
