import { Stack, styled, Typography } from '@mui/material';
import { Button, ButtonProps } from '@braskai/ui-kit';
import { DialogContent } from '../../../ui-tmp';

export const Title = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('mobileLarge')]: {
    textAlign: 'left',
  },
}));

export const Content = styled(DialogContent)({
  gap: 20,
});

export const ErrorCard = styled(Stack)(({ theme }) => ({
  ...theme.typography.textSmMedium,
  flexDirection: 'row',
  alignItems: 'center',
  gap: 14,
  border: `1px solid ${theme.palette.error[300]}`,
  borderRadius: 10,
  backgroundColor: theme.palette.error[25],
  padding: '10px 16px',
  position: 'relative',
  color: theme.palette.error[600],
}));

export const CancelButton = styled((props: ButtonProps) => (
  <Button variant="outlined" fullWidth size="lg" {...props} />
))('');

export const SaveButton = styled((props: ButtonProps) => <Button fullWidth size="lg" variant="primary" {...props} />)(
  '',
);
