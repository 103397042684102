import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '@/shared/api';
import { Project } from '@/shared/types/Project';

export const deleteProject = createAsyncThunk(
  'project/deleteProject',
  async (id: Project['id'], { dispatch, rejectWithValue }) => {
    try {
      await API.deleteProject(id);
      return true;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);
