import { combineReducers, AnyAction, createAsyncThunk } from '@reduxjs/toolkit';
import { onboardingReducer } from './onboarding/OnboardingSlice';
import { alertReducer } from './AlertSlice';
import { userReducer } from './user/UserSlice';
import { projectReducer } from './project/ProjectSlice';
import { billingReducer } from './billing/BillingSlice';
import { editorReducer } from './editor/EditorSlice';
import { adminReducer } from './admin/AdminSlice';
import { voicesReducer } from './voices/VoicesSlice';
import { globalErrorsReducer } from './GlobalErrorsSlice';
import { accountsReducer } from './accounts/AccountsSlice';
import { audioPlotDataReducer } from './audioPlotData/audioPlotDataSlice';

const reducers = {
  alert: alertReducer,
  user: userReducer,
  project: projectReducer,
  editor: editorReducer,
  billing: billingReducer,
  admin: adminReducer,
  voices: voicesReducer,
  globalErrors: globalErrorsReducer,
  onboarding: onboardingReducer,
  accounts: accountsReducer,
  audioPlotData: audioPlotDataReducer,
};
const rootReducer = combineReducers(reducers);
export const resettableRootReducer = (state: ReturnType<typeof rootReducer> | undefined, action: AnyAction) => {
  if (action.type === 'store/reset') {
    return rootReducer(undefined, action);
  }
  return rootReducer(state, action);
};
export const resetStore = createAsyncThunk('store/reset', (payload, { dispatch }) => {
  dispatch({ type: 'store/reset' });
});
