import { TServerSegment } from '@/shared/types/Editor';

export enum ESrtType {
  Src = 'src',
  Dst = 'dst',
}

export type TParsedSegment = Pick<TServerSegment, 'start' | 'end'> & {
  speaker?: TServerSegment['speaker'];
  [ESrtType.Src]?: TServerSegment['src'];
  [ESrtType.Dst]?: TServerSegment['dst'];
};

export type TParsedSrt = Record<ESrtType, TParsedSegment[] | null>;

export enum EFormError {
  InvalidSrc = 'invalidSrc',
  InvalidSrcLang = 'invalidSrcLang',
  InvalidDst = 'invalidDst',
  InvalidSpeakersQty = 'invalidSpeakersQty',
  TimestampsMismatch = 'timestampsMismatch',
  FetchingMetadata = 'fetchingMetadata',
  PlanLimitsExceeded = 'planLimitsExceeded',
  ForbiddenLink = 'forbiddenLink',
}

export type TErrors = Record<EFormError, boolean>;

export type TErrorHandlers = {
  isVideoDurationExceedsPlanLimit: (duration: number) => boolean;
  isVideoSizeExceedsPlanLimit: (fileSize: number) => boolean;
  checkTimestampsEquality: (parsedSrt: TParsedSrt) => void;
  setFormError: (key: keyof TErrors) => void;
  resetError: (key?: keyof TErrors) => void;
};
