import { TranslateIcon, VoiceIcon, HelpIcon, MessageCircleIcon, BookOpenIcon } from '@braskai/icons';
import { ReactNode } from 'react';
import { HELP_CENTER_LINK, WHITE_LABEL_SUPPORT_LINK } from '@/shared/lib/constants/docLinks';
import { PATHS } from '@/shared/routes/paths';
import { MixpanelEvents } from '@/shared/thirdParty/mixpanel';
import { showChat } from '@/shared/thirdParty/hubSpot';
import { DATA_TEST_IDS } from './constants';

type Nav = {
  title: string;
  rightText?: string;
  path: string;
  icon: ReactNode;
  action?: () => void;
  isDisabled?: boolean;
};

type GetNavOptionsProps = {
  isVoicePresetsFeatureVisible: boolean;
  isGlossaryFeatureVisible: boolean;
  isMobile?: boolean;
};

export const getNavOptions = ({
  isVoicePresetsFeatureVisible,
  isGlossaryFeatureVisible,
  isMobile = false,
}: GetNavOptionsProps) => {
  const navItems: Nav[] = [
    {
      title: 'Translation',
      path: PATHS.homepage,
      icon: <TranslateIcon width={16} height={16} />,
      action: () => {
        MixpanelEvents.translationTabClick();
      },
    },
  ];

  if (isVoicePresetsFeatureVisible) {
    navItems.push({
      title: 'Voice Clone Library',
      path: `/${PATHS.voices}`,
      icon: <VoiceIcon width={16} height={16} />,
      action: () => {
        MixpanelEvents.voiceCloneLibraryTabClick();
      },
    });
  }

  if (isGlossaryFeatureVisible) {
    navItems.push({
      title: 'Dictionary',
      rightText: isMobile ? 'Desktop only' : undefined,
      path: `/${PATHS.glossaries}`,
      icon: <BookOpenIcon width={16} height={16} />,
      isDisabled: isMobile,
      action: () => {
        MixpanelEvents.dictionaryTabClick();
      },
    });
  }

  return navItems;
};

type GetHelpNavOptionsProps = {
  isWhiteLabel?: boolean;
};

type GetHelpNavOptionsReturn = {
  title: string;
  icon: ReactNode;
  action: () => void;
  dataTestId: string;
};

export const getHelpNavOptions = ({ isWhiteLabel = false }: GetHelpNavOptionsProps): GetHelpNavOptionsReturn[] => {
  if (isWhiteLabel) {
    return [
      {
        title: 'Get help',
        icon: <HelpIcon width={16} height={16} />,
        action: () => {
          window.open(`mailto:${WHITE_LABEL_SUPPORT_LINK}`);
        },
        dataTestId: DATA_TEST_IDS.questionButton,
      },
    ];
  }

  return [
    {
      title: 'Help Center',
      icon: <HelpIcon width={16} height={16} />,
      action: () => {
        MixpanelEvents.helpCenterClick();
        window.open(HELP_CENTER_LINK);
      },
      dataTestId: DATA_TEST_IDS.helpCenter,
    },
    {
      title: 'Get help',
      icon: <MessageCircleIcon width={16} height={16} />,
      action: () => {
        MixpanelEvents.getHelpClick();
        showChat();
      },
      dataTestId: DATA_TEST_IDS.questionButton,
    },
  ];
};
