import { createAsyncThunk } from '@reduxjs/toolkit';
// commented by business requirement
// import { cookies } from '@/shared/utils/Cookie';
// import { getABTestStarterGroup } from '@/entities/user/abTest';

import { API } from '@/shared/api';
import { MixpanelEvents, setMixpanelUser } from '@/shared/thirdParty/mixpanel';
import { GTMEvents } from '@/shared/thirdParty/gtm';
import { getBalance } from '@/shared/utils-tmp/user/balance/getBalance';
import { CONFLICT_STATUS_CODE } from '@/shared/api/errors';
import { logout } from '@/shared/utils-tmp/logout';
import { LS_IS_JUST_SIGNED_IN_NAME } from '@/entities/user';
import { setBalance } from '../../billing/BillingSlice';
import { setUser } from '../UserSlice';

type CreateUserParams = {
  isSocial?: boolean;
  referral?: string;
  email?: string;
};

// const addUserToFeatureGroup = async (featureToggle: string) => {
//   await API.addUserToFeatureGroup({
//     feature_group_id: `${featureToggle}`[0],
//     feature_group_option_id: `${featureToggle}`?.[1],
//   });
// };

export const createUser = createAsyncThunk(
  'user/createUser',
  async ({ isSocial, referral, email }: CreateUserParams, { dispatch, rejectWithValue }) => {
    try {
      const user = await API.createUser({ referral_id: referral, email });
      const balance = getBalance(user);
      dispatch(setUser(user));
      dispatch(setBalance(balance));
      setMixpanelUser(user.id, user.email, user.email_type);
      MixpanelEvents.signUp({ isSocial: Boolean(isSocial) });
      GTMEvents.setUserId(user.id);
      GTMEvents.registration();

      // const featureToggle = cookies.get('featureToggle') as string;

      // if (featureToggle) {
      //   await addUserToFeatureGroup(featureToggle);
      // } else {
      //   const group = getABTestStarterGroup(50, 40);

      //   await addUserToFeatureGroup(group);
      // }
      // remove this LS item to prevent sending 'login' event after 'registration'
      localStorage.removeItem(LS_IS_JUST_SIGNED_IN_NAME);
    } catch (e) {
      if (typeof e === 'object' && e !== null && 'status' in e && e.status === CONFLICT_STATUS_CODE) {
        void logout();
        localStorage.clear();
        window.location.reload();
      }

      return rejectWithValue(e);
    }
  },
);
