import { API } from '@/shared/api';
import { HubSpotEventPayload } from './types';

export const initUser = async (email: string) => {
  try {
    if (window._hsq) {
      const { token } = await API.getHubspotToken();
      window.hsConversationsSettings = {
        ...(window.hsConversationsSettings || {}),
        identificationEmail: email,
        identificationToken: token,
      };
    }
  } catch (e) {
    console.error('Error on getting hubspot token \n', e);
  }
};

const update = (email: string, payload?: HubSpotEventPayload) => {
  if (window._hsq) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    window._hsq.push(['identify', { email, ...payload }]);
  }
};

const trackEvent = (eventName: string, payload?: HubSpotEventPayload) => {
  if (window._hsq) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    window._hsq.push([
      'trackCustomBehavioralEvent',
      {
        name: eventName,
        properties: payload || {},
      },
    ]);
  }
};

export const hubSpotMethods = {
  update,
  trackEvent,
  initUser,
};
