import { Stack, css, styled } from '@mui/material';

export const SContainer = styled(Stack)(
  ({ theme }) => css`
    flex-direction: row;
    height: 100svh;

    ${theme.breakpoints.up('tablet')} {
      min-height: 100vh;
    }
  `,
);
