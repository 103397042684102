import { CheckboxProps as MuiCheckboxProps, styled } from '@mui/material';
import { forwardRef } from 'react';
import { BaseCheckbox, SCheckboxBaseIcon, SCheckboxFilledIcon } from './styled';

type CheckboxProps = {
  filled?: boolean;
} & MuiCheckboxProps;

export const Checkbox = styled(
  forwardRef<HTMLButtonElement, CheckboxProps>(({ size, ...props }: CheckboxProps, ref) => {
    const sizePx = size === 'small' ? 16 : 20;
    return (
      <BaseCheckbox
        icon={<SCheckboxBaseIcon width={sizePx} height={sizePx} />}
        checkedIcon={<SCheckboxFilledIcon width={sizePx} height={sizePx} />}
        ref={ref}
        {...props}
      />
    );
  }),
)({
  padding: 0,
});
