import { JSX } from 'react';
import { Project } from './Project';
import { UserFeedback } from './Survey';
import { User } from './User';
import { ProjectVideoFeedback } from './VideoFeedback';

export type Customer = {
  created_date: Date;
  is_active: boolean;
} & User;
export enum CustomersType {
  All = 'all',
  Paid = 'paid',
  Free = 'free',
  Trial = 'trial',
}
export enum ContentType {
  Customers = 'customers',
  Feedback = 'feedback',
  Survey = 'survey',
}
export type Columns<T> = Record<
  string,
  {
    label: () => JSX.Element | string;
    width: number;
    accessor: (data: T, isLoading: boolean) => JSX.Element;
  }
>;
export type CustomerVideoFeedback = {
  date: Date;
  id: string;
  project_id: Project['id'];
  rating: UserFeedback | null;
  user_email: Customer['email'];
  user_id: Customer['id'];
} & Pick<ProjectVideoFeedback, 'options' | 'rating_score' | 'text'>;
