import { getMappedEvents } from '../utils/getMappedEvents';

const EVENTS = [
  'rateResultSurvey',
  'rateLipSyncStart',
  'rateLipSyncDone',
  'ttsFirstProjectFeedbackRequested',
  'ttsFirstProjectRatingReceived',
  'ttsFirstProjectFeedbackReceived',
] as const;

export const feedbackEvents = getMappedEvents([...EVENTS], {
  shouldSendSubscriptionData: true,
  shouldSendRaskTTSData: true,
});
