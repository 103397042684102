import { styled, Stack, Typography, StackProps, TypographyProps } from '@mui/material';
import AmazonImg from '@/shared/assets/images/partners/amazon.png';
import FameplayImg from '@/shared/assets/images/partners/fameplay.png';
import MengmiuImg from '@/shared/assets/images/partners/mengmiu.png';
import SemrushImg from '@/shared/assets/images/partners/semrush.png';
import PublicisImg from '@/shared/assets/images/partners/publicis.png';

type ContainerProps = {
  isSmallFromTablet: boolean;
} & StackProps;

export const Container = styled(({ isSmallFromTablet, ...props }: ContainerProps) => <Stack {...props} />)(
  ({ theme, isSmallFromTablet }) => ({
    justifyContent: 'center',
    alignItems: 'center',
    background: isSmallFromTablet ? theme.palette.primary[25] : theme.palette.grey[50],
    borderRadius: 24,
    padding: '48px 24px',
    gap: 32,

    ...(isSmallFromTablet && {
      [theme.breakpoints.up('tablet')]: {
        alignItems: 'flex-start',
        gap: 12,
        padding: 0,
      },
    }),

    [theme.breakpoints.up('desktop')]: {
      padding: isSmallFromTablet ? 0 : 64,
    },
  }),
);

type PartnersTitleProps = {
  isSmallFromTablet: boolean;
} & TypographyProps;

export const PartnersTitle = styled(({ isSmallFromTablet, ...props }: PartnersTitleProps) => <Typography {...props} />)(
  ({ theme, isSmallFromTablet }) => ({
    ...theme.typography.textMdMedium,
    color: theme.palette.grey[500],

    ...(isSmallFromTablet && {
      [theme.breakpoints.up('tablet')]: {
        ...theme.typography.textXsRegular,
      },
    }),

    [theme.breakpoints.up('laptop')]: {
      ...(isSmallFromTablet ? theme.typography.textXsRegular : theme.typography.textXlMedium),
    },
  }),
);

type PartnersIconsContainerProps = {
  isSmallFromTablet: boolean;
} & StackProps;

export const PartnersIconsContainer = styled(({ isSmallFromTablet, ...props }: PartnersIconsContainerProps) => (
  <Stack {...props} />
))(({ theme, isSmallFromTablet }) => ({
  gap: 28,
  alignItems: 'center',
  flexDirection: 'column',

  '& > img': {
    transform: 'scale(0.7)',
  },

  ...(isSmallFromTablet && {
    [theme.breakpoints.up('tablet')]: {
      ...theme.typography.textXsRegular,
      flexDirection: 'row',
      gap: 14.5,

      '& > img': {
        transform: 'scale(1)',
      },
    },
  }),

  [theme.breakpoints.up('laptop')]: {
    width: isSmallFromTablet ? 'auto' : '100%',
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: isSmallFromTablet ? 14.5 : 16,

    '& > img': {
      transform: 'scale(1)',
    },
  },
}));

type IconProps = {
  isSmallFromTablet: boolean;
};

export const FameplayIcon = styled(({ isSmallFromTablet, ...props }: IconProps) => (
  <img alt="Fameplay" src={FameplayImg} {...props} />
))(({ theme, isSmallFromTablet }) => ({
  width: 127,
  height: 48,

  ...(isSmallFromTablet && {
    [theme.breakpoints.up('tablet')]: {
      width: 44,
      height: 17,
    },
  }),
}));

export const AmazonIcon = styled(({ isSmallFromTablet, ...props }: IconProps) => (
  <img alt="Amazon" src={AmazonImg} {...props} />
))(({ theme, isSmallFromTablet }) => ({
  width: 132,
  height: 40,

  ...(isSmallFromTablet && {
    [theme.breakpoints.up('tablet')]: {
      width: 46,
      height: 14,
    },
  }),
}));

export const SemrushIcon = styled(({ isSmallFromTablet, ...props }: IconProps) => (
  <img alt="Semrush" src={SemrushImg} {...props} />
))(({ theme, isSmallFromTablet }) => ({
  width: 269,
  height: 36,

  ...(isSmallFromTablet && {
    [theme.breakpoints.up('tablet')]: {
      width: 94,
      height: 12,
    },
  }),
}));

export const MengmiuIcon = styled(({ isSmallFromTablet, ...props }: IconProps) => (
  <img alt="Mengmiu" src={MengmiuImg} {...props} />
))(({ theme, isSmallFromTablet }) => ({
  width: 135,
  height: 40,

  ...(isSmallFromTablet && {
    [theme.breakpoints.up('tablet')]: {
      width: 47,
      height: 14,
    },
  }),
}));

export const PublicisIcon = styled(({ isSmallFromTablet, ...props }: IconProps) => (
  <img alt="Publicis" src={PublicisImg} {...props} />
))(({ theme, isSmallFromTablet }) => ({
  width: 177,
  height: 40,

  ...(isSmallFromTablet && {
    [theme.breakpoints.up('tablet')]: {
      width: 62,
      height: 14,
    },
  }),
}));
