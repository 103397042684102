import { Box, Drawer, drawerClasses, styled, css } from '@mui/material';

const SMALL_DRAWER_WIDTH = 60;
const LARGE_DRAWER_WIDTH = 208;

export const SContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-shrink: 0;
    width: ${SMALL_DRAWER_WIDTH}px;
    height: 100vh;
    z-index: ${theme.zIndex.dialog};
  `,
);

export const SDrawer = styled(Drawer)(
  ({ theme }) => css`
    width: ${SMALL_DRAWER_WIDTH}px;
    position: fixed;
    top: 0;
    bottom: 0;
    right: unset;
    z-index: ${theme.zIndex.sidebar};
    transition: width ${theme.transitions.duration.leavingScreen}ms ${theme.transitions.easing.sharp};

    .${drawerClasses.paper} {
      padding: 0;
      box-sizing: border-box;
      border-right: 1px solid ${theme.palette.grey[200]};
      box-shadow: unset;
      overflow-x: hidden;

      ${theme.breakpoints.up('tablet')} {
        position: relative;
        z-index: ${theme.zIndex.basic};
      }
    }

    &:hover {
      width: ${LARGE_DRAWER_WIDTH}px;
    }
  `,
);
