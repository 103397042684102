import { inIframe } from '@/shared/lib/utils';
import { Options } from './types';
// note: every change in option values, even a space, should be
// reflected in HubSpot account otherwise no data
// for this option will be passed
const SELECT_OPTIONS: Options = {
  role: {
    controller: 'role',
    label: 'What is your role?',
    options: [
      'Blogger / Influencer',
      'Marketer',
      'Founder / CEO',
      'E-learning specialist',
      'Video Production Manager',
      'Product Manager',
      'Other',
    ],
  },
  content: {
    controller: 'content',
    label: 'What type of content are you creating?',
    options: [
      'Videos for my blog',
      'Marketing videos',
      'Educational content',
      'Content for TV / Media',
      'Podcasts and interviews',
      'Other',
    ],
  },
  referral: {
    controller: 'referral',
    label: 'How did you hear about us?',
    options: [
      'Google Search',
      'YouTube',
      'X (ex-Twitter)',
      'Linkedin',
      'Instagram',
      'Facebook Ads',
      'TikTok',
      'Recommended by a friend',
      'Other',
    ],
  },
};
export const INIT_SELECT_VALUES = {
  option: '',
  text: '',
};
export const SELECT_FORM_STEPS = {
  0: SELECT_OPTIONS.role,
  1: SELECT_OPTIONS.content,
  2: SELECT_OPTIONS.referral,
};
export const OTHER = 'other';
export const EMAIL_CONSENT_STEP = 1;
export const TOTAL_STEPS = Object.keys(SELECT_FORM_STEPS).length + EMAIL_CONSENT_STEP + (inIframe() ? -1 : 0);

export const DATA_TEST_IDS = {
  backToSignIn: 'signup-survey-back-to-sign-in',
  chooseInput: 'signup-survey-choose-input',
  nextQuestionButton: 'signup-survey-next-question-button',
};
