import React from 'react';
import { Typography } from '@mui/material';
import { useDeviceType } from '@braskai/ui-kit';
import { VoiceGroup } from '../../types';
import { SBadge, SBadgeContainer, SContainer, SGroupHeader } from './styled';

const LABELS = {
  [VoiceGroup.None]: 'Languages with AI voices',
  [VoiceGroup.Beta]: 'Languages with voice clone',
  [VoiceGroup.FreeDubbing]: 'Languages with voice clone',
  [VoiceGroup.Production]: 'Languages with voice clone',
};

const SHORT_LABELS = {
  [VoiceGroup.None]: 'Ai voices',
  [VoiceGroup.Beta]: 'Voice Clone',
  [VoiceGroup.FreeDubbing]: 'Voice Clone',
  [VoiceGroup.Production]: 'Voice Clone',
};

type TOptionGroupProps = {
  group: VoiceGroup;
  children?: React.ReactNode;
  isSrtUploadMode?: boolean;
};

export const OptionGroup = ({ group, children, isSrtUploadMode }: TOptionGroupProps) => {
  const { isMobileSmall, isMobileMedium } = useDeviceType();

  if (!group) {
    return <span>{children}</span>;
  }

  return (
    <SContainer>
      <SGroupHeader>
        <Typography variant="textXsMedium" color="grey.700">
          {isSrtUploadMode && !isMobileSmall && !isMobileMedium ? SHORT_LABELS[group] : LABELS[group]}
        </Typography>
        {group === VoiceGroup.Beta ? (
          <Typography variant="textXsMedium" color="grey.400">
            Beta
          </Typography>
        ) : null}
        {group === VoiceGroup.FreeDubbing ? (
          <SBadgeContainer>
            <Typography variant="textXsMedium" color="grey.400">
              Beta
            </Typography>
            <SBadge>
              <Typography variant="textXsMedium" color="success.700">
                Free {((!isMobileSmall && !isSrtUploadMode) || isMobileMedium) && 'Dubbing'}
              </Typography>
            </SBadge>
          </SBadgeContainer>
        ) : null}
      </SGroupHeader>
      <span>{children}</span>
    </SContainer>
  );
};
