import { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useAppDispatch } from '@/shared/hooks-tmp/reduxToolkitHooks';
import { AUTH_API } from '@/shared/api/amplify';
import { showAlert } from '@/shared/store-tmp/AlertSlice';
import { AlertType } from '@/shared/types/Alert';
import { CognitoErrors } from '@/shared/types/Cognito';
import { PasswordInput } from '../../inputs/PasswordInput';
import { MailCard } from '../../cards/MailCard';
import { Dialog, DialogActions, DialogTitle } from '../../../ui-tmp';
import { CancelButton, Content, ErrorCard, SaveButton, Title } from './styled';

type AccountSettingsModalProps = {
  onClose: () => void;
};

type FormValues = {
  oldPassword: string;
  newPassword: string;
};

export const ChangePasswordModal = ({ onClose }: AccountSettingsModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  const [arePasswordsSame, setArePasswordsSame] = useState(false);

  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      oldPassword: '',
      newPassword: '',
    },
  });

  const oldPassword = watch('oldPassword');
  const newPassword = watch('newPassword');

  useEffect(() => {
    if (newPassword && oldPassword) {
      setArePasswordsSame(newPassword === oldPassword);
    }
  }, [newPassword, oldPassword]);

  const onCancel = () => {
    reset();
    onClose();
  };

  const onSubmit = async (value: FormValues) => {
    try {
      setIsLoading(true);

      await AUTH_API.changePassword(value.oldPassword, value.newPassword);

      dispatch(showAlert({ message: 'Password updated', type: AlertType.Success }));
      onClose();
    } catch (e) {
      const error = e as Error & {
        code: CognitoErrors;
        message: string;
      };

      if (
        error?.code === CognitoErrors.NotAuthorizedException ||
        error?.code === CognitoErrors.InvalidParameterException
      ) {
        dispatch(showAlert({ message: 'Incorrect password' }));
      } else {
        dispatch(showAlert({ message: error?.message }));
      }
    }
    setIsLoading(false);
  };

  return (
    <Dialog open onClose={isLoading ? undefined : onClose} size="sm">
      <DialogTitle onClose={onClose} renderTitle={() => <Title>Account Settings</Title>} buttonsDisabled={isLoading} />

      <Content>
        <div>
          <MailCard />
        </div>
        <PasswordInput
          control={control}
          placeholder="Enter old password"
          label="Old password"
          isDisabled={isLoading}
          autocomplete="new-password"
          fieldName="oldPassword"
          isSmallVisibilityIcon
        />
        <PasswordInput
          control={control}
          placeholder="Enter new password"
          label="New password"
          isDisabled={isLoading}
          autocomplete="new-password"
          fieldName="newPassword"
          isSmallVisibilityIcon
        />

        {arePasswordsSame ? <ErrorCard>Passwords can't be the same</ErrorCard> : null}
      </Content>

      <DialogActions paddingTop={20}>
        <CancelButton onClick={onCancel} disabled={isLoading}>
          Cancel
        </CancelButton>
        <SaveButton
          disabled={!isValid || isLoading || arePasswordsSame}
          isLoading={isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </SaveButton>
      </DialogActions>
    </Dialog>
  );
};
