import { Settings } from '@/shared/types/Settings';

const INIT_AVAILABLE_FEATURE = {
  enabled: false,
  visible: false,
  metadata: {
    desktop: false,
    mobile: false,
  },
};

export const defaultSettings: Settings = {
  scope_settings: {
    max_video_size: null,
    max_video_duration: null,
    max_video_download_duration: null,
    available_features: {
      paywall: INIT_AVAILABLE_FEATURE,
      lipsync: INIT_AVAILABLE_FEATURE,
      multilanguage: INIT_AVAILABLE_FEATURE,
      srt_upload: INIT_AVAILABLE_FEATURE,
      new_checkout: INIT_AVAILABLE_FEATURE,
      api: INIT_AVAILABLE_FEATURE,
      platform: INIT_AVAILABLE_FEATURE,
      subtitles: INIT_AVAILABLE_FEATURE,
      srt_download: INIT_AVAILABLE_FEATURE,
      ai_rewrite: INIT_AVAILABLE_FEATURE,
      free_languages: INIT_AVAILABLE_FEATURE,
      audio_track_editor: INIT_AVAILABLE_FEATURE,
      voice_presets: INIT_AVAILABLE_FEATURE,
      instant_voice_preset: INIT_AVAILABLE_FEATURE,
      audio_download: INIT_AVAILABLE_FEATURE,
      glossary: INIT_AVAILABLE_FEATURE,
    },
  },
  features: {
    maintenance: false,
  },
  languages: null,
  surveys: [],
};
