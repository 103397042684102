import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '@/shared/api';
import { TAccountsRegisterRequest } from '@/shared/store-tmp/accounts/types';
import { setAccount } from '../AccountsSlice';

export const createAccount = createAsyncThunk(
  'accounts/createAccount',
  async (data: TAccountsRegisterRequest, { dispatch, rejectWithValue }) => {
    try {
      const user = await API.createAccount(data);

      dispatch(setAccount(user));
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);
