import { create } from 'zustand';
import { Settings } from '@/shared/types/Settings';
import { defaultSettings } from '../lib/defaultSettings';
import { settingsAdapter } from '../lib/settingsAdapter';

type SettingsActions = {
  setSettings: (payload: Settings) => void;
  resetSettings: () => void;
};

export const useSettingsStore = create<Settings & SettingsActions>((set) => ({
  ...defaultSettings,
  setSettings: (payload: Settings) => set(settingsAdapter(payload)),
  resetSettings: () => set(defaultSettings),
}));
