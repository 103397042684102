import { Stack, styled, Typography } from '@mui/material';
import { BackIcon } from '@braskai/icons';
import { Button, ButtonProps } from '@braskai/ui-kit';

export const Content = styled(Stack)({
  padding: '0 24px 20px',
  gap: 20,
});

export const Message = styled(Typography)(({ theme }) => ({
  ...theme.typography.textSmRegular,
}));

export const BackButton = styled((props: ButtonProps) => (
  <Button variant="outlined" fullWidth startIcon={<BackIcon />} {...props} />
))('');
