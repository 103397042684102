import { create } from 'zustand';

type UseHubSpotChatStore = {
  isOpen: boolean;
  isLoading: boolean;
  showChat: () => void;
  hideChat: () => void;
  onWidgetReady: () => void;
};

export const useHubSpotChatStore = create<UseHubSpotChatStore>((set) => ({
  isOpen: false,
  isLoading: true,
  showChat: () => {
    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.load({ widgetOpen: true });
      set({ isOpen: true });
    } else {
      console.error('HubSpot Conversations is not loaded');
    }
  },
  hideChat: () => set({ isOpen: false }),
  onWidgetReady: () => set({ isLoading: false }),
}));

export const showChat = () => useHubSpotChatStore.getState().showChat();
export const hideChat = () => useHubSpotChatStore.getState().hideChat();
