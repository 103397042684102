import { IconButton as MuiIconButton, IconButtonProps as MuiIconButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { forwardRef } from 'react';

export type IconButtonProps = {
  disableBackground?: boolean;
} & MuiIconButtonProps;

const StyledIconButton = styled(MuiIconButton, {
  shouldForwardProp: (prop) => prop !== 'disableBackground',
})<IconButtonProps>(({ theme, disableBackground }) => ({
  padding: disableBackground ? 0 : undefined,
  color: theme.palette.grey[700],

  '&:hover': {
    background: disableBackground ? 'none' : undefined,
  },

  '&:disabled': {
    '& path': {
      stroke: theme.palette.grey[300],
    },
  },
}));

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(({ ...props }, ref) => (
  <StyledIconButton size="small" ref={ref} {...props} />
));
