import { useDeviceType } from '@braskai/ui-kit';
import { DesktopNav } from '../DesktopNav';
import { SContainer, SDrawer } from './styled';

export const DesktopSidebar = () => {
  const { isDesktop, isLaptop } = useDeviceType();

  if (!isDesktop && !isLaptop) {
    return null;
  }

  return (
    <SContainer>
      <SDrawer open variant="permanent">
        <DesktopNav />
      </SDrawer>
    </SContainer>
  );
};
