import { BASE_URL_WITHOUT_PORT } from '@/shared/api/constants';
import { Project } from '@/shared/types/Project';
import { formatDate } from '@/shared/utils-tmp/dateTime/formatDate';
import { adaptLipSyncInfo } from './adaptLipSyncInfo';

export const adaptProject = (project: Omit<Project, 'adaptedData'>): Project => {
  const {
    voiceover,
    created_date,
    cover,
    translated_video,
    original_video,
    lipsync_info,
    subtitles_info,
    translation_srt_path,
    translation_vtt_path,
    translated_audio,
  } = project;
  const lipSyncAdaptedData = lipsync_info && adaptLipSyncInfo(lipsync_info);
  return {
    ...project,
    adaptedData: {
      created_date: formatDate(created_date, true),
      ...(cover && { cover: cover ? BASE_URL_WITHOUT_PORT + cover : null }),
      ...(translated_video && {
        dstVideo: translated_video ? BASE_URL_WITHOUT_PORT + translated_video : null,
      }),
      ...(original_video && {
        srcVideo: original_video ? BASE_URL_WITHOUT_PORT + original_video : null,
      }),
      ...(translation_vtt_path && {
        vtt: BASE_URL_WITHOUT_PORT + translation_vtt_path,
      }),
      ...(translation_srt_path && {
        srt: BASE_URL_WITHOUT_PORT + translation_srt_path,
      }),
      ...(subtitles_info?.subtitles_burn_result_path && {
        translatedVideoSubtitles: BASE_URL_WITHOUT_PORT + subtitles_info.subtitles_burn_result_path,
      }),
      ...(subtitles_info?.subtitles_lipsync_burn_result_path && {
        lipSyncedVideoSubtitles: BASE_URL_WITHOUT_PORT + subtitles_info.subtitles_lipsync_burn_result_path,
      }),
      ...(voiceover && { voiceover: voiceover ? BASE_URL_WITHOUT_PORT + voiceover : null }),
      ...(lipSyncAdaptedData && lipSyncAdaptedData.adaptedData),
      ...(translated_audio && { translatedAudio: translated_audio ? BASE_URL_WITHOUT_PORT + translated_audio : null }),
    },
  };
};
