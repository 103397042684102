import { Outlet } from 'react-router-dom';
import LogRocket from 'logrocket';
import { useState, useEffect, useRef, useCallback } from 'react';
import { useProducts } from '@/entities/pricing';

import { IS_LOGROCKET_DEV_MODE_ENABLED, IS_PRODUCTION_BUILD } from '@/shared/lib/environments';
import { usePrevious } from '@/shared/lib/hooks';
import { Loader } from '@/shared/ui-tmp';
import { getAccount } from '@/shared/store-tmp/accounts/thunks/getAccount';
import { SignupSurveyModal } from '@/shared/components-tmp/modals/SignupSurveyModal';
import { useAppDispatch, useAppSelector } from '@/shared/hooks-tmp/reduxToolkitHooks';
import { HubSpotUserUpdateEvents } from '@/shared/thirdParty/hubSpot';
import { MaintenancePage } from '@/pages/MaintenancePage';
import { initChurnkey } from '@/shared/thirdParty/churnkey';
import { useGlobalErrors } from '@/shared/hooks-tmp/useGlobalErrors';
import { getUser } from '@/shared/store-tmp/user/thunks/getUser';
import { AddEmailModal, useAddEmailModal } from '@/entities/user/addEmailModal';
import { useGetUserSettings, useSettingsStore } from '@/entities/user/settings';
import { useCognitoCheckUser } from '@/entities/user';
import { IS_MAINTENANCE_DISABLED } from '../../config';
import { useDelayAfterPayment } from './hooks/useDelayAfterPayment';
import { useSignupSurveyModal } from './hooks/useSignupSurveyModal';
import { useGTMAuthorization } from './hooks/useGTMAuthorization';
import { useSubscriptionState } from './hooks/useSubscriptionState';

const LOGROCKET_KEY = import.meta.env.VITE_LOGROCKET;

// TODO move to abstraction slice [hash234123]
const IS_LOGROCKET_ENABLED = !!LOGROCKET_KEY && (IS_PRODUCTION_BUILD || IS_LOGROCKET_DEV_MODE_ENABLED);

window.rewardful = window.rewardful || {};
window.Rewardful = window.Rewardful || {};

export const InitAppLayout = () => {
  useGlobalErrors();

  const dispatch = useAppDispatch();

  const referral = useRef<string>();
  const ignoreInitialDataLoading = useRef(false);

  const user = useAppSelector((state) => state.user);
  const { data } = useCognitoCheckUser();
  const isUsedSocialProviders = data?.isUsedSocialProviders;

  const maintenance = useSettingsStore((state) => state.features.maintenance);

  const openAddEmailModal = useAddEmailModal((state) => state.open);

  const [isInitialDataReceived, setIsInitialDataReceived] = useState(false);
  const productsQuery = useProducts(isInitialDataReceived);

  useSubscriptionState();
  const { refetch: getUserSettings } = useGetUserSettings();

  useSignupSurveyModal(isInitialDataReceived);

  const previousMaintenance = usePrevious(maintenance);

  const isDelayAfterPayment = useDelayAfterPayment();

  const handleGetUserSuccess = useCallback(
    async (email?: string) => {
      const { data: settings } = await getUserSettings();
      await dispatch(getAccount({ referral: referral.current, email }));

      if (!settings?.features.maintenance || IS_MAINTENANCE_DISABLED) {
        setIsInitialDataReceived(true);
      }
    },
    [dispatch, getUserSettings],
  );

  const handleOpenAddEmailModal = useCallback(() => {
    openAddEmailModal({ handleSuccess: handleGetUserSuccess, referral: referral.current });
  }, [handleGetUserSuccess, openAddEmailModal]);

  useEffect(() => {
    if (isDelayAfterPayment || ignoreInitialDataLoading.current) {
      return;
    }

    ignoreInitialDataLoading.current = true;

    window.rewardful('ready', () => {
      if (window.Rewardful.referral) {
        referral.current = window.Rewardful.referral;
      }
    });

    void dispatch(
      getUser({
        referral: referral.current,
        isSocial: isUsedSocialProviders,
        tiktokCallback: handleOpenAddEmailModal,
        handleSuccess: handleGetUserSuccess,
      }),
    );
  }, [dispatch, handleGetUserSuccess, handleOpenAddEmailModal, isDelayAfterPayment, isUsedSocialProviders]);

  useGTMAuthorization(user);

  useEffect(() => {
    const isSwitchedFromMaintenance = previousMaintenance && !maintenance;

    if (isSwitchedFromMaintenance) {
      window.location.reload();
    }
  }, [maintenance, previousMaintenance]);

  if (maintenance && !IS_MAINTENANCE_DISABLED) {
    return <MaintenancePage />;
  }

  if (!isInitialDataReceived || !user || productsQuery.isLoading) {
    return (
      <>
        <Loader />
        <AddEmailModal />
      </>
    );
  }

  if (IS_LOGROCKET_ENABLED) {
    LogRocket.init(LOGROCKET_KEY);
    LogRocket.identify(user.email);
  }

  HubSpotUserUpdateEvents.identifyUser(user.email);

  initChurnkey();

  return (
    <>
      <Outlet />
      <SignupSurveyModal />
    </>
  );
};
