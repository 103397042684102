import { getMappedEvents } from '../utils/getMappedEvents';

const EVENTS = [
  'createDictionaryUploadModalClick',
  'createDictionaryProjectClick',
  'createDictionaryDone',
  'dictionaryProfileClick',
  'createNewDictionaryModaDictionarylClick',
  'dictionaryDeleted',
  'dictionaryChooseUploadModal',
  'upgardeDictionaryProjectClick',
  'upgardeDictionaryAccountClick',
  'newWordsDictionarySaved',
  'addNewWordDictionary',
  'addNewWordDictionaryDontTranslate',
  'retranslateProjectUpdatedDictionary',
  'dictionaryOffClickProject',
  'dictionaryChoosedProject',
  'upgradeDictionaryModalShown',
  'UpgradeDictionaryChoosePlanModalClick',
  'dictionaryTabClick',
] as const;

export const glossaryEvents = getMappedEvents([...EVENTS], {
  shouldSendSubscriptionData: true,
  shouldSendRaskTTSData: true,
});
