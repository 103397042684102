export const STATUS_OPTIONS = [
  {
    id: '1',
    label: 'Active',
  },
  {
    id: '2',
    label: 'Inactive',
  },
];
export const ACCESS_OPTIONS = [
  {
    id: '1',
    label: 'Admin',
  },
  {
    id: '2',
    label: 'User',
  },
];
