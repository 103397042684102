import { styled, Typography, Stack, CardMedia, CardMediaProps, css } from '@mui/material';
import { Button } from '@braskai/ui-kit';

export const SContainer = styled(Stack)(
  ({ theme }) => css`
    position: relative;
    overflow: hidden;
    border-radius: 12px;
    align-items: flex-start;
    padding: 20px 16px 16px;
    gap: 20px;
    background-color: ${theme.palette.primary[50]};

    ${theme.breakpoints.up('mobileMedium')} {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  `,
);

export const SBackgroundImage = styled(CardMedia)<CardMediaProps<'img'>>(
  () => css`
    position: absolute;
    height: 100%;
    width: auto;
    top: 0;
    right: 0;
  `,
);

export const STextContainer = styled(Stack)(
  () => css`
    position: relative;
    flex-grow: 1;
  `,
);

export const SText = styled(Typography)(
  () => css`
    margin-top: 4px;
  `,
);

export const SButton = styled(Button)(
  ({ theme }) => css`
    height: 40px;
    width: 100%;
    white-space: nowrap;

    ${theme.breakpoints.up('mobileMedium')} {
      width: 140px;
    }
  `,
);
