import { useEffect, useState } from 'react';
import { useProducts } from '@/entities/pricing';
import { getPricePerMin } from '../utils/getPricePerMin';

export const usePrice = () => {
  const [price, setPrice] = useState(0);
  const [totalMinutes, setTotalMinutes] = useState(0);

  const { products } = useProducts();

  const product = products?.credit[0];
  const pricePerMin = getPricePerMin(product);

  // MUI Slider требует указать тип number | number[] т.к. может работать с range
  // Доп проверки, чтобы передавать только тип number, делать не стал
  const handleChangeMinutes = (newMinutes: number | number[]) => {
    if (Array.isArray(newMinutes)) {
      return;
    }
    setTotalMinutes(newMinutes);
  };

  useEffect(() => {
    setPrice(totalMinutes * pricePerMin);
  }, [totalMinutes, pricePerMin]);

  return { handleChangeMinutes, price, totalMinutes, pricePerMin, product };
};
