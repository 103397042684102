import { getMappedEvents } from '../utils/getMappedEvents';

const EVENTS_WITHOUT_METADATA = [
  'firstSessionTranslateClick',
  'firstSessionTranslateSampleClick',
  'firstSessionUploadVideoButtonPressedTranslation',
] as const;

const EVENTS_WITH_SUBSCRIPTION_DATA = [
  'translationTabClick',
  'translateModalUploadClick',
  'translateModalUploadFileDrop',
  'uploadCardTrialEndUpgradeClickTranslation',
  'uploadVideoButtonNoMinutesUpgrade',
] as const;

const surveyEventsWithoutMetadata = getMappedEvents([...EVENTS_WITHOUT_METADATA]);
const surveyEventsWithoutMetadataWithSubscriptionData = getMappedEvents([...EVENTS_WITH_SUBSCRIPTION_DATA], {
  shouldSendSubscriptionData: true,
});

export const platformEvents = [...surveyEventsWithoutMetadata, ...surveyEventsWithoutMetadataWithSubscriptionData];
