import { useLocation, useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useBillingStore } from '@/shared/hooks-tmp/reduxHooks/useBillingStore';
import { MixpanelEvents } from '@/shared/thirdParty/mixpanel';
import { PATHS } from '@/shared/routes/paths';
import CroppedPercentImg from '@/shared/assets/images/cropped-percent.png';
import { DATA_TEST_IDS } from '../../lib/constants';
import { SBackgroundImage, SButton, SContainer, SText, STextContainer } from './styled';

type TUpgradeCardProps = {
  onClose: () => void;
};

export const UpgradeCard = ({ onClose }: TUpgradeCardProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    balance: { hasArchivedSubscription },
  } = useBillingStore();

  const handleButtonClick = () => {
    if (hasArchivedSubscription) {
      MixpanelEvents.minutesModalViewPlansClick();
    }

    if (!hasArchivedSubscription) {
      MixpanelEvents.payLessMinutesButton();
    }

    navigate(`/${PATHS.pricing}`, { state: { path: location.pathname } });
    onClose();
  };

  return (
    <SContainer>
      <SBackgroundImage component="img" src={CroppedPercentImg} />

      <STextContainer>
        <Typography variant="textSmMedium">
          {hasArchivedSubscription ? 'We just launched new pricing!' : 'Pay only $1.5 per minute!'}
        </Typography>

        <SText variant="textXsRegular" color="grey.500">
          {hasArchivedSubscription ? (
            'Check out our new pricing with flexible bundled minutes and new features!'
          ) : (
            <>
              Upgrade your plan and pay less
              <br />
              for additional minutes
            </>
          )}
        </SText>
      </STextContainer>

      <SButton variant="secondary" onClick={handleButtonClick} data-test-id={DATA_TEST_IDS.viewPlans}>
        {hasArchivedSubscription ? 'View plans' : 'Pay less'}
      </SButton>
    </SContainer>
  );
};
