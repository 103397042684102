import { Container, Progress } from './styled';

type LoaderProps = {
  isSemiTransparent?: boolean;
  zIndex?: number;
};

export const Loader = ({ isSemiTransparent = false, zIndex }: LoaderProps) => {
  return (
    <Container isSemiTransparent={isSemiTransparent} zIndex={zIndex}>
      <Progress />
    </Container>
  );
};
