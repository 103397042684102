import { Box, BoxProps, CircularProgress, styled } from '@mui/material';

type ContainerProps = {
  isSemiTransparent?: boolean;
  zIndex?: number;
} & BoxProps;

export const Container = styled(({ isSemiTransparent, zIndex, ...props }: ContainerProps) => <Box {...props} />)(
  ({ theme, isSemiTransparent, zIndex }) => ({
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.white,
    opacity: isSemiTransparent ? 0.7 : 1,
    zIndex: zIndex ?? theme.zIndex.loader,
  }),
);

export const Progress = styled((props) => <CircularProgress size={24} {...props} />)(({ theme }) => ({
  color: theme.palette.primary[600],
  position: 'absolute',
  top: 'calc(50% - 12px)',
  left: 'calc(50% - 12px)',
  right: 0,
  bottom: 0,
  transform: 'translate(-50%, -50%)',
}));
