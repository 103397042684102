export const formatDate = (formattingDate: string, isStringsForNearestDates = false) => {
  const now = new Date();
  const date = new Date(formattingDate);
  if (isStringsForNearestDates) {
    if (
      now.getDate() === date.getDate() &&
      now.getMonth() === date.getMonth() &&
      now.getFullYear() === date.getFullYear()
    ) {
      return 'Today';
    }
    if (
      now.getDate() - 1 === date.getDate() &&
      now.getMonth() === date.getMonth() &&
      now.getFullYear() === date.getFullYear()
    ) {
      return 'Yesterday';
    }
    if (
      now.getDate() + 1 === date.getDate() &&
      now.getMonth() === date.getMonth() &&
      now.getFullYear() === date.getFullYear()
    ) {
      return 'Tomorrow';
    }
  }
  const dd = String(date.getDate()).padStart(2, '0');
  const mm = String(date.getMonth() + 1).padStart(2, '0'); // January is 0
  const yyyy = date.getFullYear();
  return `${dd}.${mm}.${yyyy}`;
};
