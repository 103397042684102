import { Language, Settings } from '@/shared/types/Settings';

const filterFreeLanguages = (settings: Settings) => {
  const { languages, scope_settings } = settings;
  const isFreeLanguagesEnabled = Boolean(scope_settings?.available_features?.free_languages?.enabled);

  if (!languages) {
    return null;
  }

  const translation = languages.translation?.filter((language) => isFreeLanguagesEnabled || !language.is_free) || [];

  return {
    ...languages,
    translation,
  };
};

type Languages = {
  transcription: Language[];
  translation: Language[];
};

const sortLanguages = (languages: Languages | null) => {
  if (!languages) {
    return null;
  }

  const transcription = languages.transcription.slice().sort((a, b) => Number(a.id) - Number(b.id));
  const translation = languages.translation.slice().sort((a, b) => Number(a.id) - Number(b.id));

  return {
    transcription,
    translation,
  };
};

export const settingsAdapter = (settings: Settings) => {
  const filteredFreeLanguages = filterFreeLanguages(settings);
  const sortedLanguages = sortLanguages(filteredFreeLanguages);

  return {
    ...settings,
    languages: sortedLanguages,
  };
};
