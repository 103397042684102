import { memo } from 'react';

import { hideAlert } from '@/shared/store-tmp/AlertSlice';
import { useAppDispatch, useAppSelector } from '@/shared/hooks-tmp/reduxToolkitHooks';
import { Toast } from '@/shared/ui-tmp/Toast';

export const AlertContainer = memo(() => {
  const dispatch = useAppDispatch();
  const { type, message, isOpen, duration, isClickAwayDisabled } = useAppSelector((state) => state.alert);

  const handleHideAlert = () => {
    dispatch(hideAlert());
  };

  return (
    <Toast
      message={message}
      severity={type}
      onClose={handleHideAlert}
      isOpen={isOpen}
      duration={duration}
      isClickAwayDisabled={isClickAwayDisabled}
    />
  );
});
