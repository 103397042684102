import { GenericAbortSignal } from 'axios';
import { TLibraryResponse } from '@/shared/types/Library';
import { PartialVoicePresetProject, VoicePreset } from '@/shared/types/VoicePreset';
import { del, get, patch, post } from '../../lib/api';
import { BASE_URL, LIBRARY_API_URL } from '../constants';
import {
  CreateVoicePresetPayload,
  VoiceSampleSettings,
  UpdateVoicePresetPayload,
  UploadVoiceSamplePayload,
  GetVoicePresetStatusResponse,
  CreateVoicePresetFromLocProjectPayload,
} from './type';

const VOICE_PRESET_BASE_URL = `${BASE_URL}/api/voice_preset/`;

export const VOICE_PRESETS_API = {
  uploadVideoVoiceSample: (data: UploadVoiceSamplePayload, signal?: GenericAbortSignal) =>
    post<TLibraryResponse>(`${LIBRARY_API_URL}/extract-audio`, {
      type: 'multipart/form-data',
      data,
      signal,
    }),

  uploadAudioVoiceSample: (data: Omit<UploadVoiceSamplePayload, 'url'>, signal?: GenericAbortSignal) =>
    post<TLibraryResponse>(`${LIBRARY_API_URL}`, {
      type: 'multipart/form-data',
      data,
      signal,
      params: {
        kind: 'audio',
      },
    }),

  getVoicePresetStatus: (ids: string) =>
    get<GetVoicePresetStatusResponse[]>(`${VOICE_PRESET_BASE_URL}statuses?ids=${ids}`),

  createVoicePreset: (data: CreateVoicePresetPayload) => post<VoicePreset>(VOICE_PRESET_BASE_URL, { data }),

  updateVoicePreset: ({ id, ...rest }: UpdateVoicePresetPayload) =>
    patch<VoicePreset>(`${VOICE_PRESET_BASE_URL}${id}`, { data: rest }),

  deleteVoicePreset: (id: VoicePreset['id']) => del<{ ok: boolean }>(`${VOICE_PRESET_BASE_URL}${id}`),

  getAllVoicePresets: () => get<PartialVoicePresetProject[]>(VOICE_PRESET_BASE_URL),
  getVoicePreset: (id: VoicePreset['id'], signal: AbortSignal) =>
    get<VoicePreset>(`${VOICE_PRESET_BASE_URL}${id}`, { signal }),

  fetchVoiceSampleLink: ({ voicePresetId, selectedLanguage, accent, expressiveness }: VoiceSampleSettings) =>
    get<{ sample_path: string }>(
      `${VOICE_PRESET_BASE_URL}${voicePresetId}/sample?language=${selectedLanguage}&similarity_boost=${accent}&stability=${expressiveness}`,
    ),

  updateVoicePresetSettings: ({
    voicePresetId,
    accent,
    expressiveness,
  }: Omit<VoiceSampleSettings, 'selectedLanguage'>) =>
    post<PartialVoicePresetProject>(
      `${VOICE_PRESET_BASE_URL}${voicePresetId}/update_settings?similarity_boost=${accent}&stability=${expressiveness}`,
    ),

  updateInstantPresetSettings: ({
    voicePresetId,
    accent,
    expressiveness,
  }: Omit<VoiceSampleSettings, 'selectedLanguage'>) =>
    post<PartialVoicePresetProject>(
      `${VOICE_PRESET_BASE_URL}${voicePresetId}/update_settings_from_project?similarity_boost=${accent}&stability=${expressiveness}`,
    ),

  createVoicePresetFromLocProject: ({
    accent,
    expressiveness,
    label,
    projectId,
    speakerId,
    isTemporary,
  }: CreateVoicePresetFromLocProjectPayload) =>
    post<VoicePreset>(`${VOICE_PRESET_BASE_URL}from_project`, {
      data: {
        project_id: projectId,
        label,
        stability: expressiveness,
        similarity_boost: accent,
        speaker_id: speakerId,
        is_temporary: isTemporary,
      },
    }),
};
