import { ViewItemList, ViewItemListEventData } from '@/shared/thirdParty/gtm/types';

type GetBuyMinutesViewItemListEventData = {
  price: number;
  productId: string;
};

export const getBuyMinutesViewItemListEventData = ({
  price,
  productId,
}: GetBuyMinutesViewItemListEventData): ViewItemListEventData => {
  return {
    ecommerce: {
      view_item_list: ViewItemList.Minutes,
      items: [
        {
          item_id: productId,
          item_name: 'Minutes',
          price,
          quantity: 1,
        },
      ],
    },
  };
};
