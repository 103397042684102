import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@braskai/ui-kit';
import { Select } from '@/shared/ui-tmp';
import { getDisplayingSpeakerId } from '@/shared/utils-tmp/project/getDisplayingSpeakerId';
import { SLabel, SOption } from './styled';
import { TChooseSpeakerModalProps } from './types';
import { useChooseSpeakerModal } from './hooks/useChooseSpeakerModal';

export const ChooseSpeakerModal = ({ onClose, onSubmit, initialSpeakerId, speakers }: TChooseSpeakerModalProps) => {
  const { chosenSpeakerId, handleChange, handleSubmit } = useChooseSpeakerModal({
    onClose,
    onSubmit,
    initialSpeakerId,
  });

  const options = speakers?.map((id) => (
    <SOption key={id} value={id}>
      {getDisplayingSpeakerId(id)}
    </SOption>
  ));

  const renderValue = () => (chosenSpeakerId ? getDisplayingSpeakerId(chosenSpeakerId) : '');

  return (
    <Dialog open size="sm" onClose={onClose}>
      <DialogTitle
        onClose={onClose}
        renderTitle="Select new segment speaker"
        renderSubtitle="The merging segments have different speakers, please select speaker for the new segment."
      />

      <DialogContent>
        <SLabel variant="textSmMedium" color="grey.700">
          Speaker
        </SLabel>
        <Select isMenuInModal renderValue={renderValue} onChange={handleChange} value={chosenSpeakerId}>
          {options}
        </Select>
      </DialogContent>

      <DialogActions>
        <Button fullWidth size="xl" onClick={handleSubmit}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
