import { Dayjs } from 'dayjs';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@/shared/ui-tmp';
import { Container } from './styled';

type DateRangePickerProps = {
  onStartDateChange: (startDate: Dayjs | null) => void;
  onEndDateChange: (endDate: Dayjs | null) => void;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  disabled?: boolean;
  disableFutureStartDate?: boolean;
  disableFutureEndDate?: boolean;
  disablePastStartDate?: boolean;
  disablePastEndDate?: boolean;
};

export const DateRangePicker = ({
  disabled,
  startDate,
  endDate,
  onEndDateChange,
  onStartDateChange,
  disableFutureStartDate,
  disableFutureEndDate,
  disablePastStartDate,
  disablePastEndDate,
}: DateRangePickerProps) => {
  return (
    <Container>
      <DatePicker
        value={startDate}
        onChange={(newValue) => onStartDateChange(newValue)}
        slots={{ textField: TextField }}
        disableFuture={disableFutureStartDate}
        disablePast={disablePastStartDate}
        format="D.M.YYYY"
        disabled={disabled}
      />
      to
      <DatePicker
        value={endDate}
        onChange={(newValue) => onEndDateChange(newValue)}
        slots={{ textField: TextField }}
        disableFuture={disableFutureEndDate}
        disablePast={disablePastEndDate}
        format="D.M.YYYY"
        disabled={disabled}
      />
    </Container>
  );
};
