import { CssBaseline, ThemeProvider } from '@mui/material';
import { RouterProvider } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { theme } from '@braskai/ui-kit';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { QueryClientProvider } from '@tanstack/react-query';
import { Suspense } from 'react';

import { queryClient } from '@/shared/lib/config';
import { initMixpanel } from '@/shared/thirdParty/mixpanel';
import { AlertContainer } from '@/shared/components-tmp/AlertContainer';
import { MaintenancePage } from '@/pages/MaintenancePage';
import { store } from '@/shared/store-tmp';
import { Loader } from '@/shared/ui-tmp';
import { HubSpotChat } from '@/shared/thirdParty/hubSpot';
import { SentryRask } from '@/shared/thirdParty/sentry';
import { ServerEnvSelector } from './ui';
import { AUTH_CONFIG, IS_REACT_QUERY_TOOLS } from './config';
import { ModalProvider } from './providers';
import { router } from './router';
import { useMaintenance } from './model/useMaintenance';
import { useRequestInterceptor } from './model/useRequestInterceptor';

initMixpanel();

Amplify.configure(AUTH_CONFIG);

SentryRask.initIfAvailable();

// Enable 3d party services for local build
// sessionStorage.setItem('enableMixpanel', '1')
// sessionStorage.setItem('enableLogRocket', '1')

const AppComponent = () => {
  const { isMaintenance, isLoading } = useMaintenance();
  useRequestInterceptor();

  if (isMaintenance) {
    return <MaintenancePage />;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Suspense fallback={<Loader />}>
      <CssBaseline />
      <RouterProvider router={router} />
      <AlertContainer />
      {IS_REACT_QUERY_TOOLS && <ReactQueryDevtools />}
      <ServerEnvSelector />
    </Suspense>
  );
};

const App = () => (
  <HelmetProvider>
    <ModalProvider>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <QueryClientProvider client={queryClient}>
              <AppComponent />
              <HubSpotChat />
            </QueryClientProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </Provider>
    </ModalProvider>
  </HelmetProvider>
);

export default App;
