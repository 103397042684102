import { AttentionIcon, Dialog, DialogTitle } from '../../../ui-tmp';
import { Subtitle, TitleContainer } from './styled';

type EnterprisePaymentRequiredModalProps = {
  onClose: () => void;
};

export const EnterprisePaymentRequiredModal = ({ onClose }: EnterprisePaymentRequiredModalProps) => {
  return (
    <Dialog open onClose={onClose} size="sm">
      <DialogTitle
        onClose={onClose}
        renderTitle={() => (
          <TitleContainer>
            <AttentionIcon severity="error" />
            Unknown Error
          </TitleContainer>
        )}
        renderSubtitle={() => (
          <Subtitle>
            Please contact the support chat or email us at <a href="mailto:support@rask.ai">support@rask.ai</a>.
          </Subtitle>
        )}
      />
    </Dialog>
  );
};
