import { Typography } from '@mui/material';
import { TranslateIcon } from '@braskai/icons';
import { Button } from '@braskai/ui-kit';
import { ControlledCheckbox } from '@/shared/components-tmp/controlled/ControlledCheckbox';
import { useExecuteOneTime } from '@/shared/hooks-tmp/useExecuteOneTime';
import { MixpanelEvents } from '@/shared/thirdParty/mixpanel';
import { Dialog, DialogTitle } from '../../../ui-tmp';
import { SActionsContainer, SCheckboxContainer, SContent, SIconWrapper } from './styled';
import { useSplitSegmentModalForm } from './hooks/useSplitSegmentModalForm';

type TSplitSegmentModalProps = {
  onClose: () => void;
  onSplitSegment?: () => void;
};

export const SplitSegmentModal = ({ onClose, onSplitSegment }: TSplitSegmentModalProps) => {
  const { control, isLoading, onSubmit } = useSplitSegmentModalForm({ onClose, onSplitSegment });

  useExecuteOneTime(() => {
    MixpanelEvents.splitSegmentsModalShown();
  });

  return (
    <Dialog open onClose={onClose} size="sm">
      <DialogTitle
        onClose={onClose}
        renderTitle={() => (
          <SIconWrapper>
            <TranslateIcon width={24} height={24} />
          </SIconWrapper>
        )}
      />

      <SContent>
        <Typography variant="textLgMedium">New segments will be re-translated</Typography>

        <Typography variant="textSmRegular" color="grey.500">
          Splitting the original text will result in the re-translation of relevant segments.
        </Typography>

        <Typography variant="textSmRegular" color="grey.500">
          If you want to keep the current translation, you can split the translated text.
        </Typography>

        <SCheckboxContainer>
          <ControlledCheckbox
            label={
              <Typography variant="textXsMedium" color="grey.500">
                Don't show this again
              </Typography>
            }
            controller="enabled"
            control={control}
          />
        </SCheckboxContainer>
      </SContent>

      <SActionsContainer>
        <Button size="lg" variant="outlined" fullWidth onClick={onClose} disabled={isLoading}>
          Cancel
        </Button>
        <Button size="lg" fullWidth onClick={onSubmit} disabled={isLoading} isLoading={isLoading}>
          Split segments
        </Button>
      </SActionsContainer>
    </Dialog>
  );
};
