import { BackIcon } from '@braskai/icons';
import { DATA_TEST_IDS } from '../../constants';
import { Container, Loader, LoaderContainer, SLinkContainer } from './styled';

type TLogoutLinkProps = {
  onLogout: () => void;
  isDisabled: boolean;
  isLoading: boolean;
};

export const LogoutLink = ({ onLogout, isLoading, isDisabled }: TLogoutLinkProps) => {
  return (
    <Container onClick={isDisabled ? undefined : onLogout} isDisabled={isDisabled}>
      {isLoading ? (
        <LoaderContainer>
          <Loader />
          <span>Logging out...</span>
        </LoaderContainer>
      ) : (
        <SLinkContainer>
          <BackIcon />
          <span data-test-id={DATA_TEST_IDS.backToSignIn}>Back to Sign in</span>
        </SLinkContainer>
      )}
    </Container>
  );
};
