type TMixinProps = {
  [key: string]: boolean;
};

export const getMappedEvents = <T extends string>(events: T[], mixin?: TMixinProps) => {
  return events.map((eventName) => ({
    eventName,
    ...mixin,
  }));
};
