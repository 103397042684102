import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { useSettingsStore } from '@/entities/user/settings';
import { Language } from '@/shared/types/Settings';
import { showErrorAlert } from '@/shared/utils-tmp/alert/showErrorAlert';
import { Project } from '@/shared/types/Project';
import { MixpanelEvents } from '@/shared/thirdParty/mixpanel';
import { useAppSelector } from '@/shared/hooks-tmp/reduxToolkitHooks';
import { PATHS } from '@/shared/routes/paths';
import { API } from '@/shared/api';

export type IFormValues = {
  dst_lang: Language | null;
};

export const useMultilanguage = (onClose: () => void) => {
  const { project } = useAppSelector((state) => state.project);
  const languages = useSettingsStore((state) => state.languages);

  const [copiedProjectId, setCopiedProjectId] = useState<Project['id'] | null>(null);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm<IFormValues>({
    mode: 'onChange',
    defaultValues: {
      dst_lang: null,
    },
  });

  const onSubmit = async (formValues: IFormValues) => {
    try {
      const { dst_lang } = formValues;

      if (!project?.id || !dst_lang?.value) {
        return;
      }

      const copiedProject = await API.copyProject({
        id: project.id,
        dst_lang: dst_lang.value,
        free_dubbing: dst_lang.is_free,
      });

      MixpanelEvents.translateAnotherLanguageDone({ raskTTS: dst_lang.is_free });

      setCopiedProjectId(copiedProject.id);
    } catch (e) {
      showErrorAlert(e);
    }
  };

  const openCopiedProject = () => {
    if (!copiedProjectId) {
      return;
    }

    window.open(`/${PATHS.project}/${copiedProjectId}`);
    onClose();
  };

  return {
    control,
    languages,
    isSubmitting,
    isSuccess: !!copiedProjectId,
    isFormInvalid: !isValid,
    handleSubmit: handleSubmit(onSubmit),
    openCopiedProject,
  };
};
