import { styled, Stack, Typography } from '@mui/material';

export const Container = styled(Stack)({
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
  padding: 20,
});

export const Image = styled('img')({
  width: 214,
});

export const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.textMdRegular,
  fontWeight: 600,
  marginTop: 20,
  marginBottom: 8,
  textAlign: 'center',

  [theme.breakpoints.up('tablet')]: {
    ...theme.typography.displaySmSemibold,
    marginBottom: 16,
  },

  [theme.breakpoints.up('laptop')]: {
    ...theme.typography.displayLgSemibold,
  },
}));

export const Subtitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.textXsRegular,
  color: theme.palette.grey[500],
  textAlign: 'center',
  maxWidth: '90%',

  [theme.breakpoints.up('tablet')]: {
    ...theme.typography.textMdRegular,
    maxWidth: '70%',
  },

  [theme.breakpoints.up('laptop')]: {
    ...theme.typography.textXlRegular,
  },
}));
