import { useForm } from 'react-hook-form';
import { inIframe } from '@/shared/lib/utils';
import { INIT_SELECT_VALUES, OTHER, SELECT_FORM_STEPS } from '../constants';
import { FormValues } from '../types';
import { getShuffledSelectOptions } from '../utils';

type UseSignupSurveyModalForm = {
  activeStep: number;
};

export const useSignupSurveyModalForm = ({ activeStep }: UseSignupSurveyModalForm) => {
  const { control, handleSubmit, watch, setValue } = useForm<FormValues>({
    mode: 'all',
    defaultValues: {
      role: INIT_SELECT_VALUES,
      content: INIT_SELECT_VALUES,
      referral: inIframe()
        ? {
            ...INIT_SELECT_VALUES,
            option: 'TikTok',
          }
        : INIT_SELECT_VALUES,
    },
  });

  const formValues = watch();

  const { controller, label, options } = SELECT_FORM_STEPS[activeStep as keyof typeof SELECT_FORM_STEPS];

  const selectedValue = formValues[controller];
  const isCommentRequired = selectedValue.option.toLowerCase() === OTHER;

  const isFormInvalid =
    !selectedValue?.option || Boolean(isCommentRequired && (!selectedValue.text || !selectedValue.text.trim().length));

  const optionList = ['minutes', 'expenses'].includes(controller) ? options : getShuffledSelectOptions(options || []);

  const resetTextField = () => {
    setValue(`${controller}.text`, '');
  };

  return {
    control,
    selectedValue,
    label,
    options: optionList,
    controller,
    isFormInvalid,
    resetTextField,
    handleSubmit,
  };
};
