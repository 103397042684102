import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { User, ApiData } from '@/shared/types/User';

type UserState = User | null;

const initialState = null as UserState;

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, { payload }: PayloadAction<User>) => {
      return { ...state, ...payload };
    },
    setApiData: (state, { payload }: PayloadAction<ApiData>) => {
      if (state) {
        state.api_data = payload;
      }
    },
  },
});
export const userReducer = userSlice.reducer;
export const { setUser, setApiData } = userSlice.actions;
