import { Skeleton } from '@braskai/ui-kit';
import { Box, Stack } from '@mui/material';

const SKELETONS_ITEMS = Array.from({ length: 5 }, (_, index) => index + 1);

export const EditGlossarySkeletons = () => {
  return (
    <Stack gap={24} padding="24px 24px 0">
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 64px',
          gap: 8,
        }}
      >
        <Stack gap={8}>
          <Skeleton borderRadius={4} variant="rectangular" width="50%" height={20} />
          <Skeleton borderRadius={8} variant="rectangular" width="100%" height={40} />
          <Skeleton borderRadius={4} variant="rectangular" width="60%" height={18} />
        </Stack>

        <Stack gap={8}>
          <Skeleton borderRadius={4} variant="rectangular" width="50%" height={20} />
          <Skeleton borderRadius={8} variant="rectangular" width="100%" height={40} />
        </Stack>

        <Skeleton borderRadius={8} variant="rectangular" width="100%" height={40} sx={{ alignSelf: 'center' }} />
      </Box>

      <Stack gap={12}>
        {SKELETONS_ITEMS.map((item) => {
          return (
            <Box
              key={item}
              sx={(theme) => ({
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 64px',
                borderBottom: `1px solid ${theme.palette.grey[200]}`,
                paddingBottom: 12,
                gap: 8,
              })}
            >
              <Skeleton borderRadius={4} variant="rectangular" width="100%" height={22} />
              <Skeleton borderRadius={4} variant="rectangular" width="100%" height={22} />
              <Skeleton borderRadius={4} variant="rectangular" width={20} height={22} sx={{ justifySelf: 'end' }} />
            </Box>
          );
        })}
      </Stack>
    </Stack>
  );
};
