import { useState, useCallback } from 'react';
import { useAppDispatch } from '@/shared/hooks-tmp/reduxToolkitHooks';
import { setAuthForm } from '@/entities/viewer';
import { resetStore } from '@/shared/store-tmp/RootReducer';
import { AuthForm } from '@/shared/types/Cognito';
import { showErrorAlert } from '@/shared/utils-tmp/alert/showErrorAlert';
import { logout } from '@/shared/utils-tmp/logout';

export const useLogout = () => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const onLogout = useCallback(async () => {
    try {
      setIsLoading(true);

      await logout();
      void dispatch(resetStore());
      setAuthForm(AuthForm.Signin);
    } catch (e) {
      showErrorAlert(e);
    }

    setIsLoading(false);
  }, [dispatch]);

  return { logout: onLogout, isLoggingOut: isLoading };
};
