import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { PAYMENT_SUCCESSFUL_PARAM } from '@/shared/routes/searchParams';
import { useExecuteOneTime } from '@/shared/hooks-tmp/useExecuteOneTime';

export const useDelayAfterPayment = () => {
  const [searchParams] = useSearchParams();

  const [isDelayAfterPayment, setIsDelayAfterPayment] = useState(true);

  useExecuteOneTime(() => {
    const hasPaymentSuccessfulParam = searchParams.has(PAYMENT_SUCCESSFUL_PARAM);

    if (hasPaymentSuccessfulParam) {
      setTimeout(() => {
        setIsDelayAfterPayment(false);
      }, 2000);
    } else {
      setIsDelayAfterPayment(false);
    }
  });

  return isDelayAfterPayment;
};
