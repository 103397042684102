export const BUTTON_TEXTS = {
  toCreator: 'Upgrade to Creator Plan',
  toCreatorPro: 'Upgrade to Creator Pro Plan',
  toBusiness: 'Upgrade to Business Plan',
  contactUs: 'Contact us',
  choosePlan: 'Choose plan',
} as const;

export const BULLETS_HEADER_TEXT = {
  inStarter: 'Everything in Starter, plus:',
  inCreator: 'Everything in Creator, plus:',
  inCreatorPro: 'Everything in Creator Pro, plus:',
  withCreator: "With the Creator plan, you'll get:",
  withCreatorPro: "With the Creator Pro plan, you'll get:",
  withBusiness: "With the Business plan, you'll get:",
  inBusiness: 'Everything in Business, plus:',
} as const;
