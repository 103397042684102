import { IGetLatestPaymentInfoResponse } from '@/shared/api/billing/types';
import { getRange } from './getRange';

export const getLatestPaymentData = (payment: IGetLatestPaymentInfoResponse) => {
  const { items } = payment;

  const latestPaymentPeriod = items[0].item_name.split(' ').slice(-1)[0];
  const name = items[0].item_name.split(' ').slice(0, -1).join(' ') ?? '';
  const { quantity } = items[0];
  const range = getRange(quantity);

  return { latestPaymentPeriod, name, quantity, range };
};
