import { Control } from 'react-hook-form';
import { Stack } from '@mui/material';
import { BackIcon } from '@braskai/icons';
import { Button } from '@braskai/ui-kit';
import { Title } from '../../components/Title/styled';
import { FormValues, FormValuesOption, Option } from '../../types';
import { DATA_TEST_IDS } from '../../constants';
import { Select } from './components/Select';

type FormViewProps = {
  activeStep: number;
  controller: Option['controller'];
  control: Control<FormValues>;
  selectedValue: FormValuesOption;
  label: string;
  options: string[];
  shouldHideBackButton: boolean;
  isFormInvalid: boolean;
  isLoggingOut: boolean;

  resetTextField: () => void;
  onNextStep: () => void;
  onPreviousStep: () => void;
};

export const SelectFormView = ({
  activeStep,
  controller,
  control,
  selectedValue,
  label,
  options,
  shouldHideBackButton,
  isFormInvalid,
  isLoggingOut,

  resetTextField,
  onNextStep,
  onPreviousStep,
}: FormViewProps) => {
  const renderForm = () => {
    return (
      <Select
        key={activeStep}
        control={control}
        options={options}
        label={label}
        value={selectedValue}
        controller={controller}
        disabled={isLoggingOut}
        onResetTextField={resetTextField}
        data-test-id={DATA_TEST_IDS.chooseInput}
      />
    );
  };

  return (
    <>
      <Title>Tell us about yourself</Title>
      {renderForm()}

      <Stack gap={8} direction="row">
        {shouldHideBackButton ? null : (
          <Button
            size="xl"
            startIcon={
              <BackIcon
                style={{
                  opacity: isLoggingOut ? 0.5 : 1,
                }}
              />
            }
            variant="outlined"
            disabled={isLoggingOut}
            onClick={onPreviousStep}
          />
        )}

        <Button
          size="xl"
          fullWidth
          disabled={isLoggingOut || isFormInvalid}
          onClick={onNextStep}
          data-test-id={DATA_TEST_IDS.nextQuestionButton}
        >
          Next question
        </Button>
      </Stack>
    </>
  );
};
