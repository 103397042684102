import { ChangeEvent, useState } from 'react';
import { TChooseSpeakerModalProps } from '../types';

export const useChooseSpeakerModal = ({
  onClose,
  initialSpeakerId,
  onSubmit,
}: Pick<TChooseSpeakerModalProps, 'onClose' | 'onSubmit' | 'initialSpeakerId'>) => {
  const [chosenSpeakerId, setChosenSpeakerId] = useState(initialSpeakerId);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const id = e.target.value;

    if (id !== chosenSpeakerId) {
      setChosenSpeakerId(id);
    }
  };

  const handleSubmit = () => {
    if (chosenSpeakerId && onSubmit) {
      onSubmit(chosenSpeakerId);
    }
    onClose();
  };

  return {
    chosenSpeakerId,
    handleChange,
    handleSubmit,
  };
};
