import { createModalStore } from '@braskai/utils';
import { LimitedFeature } from '../lib/types';

type UseUpgradePlanModalProps = {
  limitedFeature?: LimitedFeature;
};

const initialState: UseUpgradePlanModalProps = {
  limitedFeature: undefined,
};

export const useUpgradePlanModalStore = createModalStore<UseUpgradePlanModalProps>(initialState, false);
