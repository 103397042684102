import { Box, styled, Typography } from '@mui/material';

export const Container = styled(Box)({
  width: '100%',
});

export const Label = styled(Typography)(({ theme }) => ({
  ...theme.typography.textSmMedium,
  color: theme.palette.grey[700],
  paddingBottom: 6,
  display: 'block',
}));
