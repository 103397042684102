import { styled, css, tooltipClasses, Stack, ButtonBase } from '@mui/material';
import { Tooltip, getTextStyles, squareBlockCSS } from '@braskai/ui-kit';

export const STooltip = styled(Tooltip)(
  ({ theme }) => css`
    z-index: ${theme.zIndex.popup};

    & .${tooltipClasses.tooltip} {
      width: 214px;
      padding: 0;
    }
  `,
);

export const STooltipContent = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px;
    text-align: start;

    > span {
      color: ${theme.palette.grey[900]};
      ${getTextStyles('textXsMedium')};
    }

    > p {
      color: ${theme.palette.grey[700]};
      margin: 0;
      padding: 0;
      ${getTextStyles('textXsRegular')}
    }

    ${theme.breakpoints.down('mobileLarge')} {
      padding: 8px;

      > span,
      p {
        ${getTextStyles('caption-2-md')}
      }
    }
  `,
);

export const SImage = styled('img')(
  () => css`
    border-radius: 8px 8px 0 0;
    width: 100%;
    height: auto;
    min-height: 122px;
  `,
);

export const SContent = styled(Stack)(
  () => css`
    position: relative;
  `,
);

export const SCloseButton = styled(ButtonBase)(
  ({ theme }) => css`
    ${squareBlockCSS(20)};
    position: absolute;
    top: 8px;
    right: 8px;
    border-radius: 24px;
    background-color: ${theme.palette.common.white};
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);

    ${theme.breakpoints.up('tablet')} {
      ${squareBlockCSS(24)};
    }
  `,
);
