import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '@/shared/api';
import { GetCustomersRequestData, UpdateCustomerRequestData } from '@/shared/api/admin/types';
import { sortByCreatedDate } from '@/shared/utils-tmp/sortByDate';
import { setCustomers, updateCustomerInCustomers } from '../AdminSlice';

export const getCustomers = createAsyncThunk(
  'admin/getCustomers',
  async (data: GetCustomersRequestData, { dispatch, rejectWithValue }) => {
    try {
      const customers = await API.getCustomers(data);
      const sortedCustomers = sortByCreatedDate(customers);
      dispatch(setCustomers(sortedCustomers));
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);
export const updateCustomer = createAsyncThunk(
  'admin/updateCustomer',
  async (data: Partial<UpdateCustomerRequestData>, { dispatch, rejectWithValue }) => {
    try {
      const updatedCustomer = await API.updateCustomer(data);
      dispatch(updateCustomerInCustomers(updatedCustomer));
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);
export const searchCustomer = createAsyncThunk(
  'admin/searchCustomer',
  async (email: string, { dispatch, rejectWithValue }) => {
    try {
      const searchResults = await API.searchCustomer(encodeURIComponent(email));
      const sortedCustomers = sortByCreatedDate(searchResults);
      dispatch(setCustomers(sortedCustomers));
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);
