import { useState } from 'react';
import { showErrorAlert } from '@/shared/utils-tmp/alert/showErrorAlert';
import { API } from '@/shared/api';
import { useAppDispatch, useAppSelector } from '@/shared/hooks-tmp/reduxToolkitHooks';
import { getUser } from '@/shared/store-tmp/user/thunks/getUser';
import { getSurveyDataForMixpanel } from '@/shared/utils-tmp/getSurveyDataForMixpanel';
import { MixpanelEvents } from '@/shared/thirdParty/mixpanel';
import { GTMEvents } from '@/shared/thirdParty/gtm';
import {
  useHubSpotEmbedForm,
  HUBSPOT_HIDDEN_EMBED_FORMS,
  HUBSPOT_HIDDEN_FORM_INPUTS,
} from '@/shared/thirdParty/hubSpot';
import { useCognitoCheckUser } from '@/entities/user';
import { FormValues } from '../types';

type UseSubmitForm = {
  onClose: () => void;
};

export const useSubmitForm = ({ onClose }: UseSubmitForm) => {
  const dispatch = useAppDispatch();

  const [isConsenting, setIsConsenting] = useState(false);
  const [isDeclining, setIsDeclining] = useState(false);

  const user = useAppSelector((state) => state.user);

  const { data } = useCognitoCheckUser();
  const cognitoId = data?.cognitoId || '';

  const { hiddenFormId, sendDataToHubSpot } = useHubSpotEmbedForm(HUBSPOT_HIDDEN_EMBED_FORMS.questionnaire);

  const startLoading = (isEmailConsent: boolean) => {
    return isEmailConsent ? setIsConsenting(true) : setIsDeclining(true);
  };

  const stopLoading = () => {
    setIsConsenting(false);
    setIsDeclining(false);
  };

  const sendSurvey = (formValues: FormValues) => {
    return API.postSurvey({
      id: '2',
      name: 'welcome_survey',
      survey_data: formValues,
    });
  };

  const updateUser = (isEmailConsent: boolean) => {
    return API.updateUser({ email_consent: isEmailConsent });
  };

  const sendAnalytics = async (formValues: FormValues, isEmailConsent: boolean) => {
    if (!user) {
      return;
    }

    const surveyDataForAnalytics = getSurveyDataForMixpanel(formValues);

    MixpanelEvents.registrationSurveyCompleted(surveyDataForAnalytics);
    GTMEvents.customerSurvey(surveyDataForAnalytics);

    await sendDataToHubSpot({
      [HUBSPOT_HIDDEN_FORM_INPUTS.role]: surveyDataForAnalytics.role,
      [HUBSPOT_HIDDEN_FORM_INPUTS.content]: surveyDataForAnalytics.content,
      [HUBSPOT_HIDDEN_FORM_INPUTS.referral]: surveyDataForAnalytics.referral,
      [HUBSPOT_HIDDEN_FORM_INPUTS.email]: user.email,
      [HUBSPOT_HIDDEN_FORM_INPUTS.legalConsent]: true,
      [HUBSPOT_HIDDEN_FORM_INPUTS.cognitoId]: cognitoId,
    });
  };

  const onSubmit = async (formValues: FormValues, isEmailConsent: boolean) => {
    try {
      startLoading(isEmailConsent);

      await Promise.all([sendSurvey(formValues), updateUser(isEmailConsent)]);
      await dispatch(getUser({}));
      await sendAnalytics(formValues, isEmailConsent);

      onClose();
    } catch (e) {
      showErrorAlert(e);
    } finally {
      stopLoading();
    }
  };

  return { onSubmit, hiddenFormId, isConsenting, isDeclining };
};
