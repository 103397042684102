import { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal } from '@/shared/types/Modal';
import { ModalInitialState } from '../types';

const INIT_STATE = {
  type: null,
};

export const useModalProvider = () => {
  const [modal, setModal] = useState<ModalInitialState>(INIT_STATE);

  const openModal = useCallback((payload: Modal) => {
    setModal(payload);
  }, []);

  const closeModal = useCallback(() => {
    setModal(INIT_STATE);
  }, []);

  useEffect(() => {
    window.onpopstate = () => {
      closeModal();
    };
  }, [closeModal]);

  return useMemo(
    () => ({
      ...modal,
      openModal,
      closeModal,
    }),
    [modal, openModal, closeModal],
  );
};
