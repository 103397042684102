import { isVariableInLS } from '@/shared/lib/utils';

export const HUBSPOT_TRACKING_CODE = import.meta.env.VITE_HUBSPOT_TRACKING_CODE;
export const IS_HUBSPOT_EMBED_FORMS_DEBUGGER_ENABLED = isVariableInLS('enableHubSpotEmbedFormsDebugger');

export const HUBSPOT_HIDDEN_FORM_INPUTS = {
  email: 'email',
  role: 'role',
  referral: 'how_did_you_hear_about_us',
  content: 'type_of_content',
  legalConsent: 'LEGAL_CONSENT\\.subscription_type_125520915',
  cognitoId: 'app_user_id',
} as const;

export const HUBSPOT_INPUT_ID_PREFIXES = Object.values(HUBSPOT_HIDDEN_FORM_INPUTS);

export const HUBSPOT_HIDDEN_EMBED_FORMS = {
  questionnaire: {
    formId: 'ba23e372-089f-4601-b94c-2664f6e83714',
    inputIdPrefixes: [...HUBSPOT_INPUT_ID_PREFIXES],
  },
};

export const HUBSPOT_EVENTS = {
  downloadVideo: `pe${HUBSPOT_TRACKING_CODE}_download_video_v2`,
  pricingPageShown: `pe${HUBSPOT_TRACKING_CODE}_pricing_page_shown`,
  editorApplyChanges: `pe${HUBSPOT_TRACKING_CODE}_apply_changes`,
  clickOnLockedFeature: `pe${HUBSPOT_TRACKING_CODE}_click_on_locked_feature`,
} as const;

export const HUBSPOT_VIDEO_FEEDBACK_EVENTS = {
  transcriptionProblem: `pe${HUBSPOT_TRACKING_CODE}_video_feedback_transcription_problem`,
  translationProblem: `pe${HUBSPOT_TRACKING_CODE}_video_feedback_translation_problem`,
  speakerSeparation: `pe${HUBSPOT_TRACKING_CODE}_video_feedback_speaker_separation_problem`,
  unsynchronization: `pe${HUBSPOT_TRACKING_CODE}_video_feedback_unsynchronization_problem`,
  roboticVoice: `pe${HUBSPOT_TRACKING_CODE}_video_feedback_robotic_voice_problem`,
  notClonedVoice: `pe${HUBSPOT_TRACKING_CODE}_video_feedback_not_cloned_voice_problem`,
  accent: `pe${HUBSPOT_TRACKING_CODE}_video_feedback_accent_problem`,
  backgroundNoise: `pe${HUBSPOT_TRACKING_CODE}_video_feedback_background_noise_problem`,
  negativeFeedback: `pe${HUBSPOT_TRACKING_CODE}_video_feedback_negative`,
} as const;
