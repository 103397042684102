import { ModalHeader } from '@braskai/ui-kit';
import { Stack } from '@mui/material';
import { ModalHeaderProps } from '../../lib/types';

const { Container, Subtitle, Title } = ModalHeader;

export const ModalHeaderSecondary = ({
  title,
  subtitle,
  maxLinesSubtitle,
  maxLinesTitle,
  ...props
}: ModalHeaderProps) => (
  <Container {...props}>
    <Stack gap={4}>
      <Title variant="title-3-sb" maxLines={maxLinesTitle} mr={40}>
        {title}
      </Title>
      {subtitle && (
        <Subtitle maxLines={maxLinesSubtitle} variant="body-1-md" color="grey.700">
          {subtitle}
        </Subtitle>
      )}
    </Stack>
  </Container>
);
