import { AnyAction, configureStore, createListenerMiddleware, isRejected } from '@reduxjs/toolkit';
import { BAD_REQUEST_STATUS_CODE, NOT_FOUND_STATUS_CODE, PAYMENT_REQUIRED_STATUS_CODE } from '@/shared/api/errors';
import { GlobalErrorType } from '@/shared/types/GlobalErrors';
import { showAlert } from './AlertSlice';
import { resettableRootReducer } from './RootReducer';
import { setGlobalError } from './GlobalErrorsSlice';

const listenerMiddleware = createListenerMiddleware();
listenerMiddleware.startListening({
  matcher: isRejected,
  effect: (action: AnyAction, listenerApi) => {
    const {
      payload: { status, data, statusText },
    } = action;

    const { dispatch } = listenerApi;
    if (status === BAD_REQUEST_STATUS_CODE && data.detail === 'Inactive user') {
      dispatch(setGlobalError({ type: GlobalErrorType.InactiveUser }));
      return;
    }
    if (status === PAYMENT_REQUIRED_STATUS_CODE && action.type !== 'project/startLipSync/rejected') {
      dispatch(setGlobalError({ type: GlobalErrorType.PaymentRequired }));
      return;
    }
    if (action.type === 'project/getProjectById/rejected' && status === NOT_FOUND_STATUS_CODE) {
      return;
    }

    // TODO: type needs to be corrected
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    dispatch(showAlert({ message: statusText || data?.detail }));
  },
});
export const store = configureStore({
  reducer: resettableRootReducer,
  devTools: import.meta.env.DEV,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(listenerMiddleware.middleware),
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
