import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { Typography } from '@mui/material';
import { useDeviceType } from '@braskai/ui-kit';
import { useStarterPlan } from '@/pages/PricingPage/components/PricingCards/components/StarterBannerCard/useStarterPlan';
import { PATHS } from '@/shared/routes/paths';
import { TERMS_OF_SERVICE_LINK } from '@/shared/lib/constants/docLinks';

import { MixpanelEvents } from '@/shared/thirdParty/mixpanel';
import { useExecuteOneTime } from '@/shared/hooks-tmp/useExecuteOneTime';
import { Dialog } from '../../../ui-tmp';
import {
  ActionsContainer,
  BulletsContainer,
  ChoosePlansButton,
  Content,
  Image,
  ImageContainer,
  SBullet,
  SContainer,
  Subtitle,
  TermsLink,
  Title,
} from './styled';
import { BULLETS } from './constants';

type TMobilePaywallModalProps = {
  onClose: () => void;
};

export const MobilePaywallModal = ({ onClose }: TMobilePaywallModalProps) => {
  const navigate = useNavigate();
  const { isLaptop } = useDeviceType();

  const { isStarterPlanAvailable } = useStarterPlan();

  const navigateToPricing = () => {
    MixpanelEvents.viewPlanPaywallClick();
    navigate(PATHS.pricing);
    onClose();
  };

  useExecuteOneTime(() => {
    MixpanelEvents.paywallOpened();
  });

  useEffect(() => {
    if (isLaptop) {
      onClose();
    }
  }, [isLaptop, onClose]);

  return (
    <Dialog open size="sm" onClose={onClose}>
      <SContainer>
        <ImageContainer>
          <Image />
        </ImageContainer>
        <Content>
          <Title>Subscribe to translate video with Rask AI</Title>
          {isStarterPlanAvailable ? (
            <Subtitle>Try it out for only $15 per month!</Subtitle>
          ) : (
            <Subtitle>You have 14 days to request a full refund, if you don’t love it!</Subtitle>
          )}
          <BulletsContainer>
            {/*TODO: remove feature flag after release */}
            {BULLETS.map(({ icon, title, isBeta }) => (
              <SBullet key={title}>
                {icon}
                <Typography variant="textSmRegular" color="grey.900">
                  {title}
                  {isBeta && (
                    <Typography variant="textSmRegular" color="grey.500">
                      {' beta'}
                    </Typography>
                  )}
                </Typography>
              </SBullet>
            ))}
          </BulletsContainer>
        </Content>

        <ActionsContainer>
          <ChoosePlansButton onClick={navigateToPricing}>Choose plan</ChoosePlansButton>
          <TermsLink href={TERMS_OF_SERVICE_LINK} target="_blank">
            Terms of use
          </TermsLink>
        </ActionsContainer>
      </SContainer>
    </Dialog>
  );
};
