import {
  aiRewriteEvents,
  apiEvents,
  feedbackEvents,
  lipSyncEvents,
  miscEvents,
  multilanguageEvents,
  onboardingEvents,
  paywallEvents,
  platformEvents,
  pricingEvents,
  signUpEvents,
  srtEditorEvents,
  srtUploadEvents,
  subscriptionEvents,
  subtitlesBurningEvents,
  uploadVideoEvents,
  videoPlayerEvents,
  surveyEvents,
  voiceEvents,
  audioDownloadEvents,
  srtDownloadEvents,
  humanReviewEvents,
} from './events';
import { glossaryEvents } from './events/glossaryEvents';
import { initMixpanel, setMixpanelUser, shutdownMixpanel, trackEvent } from './mixpanelMethods';

export { initMixpanel, setMixpanelUser, shutdownMixpanel };

export const EVENTS = [
  ...pricingEvents,
  ...paywallEvents,
  ...onboardingEvents,
  ...srtUploadEvents,
  ...apiEvents,
  ...aiRewriteEvents,
  ...multilanguageEvents,
  ...srtEditorEvents,
  ...subscriptionEvents,
  ...lipSyncEvents,
  ...surveyEvents,
  ...subtitlesBurningEvents,
  ...platformEvents,
  ...signUpEvents,
  ...uploadVideoEvents,
  ...videoPlayerEvents,
  ...feedbackEvents,
  ...miscEvents,
  ...voiceEvents,
  ...audioDownloadEvents,
  ...srtDownloadEvents,
  ...glossaryEvents,
  ...humanReviewEvents,
];

type EventNames = (typeof EVENTS)[number]['eventName'];

type EventPayload = {
  [key: string]: string | boolean | number;
};

type Event = {
  [key in EventNames]: (payload?: EventPayload) => void;
};

type MixpanelEvent = {
  eventName: EventNames;
  shouldSendRaskTTSData?: boolean;
  shouldSendSubscriptionData?: boolean;
};

export const getEvents = (events: MixpanelEvent[]) => {
  return events.reduce((acc, event) => {
    const { eventName, shouldSendRaskTTSData, shouldSendSubscriptionData } = event;

    return {
      ...acc,
      [eventName]: (properties?: EventPayload) =>
        trackEvent({
          eventName,
          metadata: { shouldSendRaskTTSData, shouldSendSubscriptionData },
          properties,
        }),
    };
  }, {} as Event);
};

export const MixpanelEvents = getEvents(EVENTS);
