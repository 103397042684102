export const DEFAULT_SUBSCRIPTION_STATE = {
  subscriptionStatus: null,
  hasTrialPlan: true,
  hasActiveSubscription: false,
  hasPausedSubscription: false,
  hasExpiredSubscription: false,
  hasFreeSubscription: false,
  hasCanceledSubscription: false,
  hasSubscriptionCancelAtPeriodEnd: false,
  hasUnpaidSubscription: false,
  hasPastDueSubscription: false,
  canBuyMinutes: false,
  hasMinutes: false,
  hasFreeVideos: false,
  hasFreeLipSyncMinutes: false,
  hasArchivedSubscription: false,
  availableMinutes: 0,
  availableFreeVideos: 0,
};

export const DEFAULT_ENTERPRISE_SUBSCRIPTION_STATE = {
  hasEnterprisePlan: false,
  hasActiveEnterprisePlan: false,
  hasTrialingEnterprisePlan: false,
};
