import { getMappedEvents } from '../utils/getMappedEvents';

const EVENTS_WITH_SUBSCRIPTION_DATA_AND_TTS = [
  'editVideoButtonPressed',
  'transcriptionPageShown',
  'trialPageViewed',
  'dubButtonPressed',
  'redubButtonPressed',
  'videoIsGenerated',
  'downloadTranscription',
  'downloadTranscriptionWithSpeakers',
  'downloadTranslation',
  'downloadTranslationWithSpeakers',
  'downloadAudio',
  'downloadAudioBackground',
  'downloadVideo',
  'downloadOriginalVideo',
  'rewriteTooltipShownTooManyChar',
  'rewriteTooltipShownLessChar',
  'rewriteButtonPressedMore',
  'applyRewrittenPressedAIRewrite',
  'rewriteButtonPressed',
  'newSegmentAdded',
  'textEditedSegmentTranscript',
  'textEditedSegmentTranslation',
  'segmentDeleted',
  'newSpeakerAdded',
  'clickMergeSegments',
  'timestampEdited',
  'speakersEdited',
  'speakersVoiceEdited',
  'advancedEditorTimestampChange',
  'advancedEditorOpened',
  'advancedEditorClosed',
  'advancedEditorSegmentDelete',
  'splitSegmentsModalShown',
  'clickSplitSegments',
  'srcSplitSegments',
  'dstSplitSegment',
] as const;

const EVENTS_WITH_SUBSCRIPTION_DATA = ['ttsDownloadVideo'] as const;

const srtEditorEventsWithSubscriptionDataAndTTS = getMappedEvents([...EVENTS_WITH_SUBSCRIPTION_DATA_AND_TTS], {
  shouldSendSubscriptionData: true,
  shouldSendRaskTTSData: true,
});

const srtEditorEventsWithSubscriptionData = getMappedEvents([...EVENTS_WITH_SUBSCRIPTION_DATA], {
  shouldSendSubscriptionData: true,
});

export const srtEditorEvents = [...srtEditorEventsWithSubscriptionDataAndTTS, ...srtEditorEventsWithSubscriptionData];
