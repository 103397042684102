import { BeginCheckoutEventData } from '@/shared/thirdParty/gtm/types';
import { TCredit } from '@/shared/types/Billing';
import { getPricePerMin } from './getPricePerMin';

type GetBuyMinutesBeginCheckoutEventData = {
  product: TCredit;
  price: number;
  totalMinutes: number;
};

export const getBuyMinutesBeginCheckoutEventData = ({
  price,
  product,
  totalMinutes,
}: GetBuyMinutesBeginCheckoutEventData): BeginCheckoutEventData => {
  const { currency } = product.prices[0];
  return {
    ecommerce: {
      value: price,
      currency: currency.toUpperCase(),
      items: [
        {
          item_id: product.id,
          item_name: 'Minutes',
          price: getPricePerMin(product),
          quantity: totalMinutes,
        },
      ],
    },
  };
};
