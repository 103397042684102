import mixpanel from 'mixpanel-browser';
import { getDeviceType } from '@/shared/lib/utils';
import { IS_MIXPANEL_DEV_MODE_ENABLED, IS_PRODUCTION_BUILD } from '@/shared/lib/environments';
import { User } from '@/shared/types/User';
import { IS_MIXPANEL_DEBUGGER_ENABLED } from './constants';
import { getMixpanelProjectData } from './utils/getMixpanelProjectData';
import { getMixpanelSubscriptionData } from './utils/getMixpanelSubscriptionData';
import { getEmailType } from './utils/getEmailType';

const MIXPANEL_TOKEN = import.meta.env.VITE_MIXPANEL_TOKEN as string;

// TODO move to abstraction slice [hash234123]
const MIXPANEL_ENABLED = !!MIXPANEL_TOKEN && (IS_PRODUCTION_BUILD || IS_MIXPANEL_DEV_MODE_ENABLED);

const deviceType = getDeviceType();

export const initMixpanel = () => {
  if (MIXPANEL_ENABLED) {
    mixpanel.init(MIXPANEL_TOKEN || '', { debug: false });
    mixpanel.register({ deviceType });
  }
};

export const setMixpanelUser = (id: User['id'], email: User['email'], emailType: User['email_type']) => {
  if (MIXPANEL_ENABLED) {
    const distinctId: unknown = mixpanel.get_distinct_id();
    const mixpanelEmail: unknown = mixpanel.get_property('email');

    // Если уже установлен актуальный id, то ничего делать не надо
    if (distinctId !== id) {
      mixpanel.identify(id);
    }

    if (!mixpanelEmail || mixpanelEmail !== email) {
      mixpanel.people.set({ $email: email, emailType });
    }

    mixpanel.register({ email, emailType });
  }
};

export const shutdownMixpanel = () => {
  if (MIXPANEL_ENABLED) {
    mixpanel.reset();
  }
};

type EventPayload = {
  eventName: string;
  properties?: object;
  metadata?: {
    shouldSendRaskTTSData?: boolean;
    shouldSendSubscriptionData?: boolean;
  };
};

export const trackEvent = ({ eventName, properties, metadata }: EventPayload) => {
  const emailType = getEmailType();

  const subscriptionData = metadata?.shouldSendSubscriptionData ? getMixpanelSubscriptionData() : {};
  const projectData = getMixpanelProjectData();

  const projectDataPayload = {
    ...(metadata?.shouldSendRaskTTSData && { raskTTS: Boolean(projectData?.raskTTS) }),
    ...(projectData?.projectId && { locProjectId: projectData.projectId }),
    ...(projectData?.dictionary && { dictionary: projectData.dictionary }),
  };

  const eventProperties = { ...subscriptionData, ...projectDataPayload, ...properties };

  if (MIXPANEL_ENABLED) {
    mixpanel.track(eventName, eventProperties);
  }

  if (IS_MIXPANEL_DEBUGGER_ENABLED) {
    console.info('%cMixpanel debugger event:', 'color: #7F56D9');
    console.table({ eventName, deviceType, emailType, ...eventProperties });
  }
};
