import { css, Stack, styled } from '@mui/material';
import { CheckCircleIcon } from '@braskai/icons';

export const SContainer = styled(Stack)(
  () => css`
    flex-direction: row;
    align-items: center;
    gap: 8px;
  `,
);

export const SCheckCircleIcon = styled(CheckCircleIcon)(
  ({ theme }) => css`
    color: ${theme.palette.primary[600]};
  `,
);
