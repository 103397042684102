import { Products } from '@/shared/types/Billing';
import { User } from '@/shared/types/User';

export const getPlanName = ({
  user,
  products,
  hasFreeSubscription,
  hasTrialPlan,
}: {
  user: User | null;
  products?: Products;
  hasFreeSubscription: boolean;
  hasTrialPlan: boolean;
}) => {
  const productName = products?.plan.find((plan) => plan.id === user?.subscription?.plan.product_id)?.name ?? '';

  if (!productName) {
    return hasFreeSubscription || hasTrialPlan ? 'Free Plan' : 'Archive Plan';
  }

  return productName;
};
