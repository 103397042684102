import { useEffect, useState } from 'react';
import { useGetAppSettings } from '@/entities/user/settings';
import { IS_MAINTENANCE_DISABLED } from '../config';

export const useMaintenance = () => {
  const [isMaintenance, setIsMaintenance] = useState(false);

  const { isPending, data } = useGetAppSettings();

  useEffect(() => {
    if (data) {
      if (data?.features?.maintenance && !IS_MAINTENANCE_DISABLED) {
        setIsMaintenance(true);
      }
    }
  }, [data]);

  return {
    isMaintenance,
    isLoading: isPending,
  };
};
