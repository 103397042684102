import { getTextStyles, styledOmitCustomProps } from '@braskai/ui-kit';
import {
  ListItem,
  ListItemButton,
  listItemIconClasses,
  listItemTextClasses,
  styled,
  css,
  ListItemText,
} from '@mui/material';

export const SListItem = styled(ListItem)(
  () => css`
    margin-top: 4px;
    margin-bottom: 4px;
  `,
);

type SListItemButtonProps = {
  $isMobile: boolean;
  $isDisabled: boolean;
};

export const SListItemButton = styled(
  ListItemButton,
  styledOmitCustomProps,
)<SListItemButtonProps>(
  ({ theme, $isMobile, $isDisabled }) => css`
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    padding: 12px;
    height: 44px;
    color: ${theme.palette.grey[500]};

    ${$isMobile &&
    css`
      color: ${theme.palette.grey[900]};
      border: 1px solid ${theme.palette.grey[200]};
    `}

    &.Mui-selected {
      color: ${theme.palette.grey[900]};
      background-color: ${theme.palette.grey[50]};
    }

    &:hover,
    &.Mui-selected:hover {
      background-color: ${theme.palette.grey[25]};
    }

    & > .${listItemIconClasses.root} {
      color: currentColor;
      min-width: 20px;
    }

    & > .${listItemTextClasses.root} > .${listItemTextClasses.primary} {
      color: currentColor;
      overflow: hidden;
      white-space: nowrap;
      ${getTextStyles('textSmMedium')}
    }

    ${$isDisabled &&
    css`
      color: ${theme.palette.grey[300]};
      pointer-events: none;
    `}
  `,
);

export const SListItemText = styled(ListItemText)(
  () => css`
    ${getTextStyles('body-2-md')}
    display: flex;
    justify-content: space-between;

    .${listItemTextClasses.secondary} {
      color: currentColor;
    }
  `,
);
