import { Stack, styled } from '@mui/material';
import { PropsWithChildren } from 'react';
import { AlertCircleIcon } from '@braskai/icons';
import { SettingsFeatureWarning } from '@/shared/types/Settings';

type TBannerProps = {
  color: SettingsFeatureWarning['severity'];
};

export const Banner = styled(({ color, ...props }: PropsWithChildren<TBannerProps>) => <Stack {...props} />)(
  ({ theme, color }) => ({
    ...theme.typography.textSmRegular,
    flexDirection: 'row',
    alignItems: 'center',
    padding: '12px 26px',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    backgroundColor: theme.palette[color][100],
  }),
);

export const AlertIcon = styled((props) => <AlertCircleIcon {...props} />)(({ theme }) => ({
  width: 22,
  height: 22,
  marginRight: 10,
  flexShrink: 0,

  '& path': {
    stroke: theme.palette.warning[600],
  },
}));
