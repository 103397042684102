import { Dialog } from '../../../ui-tmp';
import { useMultilanguage } from './hooks/useMultilanguage';
import { MultilanguageForm } from './views/MultilanguageForm';
import { SuccessMessage } from './views/SuccessMessage';

type IMultilanguageModalProps = {
  onClose: () => void;
};

export const MultilanguageModal = ({ onClose }: IMultilanguageModalProps) => {
  const { control, languages, isSubmitting, isSuccess, isFormInvalid, handleSubmit, openCopiedProject } =
    useMultilanguage(onClose);

  if (!languages) {
    return null;
  }

  return (
    <Dialog open onClose={isSubmitting ? undefined : onClose} size="sm">
      {isSuccess ? (
        <SuccessMessage onOpenCopiedProject={openCopiedProject} onClose={onClose} />
      ) : (
        <MultilanguageForm
          isSubmitting={isSubmitting}
          languages={languages}
          onClose={onClose}
          onSubmit={handleSubmit}
          control={control}
          isFormInvalid={isFormInvalid}
        />
      )}
    </Dialog>
  );
};
