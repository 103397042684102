import { createModalStore } from '@braskai/utils';

type DeleteGlossaryModalState = {
  glossaryId: string;
};

const initialState: DeleteGlossaryModalState = {
  glossaryId: '',
};

export const useDeleteGlossaryModalStore = createModalStore(initialState);
