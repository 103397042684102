import { TAccountsRegisterRequest, TAccountsState } from '@/shared/store-tmp/accounts/types';

import { del, get, post } from '../lib/api';
import { ACCOUNTS_API_URL } from './constants';

const ACCOUNTS_URL = `${ACCOUNTS_API_URL}/users`;
export const CURRENT_ACCOUNT_URL = `${ACCOUNTS_URL}/current`;

export const ACCOUNTS_API = {
  getAccount: async () => get<TAccountsState>(CURRENT_ACCOUNT_URL),
  createAccount: async (data: TAccountsRegisterRequest) => post<TAccountsState>(ACCOUNTS_URL, { data }),
  deleteAccount: async () => del<void>(CURRENT_ACCOUNT_URL),
};
