import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '@/shared/api';
import { TServerSegment } from '@/shared/types/Editor';
import { Project } from '@/shared/types/Project';
import { removeSegment, recalculateOverlappedTimestamps } from '../EditorSlice';

type TDeleteSegment = {
  projectId: Project['id'];
  segmentId: TServerSegment['id'];
};

export const deleteSegment = createAsyncThunk(
  'editor/deleteSegment',
  async ({ projectId, segmentId }: TDeleteSegment, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.deleteSegment(projectId, segmentId);

      if (!response.ok) {
        throw new Error();
      }

      dispatch(removeSegment({ id: segmentId }));

      dispatch(recalculateOverlappedTimestamps());
    } catch (e) {
      return rejectWithValue({ ...(e as Error), statusText: 'Something went wrong' });
    }
  },
);
