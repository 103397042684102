import { Status } from '@/shared/types/Status';

// При статусах voiceSuggestFailed и voiceUpdateFailed бэк подставляет рандомный голос спикеру, для которого голос не опознался
// На данный момент эти статусы на фронте не нужно считать ошибочными
export const ALL_ERROR_STATUSES = [
  Status.failed,
  Status.uploadFailed,
  Status.separateBackgroundFailed,
  Status.transcribeSegmentsFailed,
  Status.determineSpeakersFailed,
  Status.transcriptionFailed,
  Status.translationFailed,
  Status.voiceoverFailed,
  // см коммент в начале файла
  // Status.voiceSuggestFailed,
  // Status.voiceUpdateFailed,
  Status.mergingFailed,
  Status.noAudio,
  Status.noWords,
  Status.forbiddenLink,
];

export const FATAL_ERROR_STATUSES = [
  Status.failed,
  Status.uploadFailed,
  Status.separateBackgroundFailed,
  Status.transcribeSegmentsFailed,
  Status.determineSpeakersFailed,
  Status.transcriptionFailed,
  Status.translationFailed,

  Status.noAudio,
  Status.noWords,
  Status.forbiddenLink,
];

export const DUBBING_ERROR_STATUSES = [Status.voiceoverFailed, Status.mergingFailed];

export const NOT_UPDATING_STATUSES = [
  Status.mergingDone,
  Status.translationDone,
  Status.transcriptEdited,
  Status.voiceEdited,
  Status.voiceUpdateDone,
  Status.voiceUpdateFailed,
  ...ALL_ERROR_STATUSES,
];
export const DISABLE_VIDEO_DOWNLOAD_STATUSES = [
  Status.voiceoverStarted,
  Status.voiceoverDone,
  Status.mergingStarted,
  Status.voiceoverFailed,
  Status.mergingFailed,
];

export const DUBBING_STATUSES = [Status.voiceoverStarted, Status.voiceoverDone, Status.mergingStarted];

export const NOT_UPDATING_STATUSES_FOR_AUTOGENERATION = [
  Status.transcriptEdited,
  Status.mergingDone,
  ...ALL_ERROR_STATUSES,
];

// Srt src project processing statuses
export const SRC_SRT_PROJECT_PROCESSING_STATUSES = [
  Status.created,
  Status.uploading,
  Status.uploaded,
  Status.separateBackgroundStarted,
  Status.separateBackgroundDone,
  Status.determineSpeakersStarted,
  Status.determineSpeakersDone,
  Status.voiceSuggestStarted,
  Status.voiceSuggestDone,
  // см коммент в начале файла
  Status.voiceSuggestFailed,
  Status.translationStarted,
];

// Srt dst project processing statuses
export const DST_SRT_PROJECT_PROCESSING_STATUSES = [
  Status.created,
  Status.uploading,
  Status.uploaded,
  Status.separateBackgroundStarted,
  Status.separateBackgroundDone,
  Status.transcribeSegmentsStarted,
  Status.transcribeSegmentsDone,
  Status.determineSpeakersStarted,
  Status.determineSpeakersDone,
  Status.voiceSuggestStarted,
];

// Statuses when segments are not ready to download
export const PROJECT_PROCESSING_STATUSES = [
  Status.created,
  Status.uploading,
  Status.uploaded,
  Status.transcriptionStarted,
  Status.transcriptionDone,
  Status.voiceSuggestStarted,
  Status.voiceSuggestDone,
  // см коммент в начале файла
  Status.voiceSuggestFailed,
  Status.translationStarted,
];
