import { useEffect } from 'react';
import { useHubSpotChatStore } from './useHubSpotChatStore';

export const useInitHubSpotChat = () => {
  const onWidgetReady = useHubSpotChatStore((state) => state.onWidgetReady);

  useEffect(() => {
    const initHubSpot = () => {
      if (window.HubSpotConversations) {
        window.HubSpotConversations.on('widgetLoaded', onWidgetReady);
      }
    };

    if (window.HubSpotConversations) {
      initHubSpot();
    } else {
      window.hsConversationsOnReady = [...(window.hsConversationsOnReady || []), initHubSpot];
    }

    return () => {
      if (window.HubSpotConversations) {
        window.HubSpotConversations.off('widgetLoaded', onWidgetReady);
      }
    };
  }, [onWidgetReady]);
};
